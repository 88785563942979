import images from 'assets/images'
import { isWindow } from 'common/constants'
import { Icon } from 'components/Icon'
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import './style.scss'
import cn from 'clsx'

const Pagination = ({ className, totalPage, handleSelectedPage, forcePage = 0 }) => {
  const [widthScreen, setWidthScreen] = useState(isWindow && window.innerWidth)

  const handleResizeWidth = () => {
    setWidthScreen(window.innerWidth)
  }

  useEffect(() => {
    isWindow && window.addEventListener('resize', handleResizeWidth)
  }, [])

  return (
    <div className={cn('pagination-wr', className)}>
      <ReactPaginate
        pageClassName="pagination-page"
        activeClassName="pagination-page--active"
        onPageChange={handleSelectedPage}
        previousLabel={
          <div className="icon-box icon-box--prev">
            <Icon className="icon-arrow" name="web_arrow_left"/>
          </div>
        }
        nextLabel={
          <div className="icon-box icon-box--next">
            <Icon className="icon-arrow" name="web_arrow_right"/>
          </div>
        }
        pageCount={totalPage}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        forcePage={forcePage}
      />
    </div>
  )
}

export default Pagination
