import { useWallet } from '@coin98-com/wallet-adapter-react'
import { ComputeBudgetProgram, PublicKey } from '@solana/web3.js'
import { encodeMessErr, genConnectionSolana } from 'common/solana'
import { store } from 'controller/redux/store/configureStore'
import get from 'lodash/get'

const useServices = () => {
  const { sendTransaction, signTransaction } = useWallet()

  const signTrans = async (transactions) => {
    const connection = genConnectionSolana()
    const { accountSol } = store.getState()
    const publicKey = new PublicKey(accountSol)
    transactions.feePayer = publicKey
    transactions.recentBlockhash = (
      await connection.getRecentBlockhash('max')
    ).blockhash

    const resSig = await signTransaction(transactions)
    console.log('🚀 ~ signTrans ~ resSig:', resSig)
  }

  const sendRawTxn = async ({
    transactions,
    isWaitDone,
    callBackNotWaitDone,
    callBack,
    signers,
    commitment = 'confirmed' // processed, confirmed, finalized
  }) => {
    const connection = genConnectionSolana()
    const { accountSol } = store.getState()
    const publicKey = new PublicKey(accountSol)
    transactions.feePayer = publicKey
    transactions.recentBlockhash = (
      await connection.getRecentBlockhash('max')
    ).blockhash

    // estimate gas

    const UNIT_DEFAULT = 200_000
    const FEE_DEFAULT = 100000

    const getPriority = await connection.getRecentPrioritizationFees().catch(() => [])
    const currentFee = getPriority
      .filter((fee) => fee?.prioritizationFee > 0)
      .map((fee) => fee?.prioritizationFee)

    const simulate = await connection.simulateTransaction(transactions)
    const budgetUnitEst = get(simulate, 'value.unitsConsumed', UNIT_DEFAULT)
    const feePriority = Math.max(...currentFee) * window.walletServices.getMultiplyFeePriority()

    let transactionFeePriorityInstruction = null
    let budgetUnitInstruction = null
    let totalUnit = UNIT_DEFAULT

    // set fee priority
    if (!feePriority || feePriority <= FEE_DEFAULT) {
      transactionFeePriorityInstruction =
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: FEE_DEFAULT
        })
    } else {
      transactionFeePriorityInstruction =
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: feePriority
        })
    }

    // set budget unit
    if (budgetUnitEst <= UNIT_DEFAULT) {
      budgetUnitInstruction = ComputeBudgetProgram.setComputeUnitLimit({
        units: UNIT_DEFAULT
      })
    } else {
      // example:
      // budgetUnitEst = 285_000
      // unitCalculate = 285_000 - 200_000 = 85_000
      // result = 85_000 + 200_000 + 1_000 = 286_000
      const UNIT_BUFFER = 1_000
      const unitCalculate = budgetUnitEst - UNIT_DEFAULT
      totalUnit = unitCalculate + UNIT_DEFAULT + UNIT_BUFFER
      budgetUnitInstruction = ComputeBudgetProgram.setComputeUnitLimit({
        units: totalUnit
      })
    }

    transactions
      .add(budgetUnitInstruction)
      .add(transactionFeePriorityInstruction)

    // const estFee = await transactions.getEstimatedFee(connection)

    // send

    const resSend = await sendTransaction(transactions, connection, {
      preflightCommitment: 'finalized',
      skipPreflight: false,
      signers
    })
    resSend.error = resSend?.error ? encodeMessErr(resSend.error) : ''

    const { data, error, isError } = resSend

    !isError && callBackNotWaitDone && callBackNotWaitDone(resSend)

    if (!isWaitDone || isError) {
      callBack && callBack(resSend)
      return resSend
    }

    await connection.onSignatureWithOptions(
      resSend.data,
      async () => {
        callBack && callBack(resSend)
      },
      {
        commitment: commitment
      }
    )

    return resSend // { data, error, isError }
  }

  return {
    sendRawTxn,
    signTrans
  }
}

export default useServices
