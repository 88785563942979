import useScreenWidth from 'hooks/useScreenWidth'
import React, { useEffect } from 'react'
const VideoContainer = ({ className, src, type = 'video/webm', autoPlay = true }) => {
  useEffect(() => {
    const el = document.getElementById('video-container')
    if (el) {
      el.addEventListener('contextmenu', (e) => {
        e.preventDefault()
      })
    }
  }, [])

  return (
    <video id="video-container" className={className} loop muted autoPlay={autoPlay}>
      <source
        src={src}
        type={type}
      />
    </video>

  )
}

export default VideoContainer
