/* eslint-disable multiline-ternary */
import images from 'assets/images'
import {
  formatNumberBro,
  formatPrice,
  getLength,
  nFormatter,
  upperCase
} from 'common/functions'
import Badge from 'components/Badge'
import PuffLoading from 'components/common/PuffLoading'
import { get } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts'
import './style.scss'
import cn from 'clsx'
import { Icon } from 'components/Icon'
import ReduxServices from 'common/redux'
import { useSelector } from 'react-redux'
import { CURRENCY_SYMBOL } from 'common/constants'

const RechartLine = ({
  dataChart,
  width = '100%',
  height = 300,
  isHideInfoValue,
  title,
  isLoading,
  isSwapChart,
  handleExpand,
  isExpandChart,
  fromCoin,
  coinInfo,
  detailCgk,
  handleChooseTime,
  timeChoose,
  renderRight,
  isVolumeChart
}) => {
  const { t } = useTranslation()
  const [dataHead, setDataHead] = useState({
    date: new Date(),
    number: 0
  })

  const [viewChoose, setViewChoose] = useState('default')

  const currencyRedux = useSelector(state => state.currencyRedux) // needed to rerender onChange Currency

  const priceChange = parseFloat(
    get(detailCgk, 'market_data.price_change_24h_in_currency.usd', 0)
  )

  const isPriceChangeUp = priceChange > 0
  let currentPrice = window.walletServices.findCoinGeckoPrice(coinInfo?.id)

  const arrTimes = [
    {
      name: '24h',
      value: '1D'
    },
    {
      name: '1W',
      value: '1W'
    },
    {
      name: '1M',
      value: '1M'
    },
    {
      name: '1Y',
      value: '1Y'
    }
  ]

  useEffect(() => {
    if (!dataChart.length) return
    const newData = { ...dataChart[dataChart.length - 1] }

    if (currentPrice) {
      newData.number = currentPrice
    } else currentPrice = dataChart[dataChart.length - 1].number

    setDataHead(newData)
  }, [dataChart])

  useEffect(() => {
    if (isSwapChart) {
      setDataHead({
        date: new Date(),
        number: currentPrice
      })
    }
  }, [detailCgk])

  const tickFormatter = (data) => {
    if (timeChoose === '1D') {
      return moment.unix(data).format('H:mm')
    }
    return moment.unix(data).format('MMM DD')
  }

  const tickFormatterYAxis = useCallback(data => {
    // return '$' + nFormatter(data)
    return ReduxServices.formatPrice(data, true, data < 1 ? 2 : 1)
  }, [])

  const onMouseOver = (data) => {
    setDataHead({
      ...data
    })
  }

  const formatDate = (date) => {
    return typeof date === 'number' ? moment.unix(date).format('LL') : moment(date).format('LL')
  }

  const renderPrice = () => {
    if (isSwapChart) {
      // formatPrice(get(dataHead, 'number', 0))
      return ReduxServices.formatPrice(get(dataHead, 'number', 0), true)
    }
    // return '$' + nFormatter(get(dataHead, 'number', 0), 2)
    return ReduxServices.formatPrice(get(dataHead, 'number', 0), true)
  }

  const onMouseOut = () => {
    const lengthDataChart = getLength(dataChart)

    if (lengthDataChart > 0) {
      const data = dataChart[lengthDataChart - 1]
      if (isSwapChart) {
        data.number = get(detailCgk, 'market_data.current_price.usd', 0)
      }

      setDataHead({
        ...data
      })
    }
  }

  const handleChooseView = (value) => () => {
    setViewChoose(value)
  }

  const tickCount = useMemo(() => {
    switch (timeChoose) {
    case '1D':
      return 10
    case '1W':
      return 8
    case '1M':
      return 7
    default:
      return 5
    }
  }, [timeChoose])

  const renderChart = useMemo(() => {
    if (isLoading) {
      return (
        <div className="loading-chart">
          <PuffLoading />
        </div>
      )
    } else {
      return dataChart.length ? (
        <ResponsiveContainer width="95%" height="100%">
          <AreaChart
            width={500}
            height={300}
            data={dataChart}
            margin={{
              top: 20,
              right: -40,
              left: 0,
              bottom: 60
            }}
            onMouseLeave={() => {
              onMouseOut()
            }}
          >
            <defs>
              <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={'#805dfd33'} stopOpacity={1} />
                <stop offset="100%" stopColor={'#1b1b1b00'} stopOpacity={1} />
              </linearGradient>

              <linearGradient id="gradientVolume" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={'#c5ffdc'} stopOpacity={1} />
                <stop
                  offset="50%"
                  stopColor={'rgba(254, 255, 254, 0.17)'}
                  stopOpacity={1}
                />
                <stop
                  offset="100%"
                  stopColor={'rgba(254, 254, 254, 0)'}
                  stopOpacity={1}
                />
              </linearGradient>
            </defs>
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis
              tickFormatter={tickFormatter}
              dataKey="date"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 14 }}
              type="number" // unless typeof each tick is 'number' ex: 1519482900000 (timestamp), chart is null
              tickCount={tickCount}
              interval="preserveStartEnd"
              domain={['auto', 'auto']}
              dy={15}
            />
            <YAxis
              tickFormatter={tickFormatterYAxis}
              orientation="right"
              axisLine={false}
              tickLine={false}
              scale="linear"
              domain={['auto', 'auto']}
              allowDataOverflow={true}
              tick={{ fontSize: 14 }}
              dx={isSwapChart ? -2 : -5 }

            />
            <Tooltip
              wrapperStyle={{ display: 'none' }}
              cursor={{ stroke: '#5f3cd8', strokeWidth: 1 }}
              formatter={(tooltipValue, name, props) => {
                onMouseOver(get(props, 'payload', {}))
              }}
            />
            <Area
              // onMouseMove={onMouseOver}
              onMouseLeave={onMouseOut}
              type="monotone"
              dataKey="number"
              stroke={isVolumeChart ? '#89e2cb' : '#7f5dfd'}
              strokeWidth={1.5}
              fill={isVolumeChart ? 'url(#gradientVolume)' : 'url(#gradient)'}
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <div className="empty-chart">
          {/* <img
            src={images.iconEmptyTable}
            alt=""
            className="empty-chart__icon"
          /> */}
          <Icon name="web_empty" className="color-theme" type="lg"/>
          {t('emptyData')}
        </div>
      )
    }
  }, [isLoading, dataChart, detailCgk, isVolumeChart])

  const renderTimeSwap = () => {
    return arrTimes.map((item, i) => {
      return (
        <div
          className={`item-time ${
            timeChoose === get(item, 'value') && 'item-time--active'
          }`}
          key={i}
          onClick={handleChooseTime(get(item, 'value'))}
        >
          {get(item, 'name')}
        </div>
      )
    })
  }

  return (
    <React.Fragment>
      <div className="info-value">
        <div className="info-value-left">
          {title && <div className="info-value__title">{title}</div>}

          {isSwapChart && (
            <div className="info-value__title-swap">
              <div className="title-swap-left">
                <div className="icon-tokens">
                  <img
                    src={get(coinInfo, 'icon')}
                    className="icon-token-swap-chart"
                    alt=""
                  />
                </div>
                {upperCase(get(coinInfo, 'symbol'))}
              </div>
            </div>
          )}

          {
            !isHideInfoValue &&
            <>
              <div
                className={`info-value__usd ${
                  isSwapChart && 'info-value__usd--swap'
                }`}
              >
                <div className="usd-swap--left">
                  {renderPrice()}

                  {isSwapChart && (
                    <>
                      {/* <div className="token-symbol">
                    {upperCase(get(coinInfo, 'symbol'))}
                  </div> */}

                      <Badge className="ml-3" variant={isPriceChangeUp ? 'success' : 'danger'}>
                        {priceChange === 0 ? '' : isPriceChangeUp ? '+' : ''}
                        {formatNumberBro({ number: priceChange, mantissa: 2 })}%
                      </Badge>
                    </>
                  )}
                </div>
              </div>

              <div className="info-value__date">
                {formatDate(get(dataHead, 'date'))}
              </div>
            </>
          }

        </div>

        <div className={cn('d-flex flex-1 justify-content-end align-items-start', !renderRight && 'd-none')}>
          {renderRight}
        </div>
      </div>

      {renderChart}
    </React.Fragment>
  )
}

export default RechartLine
