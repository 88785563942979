import TokenImage from 'components/common/TokenImage'
import React from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
import { get } from 'lodash'
import { formatNumberBro, upperCase } from 'common/functions'
import { useTranslation } from 'react-i18next'
import ReduxServices from 'common/redux'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'

const BoxStatistical = ({ label, data = [], type = 'token', isLoading }) => {
  const { t } = useTranslation()
  const currencyRedux = useSelector(state => state.currencyRedux)

  const renderRightCol = (item = {}) => {
    if (label === 'topVolume24h') {
      return ReduxServices.formatPrice(item.volume24h)
    }
    if (label === 'trending') {
      return ReduxServices.formatPrice(item.volume24h)
    }
    if (label === 'recentlyAdded') {
      return ReduxServices.formatPrice(item.volume24h)
    }
    if (label === 'gainer') {
      const isPositive = parseFloat(item.price_change_percentage_24h) > 0
      return (
        <span className={isPositive ? 'color-success' : 'color-danger'}>
          {isPositive ? '+' : '-'}{formatNumberBro({ number: item.price_change_percentage_24h, mantissa: 2 })}%
        </span>
      )
    }
    return ReduxServices.formatPrice(item.liquidity)
  }
  const renderListBox = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton className="skeleton-main-image" />
          <Skeleton className="skeleton-main-image" />
          <Skeleton className="skeleton-main-image" />
        </>
      )
    }
    if (type === 'pool') {
      return data.map((item, id) => {
        const { token0, token1 } = get(item, 'info', {})
        return (
          <div key={id} className='token-line'>
            <Link to={`/pool/${get(item, 'poolAddress')}`}>
              <a className='d-flex align-items-center uppercase cursor-pointer'>
                <TokenImage src={get(token0, 'icon')} alt="" className="token-img token-img--token-1 img-24 mr-2" />
                <TokenImage src={get(token1, 'icon')} alt="" className="token-img token-img--token-2 img-24 mr-2" />
                {get(token0, 'symbol')}/{get(token1, 'symbol')}
              </a>
            </Link>

            <div>{renderRightCol(item)}</div>
          </div>
        )
      })
    }
    return data.map((item, id) => {
      const info = get(item, 'info', {})
      return (
        <div key={id} className='token-line'>
          <Link to={`/token/${item.address}`}>
            <a className='d-flex align-items-center'>
              <TokenImage className="img-24 mr-2" src={info.icon}/>
              <div>{info.name}</div>
              <div className='color-grey ml-2'>{upperCase(info.symbol)}</div>
            </a>
          </Link>

          <div>{renderRightCol(item)}</div>
        </div>
      )
    })
  }
  return (
    <div className={cn('box-wrapper', styles.boxStatistical)}>
      <div className='capitalize'>{t(label)}</div>

      {renderListBox()}
      {/* <div className='token-line'>
        <div className='d-flex align-items-center'>
          <TokenImage className="img-24 mr-1" src=""/>
          <div>Coin98</div>
          <div className='color-grey ml-2'>C98</div>
        </div>
        <div>$2.22</div>
      </div>

      <div className='token-line'>
        <div className='d-flex align-items-center'>
          <TokenImage className="img-24 mr-1" src=""/>
          <div>Coin98</div>
          <div className='color-grey ml-2'>C98</div>
        </div>
        <div>$2.22</div>
      </div>

      <div className='token-line'>
        <div className='d-flex align-items-center'>
          <TokenImage className="img-24 mr-1" src=""/>
          <div>Coin98</div>
          <div className='color-grey ml-2'>C98</div>
        </div>
        <div>$2.22</div>
      </div> */}

    </div>
  )
}

export default BoxStatistical
