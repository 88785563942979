/* eslint-disable multiline-ternary */
import Button from 'components/common/Button'
import React, { useEffect, useMemo, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import images from 'assets/images'
import cn from 'clsx'
import styles from './style.module.scss'
import { useSelector } from 'react-redux'
import { Icon } from 'components/Icon'
import { useTranslation } from 'react-i18next'
const ModalContent = ({
  content,
  noButton,
  type,
  onCancelPopup,
  onOk,
  okText,
  isDisable,
  noIcon,
  isFullHeight
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const onOkClick = async () => {
    setIsLoading(true)
    await onOk()
    setIsLoading(false)
  }

  const renderButton = () => {
    if (noButton) return null
    switch (type) {
    case 'success':
      return (
        <Button
          isFullWidth
          className="button-item button-item--singe"
          onClick={onCancelPopup}
        >
          {okText || 'OK'}
        </Button>
      )
    case 'save-info':
      return (
        <Button
          isFullWidth
          className="button-item button-item--singe"
          onClick={onOk}
        >
          {okText || 'OK'}
        </Button>
      )
    case 'error':
      return (
        <Button
          className="button-item button-item--singe"
          onClick={onCancelPopup}
        >
          {okText || 'OK'}
        </Button>
      )

    case 'warning':
    case 'confirm':
    case 'connect':
      return (
        <div className="button-list">
          <Button
            type="grey"
            className="button-item"
            disabled={isLoading}
            onClick={onCancelPopup}
          >
            {t('cancel')}
          </Button>
          <Button
            className="button-item"
            block
            onClick={onOkClick}
            isLoading={isLoading}
            isDisable={isDisable || isLoading}
          >
            {okText || t('confirm')}
          </Button>
        </div>
      )
    case 'no-button':
      return null
    }
  }

  return (
    <>
      <div
        className={`text-content ${isFullHeight ? 'height-full' : ''} ${
          type === 'none' ? 'text-content--mb0' : ''
        }`}
      >
        {content}
      </div>
      {!noIcon && !noButton && (
        <div className="modal-button">{renderButton()}</div>
      )}
    </>
  )
}

const Modal = ({
  children,
  content,
  className,
  title,
  isOpen,
  onClose,
  onOk,
  okText,
  icon,
  decorator,
  noIcon,
  type,
  isDisable,
  onCancel,
  isScroll,
  noButton,
  preventClose,
  noExit,
  noPadding,
  isNoPaddingModal,
  contentClassName,
  isFullHeight,
  backgroundTrans,
  isSwapAllScreen,
  containerClass,
  isCustomModal = false,
  isShowCancel = false
    .isSwapModal,
  isConfirmPassWord

}) => {
  const isDarkMode = useSelector(state => state.themeRedux) === 'dark'

  // -- close 15s
  const timer = useMemo(() => {
    if (type === 'error' && isOpen) {
      return setTimeout(() => {
        onCancelPopup()
      }, 10000)
    }
  }, [type, isOpen])

  const propsMotion = useMemo(() => {
    if (isSwapAllScreen) {
      return {
        initial: { right: '2.5rem', bottom: '2vh', translateX: '120%' },
        animate: { right: '2.5rem', bottom: '3vh', translateX: '0' },
        exit: { right: '2.5rem', bottom: 0, translateX: '120%' }
      }
    } else {
      return {
        initial: { y: '-100%' },
        animate: { y: 0 },
        exit: { y: '-200%' }
      }
    }
  })

  const handleClickInside = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onCancelPopup = async () => {
    if (timer) {
      await clearTimeout(timer)
    }

    if (typeof onCancel === 'function') {
      onCancel()
    }
    onClose && onClose()
  }

  const renderIcon = () => {
    switch (type) {
    case 'notice':
      return <img src={images.iconNoti} className="modal-icon" />
    case 'success':
      return <img src={images.iconCheckWhite} className="modal-icon" />
    case 'error':
      return <Icon type="lg" name="saros_error" />
    case 'none':
      return null
    case 'confirm':
      return <Icon type="lg" name="saros_warning" />
    case 'warning':
      return <Icon type="lg" name="saros_warning" />
    default:
      return <img src={images[isDarkMode ? 'iconNotiPopupSaros' : 'iconNotiPopupSaros']} className="modal-icon" />
    }
  }

  const classNames = cn(
    styles.modal,

    isNoPaddingModal && 'modal-no-padding',
    containerClass
  )

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  return (
    <AnimatePresence layout>
      {isOpen && (
        <motion.div
          key={`my-modal-${Math.random()}`}
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={noExit ? null : { opacity: 0 }}
          className={classNames}
          onClick={preventClose ? () => {} : onCancelPopup}
        >
          <motion.div
            {...propsMotion}
            className={`custom-modal-content ${
              noIcon ? 'custom-modal-content--no-icon' : ''
            } ${isScroll ? 'scroll' : ''} ${
              noPadding ? 'custom-modal-content--no-padding' : ''
            }  ${backgroundTrans ? 'custom-modal-content--trans' : ''} ${
              type === 'none' ? 'custom-modal-content--none' : ''
            }
             ${className}`}
            onClick={handleClickInside}
          >
            { isShowCancel &&
              <div className='d-flex justify-content-end mr-2 mt-2'>
                <Icon name="web_close" onClick={onCancelPopup} />
              </div>
            }
            {isCustomModal ? (
              content
            ) : (
              <>
                {noIcon ? null : (
                  <div className={`modal-icon-wrapper ${type && `modal--${type}`}`}>
                    {icon || renderIcon()}
                    <div className=" title-text text-l text-center">{title}</div>
                  </div>
                )}
                <div className={cn(contentClassName, 'custom-modal-content__body', isConfirmPassWord && 'confirm-password')}>
                  <ModalContent
                    content={content || children}
                    noButton={noButton}
                    type={type}
                    onCancelPopup={onCancelPopup}
                    onOk={onOk}
                    okText={okText}
                    isDisable={isDisable}
                    noIcon={noIcon}
                    isFullHeight={isFullHeight}
                  />
                </div>
              </>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Modal
