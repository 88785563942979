import React from 'react'
import { toast } from 'react-toastify'
import cn from 'clsx'
import { upperFirst } from 'lodash'
import { useTranslation } from 'react-i18next'
const ToastComponent = ({ type, src, description, title }) => {
  const { t } = useTranslation()
  return (
    <div className="toast-container">
      <img className="toast-container__img" src={src} />
      <div className="toast-container__text">
        <div className="text-l semibold">
          {type === 'success' ? t('success') : upperFirst(t(type))}
        </div>
        {description && (
          <div
            className={cn(!title && 'description-title', 'text-sm')}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
      <div onClick={() => toast.dismiss()} className="toast-container__close">
        <span className="icon-web_close text-xl color-normal"></span>
      </div>
    </div>
  )
}

export default ToastComponent
