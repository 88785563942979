import { PublicKey, Transaction } from '@solana/web3.js'
import { solToken } from 'common/orca/quote/stable-quote'
import { SolanaService } from 'common/pool/solanaService'
import { TokenProgramInstructionService } from 'common/pool/tokenProgramInstructionService'
import { TokenProgramService } from 'common/pool/tokenProgramService'
import FindAmmServices from 'common/swap/findAmmServices'
import { get } from 'lodash'

const ADDRESS_FEE = new PublicKey(process.env.REACT_APP_FEE_WALLET)

export class AggregatorService {
  static async buildSwapTransaction (
    connection,
    routers,
    userAuthority,
    listAmm,
    signers,
    dataUiFeeSaros,
    isSaros,
    addressMintRouter
  ) {
    const transaction = new Transaction()
    // if hasCloseAccountSol === true no need close account sol

    // create token account
    await Promise.all(
      addressMintRouter.map(async (item, index) => {
        if ((index === 0) && (item === 'So11111111111111111111111111111111111111112')) return
        await TokenProgramService.resolveOrCreateAssociatedTokenAddress(
          connection,
          userAuthority,
          new PublicKey(item),
          transaction,
          signers
        )
      })
    )

    let hasCloseAccountSol = false
    for (const route of routers) {
      const index = routers.findIndex(item => {
        const mint = get(item, 'sourceToken', '').toString()
        const routeMint = get(route, 'sourceToken', '').toString()
        return mint === routeMint
      })

      const isSyncNative = index === 0
      if (isSyncNative) {
        await TokenProgramService.resolveOrCreateAssociatedTokenAddress(
          connection,
          userAuthority,
          route.sourceToken,
          transaction,
          signers,
          route.amountIn.toString(),
          isSyncNative
        )
      }

      // await TokenProgramService.resolveOrCreateAssociatedTokenAddress(
      //   connection,
      //   userAuthority,
      //   route.destinationToken,
      //   transaction,
      //   signers
      // )

      const AMM = await AggregatorService.getAMM(route.type)
      const swapInstruction = await AMM.buildSwapInstruction(
        connection,
        route,
        userAuthority,
        listAmm,
        isSaros
      )

      transaction.add(swapInstruction)
    }

    // transfer ui fee to address _____
    if (
      get(dataUiFeeSaros, 'mintAddress', '').toString() !==
      solToken.mint.toString()
    ) {
      const feeAddress =
      await TokenProgramService.findAssociatedTokenAddress(
        ADDRESS_FEE,
        get(dataUiFeeSaros, 'mintAddress')
      )

      if (!(await SolanaService.isAddressInUse(connection, feeAddress))) {
        const createATPATransaction =
          await TokenProgramInstructionService.createAssociatedTokenAccountTransaction(
            userAuthority,
            ADDRESS_FEE,
            get(dataUiFeeSaros, 'mintAddress')
          )
        transaction.add(createATPATransaction.instructions[0])
      }

      const tokeInfo = routers.length > 1 ? routers[1] : routers[0]
      const sourceTokenAddress =
        await TokenProgramService.findAssociatedTokenAddress(
          userAuthority,
          get(tokeInfo, 'destinationToken')
        )

      const transferTokenInstruction = TokenProgramInstructionService.transfer(
        userAuthority,
        sourceTokenAddress,
        feeAddress,
        get(dataUiFeeSaros, 'amount', '')
      )
      transaction.add(transferTokenInstruction)
    }

    // clean instruction
    for (const route of routers) {
      if (route.sourceToken.toString() === solToken.mint.toString()) {
        const sourceAddress =
          await TokenProgramService.findAssociatedTokenAddress(
            userAuthority,
            solToken.mint
          )
        const closeInstruction = TokenProgramService.closeAccountSol(
          userAuthority,
          sourceAddress
        )
        if (!hasCloseAccountSol) {
          transaction.add(closeInstruction)
        }
        hasCloseAccountSol = true
      }

      if (route.destinationToken.toString() === solToken.mint.toString()) {
        const destinationAddress =
          await TokenProgramService.findAssociatedTokenAddress(
            userAuthority,
            solToken.mint
          )
        const closeInstruction = TokenProgramService.closeAccountSol(
          userAuthority,
          destinationAddress
        )

        if (!hasCloseAccountSol) {
          transaction.add(closeInstruction)
        }
        hasCloseAccountSol = true
      }
    }
    return transaction
  }

  static async getAMM (type) {
    try {
      return FindAmmServices.getAmmByType(type)
    } catch (err) {
      console.log({ err })
    }
  }
}
