import React from 'react'
import NewSwapScreen from '.'
import { SwapContextProvider } from './context/SwapContext'

const NewSwapContent = ({ shortcut0Mint, shortcut1Mint }) => {
  return (
    <SwapContextProvider>
      <NewSwapScreen shortcut0Mint={shortcut0Mint} shortcut1Mint={shortcut1Mint} />
    </SwapContextProvider>
  )
}

export default NewSwapContent
