import { PublicKey } from '@solana/web3.js'
import {
  convertBalanceToWei,
  convertWeiToBalance,
  getLength
} from 'common/functions'
import { MINT_LP_DEFAULT } from 'common/pool/constants'
import { SarosSwapInstructionService } from 'common/pool/saros_swap/sarosSwapIntructions'
import { TokenProgramInstructionService } from 'common/pool/tokenProgramInstructionService'
import { TokenProgramService } from 'common/pool/tokenProgramService'
import {
  fetchMultipleAccount,
  genConnectionSolana,
  genOwnerSolana,
  getAllTokenSolanaByOwner
} from 'common/solana'
import { AppContext } from 'context/appContext'
import BaseAPI from 'controller/api/BaseAPI'
import { chunk, get, isArray } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useLiquidity = ({ isFetchListToken }) => {
  const appContext = useContext(AppContext)
  const { listAccountOwner } = appContext
  const accountSol = useSelector((state) => state.accountSol)

  const [listToken, setListToken] = useState([])
  const [poolLiquidityInfo, setPoolLiquidityInfo] = useState(null)

  useEffect(() => {
    if (!isFetchListToken) return
    getListTokenByOwner()
  }, [])

  const getListTokenByOwner = async () => {
    const listTokenByOwner = await getAllTokenSolanaByOwner({
      address: accountSol,
      isAllToken: true,
      tokenList: listToken,
      listAddressOwner: listAccountOwner
    })

    setListToken(listTokenByOwner)
  }

  const getListLiquidity = async () => {
    if (!accountSol) return []
    const owner = await genOwnerSolana(accountSol)
    const listMintToken = listAccountOwner.map((item) => item.mint)

    const response = await BaseAPI.postData('saros/pool/lp', {
      arrLp: listMintToken
    })
    if (!response) return []
    const listLpMint = response.map(
      (lp) => new PublicKey(get(lp, 'poolAccountInfo.lpTokenMint', ''))
    )

    const listAccountInPool = response
      .map((pool) => {
        const token0Account = new PublicKey(
          get(pool, 'poolAccountInfo.token0Account')
        )
        const token1Account = new PublicKey(
          get(pool, 'poolAccountInfo.token1Account')
        )
        return [token0Account, token1Account]
      })
      .flat()

    const listAccountLpByOwner = await Promise.all(
      listLpMint.map(async (lpMint) => {
        const lpAccount = await TokenProgramService.findAssociatedTokenAddress(
          owner.publicKey,
          lpMint
        )
        return lpAccount
      })
    )

    const listPoolAddress = response.map((item) => {
      return new PublicKey(item.id)
    })
    const listAccountLpByOwnerChunk = chunk(listAccountLpByOwner, 99)
    const listTokenAccountInPoolChunk = chunk(listAccountInPool, 99)
    const listLpMintChunk = chunk(listLpMint, 99)
    const listPoolAddressChunk = chunk(listPoolAddress, 99)

    const fetchListTokenAccountData = fetchMultipleAccount(
      listTokenAccountInPoolChunk
    )
    const fetchListLpMint = fetchMultipleAccount(listLpMintChunk)
    const fetchListLpAccount = fetchMultipleAccount(listAccountLpByOwnerChunk)
    const fetchListPoolAddress = fetchMultipleAccount(listPoolAddressChunk)

    const [
      listTokenAccountData,
      listLpAccountData,
      listLpMintData,
      listPoolAddressData
    ] = await Promise.all([
      fetchListTokenAccountData,
      fetchListLpAccount,
      fetchListLpMint,
      fetchListPoolAddress
    ])

    const listTokenAccountDataDecode = listTokenAccountData.map(
      (item, index) => {
        const buffer = Buffer.from(get(item, 'data[0]'), 'base64')
        const info =
          TokenProgramInstructionService.decodeTokenAccountInfo(buffer)
        info.address = listAccountInPool[index]
        return info
      }
    )

    const listLpMintDataDecode = listLpMintData.map((item) => {
      const buffer = Buffer.from(get(item, 'data[0]'), 'base64')
      const info = TokenProgramInstructionService.decodeTokenMintInfo(buffer)
      return info
    })

    const listLpAccountDecode = listLpAccountData.map((item) => {
      const buffer = Buffer.from(get(item, 'data[0]'), 'base64')
      const info =
        TokenProgramInstructionService.decodeTokenAccountInfo(buffer)
      return info
    })

    const listPoolAddressDataDecode = listPoolAddressData.map((item) => {
      const buffer = Buffer.from(get(item, 'data[0]'), 'base64')
      const info = SarosSwapInstructionService.decodePoolData(buffer)
      return info
    })

    const newListPool = response.map((pool, index) => {
      const indexData = 2 * index
      const token0Account = {
        ...listTokenAccountDataDecode[indexData],
        address: pool.poolAccountInfo.token0Account
      }
      const token1Account = {
        ...listTokenAccountDataDecode[indexData + 1],
        address: pool.poolAccountInfo.token0Account
      }
      const poolAccountInfo = {
        ...pool.poolAccountInfo,
        ...listLpMintDataDecode[index],
        ...listPoolAddressDataDecode[index],
        token0Account,
        token1Account
      }
      return {
        ...pool,
        isFaming: false,
        poolAccountInfo,
        userInfo: {
          ...listLpAccountDecode[index]
        }
      }
    })

    return newListPool
  }

  const getInfoPoolLiquidity = async ({
    poolAddress,
    dataPoolLiquidity = {},
    isFetchDataOnchain = false
  }) => {
    const response = await BaseAPI.postData('saros/pool/info', {
      poolAddress
    })
    if (!response) return null
    try {
      let newDataPool = {
        ...dataPoolLiquidity,
        ...response
      }
      if (isFetchDataOnchain) {
        const poolDataOnChain = await fetchDetailPoolOnChain(response)
        newDataPool = {
          ...poolDataOnChain,
          ...response
        }
      }
      const token0 =
        get(newDataPool, 'info.token0.mintAddress', '') ===
        get(newDataPool, 'poolAccountInfo.token0Account.mint', '').toString()
          ? get(newDataPool, 'info.token0', '')
          : get(newDataPool, 'info.token1', '')
      const token1 =
        get(newDataPool, 'info.token1.mintAddress', '') ===
        get(newDataPool, 'poolAccountInfo.token1Account.mint', '').toString()
          ? get(newDataPool, 'info.token1', '')
          : get(newDataPool, 'info.token0', '')

      const amount0InPool = convertWeiToBalance(
        get(newDataPool, 'poolAccountInfo.token0Account.amount', '').toString(),
        get(token0, 'decimals')
      )
      const amount1InPool = convertWeiToBalance(
        get(newDataPool, 'poolAccountInfo.token1Account.amount', '').toString(),
        get(token1, 'decimals')
      )
      const token0Price = parseFloat(
        window.walletServices.findCoinGeckoPrice(get(token0, 'id'))
      )
      const token1Price = parseFloat(
        window.walletServices.findCoinGeckoPrice(get(token1, 'id'))
      )
      const totalPriceToken =
        parseFloat(amount0InPool.toString()) * token0Price +
        parseFloat(amount1InPool.toString()) * token1Price
      const lpDecimals = get(newDataPool, 'lpInfo.decimals', '')
      const totalSupply = convertWeiToBalance(
        get(newDataPool, 'poolAccountInfo.supply', '').toString(),
        lpDecimals
      )

      const priceLp = totalPriceToken / parseFloat(totalSupply)

      const info = {
        ...get(newDataPool, 'info'),
        token0: {
          ...token0,
          ...get(newDataPool, 'poolAccountInfo.token0Account')
        },
        token1: {
          ...token1,
          ...get(newDataPool, 'poolAccountInfo.token1Account')
        }
      }

      newDataPool = {
        ...newDataPool,
        info,
        liquidityUsd: totalPriceToken,
        priceLp
      }

      return newDataPool
    } catch (err) {
      return response
    }
  }

  const updateDataUserInPool = async (poolInfo) => {
    try {
      const connection = genConnectionSolana()
      const owner = await genOwnerSolana(accountSol)
      const lpMint = get(poolInfo, 'poolAccountInfo.lpTokenMint')
      const lpTokenAccountUserInPool =
        await TokenProgramService.findAssociatedTokenAddress(
          owner.publicKey,
          lpMint
        )
      const lpTokenData = await connection.getAccountInfo(
        lpTokenAccountUserInPool
      )
      if (!lpTokenData.data) return poolInfo
      const lpTokenAccountUserInPoolData =
        TokenProgramInstructionService.decodeTokenAccountInfo(lpTokenData.data)
      const dataInPool = calculateTokenAmountInPool({
        dataPoolInfo: poolInfo,
        lpInPool: parseFloat(
          get(lpTokenAccountUserInPoolData, 'amount', '').toString()
        )
      })
      const { amountToken0InPool, amountToken1InPool } = dataInPool

      const userInfoInPool = {
        ...lpTokenAccountUserInPoolData,
        amountToken0InPool,
        amountToken1InPool
      }

      return userInfoInPool
    } catch (err) {
      return poolInfo
    }
  }

  const fetchDetailPoolOnChain = async (poolInfo) => {
    const connection = genConnectionSolana()
    const poolAddress = new PublicKey(get(poolInfo, 'poolAddress'))

    const poolDetailData = await connection.getAccountInfo(poolAddress)
    if (!poolDetailData.data) return poolInfo
    const poolDetailDataDecode = SarosSwapInstructionService.decodePoolData(
      poolDetailData.data
    )
    const {
      token0Account,
      token1Account,
      lpTokenMint,
      token1Mint,
      token0Mint
    } = poolDetailDataDecode
    const token0 = get(poolInfo, 'info.token0')
    const token1 = get(poolInfo, 'info.token1')

    const listAddressData = chunk(
      [token0Account, token1Account, lpTokenMint],
      99
    )
    const fetchData = await fetchMultipleAccount(listAddressData)
    const bufferDataToken0 = Buffer.from(
      get(fetchData[0], 'data[0]'),
      'base64'
    )
    const bufferDataToken1 = Buffer.from(
      get(fetchData[1], 'data[0]'),
      'base64'
    )
    const bufferDataLpToken = Buffer.from(
      get(fetchData[2], 'data[0]'),
      'base64'
    )

    const dataToken0Decode =
      TokenProgramInstructionService.decodeTokenAccountInfo(bufferDataToken0)
    const dataToken1Decode =
      TokenProgramInstructionService.decodeTokenAccountInfo(bufferDataToken1)
    const dataTokenLpToken =
      TokenProgramInstructionService.decodeTokenMintInfo(bufferDataLpToken)

    const newPoolDetailDataDecode = {
      ...poolInfo,
      ...poolDetailDataDecode,
      token0Account: {
        ...dataToken0Decode
      },
      token1Account: {
        ...dataToken1Decode
      }
    }

    const rate0 = calculateAmount({
      amount: 1,
      mintAddress: token0Mint.toString(),
      poolInfo: newPoolDetailDataDecode
    })
    const rate1 = calculateAmount({
      amount: 1,
      mintAddress: token1Mint.toString(),
      poolInfo: newPoolDetailDataDecode
    })

    const info = {
      ...get(poolInfo, 'info', {}),
      token0: {
        ...token0,
        ...dataToken0Decode
      },
      token1: {
        ...token1,
        ...dataToken1Decode
      }
    }

    const newPoolInfo = {
      ...poolInfo,
      info: {
        ...info
      },
      lpInfo: {
        ...dataTokenLpToken
      },
      poolAccountInfo: {
        ...newPoolDetailDataDecode,
        supply: dataTokenLpToken.supply
      },
      rate1,
      rate0
    }

    return newPoolInfo
  }

  const getPoolLiquidityExit = async ({
    fromMint,
    toMint,
    dataPoolLiquidity
  }) => {
    try {
      if (!fromMint || !toMint) return null
      let arrTemp = []
      if (!dataPoolLiquidity) {
        const poolsLiquidity = await BaseAPI.postData('saros/pool/exists', {
          token0: fromMint,
          token1: toMint
        })

        if (poolsLiquidity === false) return false

        if (!isArray(poolsLiquidity)) return null
        const listPoolAddress = poolsLiquidity.map((item) => item.id)
        const listAddress = poolsLiquidity
          .map((item) => {
            const poolAddress = new PublicKey(item.id)
            const lpMint = new PublicKey(
              get(item, 'poolAccountInfo.lpTokenMint')
            )
            const token0Account = new PublicKey(
              get(item, 'poolAccountInfo.token0Account')
            )
            const token1Account = new PublicKey(
              get(item, 'poolAccountInfo.token1Account')
            )
            return [poolAddress, token0Account, token1Account, lpMint]
          })
          .flat()
        const listPoolAddressChunk = chunk(listAddress, 99)
        const listPoolAddressData = await fetchMultipleAccount(
          listPoolAddressChunk
        )
        const listPoolAddressDataDecode = poolsLiquidity.map((item, index) => {
          const loopIndex = 4
          const indexDataPoolInfo = index * loopIndex
          const indexDataPoolToken0 = index * loopIndex + 1
          const indexDataPoolToken1 = index * loopIndex + 2
          const indexDataLpMint = index * loopIndex + 3

          const bufferPoolInfo = Buffer.from(
            get(listPoolAddressData[indexDataPoolInfo], 'data[0]'),
            'base64'
          )
          const bufferToken0Info = Buffer.from(
            get(listPoolAddressData[indexDataPoolToken0], 'data[0]'),
            'base64'
          )
          const bufferToken1Info = Buffer.from(
            get(listPoolAddressData[indexDataPoolToken1], 'data[0]'),
            'base64'
          )

          const bufferLpMint = Buffer.from(
            get(listPoolAddressData[indexDataLpMint], 'data[0]'),
            'base64'
          )

          const poolInfo =
            SarosSwapInstructionService.decodePoolData(bufferPoolInfo)
          const token0Account =
            TokenProgramInstructionService.decodeTokenAccountInfo(
              bufferToken0Info
            )
          const token1Account =
            TokenProgramInstructionService.decodeTokenAccountInfo(
              bufferToken1Info
            )

          const lpInfo =
            TokenProgramInstructionService.decodeTokenMintInfo(bufferLpMint)

          return {
            ...item,
            ...poolInfo,
            lpInfo,
            token0Account,
            token1Account
          }
        })
        arrTemp = listPoolAddressDataDecode
          .filter((item) => {
            return listPoolAddress.includes(item.id)
          })
          .sort((a, b) => {
            const aSupply = parseFloat(get(a, 'lpInfo.supply', '').toString())
            const bSupply = parseFloat(get(b, 'lpInfo.supply', '').toString())
            return bSupply - aSupply
          })
      }
      const newData = get(dataPoolLiquidity, 'poolAccountInfo') || arrTemp[0]

      const fetchDataOnChain = await getInfoPoolLiquidity({
        poolAddress: get(newData, 'id') || get(dataPoolLiquidity, 'id'),
        isFetchDataOnchain: true,
        dataPoolLiquidity: newData
      })
      let dataPoolInfo = {
        ...dataPoolLiquidity,
        ...newData,
        ...fetchDataOnChain
      }

      const rate0 = calculateAmount({
        amount: 1,
        mintAddress: get(dataPoolInfo, 'token0Mint', '').toString(),
        poolInfo: dataPoolInfo
      })
      const rate1 = calculateAmount({
        amount: 1,
        mintAddress: get(dataPoolInfo, 'token1Mint', '').toString(),
        poolInfo: dataPoolInfo
      })
      dataPoolInfo = {
        ...dataPoolInfo,
        rate0,
        rate1
      }

      setPoolLiquidityInfo(dataPoolInfo)
      return dataPoolInfo
    } catch (err) {
      console.log({ err })
      return null
    }
  }

  const calculateAmount = ({
    amount,
    mintAddress,
    poolInfo = poolLiquidityInfo
  }) => {
    let newAmount = amount
    if (newAmount && poolInfo) {
      const isFrom = mintAddress === get(poolInfo, 'token0Mint', '').toString()
      const token0 = get(poolInfo, 'token0') || get(poolInfo, 'info.token0', '')
      const token1 = get(poolInfo, 'token1') || get(poolInfo, 'info.token1', '')

      const amountToken0InPool = parseFloat(
        convertWeiToBalance(
          get(poolInfo, 'token0Account.amount', '').toString(),
          get(token0, 'decimals')
        )
      )
      const amountToken1InPool = parseFloat(
        convertWeiToBalance(
          get(poolInfo, 'token1Account.amount', '').toString(),
          get(token1, 'decimals')
        )
      )
      const rate = amountToken1InPool / amountToken0InPool

      newAmount = isFrom
        ? parseFloat(newAmount) * rate
        : parseFloat(newAmount) * (1 / rate)

      return newAmount ? parseFloat(newAmount).toFixed(9) : ''
    }
    return newAmount
  }

  const calculateLpReceive = ({
    poolInfo = poolLiquidityInfo,
    amount,
    sourceToken,
    isCreatePool = false
  }) => {
    const { token0Mint, token1Mint, lpInfo, token0Account, token1Account, poolAccountInfo } = poolInfo
    const infoToken0 = TokenProgramService.getInfoTokenInfoByMint(
      token0Mint.toString()
    )
    const infoToken1 = TokenProgramService.getInfoTokenInfoByMint(
      token1Mint.toString()
    )

    const isToken0 = token0Mint.toString() === get(sourceToken, 'mintAddress')
    const supply = get(lpInfo, 'supply', '') || get(poolAccountInfo, 'supply', '')
    const lpTokenSupply = parseFloat(supply.toString())
    const convertFromAmount = convertBalanceToWei(
      amount,
      isToken0 ? get(infoToken0, 'decimals', 6) : get(infoToken1, 'decimals', 6)
    )
    const lpTokenAmount = isCreatePool
      ? MINT_LP_DEFAULT
      : (parseFloat(convertFromAmount) * lpTokenSupply) /
        parseFloat(
          isToken0
            ? token0Account.amount.toString()
            : token1Account.amount.toString()
        )

    return convertWeiToBalance(lpTokenAmount, 2)
  }

  const calculateTokenAmountInPool = ({ dataPoolInfo, lpInPool }) => {
    if (!dataPoolInfo || !lpInPool) {
      return { amountToken0InPool: 0, amountToken1InPool: 0 }
    }
    const poolAccountInfo = get(dataPoolInfo, 'poolAccountInfo')
    const token0Amount = parseFloat(
      get(dataPoolInfo, 'token0Account.amount', '').toString()
    )
    const token1Amount = parseFloat(
      get(dataPoolInfo, 'token1Account.amount', '').toString()
    )
    const lpTokenSupply = parseFloat(
      get(poolAccountInfo, 'supply', '').toString()
    )
    const amountToken0InPool = Math.floor(
      (token0Amount * lpInPool) / lpTokenSupply
    )

    const amountToken1InPool = Math.floor(
      (token1Amount * lpInPool) / lpTokenSupply
    )

    return {
      amountToken0InPool,
      amountToken1InPool
    }
  }

  return {
    allTokenByOwner: listToken,
    getListLiquidity,
    getInfoPoolLiquidity,
    getPoolLiquidityExit,
    calculateAmount,
    calculateLpReceive,
    calculateTokenAmountInPool,
    updateDataUserInPool
  }
}

export default useLiquidity
