import React, { useRef, useState, useEffect, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import cn from 'clsx'
import get from 'lodash/get'
import InputSearch from 'components/InputSearch'
import PageDropdown from 'components/PageDropdown'
import { getLength, validateBlockChainAddress } from 'common/functions'
import BaseAdapter from 'controller/api/BaseAdapter'
import { chainType } from 'common/constants'
import { cloneDeep } from 'lodash'
import TableFarmStake from '../TableFarmStake'
import TableLiquidityProvider from '../TableLiquiidtyProvider'
import { WalletOverviewContext } from '../Context/WalletOverviewContext'
import TokenImage from 'components/common/TokenImage'
const LIST_TYPE = [
  {
    name: 'Liquidity Providers',
    value: 'liquidityProviders'
  },
  {
    name: 'Farm',
    value: 'farm'

  },
  {
    name: 'Stake',
    value: 'stake'

  }
]

const LIMIT = 10

const cachedOverviewTable = {
  farm: [],
  stake: [],
  liquidityProviders: []
}

const totalRefDefault = {
  farm: 1,
  stake: 1,
  liquidityProviders: 1
}

const sortTypeDefault = {
  farm: 'amountUSD',
  stake: 'amountUSD',
  liquidityProviders: 'amountUSD'
}

const OverviewTable = ({ address }) => {
  const { t } = useTranslation()

  const overviewContext = useContext(WalletOverviewContext)
  const { onNavigateToDetailWallet, setLPContextInfo } = overviewContext

  // tabbar
  const [selectedTab, setSelectedTab] = useState('liquidityProviders')
  const [sort, setSort] = useState('amountUSD')
  const [txtSearch, setTxtSearch] = useState('')

  // table
  const [isLoading, setIsLoading] = useState()
  const [dataFetched, setDataFetched] = useState([])
  const [total, setTotal] = useState()
  const [currPage, setCurrPage] = useState(1)

  const isMountedLPTable = selectedTab === 'liquidityProviders'
  const isMountedFarmStakeTable = selectedTab !== 'liquidityProviders'
  const isStake = selectedTab === 'stake'
  const FARM_STAKE_SORT = [
    {
      label: isStake ? 'Token Value' : 'LP Value',
      value: 'amountUSD'
    },
    {
      label: 'APR',
      value: 'apr'
    }
  ]

  const LP_SORT = [
    {
      label: 'LP Value',
      value: 'amountUSD'
    },
    {
      label: 'Pool Share',
      value: 'poolShare'
    }
  ]

  const overviewDataRef = useRef(cachedOverviewTable)
  const totalPageRef = useRef(totalRefDefault)
  const sortTypeRef = useRef(sortTypeDefault)
  const timer = useRef()

  useEffect(() => {
    getListOverviewData()
  }, [selectedTab, address, currPage])

  const getListOverviewData = async (props) => {
    const isTriggerLoad = get(props, 'isTriggerLoad', true)
    const typeSort = get(props, 'typeSort', '')
    const page = get(props, 'page', '')

    setIsLoading(isTriggerLoad)
    const cacheDataOverview = overviewDataRef.current[selectedTab]
    const cacheTotal = totalPageRef.current[selectedTab]

    if (getLength(cacheDataOverview) > 0 && getLength(txtSearch) === 0 && !address && currPage === 1 && !typeSort) {
      setTotal(cacheTotal)
      setDataFetched(cacheDataOverview)
      setIsLoading(false)
      return
    }

    const bodyQuery = {
      page: page || currPage,
      size: LIMIT,
      sortType: typeSort || sort
    }

    if (getLength(txtSearch) > 0) {
      const isValidAddress = validateBlockChainAddress(txtSearch, chainType.solana)
      if (!isValidAddress) {
        setIsLoading(false)
        setDataFetched([])
        return
      }
      bodyQuery.address = txtSearch
    }

    if (isMountedFarmStakeTable) {
      bodyQuery.type = selectedTab
    }

    if (getLength(address) > 0) {
      bodyQuery.address = address
    }

    const response = await BaseAdapter.getData(
      isMountedLPTable
        ? 'saros/wallet/filter/lp'
        : 'saros/wallet/filter/farmStake'
      , bodyQuery)

    if (!response) return
    const data = response.data
    if (getLength(txtSearch) === 0 && getLength(address) === 0 && currPage === 1) {
      // only cache at page 1 and when no address search
      setOverviewDataRef(selectedTab, get(data, 'data'))
      setTotalPageRef(selectedTab, get(data, 'total'))
    }
    setTotal(get(data, 'total'))
    setDataFetched(get(data, 'data'))
    setIsLoading(false)
  }

  const getOverviewInitData = async () => {
    const bodyQuery = {
      page: 1,
      size: LIMIT,
      sortType: sort,
      type: selectedTab
    }
    setIsLoading(true)

    const response = await BaseAdapter.getData(
      isMountedLPTable
        ? 'saros/wallet/filter/lp'
        : 'saros/wallet/filter/farmStake'
      , bodyQuery)
    if (!response) return
    const data = response.data
    setOverviewDataRef(selectedTab, get(data, 'data'))
    setTotalPageRef(selectedTab, get(data, 'total'))
    setTotal(get(data, 'total'))
    setDataFetched(get(data, 'data'))
    setIsLoading(false)
  }

  const setOverviewDataRef = (tab, data) => {
    const newDataRef = cloneDeep(overviewDataRef.current)
    newDataRef[tab] = data
    overviewDataRef.current = newDataRef
  }

  const setTotalPageRef = (tab, data) => {
    const newDataRef = cloneDeep(totalPageRef.current)
    newDataRef[tab] = data
    totalPageRef.current = newDataRef
  }

  const setSortTypeRef = (tab, data) => {
    const newDataRef = cloneDeep(sortTypeRef.current)
    newDataRef[tab] = data
    sortTypeRef.current = newDataRef
  }

  const onChooseTab = (tab) => () => {
    setSelectedTab(tab)
    setCurrPage(1)
    setSort(sortTypeRef.current[tab])

    // if (tab === 'liquidityProviders') {
    //   setSort(LP_SORT[0].value)
    // } else {
    //   // setSort(FARM_STAKE_SORT[0].value)
    // }
  }

  const onChangeSort = (sort) => {
    setSort(sort.value)

    setSortTypeRef(selectedTab, sort.value)

    setOverviewDataRef(selectedTab, [])
    getListOverviewData({ typeSort: sort.value })
  }

  const handleClearSearch = () => {
    clearDataSearch()
  }

  const clearDataSearch = () => {
    const cacheData = overviewDataRef.current[selectedTab]
    const cachedTotal = totalPageRef.current[selectedTab]

    if (getLength(cacheData) === 0) {
      getOverviewInitData()
    }
    setTotal(cachedTotal)
    setDataFetched(cacheData)
    setTxtSearch('')
    setCurrPage(1)
  }

  const handleSearchInput = (e) => {
    setIsLoading(true)
    const { value } = e.target
    setTxtSearch(value)
    setCurrPage(1)

    clearTimeout(timer.current)
    if (getLength(value) === 0) {
      setIsLoading(false)
      clearDataSearch()
    } else if (getLength(value) > 0) {
      setTxtSearch(value)
      const isValidAddress = validateBlockChainAddress(value, chainType.solana)
      if (!isValidAddress) {
        setIsLoading(false)
        setDataFetched([])
        return
      }

      const bodyQuery = {
        page: 1,
        size: LIMIT,
        sortType: sort,
        address: value
      }

      if (isMountedFarmStakeTable) {
        bodyQuery.type = selectedTab
      }

      timer.current = setTimeout(async () => {
        const response = await BaseAdapter.getData(
          isMountedLPTable
            ? 'saros/wallet/filter/lp'
            : 'saros/wallet/filter/farmStake', bodyQuery)
        if (response) {
          const data = response.data
          setDataFetched(data.data)
          setIsLoading(false)
          setTotal(data.total)
        } else {
          setIsLoading(false)
        }
      }, 500)
    }
  }

  const handleChangePage = (val) => {
    setCurrPage(val)
    if (val === 1) {
      getListOverviewData({ page: 1 })
    } /// page 1

    // reset cache ref to null -> trigger call API
    // setOverviewDataRef(selectedTab, [])
    // setTotalPageRef(selectedTab, 0)
  }

  const onClickRow = (row) => {
    setCurrPage(1)

    onNavigateToDetailWallet(row)
    // overviewDataRef.current = cachedOverviewTable
    // totalPageRef.current = totalRefDefault
  }

  const onClickLPRow = (rowLP) => {
    onNavigateToDetailWallet(rowLP)
    setCurrPage(1)

    const token0 = get(rowLP, 'token0Amount.tokenInfo', {})
    const token1 = get(rowLP, 'token1Amount.tokenInfo', {})
    setLPContextInfo({
      value: get(rowLP, 'poolAddress'),
      label: (
        <div className='d-flex align-items-center uppercase'>
          <TokenImage src={get(token0, 'icon')} alt="" className="token-img token-img--token-1 img-24 mr-1" />
          <TokenImage src={get(token1, 'icon')} alt="" className="token-img token-img--token-2 img-24 mr-2" />
          <span>{get(token0, 'symbol')} / { get(token1, 'symbol')}</span>
        </div>
      )
    })
  }

  const renderTab = () => {
    return LIST_TYPE.map((item, i) => {
      return (
        <div
          className={cn('tab-item ',
            selectedTab === item.value && 'tab-item--active',
            item.value === 'liquidityProviders' && 'tab-item--short-padding'
          )}
          key={i}
          onClick={onChooseTab(item.value)}
        >
          {t(item.name)}
        </div>
      )
    })
  }

  const DROPDOWN_ARR = isMountedLPTable ? LP_SORT : FARM_STAKE_SORT
  const defItem = DROPDOWN_ARR.find(item => item.value === sort)

  return (
    <div className={cn(styles.overviewTableContainer, 'box-wrapper')}>
      <div className='table-controller'>
        <div className='table-controller__tabs'>
          {renderTab()}
        </div>
        <div className='table-controller__inputs'>
          {
            getLength(address) === 0 &&
              <InputSearch
                handleClear={handleClearSearch}
                className="wallet-search"
                initValue={txtSearch}
                handleChange={handleSearchInput}
                placeHolder={t('searchByAddress')}
              />
          }

          <PageDropdown
            className="right-toggle-down"
            toggleclassName="wallet-toggle"
            menuClassName="right-menu-down"
            options={DROPDOWN_ARR}
            onChange={onChangeSort}
            defaultValue={defItem}
          />
        </div>
      </div>

      {
        isMountedLPTable &&
        <TableLiquidityProvider
          isLoading={isLoading}
          currPage={currPage}
          totalPage={total}
          tableData={dataFetched}
          onChangePage={handleChangePage}
          onClickRow={onClickLPRow}

        />
      }

      {
        selectedTab === 'stake' &&
          <TableFarmStake
            isLoading={isLoading}
            currPage={currPage}
            totalPage={total}
            tableData={dataFetched}
            onChangePage={handleChangePage}
            onClickRow={onClickRow}
            isStake={true}
          />
      }

      {selectedTab === 'farm' &&
        <TableFarmStake
          isLoading={isLoading}
          currPage={currPage}
          totalPage={total}
          tableData={dataFetched}
          onChangePage={handleChangePage}
          onClickRow={onClickRow}
          isStake={false}
        />
      }

    </div>
  )
}

export default OverviewTable
