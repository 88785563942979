/* eslint-disable new-cap */
import { SarosSwapInstructionService } from 'common/pool/saros_swap/sarosSwapIntructions'
import { u64 } from '@solana/spl-token'
import { OrcaU64 } from 'common/orca/ultils/orcaU64'
import {
  Keypair,
  PublicKey,
  SystemProgram,
  Transaction
} from '@solana/web3.js'
import BN from 'bn.js'
import { SolanaService } from '../solanaService'
import {
  INITIALIZE_MINT_SPAN,
  INITIALIZE_POOL_SPAN,
  TOKEN_PROGRAM_ID
} from '../constants'
import { TokenProgramInstructionService } from '../tokenProgramInstructionService'
import { TokenProgramService } from '../tokenProgramService'
import {
  encodeMessErr,
  genOwnerSolana,
  NATIVE_SOL
} from 'common/solana'
import { closeAccount } from '@project-serum/serum/lib/token-instructions'
import { NATIVE_SOL_RAYDIUM, TOKENS } from 'common/raydium/constants'
import { renderAmountSlippage } from 'common/functions'
import { get, union } from 'lodash'

const TRADING_FEE_NUMERATOR = new BN(0)
const TRADING_FEE_DENOMINATOR = new BN(10000)
const OWNER_TRADING_FEE_NUMERATOR = new BN(30)
const OWNER_TRADING_FEE_DENOMINATOR = new BN(10000)
const OWNER_WITHDRAW_FEE_NUMERATOR = new BN(0)
const OWNER_WITHDRAW_FEE_DENOMINATOR = new BN(0)
const HOST_FEE_NUMERATOR = new BN(20)
const HOST_FEE_DENOMINATOR = new BN(100)

export class SarosSwapService {
  static async getPoolInfo (connection, poolAddress) {
    const accountInfo = await connection.getAccountInfo(poolAddress)
    if (!accountInfo) return null
    return SarosSwapInstructionService.decodePoolData(accountInfo.data)
  }

  static async getLPSupply (
    connection,
    poolTokenMint = new PublicKey('SSwapUtytfBdBn1b9NUGG6foMVPtcWgpRU32HToDUZr')
  ) {
    const context = await connection.getTokenSupply(poolTokenMint)
    const amt = new u64(context.value.amount)
    return OrcaU64.fromU64(amt, 6)
  }

  static tradingTokensToPoolTokens (sourceAmount, swapSourceAmount, poolAmount) {
    const tradingFee =
      (sourceAmount / 2) *
      (TRADING_FEE_NUMERATOR.toNumber() / TRADING_FEE_DENOMINATOR.toNumber())
    const sourceAmountPostFee = sourceAmount - tradingFee
    const root = Math.sqrt(sourceAmountPostFee / swapSourceAmount + 1)

    return Math.floor(poolAmount * (root - 1))
  }

  static async findPoolSeed (
    // token0MintAddress,
    // token1MintAddress,
    tokenSwapProgramId
  ) {
    const newToken = Keypair.generate()
    return PublicKey.findProgramAddress(
      [newToken.publicKey.toBuffer()],
      tokenSwapProgramId
    )
  }

  static async findPoolSeedLP (
    token0MintAddress,
    token1MintAddress,
    tokenSwapProgramId
  ) {
    return PublicKey.findProgramAddress(
      [token0MintAddress.toBuffer(), token1MintAddress.toBuffer()],
      tokenSwapProgramId
    )
  }

  static async createPoolSeed (address, tokenSwapProgramId) {
    return PublicKey.createProgramAddress(
      [address.toBuffer()],
      tokenSwapProgramId
    )
  }

  static async findPoolAuthorityAddress (poolAddress, tokenSwapProgramId) {
    return PublicKey.findProgramAddress(
      [poolAddress.toBuffer()],
      tokenSwapProgramId
    )
  }

  static async createPoolNew (
    sendRawTxn,
    connection,
    payerAccount,
    feeOwnerAddress,
    token0MintAddress,
    token1MintAddress,
    token0AmountWei,
    token1AmountWei,
    curveType,
    curveParameters,
    tokenProgramId,
    sarosSwapProgramId,
    callback
  ) {
    try {
      const transaction = new Transaction()
      const signers = [payerAccount]

      const [poolAccountSeed] = await SarosSwapService.findPoolSeed(
        // token0MintAddress,
        // token1MintAddress,
        sarosSwapProgramId
      )

      const userToken0Address =
        await TokenProgramService.resolveOrCreateAssociatedTokenAddress(
          connection,
          payerAccount.publicKey,
          token0MintAddress,
          transaction,
          signers,
          token0AmountWei.toNumber()
        )

      const userToken1Address =
        await TokenProgramService.resolveOrCreateAssociatedTokenAddress(
          connection,
          payerAccount.publicKey,
          token1MintAddress,
          transaction,
          signers,
          token1AmountWei.toNumber()
        )
      const poolAccount = Keypair.fromSeed(poolAccountSeed.toBuffer())
      const [poolAuthorityAddress] =
        await SarosSwapService.findPoolAuthorityAddress(
          poolAccount.publicKey,
          sarosSwapProgramId
        )
      const poolLpMintAccount = Keypair.fromSeed(
        poolAuthorityAddress.toBuffer()
      )

      if (
        !(await SolanaService.isAddressInUse(
          connection,
          poolLpMintAccount.publicKey
        ))
      ) {
        const lamportsToInitializeMint =
          await connection.getMinimumBalanceForRentExemption(
            INITIALIZE_MINT_SPAN
          )
        const initMintTransaction =
          await TokenProgramInstructionService.createInitializeMintTransaction(
            payerAccount.publicKey,
            poolLpMintAccount.publicKey,
            2,
            poolAuthorityAddress,
            null,
            lamportsToInitializeMint
          )
        transaction.add(initMintTransaction.instructions[0])
        transaction.add(initMintTransaction.instructions[1])
      }
      const poolToken0Address =
        await TokenProgramService.findAssociatedTokenAddress(
          poolAuthorityAddress,
          token0MintAddress
        )
      if (
        !(await SolanaService.isAddressInUse(connection, poolToken0Address))
      ) {
        const createATPATransaction =
          await TokenProgramInstructionService.createAssociatedTokenAccountTransaction(
            payerAccount.publicKey,
            poolAuthorityAddress,
            token0MintAddress
          )
        transaction.add(createATPATransaction.instructions[0])
      }

      const poolToken1Address =
        await TokenProgramService.findAssociatedTokenAddress(
          poolAuthorityAddress,
          token1MintAddress
        )
      if (
        !(await SolanaService.isAddressInUse(connection, poolToken1Address))
      ) {
        const createATPATransaction =
          await TokenProgramInstructionService.createAssociatedTokenAccountTransaction(
            payerAccount.publicKey,
            poolAuthorityAddress,
            token1MintAddress
          )
        transaction.add(createATPATransaction.instructions[0])
      }
      const poolLpTokenAddress =
        await TokenProgramService.findAssociatedTokenAddress(
          payerAccount.publicKey,
          poolLpMintAccount.publicKey
        )
      if (
        !(await SolanaService.isAddressInUse(connection, poolLpTokenAddress))
      ) {
        const createATPATransaction =
          await TokenProgramInstructionService.createAssociatedTokenAccountTransaction(
            payerAccount.publicKey,
            payerAccount.publicKey,
            poolLpMintAccount.publicKey
          )
        transaction.add(createATPATransaction.instructions[0])
      }

      const feeLpTokenAddress =
        await TokenProgramService.findAssociatedTokenAddress(
          feeOwnerAddress,
          poolLpMintAccount.publicKey
        )
      if (
        payerAccount.publicKey.toBase58() !== feeOwnerAddress.toBase58() &&
        !(await SolanaService.isAddressInUse(connection, feeLpTokenAddress))
      ) {
        const createATPATransaction =
          await TokenProgramInstructionService.createAssociatedTokenAccountTransaction(
            payerAccount.publicKey,
            feeOwnerAddress,
            poolLpMintAccount.publicKey
          )
        transaction.add(createATPATransaction.instructions[0])
      }

      const transferToken0Transaction =
        await TokenProgramInstructionService.createTransferTransaction(
          payerAccount.publicKey,
          userToken0Address,
          poolToken0Address,
          token0AmountWei
        )
      transaction.add(transferToken0Transaction.instructions[0])

      const transferToken1Transaction =
        await TokenProgramInstructionService.createTransferTransaction(
          payerAccount.publicKey,
          userToken1Address,
          poolToken1Address,
          token1AmountWei
        )
      transaction.add(transferToken1Transaction.instructions[0])

      const lamportsToCreatePool =
        await connection.getMinimumBalanceForRentExemption(
          INITIALIZE_POOL_SPAN
        )
      transaction.add(
        SystemProgram.createAccount({
          fromPubkey: payerAccount.publicKey,
          newAccountPubkey: poolAccount.publicKey,
          lamports: lamportsToCreatePool,
          space: INITIALIZE_POOL_SPAN,
          programId: sarosSwapProgramId
        })
      )
      const tokenSwapInstruction =
        SarosSwapInstructionService.createInitSwapInstruction(
          poolAccount,
          poolAuthorityAddress,
          poolToken0Address,
          poolToken1Address,
          poolLpMintAccount.publicKey,
          feeLpTokenAddress,
          poolLpTokenAddress,
          tokenProgramId,
          sarosSwapProgramId,
          TRADING_FEE_NUMERATOR,
          TRADING_FEE_DENOMINATOR,
          OWNER_TRADING_FEE_NUMERATOR,
          OWNER_TRADING_FEE_DENOMINATOR,
          OWNER_WITHDRAW_FEE_NUMERATOR,
          OWNER_WITHDRAW_FEE_DENOMINATOR,
          HOST_FEE_NUMERATOR,
          HOST_FEE_DENOMINATOR,
          curveType,
          curveParameters
        )
      transaction.add(tokenSwapInstruction)

      if (token0MintAddress.toString() === NATIVE_SOL.mintAddress) {
        transaction.add(
          closeAccount({
            source: userToken0Address,
            destination: payerAccount.publicKey,
            owner: payerAccount.publicKey
          })
        )
      }

      if (token1MintAddress.toString() === NATIVE_SOL.mintAddress) {
        transaction.add(
          closeAccount({
            source: userToken1Address,
            destination: payerAccount.publicKey,
            owner: payerAccount.publicKey
          })
        )
      }

      // const tx = await postBaseSendTxsNew(
      //   connection,
      //   transaction,
      //   [...signers, poolLpMintAccount, poolAccount],
      //   true,
      //   callback,
      //   null,
      //   get(poolAccount, 'publicKey', '').toString()
      // )

      const tx = await sendRawTxn({
        transactions: transaction,
        signers: [poolLpMintAccount, poolAccount],
        isWaitDone: true,
        commitment: 'finalized',
        callBack: callback(get(poolAccount, 'publicKey', '').toString())
      })

      return tx
    } catch (err) {
      const logs = get(err, 'logs', '').toString()
      return { isError: true, data: encodeMessErr(logs) }
    }
  }

  static async withdrawAllTokenTypesNew (
    sendRawTxn,
    connection,
    payerAccount,
    delegateAccount,
    lpTokenMint,
    lpTokenAmount,
    poolAddress,
    tokenSwapProgramId,
    token0Mint,
    token1Mint,
    token0AmountWei,
    token1AmountWei,
    slippage,
    callback,
    isSaros
  ) {
    try {
      const transaction = new Transaction()

      const poolAccountInfo = await SarosSwapService.getPoolInfo(
        connection,
        poolAddress
      )

      const newToken0Mint =
        token0Mint.toString() ===
        get(poolAccountInfo, 'token0Mint', '').toString()
          ? token0Mint
          : token1Mint

      const newToken1Mint =
        token1Mint.toString() ===
        get(poolAccountInfo, 'token1Mint', '').toString()
          ? token1Mint
          : token0Mint

      const userLpTokenAddress =
        await TokenProgramService.findAssociatedTokenAddress(
          payerAccount.publicKey,
          lpTokenMint
        )

      const userToken0Address =
        await TokenProgramService.findAssociatedTokenAddress(
          payerAccount.publicKey,
          newToken0Mint
        )

      if (
        await SolanaService.isAddressAvailable(connection, userToken0Address)
      ) {
        const createATAInstruction =
          await TokenProgramInstructionService.createAssociatedTokenAccount(
            payerAccount.publicKey,
            payerAccount.publicKey,
            newToken0Mint
          )
        transaction.add(createATAInstruction)
      }
      const userToken1Address =
        await TokenProgramService.findAssociatedTokenAddress(
          payerAccount.publicKey,
          newToken1Mint
        )

      if (
        await SolanaService.isAddressAvailable(connection, userToken1Address)
      ) {
        const createATAInstruction =
          await TokenProgramInstructionService.createAssociatedTokenAccount(
            payerAccount.publicKey,
            payerAccount.publicKey,
            newToken1Mint
          )
        transaction.add(createATAInstruction)
      }

      const [poolAuthorityAddress] =
        await SarosSwapService.findPoolAuthorityAddress(
          poolAddress,
          tokenSwapProgramId
        )

      const poolLpMintInfo = await TokenProgramService.getTokenMintInfo(
        connection,
        poolAccountInfo.lpTokenMint
      )
      const lpTokenSupply = parseFloat(poolLpMintInfo.supply.toString())

      const poolToken0AccountInfo =
        await TokenProgramService.getTokenAccountInfo(
          connection,
          poolAccountInfo.token0Account
        )

      const newAmount0 = Math.floor(
        (parseFloat(poolToken0AccountInfo.amount.toString()) * lpTokenAmount) /
          lpTokenSupply
      )

      const token0Amount = Math.floor(
        newAmount0 - renderAmountSlippage(newAmount0, slippage)
      )

      const poolToken1AccountInfo =
        await TokenProgramService.getTokenAccountInfo(
          connection,
          poolAccountInfo.token1Account
        )

      const newAmount1 = Math.floor(
        (parseFloat(poolToken1AccountInfo.amount.toString()) * lpTokenAmount) /
          lpTokenSupply
      )
      const token1Amount = Math.floor(
        newAmount1 - renderAmountSlippage(newAmount1, slippage)
      )

      const withdrawInstruction =
        SarosSwapInstructionService.withdrawAllTokenTypesInstruction(
          poolAddress,
          poolAuthorityAddress,
          delegateAccount.publicKey,
          poolAccountInfo.lpTokenMint,
          poolAccountInfo.feeAccount,
          userLpTokenAddress,
          poolAccountInfo.token0Account,
          poolAccountInfo.token1Account,
          userToken0Address,
          userToken1Address,
          tokenSwapProgramId,
          TOKEN_PROGRAM_ID,
          new BN(lpTokenAmount),
          new BN(token0Amount),
          new BN(token1Amount),
          isSaros
        )
      transaction.add(withdrawInstruction)

      if (newToken0Mint.toString() === NATIVE_SOL.mintAddress) {
        transaction.add(
          closeAccount({
            source: userToken0Address,
            destination: payerAccount.publicKey,
            owner: payerAccount.publicKey
          })
        )
      }

      if (newToken1Mint.toString() === NATIVE_SOL.mintAddress) {
        transaction.add(
          closeAccount({
            source: userToken1Address,
            destination: payerAccount.publicKey,
            owner: payerAccount.publicKey
          })
        )
      }

      const tx = await sendRawTxn({ transactions: transaction })

      const { isError, data, error } = tx

      if (isError) {
        return { isErr: true, data: error }
      }

      callback && callback(data, poolAddress.toString())

      return { isErr: false, data: tx }
    } catch (err) {
      return { isErr: false, data: err }
    }
  }

  static async depositAllTokenTypesNew (
    sendRawTxn,
    connection,
    payerAccount,
    delegateAccount,
    userAddress,
    token0Mint,
    token1Mint,
    token0AmountWei,
    token1AmountWei,
    lpTokenAmount,
    poolAddress,
    tokenSwapProgramId,
    slippage = 0.1,
    callBack,
    isSaros
  ) {
    const transaction = new Transaction()
    const signers = [payerAccount]
    const poolAccountInfo = await SarosSwapService.getPoolInfo(
      connection,
      poolAddress
    )

    const isEqualToken0 = token0Mint.toString() === get(poolAccountInfo, 'token0Mint', '').toString()
    const isEqualToken1 = token1Mint.toString() === get(poolAccountInfo, 'token1Mint', '').toString()
    const newToken0Mint = isEqualToken0 ? token0Mint : token1Mint
    const newToken1Mint = isEqualToken1 ? token1Mint : token0Mint
    const newAmount0Mint = isEqualToken0 ? token0AmountWei.toNumber() : token1AmountWei.toNumber()
    const newAmount1Mint = isEqualToken1 ? token1AmountWei.toNumber() : token0AmountWei.toNumber()

    const userToken0Address =
      await TokenProgramService.resolveOrCreateAssociatedTokenAddress(
        connection,
        payerAccount.publicKey,
        newToken0Mint,
        transaction,
        signers,
        newAmount0Mint
      )

    const userToken1Address =
      await TokenProgramService.resolveOrCreateAssociatedTokenAddress(
        connection,
        payerAccount.publicKey,
        newToken1Mint,
        transaction,
        signers,
        newAmount1Mint
      )

    const poolLpMintInfo = await TokenProgramService.getTokenMintInfo(
      connection,
      poolAccountInfo.lpTokenMint
    )
    const lpTokenSupply = parseFloat(poolLpMintInfo.supply.toString())

    const poolToken0AccountInfo = await TokenProgramService.getTokenAccountInfo(
      connection,
      poolAccountInfo.token0Account
    )

    const newAmount0 = Math.floor(
      (parseFloat(poolToken0AccountInfo.amount.toString()) * lpTokenAmount) /
        lpTokenSupply
    )
    const token0Amount = Math.floor(
      newAmount0 + renderAmountSlippage(newAmount0, slippage)
    )
    const poolToken1AccountInfo = await TokenProgramService.getTokenAccountInfo(
      connection,
      poolAccountInfo.token1Account
    )

    const newAmount1 = Math.floor(
      (parseFloat(poolToken1AccountInfo.amount.toString()) * lpTokenAmount) /
        lpTokenSupply
    )
    const token1Amount = Math.floor(
      newAmount1 + renderAmountSlippage(newAmount1, slippage)
    )

    const [poolAuthorityAddress] =
      await SarosSwapService.findPoolAuthorityAddress(
        poolAddress,
        tokenSwapProgramId
      )
    const userLpTokenAddress =
      await TokenProgramService.findAssociatedTokenAddress(
        userAddress,
        poolAccountInfo.lpTokenMint
      )

    if (!(await SolanaService.isAddressInUse(connection, userLpTokenAddress))) {
      const createATPATransaction =
        await TokenProgramInstructionService.createAssociatedTokenAccountTransaction(
          payerAccount.publicKey,
          userAddress,
          poolAccountInfo.lpTokenMint
        )
      transaction.add(createATPATransaction.instructions[0])
    }

    const depositInstruction =
      await SarosSwapInstructionService.depositAllTokenTypesInstruction(
        poolAddress,
        poolAuthorityAddress,
        delegateAccount.publicKey,
        userToken0Address,
        userToken1Address,
        poolAccountInfo.token0Account,
        poolAccountInfo.token1Account,
        poolAccountInfo.lpTokenMint,
        userLpTokenAddress,
        tokenSwapProgramId,
        TOKEN_PROGRAM_ID,
        new BN(lpTokenAmount),
        new BN(token0Amount),
        new BN(token1Amount),
        isSaros
      )

    transaction.add(depositInstruction)

    if (newToken0Mint.toString() === NATIVE_SOL.mintAddress) {
      transaction.add(
        closeAccount({
          source: userToken0Address,
          destination: payerAccount.publicKey,
          owner: payerAccount.publicKey
        })
      )
    }

    if (newToken1Mint.toString() === NATIVE_SOL.mintAddress) {
      transaction.add(
        closeAccount({
          source: userToken1Address,
          destination: payerAccount.publicKey,
          owner: payerAccount.publicKey
        })
      )
    }

    const tx = await sendRawTxn({ transactions: transaction })

    const { isError, data, error } = tx

    if (isError) {
      return { isErr: true, data: error }
    }

    callBack && callBack(data, poolAddress.toString())

    return { isErr: false, data: tx }
  }

  static async swapSaros (
    connection,
    userTokenSourceAddress,
    userTokenDestinationAddress,
    amountIn,
    minimumAmountOut,
    hostFeeOwnerAddress,
    poolAddress,
    tokenSwapProgramId,
    walletAddress,
    fromCoinMint,
    toCoinMint,
    toastNotiWait,
    transaction
  ) {
    const owner = await genOwnerSolana(walletAddress)
    const [poolAuthorityAddress] =
      await SarosSwapService.findPoolAuthorityAddress(
        poolAddress,
        tokenSwapProgramId
      )
    const poolAccountInfo = await SarosSwapService.getPoolInfo(
      connection,
      poolAddress
    )
    let fromMint = fromCoinMint
    let toMint = toCoinMint

    if (fromMint === NATIVE_SOL_RAYDIUM.mintAddress) {
      fromMint = TOKENS.WSOL.mintAddress
    }
    if (toMint === NATIVE_SOL_RAYDIUM.mintAddress) {
      toMint = TOKENS.WSOL.mintAddress
    }

    let poolTokenSourceAddress = null
    let poolTokenDestinationAddress = null
    if (fromMint === poolAccountInfo.token0Mint.toBase58()) {
      poolTokenSourceAddress = poolAccountInfo.token0Account
    }
    if (fromMint === poolAccountInfo.token1Mint.toBase58()) {
      poolTokenSourceAddress = poolAccountInfo.token1Account
    }
    if (toMint === poolAccountInfo.token0Mint.toBase58()) {
      poolTokenDestinationAddress = poolAccountInfo.token0Account
    }
    if (toMint === poolAccountInfo.token1Mint.toBase58()) {
      poolTokenDestinationAddress = poolAccountInfo.token1Account
    }

    const swapInstruction =
      await SarosSwapInstructionService.createSwapInstruction(
        poolAddress,
        poolAuthorityAddress,
        owner.publicKey,
        new PublicKey(userTokenSourceAddress),
        poolTokenSourceAddress,
        poolTokenDestinationAddress,
        new PublicKey(userTokenDestinationAddress),
        poolAccountInfo.lpTokenMint,
        poolAccountInfo.feeAccount,
        null,
        tokenSwapProgramId,
        TOKEN_PROGRAM_ID,
        new BN(amountIn),
        new BN(minimumAmountOut)
      )

    transaction.add(swapInstruction)
    if (fromMint.toString() === NATIVE_SOL.mintAddress) {
      const closeAccountInstructions = TokenProgramService.closeAccountSol(
        owner,
        new PublicKey(userTokenSourceAddress)
      )
      transaction.add(closeAccountInstructions)
    }

    if (toMint.toString() === NATIVE_SOL.mintAddress) {
      const closeAccountInstructions = TokenProgramService.closeAccountSol(
        owner,
        new PublicKey(userTokenDestinationAddress)
      )
      transaction.add(closeAccountInstructions)
    }

    return transaction
  }

  static getInfoByMintAddress (mintAddress) {
    const TOKEN_LIST = window.walletServices.tokenSolana
    const info = TOKEN_LIST.find((item) => item.mintAddress === mintAddress)
    return info
  }

  static async createAccountPreSwapRoute (
    sendRawTxn,
    connection,
    owner,
    fromMint,
    middleMint,
    toMint,
    callBack,
    callBackFinal
  ) {
    const transaction = new Transaction()
    const signers = [owner]
    const listUniq = union([
      fromMint.toString(),
      middleMint.toString(),
      toMint.toString()
    ])
    await Promise.all(
      listUniq.map(async (item) => {
        await TokenProgramService.resolveOrCreateAssociatedTokenAddress(
          connection,
          owner.publicKey,
          new PublicKey(item),
          transaction,
          signers
        )
      })
    )

    const tx = await sendRawTxn({ transactions: transaction, isWaitDone: true, callBackNotWaitDone: callBack, callBack: callBackFinal, commitment: 'finalized' })

    const { isError, data, error } = tx

    if (isError) {
      return { isErr: true, data: error }
    }

    return { isErr: false, data: tx }

    // const tx = await postBaseSendTxsNew(
    //   connection,
    //   transaction,
    //   signers,
    //   true,
    //   callBack,
    //   callBackFinal
    // )
    // const { isErr, data } = tx

    // if (isErr) {
    //   return { isErr: true, data }
    // }

    // return { isErr: false, data: tx }
  }
}
