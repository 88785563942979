import {
  convertWeiToBalance,
  formatBilion,
  formatNumberBro,
  formatPrice,
  upperCase
} from 'common/functions'
import { PoolInfoContext } from 'context/poolInfo/poolInfoContext'
import { get } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'

function InfoDetailPool () {
  const { t } = useTranslation()
  const poolInfoContext = useContext(PoolInfoContext)
  const { poolInfo, userInfoInPool } = poolInfoContext

  const [shareOfPool, setShareOfPool] = useState(0)
  const [userLpInPoolUSD, setUserLpInPoolUSD] = useState(0)

  const token0 = get(poolInfo, 'info.token0')
  const token1 = get(poolInfo, 'info.token1')
  const amountToken0 = convertWeiToBalance(
    get(userInfoInPool, 'amountToken0InPool', 0).toString(),
    get(token0, 'decimals')
  )
  const amountToken1 = convertWeiToBalance(
    get(userInfoInPool, 'amountToken1InPool', 0).toString(),
    get(token1, 'decimals')
  )

  useEffect(() => {
    intData()
  }, [userInfoInPool, poolInfo])

  const intData = () => {
    const decimalsLp = get(poolInfo, 'lpInfo.decimals', '')
    const priceLp = get(poolInfo, 'priceLp')
    const lpTokenSupply = parseFloat(
      convertWeiToBalance(
        get(poolInfo, 'lpInfo.supply', '').toString(),
        decimalsLp
      )
    )
    const amountLpTokenUserInPoolWei = get(userInfoInPool, 'amount', '')
    const amountLpTokenUserInPool = convertWeiToBalance(
      amountLpTokenUserInPoolWei,
      decimalsLp
    )
    const shareOfPool =
      (parseFloat(amountLpTokenUserInPool) /
        (parseFloat(amountLpTokenUserInPool) + lpTokenSupply)) *
      100
    setShareOfPool(shareOfPool)
    setUserLpInPoolUSD(parseFloat(amountLpTokenUserInPool) * priceLp)
  }

  return (
    <div className={styles['info-detail-pool']}>
      <div className="item-info">
        <div className="item-info__number color-normal">
          {formatNumberBro({ number: shareOfPool, mantissa: 2 })}%
        </div>

        <div className="item-info__title text-xs">{t('yourPoolShare')}</div>
      </div>

      <div className="item-info">
        <div className="item-info__number color-normal">
          {formatPrice(userLpInPoolUSD)}
        </div>

        <div className="item-info__title text-xs">{t('yourPoolToken')}</div>
      </div>

      <div className="item-info">
        <div className="item-info__number color-normal">
          {formatNumberBro({ number: amountToken0 })}{' '}
          {upperCase(get(token0, 'symbol'))}
        </div>

        <div className="item-info__title text-xs">{t('pooled')}</div>
      </div>

      <div className="item-info">
        <div className="item-info__number color-normal">
          {formatNumberBro({ number: amountToken1, mantissa: 2 })}{' '}
          {upperCase(get(token1, 'symbol'))}
        </div>

        <div className="item-info__title text-xs">{t('pooled')}</div>
      </div>
    </div>
  )
}

export default InfoDetailPool
