
import React from 'react'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import images from 'assets/images'
import VideoContainer from 'components/VideoContainer'
import useScreenWidth from 'hooks/useScreenWidth'

const SnapshotBanner = () => {
  const { t } = useTranslation()
  const { width } = useScreenWidth()
  const isMobile = width < 900
  return (
    <div className={styles.bannerContainer}>
      <div className='snapshot-banner'>
        <div className='snapshot-banner__title'>{t('activityCampaigns')}</div>
        <div className='snapshot-banner__description'>{t('snapshotDescription')}</div>
        <div className="flower-lottie">
          {
            isMobile
              ? <img
                className='snapshot-gif'
                src={images.sarosSnapshotGif}
              />
              : <VideoContainer
                src={images.sarosSnapshotVideo}
                className="flower-video mobile-hidden"
              />
          }

        </div>

      </div>
    </div>
  )
}

export default SnapshotBanner
