import { PublicKey } from '@solana/web3.js'
import { convertWeiToBalance } from 'common/functions'
import { SarosSwapInstructionService } from 'common/pool/saros_swap/sarosSwapIntructions'
import { TokenProgramInstructionService } from 'common/pool/tokenProgramInstructionService'
import { TokenProgramService } from 'common/pool/tokenProgramService'
import {
  fetchMultipleAccount,
  genConnectionSolana,
  genOwnerSolana
} from 'common/solana'
import BaseAPI from 'controller/api/BaseAPI'
import useLiquidity from 'hooks/liquidity/useLiquidity'
import { chunk, get, isArray, set } from 'lodash'
import moment from 'moment'
import React, { createContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

const PoolInfoContext = createContext()

const PoolInfoProvider = ({ children }) => {
  const history = useHistory()
  const { address } = useParams()
  const liquidityHook = useLiquidity({ isFetchListToken: false })
  const accountSol = useSelector((state) => state.accountSol)

  const [isLoadingScreen, setIsLoadingScreen] = useState(true)
  const [poolInfo, setPoolInfo] = useState(null)
  const [dataChartLiquidity, setDataChartLiquidity] = useState([])
  const [dataChartVolume, setDataChartVolume] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [userInfoInPool, setUserInfoInPool] = useState(null)
  const currencyRedux = useSelector(state => state.currencyRedux) // needed to rerender onChange Currency

  useEffect(() => {
    getPoolChart()
  }, [address])

  useEffect(() => {
    // TRIGGER RERENDER CHART
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
  }, [currencyRedux])

  useEffect(() => {
    getPoolInfo()
  }, [address, accountSol])

  useEffect(() => {
    if (accountSol && poolInfo) {
      updateDataUserInPool(poolInfo)
    }
  }, [poolInfo, accountSol])

  const getPoolInfo = async () => {
    setIsLoadingScreen(true)

    const response = await liquidityHook.getInfoPoolLiquidity({
      poolAddress: address,
      isFetchDataOnchain: true
    })

    if (!response) return goToNotFoundScreen()
    setPoolInfo(response)
    setIsLoadingScreen(false)
  }

  const updateDataUserInPool = async (poolInfo) => {
    const response = await liquidityHook.updateDataUserInPool(poolInfo)
    setUserInfoInPool(response)
  }

  const getPoolChart = async () => {
    const response = await BaseAPI.postData('saros/pool/chart', {
      poolAddress: address
    })
    if (!isArray(response)) return
    const arrLiquidity = response.map((item) => ({
      number: item.totalLiquidity,
      date: moment(item.date).unix()
    }))
    const arrVolume = response.map((item) => ({
      number: item.volume,
      date: moment(item.date).unix()
    }))
    setDataChartLiquidity(arrLiquidity)
    setDataChartVolume(arrVolume)
  }

  const goToNotFoundScreen = () => {
    history.push('/not-found')
  }

  return (
    <PoolInfoContext.Provider
      value={{
        isLoadingScreen,
        poolInfo,
        dataChartLiquidity,
        dataChartVolume,
        setIsLoading,
        isLoading,
        address,
        userInfoInPool
      }}
    >
      {children}
    </PoolInfoContext.Provider>
  )
}

export { PoolInfoProvider, PoolInfoContext }
