/* eslint-disable react/react-in-jsx-scope */
import { PublicKey } from '@solana/web3.js'
import { historyType } from 'common/farm/functions'
import { SarosFarmService } from 'common/farm/SarosFarmService'
import { getItemStorage, setItemStorage } from 'common/functions'
import { SAROS_FARM_ADDRESS } from 'common/pool/constants'
import { TokenProgramService } from 'common/pool/tokenProgramService'
import { genConnectionSolana, genOwnerSolana } from 'common/solana'
import BaseAdapter from 'controller/api/BaseAdapter'
import BaseAPI from 'controller/api/BaseAPI'
import BaseAPINew from 'controller/api/BaseApiNew'
import useServices from 'controller/Library/useServices'
import useFarmHook from 'hooks/farm/useFarmHook'
import useStatusTxsHash from 'hooks/useStatusTxsHash'
import { get } from 'lodash'
import HarvestModal from 'pages/NewFarmScreen/Components/Modals/HarvestModal'
import { createContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const FarmContext = createContext()

const UN_STAKE = 'unStake'
const STAKE = 'stake'
const HARVEST = 'harvest'

const FarmProvider = ({ children }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const farmActions = useFarmHook()
  const statusHash = useStatusTxsHash()
  const { sendRawTxn } = useServices()

  const isStakeScreen = get(history, 'location.pathname', '').includes(
    '/stake'
  )

  const accountSol = useSelector((state) => state.accountSol)

  const [listFarm, setListFarm] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingUser, setIsLoadingUser] = useState(false)
  const [isLoadingStake, setIsLoadingStake] = useState(false)
  const [isLoadingUnStake, setIsLoadingUnStake] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [dataFarmStake, setDataFarmStake] = useState({
    farm: [],
    stake: []
  })
  const [totalValueStaked, setTotalValueStaked] = useState({
    farm: 0,
    stake: 0
  })

  const updateDataUserInfoInPool = async (pools = listFarm, isLoading) => {
    try {
      if (accountSol) {
        setIsLoadingUser(isLoading)
        const newList = await farmActions.updateDataUserInfoInPool(pools)
        setListFarm(newList)
        setIsLoadingUser(false)
        setDataFarmStake({
          ...dataFarmStake,
          [isStakeScreen ? 'stake' : 'farm']: newList
        })
      } else {
        setListFarm(pools)
        setDataFarmStake({
          ...dataFarmStake,
          [isStakeScreen ? 'stake' : 'farm']: pools
        })
      }
    } catch (err) {
      console.log('err pools', { pools, err })
    }
  }

  const getTotalValueStaked = async () => {
    const hasValue = get(
      totalValueStaked,
      `${isStakeScreen ? 'stake' : 'farm'}`,
      0
    )
    if (hasValue) return
    const response = await BaseAdapter.getData(
      `saros/${isStakeScreen ? 'stake' : 'farm'}/totalValue`
    )
    if (!get(response, 'success', false)) return
    setTotalValueStaked({
      ...totalValueStaked,
      [`${isStakeScreen ? 'stake' : 'farm'}`]: get(response, 'data', 0)
    })
  }

  const getListPoolStake = async () => {
    const response = await BaseAPINew.getData('saros/information', {
      size: 100,
      type: 'stake'
    })
    if (!response) return []

    return response
  }

  const getListPool = async () => {
    setIsLoading(true)
    getTotalValueStaked()

    let response
    if (isStakeScreen) {
      response = await getListPoolStake()
    } else {
      response = await BaseAPINew.getData('saros/information', { size: 100 })
    }
    if (!response) return setIsLoading(false)

    const pool = response.filter(
      (item) =>
        item.poolAddress === '7Lft2TJG7KjzKkiZtZ9AeyRCX8PXiGDnbptRW16RMa6R'
    )
    try {
      const connection = genConnectionSolana()
      const currentBlock = await connection.getSlot()
      // saved if solana fetch fail to use the next time
      setItemStorage(currentBlock, 'CURRENT_BLOCK')
      const arrTemp = await Promise.all(
        response.map(async (pool) => {
          const { isErr, data } = await farmActions.fetchDetailPool(
            pool,
            currentBlock
          )
          if (isErr) return []
          return data
        })
      )
      setIsLoading(false)
      setListFarm(arrTemp)
      updateDataUserInfoInPool(arrTemp, true)
      setIsFirstRender(false)
      return arrTemp
    } catch (err) {
      const arrTemp = await Promise.all(
        response.map(async (pool) => {
          const { isErr, data } = await farmActions.fetchDetailPool(
            pool,
            getItemStorage('CURRENT_BLOCK') || 144444444
          )
          if (isErr) return []
          return data
        })
      )
      setIsLoading(false)
      setListFarm(arrTemp)
    }
  }

  const updatePoolInfoLocal = ({ dataFarm }) => {
    const listAll = [...listFarm]
    const index = listAll.findIndex(
      (item) => item.poolAddress === dataFarm.poolAddress
    )
    listAll[index] = dataFarm
    setListFarm(listAll)
  }

  const handleClaimReward = async ({ dataReward }) => {
    const connection = genConnectionSolana()
    const owner = await genOwnerSolana(accountSol)
    const poolRewardAddress = new PublicKey(
      get(dataReward, 'poolRewardAddress')
    )
    const mintAddress = new PublicKey(get(dataReward, 'mintAddress'))
    const userRewardTokenAccount =
      await TokenProgramService.findAssociatedTokenAddress(
        owner.publicKey,
        mintAddress
      )
    const [userPoolRewardAddress] =
      await SarosFarmService.findUserPoolRewardAddress(
        owner.publicKey,
        poolRewardAddress,
        new PublicKey(SAROS_FARM_ADDRESS)
      )
    const rewards = [{ ...dataReward }]

    const callBackWait = (res) => {
      const { data, error, isError } = res
      if (isError) {
        onTradeFail(error)
      } else {
        onTradeSuccess(data, dataReward)
      }
    }

    const callBackNotWait = (res) => {
      handleWaitSuccess(res?.data, rewards, dataReward)
    }

    await SarosFarmService.claimReward(
      sendRawTxn,
      connection,
      owner,
      poolRewardAddress,
      userPoolRewardAddress,
      userRewardTokenAccount,
      new PublicKey(SAROS_FARM_ADDRESS),
      mintAddress,
      callBackNotWait,
      callBackWait
    )
  }

  const handleClaimAll = async ({ dataFarm }) => {
    const connection = genConnectionSolana()
    const owner = await genOwnerSolana(accountSol)
    const rewards = get(dataFarm, 'rewards', [])

    const callBackWait = (res) => {
      const { data, error, isError } = res
      if (isError) {
        onTradeFail(error)
      } else {
        onTradeSuccess(data, dataFarm)
      }
    }

    const callBackNotWait = (res) => {
      handleWaitSuccess(res?.data, rewards, dataFarm)
    }

    const response = await SarosFarmService.claimAllReward(
      sendRawTxn,
      connection,
      owner,
      new PublicKey(SAROS_FARM_ADDRESS),
      rewards,
      callBackNotWait,
      callBackWait
    )
  }

  const onTradeFail = (textMess) => {
    window.closeModal()
    statusHash.handleTxsFailed({ message: t(textMess) })
  }

  const handleWaitSuccess = async (hash, rewards, dataPoolInfo) => {
    handlePostData({
      hash,
      walletAddress: accountSol,
      type: historyType.harvest,
      amount: 0,
      poolAddress: get(dataPoolInfo, 'poolAddress'),
      lpAddress: get(dataPoolInfo, 'lpInfo.address'),
      harvestData: rewards.map((item) => ({
        token: get(item, 'address'),
        amount: get(item, 'earn', 0)
      }))
    })
    window.closeModal()
    statusHash.handleTxsSuccess({ message: t('pleaseWaitOrder') })
  }

  const handlePostData = async ({
    hash,
    walletAddress,
    type,
    amount,
    poolAddress,
    lpAddress,
    harvestData = []
  }) => {
    const data = {
      hash,
      createdAddress: walletAddress,
      type,
      amount,
      poolAddress,
      lpAddress,
      harvestData
    }
    if (isStakeScreen) {
      return await BaseAPI.postData('saros/stake/history', data)
    }
    await BaseAPI.postData('saros/farm/history', data)
  }

  const onTradeSuccess = (hash, dataFarm) => {
    statusHash.viewCompleteHash({ hash })

    const rewards = get(dataFarm, 'rewards', []).map((item) => ({
      ...item,
      earn: 0
    }))

    updatePoolInfoLocal({
      dataFarm: {
        ...dataFarm,
        rewards
      }
    })
  }

  const onOpenHarvestModal =
    ({ dataFarm, dataReward }) =>
      () => {
        const rewards = get(dataFarm, 'rewards', [])
        window.openModal({
          isCustomModal: true,
          content: (
            <HarvestModal
              dataReward={dataFarm ? rewards : [dataReward]}
              handleHarvest={dataFarm ? handleClaimAll : handleClaimReward}
              dataFarm={dataFarm}
            />
          )
        })
      }
  return (
    <FarmContext.Provider
      value={{
        listFarm,
        isLoading,
        isLoadingUser,
        UN_STAKE,
        STAKE,
        updatePoolInfoLocal,
        setIsLoadingStake,
        isLoadingStake,
        setIsLoadingUnStake,
        isLoadingUnStake,
        onOpenHarvestModal,
        isStakeScreen,
        isFirstRender,
        dataFarmStake,
        getListPool,
        updateDataUserInfoInPool,
        totalValueStakedAllTime: totalValueStaked
      }}
    >
      {children}
    </FarmContext.Provider>
  )
}

export { FarmContext, FarmProvider }
