import React from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
const Tooltip = ({
  align = 'center',
  className, contentClassName, disabled = false,
  isLongContent, children, content, ...props
}) => {
  if (disabled) {
    return (
      <div key={props.key} className={cn(className, styles.tooltipContainer)}>
        {children}
        <div className={cn(contentClassName, 'tooltip-content', 'tooltip-content--hide')}>
        </div>
      </div>
    )
  }
  return (
    <div key={props.key} className={cn(className, styles.tooltipContainer)}>
      {children}
      <div
        className={cn(contentClassName,
          'tooltip-content', `tooltip-content--${align}`,
          isLongContent && 'tooltip-content--long')}
      >
        {content}
      </div>
    </div>
  )
}

export default Tooltip
