/* eslint-disable new-cap */
import { u64 } from '@solana/spl-token'
import Decimal from 'decimal.js'
import { DecimalUtil } from 'common/orca/ultils/decimalUltils'

export class OrcaU64 {
  constructor (value, scale = 0) {
    this.value = value
    this.scale = Math.floor(scale)
  }

  static fromDecimal (value, scale = 0) {
    const dec = Math.floor(scale)
    return new OrcaU64(DecimalUtil.toU64(value, dec), dec)
  }

  static fromNumber (value, scale = 0) {
    const dec = Math.floor(scale)
    return new OrcaU64(DecimalUtil.toU64(new Decimal(value), dec), dec)
  }

  static fromU64 (value, scale = 0) {
    const dec = Math.floor(scale)
    return new OrcaU64(value, dec)
  }

  static toDecimal () {
    return DecimalUtil.fromU64(this.value, this.scale)
  }

  static toNumber () {
    return DecimalUtil.fromU64(this.value, this.scale).toNumber()
  }

  static toU64 () {
    return new u64(this.value.toString())
  }
}
