import { PoolInfoProvider } from 'context/poolInfo/poolInfoContext'
import React from 'react'
import NewPoolInfoDetail from 'pages/NewInfoPool/Components/NewPoolInfoDetail'

const NewPoolInfoContent = () => {
  return (
    <PoolInfoProvider>
      <NewPoolInfoDetail />
    </PoolInfoProvider>
  )
}

export default NewPoolInfoContent
