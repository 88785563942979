import React, { useContext } from 'react'
import './style.scss'
import { get } from 'lodash'
import CountUpComponent from 'components/CountUpComponent'
import Button from 'components/common/Button'
import { useTranslation } from 'react-i18next'
import { formatNumberBro, formatPrice, upperCase } from 'common/functions'
import { FarmContext } from 'context/farm/farmContext'
import cn from 'clsx'
import ReduxServices from 'common/redux'

const TooltipListHarvest = ({ className, children, listReward, isCheckAprByAmount }) => {
  const { t } = useTranslation()
  const farmContext = useContext(FarmContext)
  const { onOpenHarvestModal } = farmContext
  return (
    <div className={cn(className, 'tooltip-wrap')}>
      <div className="tooltip-wrap__space">
        <div className="tooltip-harvest-list">
          {listReward.map((reward, index) => (
            <div
              key={get(reward, 'mintAddress', '')}
              className="tooltip-harvest-list__item"
            >
              <div className="d-flex align-items-center">
                <figure>
                  <img src={get(reward, 'icon')} alt="" />
                </figure>
                <div>
                  <div className="text-sm">
                    {upperCase(get(reward, 'symbol'))}
                  </div>
                  {!isCheckAprByAmount && (
                    <CountUpComponent
                      className="text-xs color-grey"
                      endNum={ReduxServices.getCurrencyPrice(get(reward, 'price'))}
                      isCurrency
                      decimals={3}
                    />
                  )}

                </div>
              </div>
              <div className="">
                <CountUpComponent
                  className="text-sm color-grey"
                  endNum={formatNumberBro({
                    number: get(reward, 'earn', 0),
                    mantissa: 4
                  })}
                />
                {!isCheckAprByAmount && (
                  <CountUpComponent
                    className="text-xs color-grey"
                    endNum={ReduxServices.getCurrencyPrice(
                      parseFloat(get(reward, 'earn', 0)) *
                    parseFloat(get(reward, 'price', 0))
                    )}
                    isCurrency
                  />
                )}

              </div>
              <div className="">
                <Button
                  isDisable={parseFloat(get(reward, 'earn', 0)) <= 0}
                  onClick={onOpenHarvestModal({ dataReward: reward })}
                  className="button-harvest"
                >
                  {t('harvest')}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="tooltip-children">{children}</div>
    </div>
  )
}

export default TooltipListHarvest
