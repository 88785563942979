export default {
  sureContinue: 'Are you sure you want to continue?',
  impactLarge: 'You might lose {{number}} of your assets due to price impact',
  shareLeaderboard: 'Share Leaderboard',
  farmStakeVolume: 'Farm/Stake Volume',
  errApi: 'System error. Please try again later',
  theme: 'Theme',
  userRejected: 'User rejected the request.',
  viewDetails: 'View Details',
  sarosV2Launching: 'Saros V2 is launching!',
  earliest: 'Earliest',
  latest: 'Latest',
  rank: 'Rank',
  share: 'Share',
  shareSnapshot: 'Share Snapshot',
  sarosTitle: 'Saros Finance | A DeFi Super-Network Built on Solana',
  totalLiquidity: 'Total Liquidity',
  loadingPieTooltip: 'Data will auto-refresh after {{number}} seconds. Click this Pie to fetch the latest data',
  totalVolume: 'Total Volume',
  totalTradingVolume: 'Total Trading Volume',
  totalUser: 'Total User',
  newestSnapshot: 'Newest Snapshot',
  snapshotRanking: 'Snapshot Ranking',
  myRank: 'My Rank',
  recentSwap: 'Recent Swap',
  joinDate: 'Join date',
  reward: 'Reward',
  compare: 'Compare',
  tradingVolume: 'Trading Volume',
  popular: 'Popular',
  poolShare: 'Pool Share',
  lpValue: 'LP Value',
  token1Amount: 'Token 1 Amount',
  token2Amount: 'Token 2 Amount',
  pair: 'Pair',
  tokenValue: 'Token Value',
  status: 'Status',
  swap: 'Swap',
  addLiquidity: 'Add Liquidity',
  removeLiquidity: 'Remove {{token0}} - {{token1}} Liquidity',
  stake: 'Stake',
  unStake: 'Unstake',
  harvest: 'Harvest',
  stakeFarm: 'Stake Farm',
  unStakeFarm: 'Unstake Farm',
  harvestFarm: 'Are your sure you want to harvest?',
  joinSnapshot: 'Join Snapshot',
  endSnapshot: 'Camp snapshot {{snapshot}} ended',
  shareSwap: 'Share Swap',
  shareFarm: 'Share Farm',
  shareStake: 'Share Stake',
  sarosLandingTitle: 'A Unified Suite of DeFi Products on <span class="color-theme">Solana</span>',
  sarosLandingDescription: 'One decentralized and permissionless platform for you to swap, stake, and invest with the low-cost, high efficiency and ultimate experience.',
  defiSuiteDescription: 'DeFi suite with significant synergy',
  landingSwapDescription: 'Swap any token on Solana at ease',
  landingStakeDescription: 'Stake and earn tokens. No impermanent loss',
  landingFarmDescription: 'Supply liquidity & farm to maximize yield',
  landingSnapshotDescription: 'Join Hottest activity campaigns on Saros',
  permissionless: 'Permissionless',
  capitalEffiency: 'Capital efficiency',
  friendlyUI: 'Friendly UI/UX',
  lowCost: 'Low cost',
  scalability: 'Scalability',
  composability: 'Composability',
  mission1: 'Anyone can create a liquidity pool on SarosSwap',
  mission2: 'Optimized pricing formula for better capital efficiency',
  mission3: 'Beautiful interface from the ground up',
  mission4: 'Average transaction fee less than a penny',
  mission5: 'Ultra fast transaction with low latency, at scale',
  mission6: 'Scalability and High Performance - Without sacrificing security and decentralization',
  landingMissionDescription: 'Resolving Problems & Offering The Best',
  ourBackers: 'Our Backers',
  buildTogether: "Let's build together",
  sort: 'Sort',
  address: 'Address',
  tradingApr: 'Trading APR',
  farmApr: 'Farm APR',
  totalValueAllTime: 'All Time TVL ',
  topVolume24h: 'Top 24h Volume',
  topLiquidity: 'Top Liquidity',
  copyLink: 'Copy Link',
  language: 'Language',
  currency: 'Currency',
  youWillBurn: 'You Will Burn',
  value: 'Value',
  token: 'Token',
  txsFailed: 'Failed Transaction',
  viewExplorer: 'View Explorer',
  lightDark: 'Light/Dark',
  changeWallet: 'Change Wallet',
  half: 'Half',
  learnMore: 'Learn More',
  ourProducts: 'Our Products',
  ourMission: 'Our Mission',
  tradingNow: 'Trading Now',
  supplyLiquidity: 'Supply Liquidity',
  stakeTokens: 'Stake Tokens',
  earnFees: 'Earn Fees',
  totalSupply: 'Total Supply',
  marketCap: 'Market Cap',
  swapNow: 'Swap Now',
  launchApp: 'Launch App',
  exploreSolanaEcosystem: 'Explore Solana Ecosystem Tokens',
  copied: 'Copied',
  snapshotDescription: 'Participate in trading tournaments to win massive reward with Saros',
  activityCampaigns: 'Activity Campaigns',
  addressAlreadyJoin: 'This address has already joined',
  get: 'Get',
  campEnd: 'Camp Ended',
  noWalletFound: 'No wallet found',
  snapshotCampaign: 'Snapshot Campaign',
  participated: 'Participated',
  upTo: 'Up to',
  totalReward: 'Total Rewards',
  top100TradingVolume: 'Top 100 Trading Volume',
  duration: 'Duration',
  joinNow: 'Join Now',
  upComing: 'Upcoming',
  sarosFinance: 'Saros Finance',
  onGoing: 'On Going',
  joinSuccess: 'Join Snapshot Successfully',
  myRewards: 'My Rewards',
  snapshot: 'Snapshot',
  joinSnapshotDescription: 'Are you sure you want to join this snapshot?',
  searchByAddress: 'Search by address',
  searchByNameOrAddress: 'Search by name or address',
  alreadyJoinedSnapshot: 'You have already joined this campaign',
  joined: 'Joined',
  USD: 'USD',
  JPY: 'JPY',
  AUD: 'AUD',
  BRL: 'BRL',
  CAD: 'CAD',
  CHF: 'CHF',
  CLP: 'CLP',
  CNY: 'CNY',
  CZK: 'CZK',
  DKK: 'DKK',
  EUR: 'EUR',
  GBP: 'GBP',
  HKD: 'HKD',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  INR: 'INR',
  ISK: 'ISK',
  KRW: 'KRW',
  MXN: 'MXN',
  MYR: 'MYR',
  NOK: 'NOK',
  NZD: 'NZD',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  RUB: 'RUB',
  SEK: 'SEK',
  SGD: 'SGD',
  THB: 'THB',
  TRY: 'TRY',
  TWD: 'TWD',
  ZAR: 'ZAR',
  VND: 'VND',
  EN: 'English',
  VI: 'Vietnamese',
  yourReward: 'Your Reward',
  poolToken: 'Pool Token',
  farming: 'Farming',
  managerPool: 'Manager Pool',
  managePool: 'Manage Pool',
  allTimeLow: 'All Time Low',
  allTimeHigh: 'All Time High',
  yourPoolShare: 'Your Pool Share',
  yourPoolToken: 'Your Pool Token',
  yourLpFarming: 'Your LP Farming',
  deposited: 'Deposited',
  reviewOrder: 'Review Order',
  save: 'Save',
  stakeLpToken: 'Stake LP Token',
  estFee: 'Est Fee',
  stakeNow: 'Stake Now',
  completeOrderFarm: 'Your order has been completed!',
  viewTransaction: 'View Transaction',
  totalShare: 'Total Share',
  topToken: 'Top Token',
  topTokens: 'Top Tokens',
  selectToken: 'Select Token',
  removeLiquidityActions: 'Remove liquidity',
  selectLiquidity: 'Select liquidity to remove',
  amountTokenMoreThan: 'Amount two tokens greater zero',
  amountTokenNotEnough: 'Amount two tokens not enough',
  trending: 'Trending',
  gainer: 'Gainer',
  recentlyAdded: 'Recently Added',
  startEarn: 'START EARNING',
  viewTokeInfo: 'VIEW TOKEN INFO',
  enoughLP: 'Your balance is not enough to stake',
  farmEnded: 'Farm Ended',
  farmDesc: 'Stake LP tokens to earn multiple rewards',
  earn: 'Earn',
  unclaimDesc: 'Unclaimed Rewards',
  viewContract: 'View Contract',
  unStakeLP: 'Unstake {{token}} Tokens',
  stakeLP: 'Stake {{token}} Tokens',
  stakeSuccess: 'Stake Successfully',
  unStakeSuccess: 'Unstake Successfully',
  harvestSuccess: 'Harvest Successfully',
  harvestFailed: 'Harvest Failed',
  totalReceive: 'Total Receive',
  endBlock: 'End at block',
  connectToSee: 'Connect to see your pool',
  farmEnd: 'Farm ended',
  createAccount: 'Create Account',
  search: 'Search',
  sarosPool: 'Saros Pool',
  permissionLessPool: 'Permissionless Pools',
  priceImpactMustBeLessThan: 'Price impact must be less than 50%',
  fees: 'Fees',
  apr: 'APR',
  notFoundScreen: '404 Not Found Screen',
  emptyData: 'Empty Data',
  emptyDataTable: 'Data table is empty',
  backTo: 'Back To',
  youAreAddLiquidity: 'You will receive',
  pooled: 'Pooled {{symbol}}',
  youAreTheFirst: 'You are the first liquidity provider',
  theRatioOf: 'The ratio of tokens you add will set the price of this pool',
  onceYouAre: 'If you are happy with the rate, please click supply to review',
  removeLiquidityDesc: 'To receive {{token0}} and {{token1}}',
  balance: 'Balance',
  youWillReceive: 'You Will Receive',
  name: 'Name',
  price: 'Price',
  priceChange: 'Price Change',
  lpRewardFee: 'LP Reward Fees 24h',
  lpRewardApr: 'LP Reward APR',
  add: 'Add',
  create: 'Create',
  transaction24h: 'Transaction 24h',
  confirmRemove: 'Confirm Remove',
  lpTokensIn: 'LP Tokens In Your Wallet',
  confirmCreate: 'Confirm Create',
  comingSoon: 'Coming Soon',
  lpTokens: 'LP Tokens',
  overview: 'Overview',
  pools: 'Pools',
  tokens: 'Tokens',
  wallets: 'Wallets',
  loading: 'Loading',
  inputAmount: 'Input an amount',
  pleaseResetWallet: 'Please Restore Wallet',
  totalLp: 'Total LP',
  trendingPools: 'Trending Pools',
  allPools: 'All Pools',
  remove: 'Remove',
  addLiquidityInstead: 'Add Liquidity Instead',
  enterAmount: 'Enter Amount',
  createPoolAndSupply: 'Create Pool & Supply',
  confirmSupply: 'Confirm Supply',
  youAreCreatingPool: 'You are creating a pool',
  Курс: 'Rates',
  tokenPool: '{{symbol}} Pool Tokens',
  youWillReceived: 'You Will Receive',
  supply: 'Supply',
  shareOfPool: 'Share of Pool',
  priceAndPool: 'Prices and pool share',
  amount: 'Amount',
  addLiquidityDesc: 'Add liquidity to receive LP tokens',
  findOtherLpToken: 'Find Other LP Tokens',
  dontSeePool: "Can't find the pools that you joined earlier?",
  noLiquidity: 'No liquidity found',
  removeLiquidityTo: 'Remove liquidity to receive tokens back',
  yourLiquidity: 'Your Liquidity',
  success: 'Success',
  yourOrderHasBeen: 'Your order has been completed!',
  estimateGasFee: 'Estimate Fee',
  saveSetting: 'Save Settings',
  close: 'Close',
  viewTxs: 'View Transaction',
  transactionSubmitted: 'Transaction Submitted',
  confirm: 'Confirm',
  estimate: 'Estimate',
  slippageTolerance: 'Slippage Tolerance',
  setting: 'Setting',
  convert: 'Convert',
  convertWSOL: 'Convert SOL To wSOL',
  priceImpactMustLessThan: 'Price impact must be less than 50%',
  minimumBalance: 'A minimum balance of 0.05 SOL is recommended to avoid failed transactions',
  exceedsLimit: 'Exceeds desired slippage limit',
  setup: 'Setup',
  setupDone: 'Setup Done',
  dueLimitSol: 'Due to limits on Solana transaction sizes, this step is required to create account in your wallet before swapping.',
  topPool: 'Top Pool',
  topPools: 'Top Pools',
  inputSearchText: 'Input SearchText',
  pool: 'Pool',
  liquidity: 'Liquidity',
  volume24h: '24H Volume',
  volume7d: '7D Volume ',
  fee24h: '24H Fee ',
  yearFee: '1 Year Fee/Liquidity',
  backToPool: 'Back to pools',
  totalTokenLocked: 'Total Tokens Locked',
  volume: 'Volume',
  transaction: 'Transaction',
  transactions: 'Transactions',
  all: 'All',
  adds: 'Adds',
  removes: 'Removes',
  action: 'Action',
  totalValue: 'Total Value',
  totalAmount: 'Total Amount',
  tokenAmount: 'Token Amount',
  tokenAmountFrom: 'Token Amount From',
  tokenAmountTo: 'Token Amount To',
  account: 'Account',
  time: 'Time',
  farm: 'Farm',
  staking: 'Stake',
  poolNotFound: 'Pool Not Found',
  gasSolNotEnough: 'Insufficient funds',
  tradeDone: 'Tokens have been deposited into your wallet',
  received: '{{symbol}} Received!',
  swapConfirmation: 'Swap Confirmation',
  txsFee: 'Fee: {{amount}} ~ {{amountUSD}}',
  sizeTooSmall: 'Size too small',
  tradeErrFund: 'Insufficient funds',
  timeOutTxs: 'Transaction timed out or unconfirmed. Please wait a moment and try again later.',
  feeErr: 'Your balance or gas fee is not enough',
  amountErr: 'Must not be more than spendable amount',
  yourPassword: 'Your Password',
  password: 'Password',
  pleaseWaitOrder: 'Please wait a moment for the order to be completed.',
  txsFail: 'Please try again in a few minutes.',
  unlockWallet: 'Unlock Wallet',
  wrongPass: 'Invalid password',
  tryLeft: 'You have {{num}} time(s) left',
  walletHasBeenReset: 'Your wallet has been reset',
  onlyOneChanceReset: 'You only have one chance left. Your wallet will be reset if the password is invalid.',
  unlock: 'Unlock',
  youPay: 'You Pay',
  youReceive: 'You Receive (Estimate)',
  previewOrder: 'Review Order',
  swappingToken: 'Swapping tokens…',
  depositToken: '{{symbol}} will be deposited into your wallet once the transaction is complete',
  disconnectWallet: 'Disconnect Wallet?',
  disconnect: 'Disconnect',
  chooseWallet: 'Please Choose Your Wallet',
  noWallet: 'No Wallet',
  coin98NotInstall: 'Coin98 Wallet has not been installed.',
  installCoin98: 'Install Coin98 Wallet for management wallet.',
  connectWallet: 'Connect Wallet',
  searchByToken: 'Search by token name, ticker, or address',
  gasNotEnough: 'Not enough {{name}} for gas.',
  tooLarge: 'You have submitted too many transactions, please consider to reduce.',
  searchPoolOrToken: 'Search pools or tokens',
  cancel: 'Cancel',
  sarosStake: 'Stake',
  sarosFarm: 'Farm',
  stakeDesc: 'Stake tokens to earn multiple rewards',
  farmingDescription: 'Stake LP tokens to earn C98',
  stakeTotal: 'Total Deposits',
  myReward: 'My Rewards',
  totalValueStaked: 'Total Value Staked',
  active: 'Active',
  completed: 'Completed',
  stakeOnly: 'Staked Only',
  staked: 'Staked',
  searchFarm: 'Search Farm',
  historyTransactions: 'History Transaction',
  default: 'Default',
  tradingView: 'Trading View',
  verify: 'Verify',
  verified: 'Verified',
  farmNow: 'Farm Now',
  enable: 'Enable',
  viewOnBlock: 'View on block explorer',
  pairInfo: 'Pair Info Detail',
  getC98BNB: 'Get C98 - BNB LP',
  copyAddress: 'Copy Address',
  copyAddressFail: 'Copy Address fail',
  dark: 'Dark',
  light: 'Light',
  openChart: 'Open Chart',
  hideChart: 'Hide Chart',
  closeChart: 'Close Chart',
  history: 'History',
  priceImpact: 'Price Impact',
  swapFee: 'Swap Fee',
  rate: 'Rate',
  uiFee: 'UI Fee',
  smartRoute: 'Smart Route',
  bestMatch: 'Best Match',
  totalAddressActive: 'Total Active Addresses',
  liquidityProviders: 'Liquidity Providers',
  accountFarming: 'Account Farming',
  accountStake: 'Account Staking',
  backToListWallet: 'Back To List Wallet',
  totalValueLocked: 'Total Value Locked',
  poolFarming: 'Pool Farming',
  poolStaked: 'Pool Staked'
}
