import { formatAddress, onViewOnScan } from 'common/functions'
import { Icon } from 'components/Icon'
import { get } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styles from './style.module.scss'

// props type

// listTokenInfo = [
//   {
//     symbol: '',
//     mintAddress: '',
//     type: token | pool
//   }
// ]

const InfoTokenEarn = ({ listTokenInfo = [] }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleNavigateToInfo = (token) => (event) => {
    event.stopPropagation()
    const type = get(token, 'type', 'token')

    let url = `/${type}/${get(token, 'mintAddress')}`
    switch (type) {
    case 'farm':
      url = `/farm?poolAddress=${get(token, 'mintAddress')}`
      break
    default:
      break
    }
    history.push(url)
  }

  const handleViewTokenOnScan = (token) => (event) => {
    event.stopPropagation()
    let type = 'token'
    switch (get(token, 'type')) {
    case 'pool':
    case 'farm':
      type = 'account'
      break

    default:
      type = 'token'
    }
    onViewOnScan({ address: get(token, 'mintAddress'), typeView: type })
  }
  return (
    <div className={styles['info-token-earn']}>
      <div className="tooltip-title text-l color-theme">{t('address')}</div>
      <div className="info-list text-sm">
        {listTokenInfo.map((item) => (
          <div key={get(item, 'poolRewardAddress')} className="info-token">
            <div className="info-token__symbol uppercase text-sm">
              {get(item, 'symbol', 'lp')}
            </div>
            <div className="info-token__address text-sm">
              {formatAddress(get(item, 'mintAddress'))}
            </div>
            <div className='d-flex align-items-center'>
              <Icon
                onClick={handleNavigateToInfo(item)}
                name="pool_info"
                className="info-token__icon text-l"
              />
              <Icon
                className="info-token__icon text-l"
                onClick={handleViewTokenOnScan(item)}
                name="pool_explore"
              />

            </div>

          </div>
        ))}
      </div>
    </div>
  )
}

export default InfoTokenEarn
