import SarosServices from '../liquidity/amm/saros'
import OrcaServices from '../liquidity/amm/orca'
import AldrinServices from '../liquidity/amm/aldrin'
import SaberServices from '../liquidity/amm/saber'
import RaydiumService from '../liquidity/amm/raydium'
import CremaService from '../liquidity/amm/crema'
import CropperService from '../liquidity/amm/cropper'
import MercurialSwapService from 'common/liquidity/amm/mercurial'
import OrcaWhirlpoolSwapService from 'common/liquidity/amm/orca_whirpools'

export default class FindAmmServices {
  static getAmmByType (type) {
    return FindAmmServices[type]()
  }

  static saros () {
    return SarosServices
  }

  static orca () {
    return OrcaServices
  }

  static aldrin () {
    return AldrinServices
  }

  static saber () {
    return SaberServices
  }

  static raydium () {
    return RaydiumService
  }

  static crema () {
    return CremaService
  }

  static cropper () {
    return CropperService
  }

  static mercurial () {
    return MercurialSwapService
  }

  // eslint-disable-next-line camelcase
  static orca_whirpools () {
    return OrcaWhirlpoolSwapService
  }
}

// penguin: {
//   type: 'penguin',
//   name: 'penguin',
//   image: 'https://coin98.s3.ap-southeast-1.amazonaws.com/2022-08-05+12.31.56.jpg'
// },
// mercurial: {
//   type: 'mercurial',
//   name: 'Mercurial Finance',
//   image: 'https://www.mercurial.finance/_next/static/media/logo_rounded.bd8c9a72.svg'
// },
// orca_whirpools: {
//   type: 'orca_whirpools',
//   name: 'Orca Whirlpool',
//   image: 'https://coin98.s3.ap-southeast-1.amazonaws.com/11165.png'
// }
