const initState = {
  boolTrue: true,
  boolFalse: false,
  objNull: null,
  objEmpty: {},
  arrayEmpty: [],
  stringEmpty: '',
  numberZero: 0,
  lang: 'en',
  internet: true,
  isLoading: true,
  header: {},
  initCurrency: {
    value: 1,
    label: 'USD'
  },
  interval: 240
}

export default initState
