import React, { useState, useEffect, useContext } from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
import { Icon } from 'components/Icon'
import { useTranslation } from 'react-i18next'
import PageDropdown from 'components/PageDropdown'
import { formatAddress, nFormatter } from 'common/functions'
import RechartLine from 'components/RechartLine'

import { get, uniqBy } from 'lodash'
import BaseAdapter from 'controller/api/BaseAdapter'
import moment from 'moment'
import { Link, useHistory, useParams } from 'react-router-dom'
import { WalletOverviewContext } from '../Context/WalletOverviewContext'
import TokenImage from 'components/common/TokenImage'
import ReduxServices from 'common/redux'

const arrTimes = [
  {
    name: '24h',
    value: '1D'
  },
  {
    name: '1W',
    value: '1W'
  },
  {
    name: '1M',
    value: '1M'
  }
]

const TIME_RANGE = {
  '1D': 1,
  '1W': 7,
  '1M': 30,
  '1Y': 365
}

const c98Info = {
  decimals: '6',
  icon: 'https://coin98.s3.amazonaws.com/TVF7VPTqpm6bmDqg',
  id: 'coin98',
  mintAddress: 'C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9',
  name: 'Coin98',
  price: '0.545543',
  symbol: 'C98'
}

const defaultLPInfo = {
  value: null, label: 'All Liquidity Share'
}

const DetailWalletInfo = () => {
  const { address } = useParams()
  if (!address) return null
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const width = window.innerWidth
  const isMobile = width < 576
  const overviewContext = useContext(WalletOverviewContext)
  const { selectedTab, setSelectedTab, setSearchInputAddress, lpContextInfo } = overviewContext
  const history = useHistory()

  const [timeChoose, setTimeChoose] = useState('1D')
  const [dayRequest, setDayRequest] = useState(1)
  const [dataChart, setDataChart] = useState([])
  const [walletInfo, setWalletInfo] = useState({})
  const [listLPInfo, setListLPInfo] = useState([])
  const [lpInfo, setLPInfo] = useState(defaultLPInfo)

  useEffect(() => {
    loadDataChart()
  }, [address, dayRequest, lpInfo.value])

  useEffect(() => {
    loadListLPInfo()
  }, [address])

  useEffect(() => {
    if (lpContextInfo && lpContextInfo.value) {
      setLPInfo(lpContextInfo)
    }
  }, [lpContextInfo])

  useEffect(() => {
    loadWalletInfo()
  }, [])

  const loadDataChart = async () => {
    setIsLoading(true)
    const queryBody = {
      address: address
    }
    if (lpInfo.value) {
      queryBody.poolAddress = lpInfo.value
    }
    const response = await BaseAdapter.getData('saros/wallet/tvl/chart', queryBody)
    if (response && response.success) {
      const data = get(response, 'data')
      const newdataChart = data.map(item => ({
        date: moment(item.time).unix(),
        number: item.value
      }))
      setDataChart(newdataChart)
    }

    setIsLoading(false)
  }

  const loadWalletInfo = async () => {
    const response = await BaseAdapter.getData('saros/wallet/address/info', {
      address: address
    })

    if (response && response.success) {
      setWalletInfo(response.data)
    }
  }

  const loadListLPInfo = async () => {
    const response = await BaseAdapter.getData('saros/wallet/address/lp', {
      address: address
    })
    if (response && response.success) {
      const listPoolData = get(response, 'data', [])
      const listPoolDropdown = listPoolData.map(item => {
        const token0 = get(item, 'token0', {})
        const token1 = get(item, 'token1', {})
        return {
          value: get(item, 'poolAddress'),
          label: (
            <div className='d-flex align-items-center uppercase'>
              <TokenImage src={get(token0, 'icon')} alt="" className="token-img token-img--token-1 img-24 mr-1" />
              <TokenImage src={get(token1, 'icon')} alt="" className="token-img token-img--token-2 img-24 mr-2" />
              <span>{get(token0, 'symbol')} / { get(token1, 'symbol')}</span>
            </div>
          )
        }
      })
      const updateList = [defaultLPInfo].concat(listPoolDropdown)
      setListLPInfo(updateList)
    }
  }

  // const handleChooseTime = (val) => () => {
  //   setTimeChoose(val)
  //   setDayRequest(TIME_RANGE[val])
  // }

  // const renderTimeList = () => {
  //   return (
  //     <div className='d-flex'>
  //       {arrTimes.map((item, i) => {
  //         return (
  //           <div
  //             className={`item-time ${timeChoose === get(item, 'value') && 'item-time--active'}`}
  //             key={i}
  //             onClick={handleChooseTime(get(item, 'value'))}
  //           >
  //             {get(item, 'name')}
  //           </div>
  //         )
  //       })}
  //     </div>
  //   )
  // }

  const onChangeLPAddress = (data) => {
    setLPInfo(data)
  }

  const handleNavigateBack = () => {
    history.push('/info?type=wallets')
    setSelectedTab('liquidityProviders')
    setSearchInputAddress('')
  }

  return (
    <div className={styles.detailWalletContainer}>
      <div className='box-wrapper chart-tvl'>
        <Link to="/info/wallet">
          <a className='navigate-back' onClick={handleNavigateBack}>
            <Icon name="web_arrow_left" onClick={() => {}} backgroundClassName='mr-2'/>
            <span>{t('backToListWallet')}</span>
          </a>
        </Link>

        <div className='chart-controller'>
          <div className='chart-controller__address'>{isMobile ? formatAddress(address, 10) : address} </div>
          <PageDropdown
            className="right-toggle-down"
            toggleclassName="wallet-toggle"
            menuClassName="right-menu-down"
            options={listLPInfo}
            defaultValue={lpInfo}
            onChange={onChangeLPAddress}
          />
        </div>

        <div className='chart-container'>
          <div className='chart-container__left-detail'>
            <div className='chart-info-box'>
              <div className='text-l bold'>{ReduxServices.formatPrice(walletInfo.tvl, true)}</div>
              <div className='text-xs color-grey'>{t('totalValueLocked')}</div>
            </div>
            <div className='chart-info-box'>
              <div className='text-l bold'>{nFormatter(walletInfo.poolFarming)}</div>
              <div className='text-xs color-grey'>{t('poolFarming')}</div>
            </div>
            <div className='chart-info-box'>
              <div className='text-l bold'>{nFormatter(walletInfo.poolStaked)}</div>
              <div className='text-xs color-grey'>{t('poolStaked')}</div>
            </div>
          </div>
          <div className='chart-container__right-chart'>
            <RechartLine
              isLoading={isLoading}
              // isHideInfoValue
              dataChart={dataChart}
              height={200}
              title="TVL"
            />
          </div>
        </div>

      </div>

    </div>
  )
}

export default DetailWalletInfo
