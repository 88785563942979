import images from 'assets/images'
import { getLength } from 'common/functions'
import React, { useEffect, useRef, useState } from 'react'

import './style.scss'
import cn from 'clsx'
import { Icon } from 'components/Icon'

function InputSearch ({ className, placeHolder, handleChange, initValue, handleClear, setIsOpenSearchMobile }) {
  const searchRef = useRef()
  const [widthResize, setWidthResize] = useState(window.innerWidth)

  const handleFocus = () => {
    if (widthResize >= 768) {
      searchRef && searchRef.current.focus()
    } else {
      return setIsOpenSearchMobile(false)
    }
  }

  const handleResize = () => {
    setWidthResize(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <div className={cn('search-container', className)}>
      <input
        type="text"
        className='search-input'
        ref={searchRef}
        placeholder={placeHolder || ''}
        onChange={handleChange}
        value={initValue}
      />
      {initValue
        ? (

          <img
            src={images.iconClear}
            className={'icon-input icon-clear-search icon-clear-search--show'}
            onClick={handleClear || null}
            alt="" />

        )
        : (
          // <img
          //   src={images.iconSearchGrey}
          //   className='icon-input icon-search'
          //   alt=""
          //   onClick={handleFocus}
          // />
          <Icon name="web_search_left" onClick={handleFocus}/>
        )}

    </div>
  )
}

export default InputSearch
