import React, { useMemo, useState } from 'react'
import cn from 'clsx'
import styles from './style.module.scss'
import images from 'assets/images'

const TokenImage = ({ src, className }) => {
  const [isErrImage, setIsErrImage] = useState(false)
  const renderIcon = useMemo(() => {
    if (src) return src
    return images.iconEmptyToken
  }, [src])

  const onErrorImage = () => {
    setIsErrImage(true)
  }

  if (isErrImage) {
    return <img className={cn(styles['token-image'], className)} src={images.iconEmptyToken} alt='icon' />
  }

  return (
    <img onError={onErrorImage} className={cn(styles['token-image'], className)} src={renderIcon} alt='icon' />
  )
}

export default TokenImage
