import React, { useContext, useState } from 'react'
import SettingsController from 'components/SettingsController'
import SlippageModal from 'pages/NewSwapScreen/components/SlippageModal'
import { useTranslation } from 'react-i18next'
import AddLiquidityNew from './Components/AddLiquidityNew'
import RemoveLiquidityNew from './Components/RemoveLiquidityNew'

import styles from './style.module.scss'
import { sarosSwapType } from 'common/constants'
import { LiquidityContext } from 'context/liquidity/liquidityContext'

const arrTabs = [
  {
    name: 'add',
    value: sarosSwapType.addLiquidiTy
  },
  {
    name: 'remove',
    value: sarosSwapType.removeLiquidity
  }
]

function LiquidityForm ({ title, isShort }) {
  const { t } = useTranslation()
  const liquidityContext = useContext(LiquidityContext)
  const { setSlippageValue, slippageValue, isShortcut } = liquidityContext

  const [tabChoose, setTabChoose] = useState(sarosSwapType.addLiquidiTy)

  const handleChooseTab = (tab) => () => {
    setTabChoose(tab)
  }

  const onOpenSlippageModal = (e) => {
    window.openModal({
      isCustomModal: true,
      content: (
        <SlippageModal
          setSlippageValue={setSlippageValue}
          slippageValue={slippageValue}
        />
      ),
      onCancel: () => {}
    })
  }

  const renderTab = () => {
    return arrTabs
      .filter((tab) =>
        isShortcut ? tab.value === sarosSwapType.addLiquidiTy : tab
      )
      .map((item, i) => {
        return (
          <div
            className={`tab-item tab-item--add ${
              tabChoose === item.value && 'tab-item--active color-theme'
            }`}
            onClick={handleChooseTab(item.value)}
            key={i}
          >
            {t(item.name)}
          </div>
        )
      })
  }

  const renderTabContent = () => {
    const componentData = {
      [sarosSwapType.addLiquidiTy]: <AddLiquidityNew />,
      [sarosSwapType.removeLiquidity]: <RemoveLiquidityNew />
    }
    return componentData[tabChoose]
  }

  return (
    <div className={styles['liquidity-form']}>
      <div className={`liquidity-form-wrapper ${!isShort && 'box-wrapper'}`}>
        <div className="liquidity-form-wrapper__head">
          <div
            className={`head-tab color-grey ${
              isShort && 'head-tab--shortcut color-theme'
            }`}
          >
            {t(title) || renderTab()}
          </div>

          <div className="head-actions">
            <SettingsController onClickSetting={onOpenSlippageModal} />
          </div>
        </div>

        {renderTabContent()}
      </div>
    </div>
  )
}

export default LiquidityForm
