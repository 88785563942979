import { Icon } from 'components/Icon'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import cn from 'clsx'
import CompareList from './CompareList'
import HistoryList from './HistoryList'
import { SwapContext } from '../../context/SwapContext'
import get from 'lodash/get'
import TokenImage from 'components/common/TokenImage'
import { TokenProgramService } from 'common/pool/tokenProgramService'
import { getLength } from 'common/functions'
import { isEmpty, uniq, uniqBy } from 'lodash'
import EmptyData from 'components/EmptyData'
import PuffLoading from 'components/common/PuffLoading'
import useRenderHash from 'hooks/useRenderHash'
import SnapshotRanking from './SnapshotRanking'
import SwitchButton from 'components/SwitchButton/SwitchButton'
import CustomTable from 'components/CustomTable'
import { useSelector } from 'react-redux'
import BaseAdapter from 'controller/api/BaseAdapter'

const LIST_TAB = ['compare', 'history']
const CompareSwap = ({ isVisble, isBase }) => {
  const swapContext = useContext(SwapContext)
  const viewHash = useRenderHash()
  const { swapRouterData, isLoadingSwapRouter } = swapContext
  const { t } = useTranslation()

  const [selectedTab, setSelectedTab] = useState('compare')
  const [isYourSnapshotRank, setIsYourSnapshotRank] = useState(false)
  const routerData = get(
    swapRouterData,
    isBase ? 'routerData.base' : 'routerData.pair'
  )
  const smartRouteList = get(routerData, 'router', [])
  const [listTokenInfo, setListTokenInfo] = useState([])
  const isEmpty = getLength(routerData) <= 0

  const [isLoading, setIsLoading] = useState()
  const [listSnapshotRanking, setListSnapshotRanking] = useState([])
  const [listTab, setListTab] = useState(LIST_TAB)
  const urlParams = new URLSearchParams(window.location.search)
  const base = urlParams.get('base')
  const pair = urlParams.get('pair')
  const accountSol = useSelector(state => state.accountSol)

  const unionTradeType = smartRouteList.reduce((acc, cur) => {
    const newList = []
    newList.push(cur.type)
    const listTradeType = [...acc, ...newList]
    return uniqBy(listTradeType, (it) => it.name)
  }, [])

  useEffect(() => {
    getListTokenInfo()
  }, [swapRouterData, isBase])

  useEffect(() => {
    fetchTopListSnapshot()
  }, [base, pair, isYourSnapshotRank, accountSol])

  const fetchTopListSnapshot = async () => {
    setIsLoading(true)
    const queryParams = {
      token0: base,
      token1: pair
    }

    if (isYourSnapshotRank) {
      queryParams.keyword = accountSol
    }
    const res = await BaseAdapter.getData('saros/snapshot/swap', queryParams)

    const listRank = get(res, 'data.rank', [])

    if (getLength(listRank) > 0 || isYourSnapshotRank) {
      setListTab(['compare', 'history', 'snapshotRanking'])
    } else setListTab(LIST_TAB)

    setListSnapshotRanking(listRank)
    setIsLoading(false)
  }

  const getListTokenInfo = async () => {
    const routerData = get(
      swapRouterData,
      isBase ? 'routerData.base' : 'routerData.pair'
    )
    const smartRouteList = get(routerData, 'router', [])
    const unionListToken = smartRouteList.reduce((acc, cur) => {
      const newList = []
      newList.push(cur.token0, cur.token1)
      const listToken = [...acc, ...newList]
      const uniqList = uniq(listToken)
      return uniqList
    }, [])

    const formatList = await Promise.all(
      unionListToken.map(async (item) => {
        const tokenInfo = await TokenProgramService.getInfoTokenInfoByMint(
          item
        )
        return tokenInfo
      })
    )

    setListTokenInfo(formatList)
  }

  const handleChangeTab = (item) => () => {
    setSelectedTab(item)
  }

  const handleToggleRankingSwitch = () => {
    setIsYourSnapshotRank(!isYourSnapshotRank)
  }

  if (!isVisble) return null

  const renderSmartRoute = () => {
    if (isLoadingSwapRouter) {
      return <PuffLoading />
    }
    if (isEmpty) {
      return <EmptyData isNoContent size="sm" />
    }
    return (
      <div className="swap-containter">
        <div className="d-flex align-items-center">
          <div
            className={cn(
              getLength(unionTradeType) > 1 && 'ml-3',
              'list-image-swap'
            )}
          >
            {unionTradeType?.map((item) => (
              <div key={item.name} className="list-image-swap--icon">
                <img src={item.image} />
              </div>
            ))}
          </div>

          <div className="d-flex align-items-center">
            {unionTradeType?.map((item) => (
              <div key={item.name} className="list-image-swap--name">
                {item.name}
                <span className="divide-name ml-1 mr-1">-</span>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          {listTokenInfo?.map((item) => {
            return (
              <a
                href={viewHash.renderHashByType({
                  hash: get(item, 'mintAddress'),
                  type: 'token'
                })}
                target="_blank"
                key={item.address}
                className="swap-token-info"
                rel="noreferrer"
              >
                <TokenImage
                  src={get(item, 'icon')}
                  className="swap-chain"
                  alt=""
                />
                <span className="uppercase">{get(item, 'symbol')}</span>
                <Icon name="web_arrow_right" className="arrow-right-icon" />
              </a>
            )
          })}
        </div>
      </div>
    )
  }

  // const a = true

  // if (a) {
  //   return (
  //     <CustomTable/>
  //   )
  // }

  // const isShowYourSnapshotRank = useMemo(() => {
  //   if(isYourSnapshotRank){
  //     return true
  //   }
  //   if(getLength(listSnapshotRanking) === 0){

  //   }

  // },[isYourSnapshotRank, listSnapshotRanking, selectedTab])

  const isShowYourSnapshotRank = (isYourSnapshotRank || getLength(listSnapshotRanking) > 0) && selectedTab === 'snapshotRanking'

  return (
    <div className={styles.compareSwapContainer}>
      <div className="box-wrapper">
        {t('smartRoute')}

        <div className={`d-flex align-items-center mt-3 ${isEmpty ? 'justify-content-center' : ''}`}>
          {renderSmartRoute()}
        </div>
      </div>
      <div className="box-wrapper mt-4">
        <div className={styles.tabBarContainer}>
          <div className='tab-list'>
            {listTab?.map((item, index) => {
              const isActiveTab = item === selectedTab
              return (
                <div
                  onClick={handleChangeTab(item)}
                  key={index}
                  className={cn('tab-item', isActiveTab && 'tab-item--active')}
                >
                  {t(item)}
                </div>
              )
            })}
          </div>

          {isShowYourSnapshotRank &&
            <div className="switch-wrapper">
              <SwitchButton onToggle={handleToggleRankingSwitch} isToggle={isYourSnapshotRank} />
              <div className="text-sm font-bold color-grey ml-2">
                {t('myRank')}
              </div>
            </div>
          }

        </div>
        <div className="box-wrapper__container">
          <CompareList isVisible={selectedTab === 'compare'} isBase={isBase} />
          <HistoryList isVisible={selectedTab === 'history'} />
          <SnapshotRanking
            isLoading={isLoading}
            listSnapshotRanking={listSnapshotRanking}
            isVisible={selectedTab === 'snapshotRanking'}
            isYourSnapshotRank={isYourSnapshotRank}
          />
        </div>
      </div>

    </div>
  )
}

export default CompareSwap
