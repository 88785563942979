import images from 'assets/images'
import Button from 'components/common/Button'
import { Icon } from 'components/Icon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'
import { isDapp } from 'common/constants'
import { useWalletModal } from '@coin98-com/wallet-adapter-react-ui'
import { useWallet, WALLETS_NAME } from '@coin98-com/wallet-adapter-react'

const ConnectWallet = () => {
  const { t } = useTranslation()
  const { openWalletModal } = useWalletModal()
  const { selectWallet } = useWallet()

  const onConnect = async () => {
    if (!isDapp) return openWalletModal()
    try {
      selectWallet(WALLETS_NAME.coin98Solana, 'solana')
    } catch (error) {
      console.log('🚀 ~ file: index.js:93 ~ onConnect ~ error:', error)
    }
  }

  return (
    <div className="connect-wallet">
      <div className="connect-wallet__content">
        <Icon type="lg" name="saros_connect_fail" className="icon-wallet" />
        <p className="description">{t('connectToSee')}</p>
      </div>
      <Button
        onClick={onConnect}
        isFullWidth
        className="connect-wallet__actions"
      >
        {t('connectWallet')}
      </Button>
    </div>
  )
}

export default ConnectWallet
