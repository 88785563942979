import CountdownCircle from 'components/CountdownCircle'
import { Icon } from 'components/Icon'
import Tooltip from 'components/Tooltip'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
const SettingsController = ({ onClickSetting, onClickCountdown, onCompleteCountdown }) => {
  const { t } = useTranslation()
  return (
    <div className='d-flex align-items-center'>

      <Tooltip
        content={t('setting')}
      >
        <Icon
          backgroundClassName={styles.iconSetting}
          name="web_setting"
          onClick={onClickSetting}
        />
      </Tooltip>
      <Tooltip align="right" content={t('loadingPieTooltip', { number: 20 })} isLongContent>
        <CountdownCircle onComplete={onCompleteCountdown} onClick={onCompleteCountdown}/>
      </Tooltip>

    </div>
  )
}

export default SettingsController
