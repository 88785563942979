/* eslint-disable new-cap */
import { closeAccount } from '@project-serum/serum/lib/token-instructions'
import {
  AccountLayout,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  u64
} from '@solana/spl-token'
import {
  PublicKey,
  SystemProgram,
  Transaction
} from '@solana/web3.js'
import { solToken } from 'common/orca/quote/stable-quote'
import { TokenProgramInstructionService } from 'common/pool/tokenProgramInstructionService.js'
import {
  ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
  TOKEN_PROGRAM_ID
} from './constants'
import { SolanaService } from './solanaService'

export class TokenProgramService {
  static async getTokenAccountInfo (connection, address) {
    const accountInfo = await connection.getAccountInfo(address)
    const data = TokenProgramInstructionService.decodeTokenAccountInfo(
      accountInfo.data
    )
    data.address = address
    return data
  }

  static async getTokenMintInfo (connection, address) {
    const accountInfo = await connection.getAccountInfo(address)
    if (accountInfo && accountInfo.data) {
      const data = TokenProgramInstructionService.decodeTokenMintInfo(
        accountInfo.data
      )
      data.address = address
      return data
    }
    return ''
  }

  static async findAssociatedTokenAddress (walletAddress, tokenMintAddress) {
    const [address] = await PublicKey.findProgramAddress(
      [
        walletAddress.toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        tokenMintAddress.toBuffer()
      ],
      ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    )
    return address || ''
  }

  static async createAssociatedTokenAccount (
    payerAccount,
    ownerAddress,
    tokenMintAddress
  ) {
    const transaction = new Transaction()

    const createATAInstruction =
      await TokenProgramInstructionService.createAssociatedTokenAccount(
        payerAccount,
        ownerAddress,
        tokenMintAddress
      )
    transaction.add(createATAInstruction)
    return createATAInstruction
  }

  static deserializeAccount (data) {
    if (data === undefined || data.length === 0) {
      return undefined
    }

    const accountInfo = AccountLayout.decode(data)
    accountInfo.mint = new PublicKey(accountInfo.mint)
    accountInfo.owner = new PublicKey(accountInfo.owner)
    accountInfo.amount = u64.fromBuffer(accountInfo.amount)

    if (accountInfo.delegateOption === 0) {
      accountInfo.delegate = null
      accountInfo.delegatedAmount = new u64(0)
    } else {
      accountInfo.delegate = new PublicKey(accountInfo.delegate)
      accountInfo.delegatedAmount = u64.fromBuffer(accountInfo.delegatedAmount)
    }

    accountInfo.isInitialized = accountInfo.state !== 0
    accountInfo.isFrozen = accountInfo.state === 2

    if (accountInfo.isNativeOption === 1) {
      accountInfo.rentExemptReserve = u64.fromBuffer(accountInfo.isNative)
      accountInfo.isNative = true
    } else {
      accountInfo.rentExemptReserve = null
      accountInfo.isNative = false
    }

    if (accountInfo.closeAuthorityOption === 0) {
      accountInfo.closeAuthority = null
    } else {
      accountInfo.closeAuthority = new PublicKey(accountInfo.closeAuthority)
    }

    return accountInfo
  }

  static getInfoTokenInfoByMint (mint) {
    try {
      const tokenList = window.walletServices.tokenSolana

      const info = tokenList.find((item) => item.mintAddress === mint)
      return info
    } catch (err) {
      return null
    }
  }

  static async resolveOrCreateAssociatedTokenAddress (
    connection,
    owner, // Publickey
    tokenMint, // Publickey
    transaction,
    signers,
    wrappedSolAmountIn = 0, //  wei,
    isSyncNative
  ) {
    const ata = await TokenProgramService.findAssociatedTokenAddress(
      owner,
      tokenMint
    )
    if (tokenMint.toString() === solToken.mint.toString()) {
      const rentExemptLamports = await Token.getMinBalanceRentForExemptAccount(
        connection
      )
      return await TokenProgramService.createWSOLAccount(
        connection,
        owner,
        tokenMint,
        wrappedSolAmountIn,
        rentExemptLamports,
        transaction,
        signers,
        ata,
        isSyncNative
      )
    } else {
      if (
        await SolanaService.isAddressAvailable(connection, new PublicKey(ata))
      ) {
        const createATAInstruction =
          await TokenProgramInstructionService.createAssociatedTokenAccount(
            owner,
            owner,
            tokenMint
          )
        transaction.add(createATAInstruction)
      }
      return ata
    }
  }

  static async createWSOLAccount (
    connection,
    owner,
    solMint,
    amountIn,
    rentExemptLamports, // number
    transaction,
    signers, // [],
    accountSol,
    isSyncNative
  ) {
    if (!(await SolanaService.isAddressAvailable(connection, accountSol))) {
      if (isSyncNative && amountIn > 0) {
        transaction.add(
          SystemProgram.transfer({
            fromPubkey: owner,
            toPubkey: accountSol,
            lamports: parseFloat(amountIn)
          })
        )

        const syncNativeInstruction = TokenProgramInstructionService.syncNativeInstruction(accountSol)
        transaction.add(syncNativeInstruction)
      }

      return accountSol
    }

    transaction.add(
      SystemProgram.transfer({
        fromPubkey: owner,
        toPubkey: accountSol,
        lamports: parseFloat(amountIn) + rentExemptLamports
      }),
      Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        solMint,
        accountSol,
        owner,
        owner
      )
    )
    return accountSol
  }

  static closeAccountSol (owner, addressSol) {
    const closeAccountInstructions = closeAccount({
      source: addressSol,
      destination: owner,
      owner: owner
    })
    return closeAccountInstructions
  }
}
