import { IS_DEV, SOL_SCAN_URL } from 'common/constants'

const useRenderHash = () => {
  const renderHashByType = ({ hash, type = 'txs' }) => {
    // type: txs, account, token

    const keyScan = IS_DEV ? 'devnet' : 'mainnet'
    const urlExplorer = SOL_SCAN_URL[keyScan]
    let typeView = 'tx'
    switch (type) {
    case 'account':
      typeView = 'account'
      break
    case 'token':
      typeView = 'token'
      break
    default:
      typeView = 'tx'
      break
    }
    return `${urlExplorer}/${typeView}/${hash}`
  }

  return {
    renderHashByType
  }
}

export default useRenderHash
