import images from 'assets/images'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ButtonConnected from '../ButtonConnected'
import { Icon } from 'components/Icon'
import cn from 'clsx'

import './style.scss'
import PageDropdown from 'components/PageDropdown'
import { getLength } from 'common/functions'
import SidebarDropdown from 'components/Header/SidebarDropdown'

const SidebarMenuMobile = ({
  chooseUrl, menus, isOpen, handleNavigateUrl,
  handleOpenMenu, langOptions, langValue, onChangeLang, currencyOptions, currencyValue, onChangeCurrency
}) => {
  const isDarkMode = useSelector(state => state.themeRedux) === 'dark'
  const { t } = useTranslation()

  const listFooter = [
    {
      name: 'Docs',
      isComing: false,
      link: 'https://docs.saros.finance/'
    },
    {
      name: 'Blog',
      isComing: false,
      link: 'https://blog.saros.finance/'
    },
    {
      name: 'Twitter',
      isComing: false,
      link: 'https://twitter.com/Saros_Finance'
    },
    {
      name: 'Telegram',
      isComing: false,
      link: 'https://t.me/saros_finance'
    },
    {
      name: 'Discord',
      isComing: false,
      link: 'https://saros.link/discord'
    }
    // {
    //   name: 'Brand Assets',
    //   isComing: false,
    //   link: 'https://saros.link/logo'
    // }
  ]

  const handleNavigate = (url) => () => {
    handleNavigateUrl(url)()
    handleOpenMenu()
    window.closeListTokenMobile()
  }

  const checkActiveUrl = (menu) => {
    const findIdx = menu.subUrl?.findIndex(item => chooseUrl.includes(item))
    return findIdx >= 0
  }

  return (
    <div className={`sidebar-menu-mobile ${isOpen && 'sidebar-menu-mobile--open'} ${!isOpen && 'zero-height'}`}>
      {/* <div className="menu-item menu-item--btn-connect">
        <ButtonConnected/>
      </div> */}
      <div className='sidebar-menu-mobile__content'>
        <SidebarDropdown menus={menus} chooseUrl={chooseUrl} handleOpenMenu={handleOpenMenu}/>
        {/* {menus.map((item, index) => {
          const isSubmenu = getLength(item?.subMenu) > 0
          return (
            !isSubmenu
              ? <div key={index} onClick={handleNavigate(item.url)} className="menu-item ">
                <div className={cn((chooseUrl === item.url || checkActiveUrl(item)) ? 'color-theme' : 'color-grey')}>{item.name} </div>
                <Icon name="web_arrow_right" className="ml-1 text-l color-normal"/>
              </div>
              : renderSubmenu(item)

          )
        }
        )} */}

        <div className='select-dropdown mt-2'>
          <div className='color-grey'>{t('language')}</div>
          <PageDropdown
            toggleclassName="toggle-lang-btn"
            prefix={<Icon name="web_global" className="text-2xl"/>}
            options={langOptions}
            defaultValue={langValue}
            onChange={onChangeLang}
          />
        </div>

        <div className='select-dropdown'>
          <div className='color-grey'>{t('currency')}</div>
          <PageDropdown
            toggleclassName="toggle-lang-btn"
            menuClassName={'currency-menu'}
            prefix={<Icon name="web_market_cap" className="text-2xl"/>}
            dropdownItemClassName="item-curency"
            options={currencyOptions}
            defaultValue={currencyValue}
            onChange={onChangeCurrency}
          />
        </div>

        <div className='list-footer'>
          {listFooter.map((item, index) => (
            <div
              key={index}
              className='mb-4 '
            >
              <a
                href={item.link}
                className={`item color-grey ${!item.link && 'item--disabled'}`}
              >
                {item.name}

                {item.isComing && (
                  <div className="tooltip-coming">
                    <div className="tooltip-coming__content">
                      <div className="trigle-up"></div>
                  Coming Soon
                    </div>
                  </div>
                )}
              </a>
            </div>

          ))}
        </div>

      </div>

    </div>
  )
}

export default SidebarMenuMobile
