import { chainType, CHAIN_DATA } from 'common/constants'
import React, { useEffect, useState } from 'react'
import { FEE_SOL } from 'common/solana'
import ReduxServices from 'common/redux'
import { formatNumberBro } from 'common/functions'
import { useSelector } from 'react-redux'

const useRateFee = () => {
  const [solPrice, setSolPrice] = useState()
  const currencyRedux = useSelector(state => state.currencyRedux)

  const getRateFee = async () => {
    const price = await window.walletServices.findCoinGeckoPrice(
      CHAIN_DATA[chainType.solana].id
    ) // sol price
    setSolPrice(price)
  }

  useEffect(() => {
    getRateFee()
  }, [])

  return {
    fee: ReduxServices.convertToFiat(FEE_SOL * solPrice, 4),
    numFee: FEE_SOL * solPrice,
    feeSol: `${FEE_SOL} SOL`,
    feeUSD: formatNumberBro({ number: FEE_SOL * solPrice, mantissa: 4 }),
    feeFull: `${FEE_SOL} SOL ~ ${ReduxServices.formatPrice(
      FEE_SOL * solPrice
    )}`
  }
}

export default useRateFee
