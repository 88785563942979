import React, { useState, useEffect, useMemo, useRef, useContext } from 'react'
import CustomTable from 'components/CustomTable'
import cn from 'clsx'
import { formatAddress, formatBilion, formatBillion, formatNumberBro, getLength } from 'common/functions'
import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'
import get from 'lodash/get'
import TokenImage from 'components/common/TokenImage'
import Pagination from 'components/Pagination'
import { WalletOverviewContext } from '../Context/WalletOverviewContext'
import ReduxServices from 'common/redux'
import { useSelector } from 'react-redux'

const LIMIT = 10

function TableLiquidityProvider ({ address, tableData, isLoading, totalPage, onChangePage, currPage, onClickRow }) {
  const { t } = useTranslation()
  // const windowRef = React.useRef(window)
  // const { width } = useElementWidth(windowRef)
  const currencyRedux = useSelector(state => state.currencyRedux) // needed to rerender onChange Currency

  const width = window.innerWidth
  const widthResize = width
  const isMobile = width < 768

  const overviewContext = useContext(WalletOverviewContext)
  const { onNavigateToDetailWallet } = overviewContext

  const dataLPTable = useMemo(() => {
    if (getLength(tableData) === 0) return []
    return tableData.map((item, index) => {
      return {
        index: LIMIT * (currPage - 1) + index + 1,
        poolAddress: get(item, 'poolAddress'),
        account: item.address,
        pair: get(item, 'poolInfo', {}),
        token0Amount: { tokenInfo: get(item, 'poolInfo.token0', {}), amount: item.token0Amount },
        token1Amount: { tokenInfo: get(item, 'poolInfo.token1', {}), amount: item.token1Amount },
        value: item.amountUSD,
        poolShare: item.poolShare
      }
    })
  }, [tableData, currencyRedux])

  const handleChangePage = (val) => {
    const { selected } = val
    onChangePage && onChangePage(selected + 1)
  }

  const columnHeaderTopToken = [
    {
      title: '',
      dataIndex: 'index',
      width: 2,
      hide: widthResize <= 768,
      render: (val) => {
        return <div>{val}</div>
      }
    },
    {
      title: t('account'),
      dataIndex: 'account',
      width: 20,
      render: (val) => {
        return <div className='color-theme'>{formatAddress(val, isMobile ? 6 : 10) }</div>
      }
    },
    {
      title: t('pair'),
      dataIndex: 'pair',
      width: 15,
      render: (val) => {
        const { token0, token1 } = val
        return (
          <div className='d-flex align-items-center uppercase'>
            <TokenImage src={get(token0, 'icon')} alt="" className="token-img token-img--token-1 img-24 mr-1" />
            <TokenImage src={get(token1, 'icon')} alt="" className="token-img token-img--token-2 img-24 mr-2" />
            <span className='mobile-hidden'>{get(token0, 'symbol')} / { get(token1, 'symbol')}</span>
          </div>
        )
      }
    },
    {
      title: t('token1Amount'),
      dataIndex: 'token0Amount',
      width: 15,
      align: 'right',
      hide: width < 1200,
      render: (val) => {
        const { tokenInfo, amount } = val
        return (
          <div className='uppercase truncate-table-text'>{formatBillion(amount, 2)} {get(tokenInfo, 'symbol')}</div>
        )
      }
    },
    {
      title: t('token2Amount'),
      dataIndex: 'token1Amount',
      width: 15,
      align: 'right',
      hide: width < 1200,

      render: (val) => {
        const { tokenInfo, amount } = val
        return (
          <span className='uppercase truncate-table-text'>{formatBillion(amount, 2)} {get(tokenInfo, 'symbol')}</span>
        )
      }
    },
    {
      title: t('lpValue'),
      dataIndex: 'value',
      hide: widthResize <= 768,
      width: 15,
      align: 'right',
      render: (val) => {
        return <div>{ReduxServices.formatPrice(val)}</div>
      }
    },
    {
      title: t('poolShare'),
      dataIndex: 'poolShare',
      align: 'right',
      width: 20,
      render: (val) => {
        return <div>{formatNumberBro({ number: val, mantissa: 2 })}%</div>
      }
    }
  ]

  const handleClickRow = (row) => {
    onNavigateToDetailWallet(row)
    onClickRow && onClickRow(row)
  }

  return (
    <div className={cn(styles.tableLiquidityProvider)}>

      <div className="table-wallet-info__content">
        <CustomTable
          isLoading={isLoading}
          columns={columnHeaderTopToken}
          dataSource={dataLPTable}
          onClickRow={handleClickRow}
          className="table-height"
        />

        {
          getLength(tableData) > 0 &&
          <Pagination
            forcePage={currPage - 1}
            totalPage={totalPage}
            handleSelectedPage={handleChangePage}
          />
        }

      </div>
    </div>
  )
}

export default TableLiquidityProvider
