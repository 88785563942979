import React, { useEffect, useRef, useMemo, useState, useCallback, useContext } from 'react'
import images from '../../assets/images'
import './style.scss'
import { debounce, get } from 'lodash'
import { Link, useHistory } from 'react-router-dom'
import ButtonConnected from 'components/common/ButtonConnected'
import { useTranslation } from 'react-i18next'
import SidebarMenuMobile from 'components/common/SidebarMenuMobile'
import { CURRENCY_DATA, CURRENCY_SYMBOL } from 'common/constants'
import SearchMobile from 'components/common/SearchMobile'
import { formatPrice, getItemStorage, getLength, setItemStorage, upperCase } from 'common/functions'
import BaseAPI from 'controller/api/BaseAPI'
import PuffLoading from 'components/common/PuffLoading'
import { AppContext } from 'context/appContext'
import { Icon } from 'components/Icon'
import i18n from 'i18n'
import EmptyData from 'components/EmptyData'
import Tooltip from 'components/Tooltip'
import PageDropdown from 'components/PageDropdown'
import { useSelector, useDispatch } from 'react-redux'
import NotiDropdown from './NotiDropdown'
import BaseAdapter from 'controller/api/BaseAdapter'
import StoreActions from 'controller/redux/actions/storeActions'
import TokenImage from 'components/common/TokenImage'
import cn from 'clsx'
import SidebarMenuTablet from './SidebarMenuTablet'
import useScreenWidth from 'hooks/useScreenWidth'
import NavbarDropdown from './NavbarDropdown'

const Header = ({ scrollEvent }) => {
  const appContext = useContext(AppContext)
  const { isDarkMode, handleToggleMode } = appContext
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const refLogo = useRef(null)
  const scrollRef = useRef(null)

  const inputSearchRef = useRef()

  const { width } = useScreenWidth()
  const widthScreen = width
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [txtSearch, setTxtSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenSearchWeb, setIsOpenSearchWeb] = useState(false)
  const [chooseUrl, setChooseUrl] = useState('')

  const [isChangingLang, setIsChangingLang] = useState()
  const [isChangingCurrency, setIsChangingCurrency] = useState()

  const langRedux = useSelector(state => state.langRedux)
  const currencyRedux = useSelector(state => state.currencyRedux)
  const currency = getItemStorage('CURRENCY')

  const [selectedCurrency, setSelectedCurrency] = useState(currency)

  const tokenRef = useRef([])
  const poolsRef = useRef([])

  const [tokens, setTokens] = useState([])
  const [pools, setPools] = useState([])

  const isEmptyDataToken = getLength(tokens) === 0 && getLength(pools) === 0

  const LIST_LANG = [
    {
      label: 'EN',
      value: 'en',
      render: (<div className='d-flex align-items-center'>
        <TokenImage src={`${window.location.origin}/Flag/en.svg`} className="token-img token-img--token-1 img-24 mr-3"/>
        <div className='currency-info'>
          <div className='d-flex align-items-center'>
            <div className={cn('text-sm')}>EN</div>
            <div className='text-xs color-grey ml-3'>{t('EN')}</div>
          </div>
          {langRedux === 'en' && <Icon name="web_tick_circle_check" className="color-theme mr-3"/>}
        </div>
      </div>)

    },
    {
      label: 'VI',
      value: 'vi',
      render: (<div className='d-flex align-items-center'>
        <TokenImage src={`${window.location.origin}/Flag/vi.svg`} className="token-img token-img--token-1 img-24 mr-3"/>
        <div className='currency-info'>
          <div className='d-flex align-items-center'>
            <div className={cn('text-sm')}>VI</div>
            <div className='text-xs color-grey ml-3'>{t('VI')}</div>
          </div>
          {langRedux === 'vi' && <Icon name="web_tick_circle_check" className="color-theme mr-3"/>}
        </div>
      </div>)

    }

  ]

  const LIST_CURRENCY = CURRENCY_DATA.map(item => {
    const imageLink = `${window.location.origin}/Currency/${item}.png`
    const isActive = item === currencyRedux.label
    return {
      value: item,
      label: item,
      render: (
        <div className='d-flex align-items-center'>
          <TokenImage src={imageLink} className="token-img token-img--token-1 img-24 mr-3"/>
          <div className='currency-info'>
            <div>
              <div className={cn(isActive && 'color-theme', 'text-sm')}>{item} ({CURRENCY_SYMBOL[item]})</div>
              <div className='text-xs color-grey'>{t(item)}</div>
            </div>
            {isActive && <Icon name="web_tick_circle_check" className="color-theme mr-3"/>}
          </div>
        </div>
      )
    }
  })
  const initSelectedLang = LIST_LANG.find(item => item.value === langRedux)
  const [selectedLang, setSelectedLang] = useState(initSelectedLang)

  const history = useHistory()

  const isLaunchPad = get(history, 'location.pathname', '').includes(
    'launchpad'
  )

  const isLaunchPadDetail = get(history, 'location.pathname', '').includes(
    'launchpad/'
  )

  const isLaunchPadAll = get(history, 'location.pathname', '').includes(
    'launchpad/all'
  )

  const isSwap = get(history, 'location.pathname', '').includes('/swap')
  const menus = useMemo(() => {
    return [
      {
        name: 'Swap',
        url: '',
        subMenu: [
          {
            name: 'Swap',
            url: '/swap'
          },
          {
            name: 'Add Liquidity',
            url: '/liquidity'
          }
        ],
        isComing: false
      },
      {
        name: 'Liquidity',
        url: '',
        subMenu: [
          {
            name: 'Overview',
            url: '/info'
          },
          {
            name: 'Pools',
            url: '/info/pool'
          },
          {
            name: 'Tokens',
            url: '/info/token'
          },
          {
            name: 'Wallets',
            url: '/info/wallet'
          }
        ],
        isComing: false
      },
      {
        name: 'Earn',
        url: '',
        subMenu: [
          {
            name: 'Farm',
            url: '/farm'
          },
          {
            name: 'Stake',
            url: '/stake'
          }
        ],
        isComing: false
      },
      {
        name: 'Campaign',
        url: '',
        subMenu: [
          {
            name: 'Snapshots',
            url: '/snapshot'
          }
        ],
        isComing: false
      },

      {
        name: 'Leaderboard',
        url: '/leaderboard',
        isComing: false,
        isNew: true
      }
    ]
  }, [get(history, 'location.pathname', '')])
  const isShowButtonConnect = useMemo(() => {
    const path = get(history, 'location.pathname', '')
    const arrPath = ['/launchpad', '/swap', '/swap/raydium', '/launchpad/all']
    return arrPath.includes(path)
  }, [get(history, 'location.pathname', '')])

  useEffect(() => {
    const currency = getItemStorage('CURRENCY')
    if (!get(currency, 'label')) {
      setItemStorage({ value: 1, label: 'USD' }, 'CURRENCY')
    }
  }, [])

  useEffect(() => {
    if (isSwap) return
    if (isShowButtonConnect) {
      refLogo.current.style.animation = 'none'
      window.addEventListener('scroll', (event) => {
        // const rotation = window.scrDyAtnY8xvpegJTsdtbcWgoJNd5DhfJF4pippXD1WBWy4ollY
        // refLogo.current.style.transform = `rotate(${rotation}deg)`
      })
    } else {
      setTimeout(() => {
        if (refLogo && refLogo.current) {
          // refLogo.current.style.animation = 'rotate 0.7s linear infinite'
          // refLogo.current.style['animation-duration'] = '3s'
        }
      }, 1500)
    }
    return () => {
      window.removeEventListener('scroll', () => {})
    }
  }, [scrollEvent, isShowButtonConnect, isSwap])

  const handleGoTo = (url) => () => {
    if (!url) return
    history.push(`${url}`)
    // setIsOpenSearch(false)
    setIsOpenSearch(false)
    handleClear()
  }

  const handleOpenMenu = () => {
    setIsOpenMenu(!isOpenMenu)
  }

  const checkActiveUrl = (menu) => {
    const findIdx = menu.subUrl?.findIndex(item => chooseUrl.includes(item))
    return findIdx >= 0
  }

  const renderRightHeader = () => {
    return (
      <React.Fragment>
        {/* <div className="header__menus">
          {menus.map((menu, index) => (
            <Link
              to={menu.url}
              key={index}
              className={`menu-item ${
                (chooseUrl === menu.url || checkActiveUrl(menu)) ? 'menu-item--active' : ''
              }`}
            >
              {t(menu.name)}
            </Link>
          ))}
        </div> */}

        <NavbarDropdown menus={menus} chooseUrl={chooseUrl}/>

      </React.Fragment>
    )
  }

  const handleNavigateUrl = (url) => () => {
    history.push(url)
  }

  const handleMode = (e) => {
    e.stopPropagation()
    handleToggleMode()
  }

  const handleFocusInput = () => {
    if (widthScreen < 900) {
      setIsOpenSearch(!isOpenSearch)
    } else {
      if (!isOpenSearchWeb) {
        inputSearchRef && inputSearchRef.current.focus()
      } else {
        inputSearchRef && inputSearchRef.current.blur()
      }
    }
  }

  const handleCloseSearch = () => {
    setIsOpenSearch(false)
    handleClear()
  }

  const handleChangeSearch = (e) => {
    const { value } = e.target
    setTxtSearch(value)
    debounceSearch(value)
  }

  const handleClear = () => {
    setTxtSearch('')
  }

  const onFocusSearch = () => {
    setIsOpenSearchWeb(true)
    onResetScroll()
  }

  const onBlurSearch = () => {
    setTimeout(() => {
      handleClear()
      setIsOpenSearchWeb(false)
    }, 150)
  }

  const onChangeLanguage = async (val) => {
    setSelectedLang(val)
    i18n.changeLanguage(val.value, () => {})
    setIsChangingLang(true)
    const res = await BaseAdapter.putData('user/chain/info', {
      locale: val.value
    })
    setIsChangingLang(false)
  }

  const onChangeCurrency = async (val) => {
    setIsChangingCurrency(true)
    const res = await BaseAdapter.getData(`program/currency/${val.value}`)
    setIsChangingCurrency(false)
    if (res && res.success) {
      const rateObj = {
        value: get(res, 'data', 1),
        label: val.value
      }
      dispatch(StoreActions.setCurrency(rateObj))
      setItemStorage(rateObj, 'CURRENCY')
      setSelectedCurrency(rateObj)
    }
  }

  const renderLogo = () => {
    // if (isSwap || isLiquidity || isPools || isFarmStake) {
    //   return (
    //     <img
    //       key={window.location.pathname}
    //       className="logo-full"
    //       src={images[isDarkMode ? 'logoSarosDark' : 'logoSaros']}
    //       alt=""
    //     />
    //   )
    // }
    return (
      <>
        <img
          className="img-logo"
          src={images[isDarkMode ? 'iconSarosWhite' : 'iconSaros']}
          alt=""
        />
        {/* <Icon name="saros_logo" className="saros-logo"/> */}
        <div className='saros-text'>Saros</div>
      </>
    )
  }

  const renderTokenItem = (arrToken) => {
    return arrToken.map((item, i) => {
      return (
        <Link className="token-item-search" key={i} to={`/token/${item.mintAddress}`} >
          <div className="token-item-search__name">
            <img src={item.icon} className='name-token' alt="" />
            <div className="name-text">{upperCase(item.symbol)}</div>
          </div>

          <div className="token-item-search__price">{formatPrice(item.price)}</div>
        </Link>
      )
    })
  }

  const renderPoolItem = (arrPools) => {
    return arrPools.map((item, i) => {
      return (
        <Link className="token-item-search" key={i} to={`/pool/${item.id}`}>
          <div className="token-item-search__name">
            <img src={get(item, 'token0.icon')} className='name-token' alt="" />
            <img src={get(item, 'token1.icon')} className='name-token' alt="" />
            <div className="name-text">{upperCase(get(item, 'token0.symbol'))}</div>
            <span>-</span>
            <div className="name-text">{upperCase(get(item, 'token1.symbol'))}</div>
          </div>

          <div className="token-item-search__price">{formatPrice(item.totalLiquidity)}</div>
        </Link>
      )
    })
  }

  const renderContent = (data, titleLeft, titleRight, isToken) => {
    if (getLength(data) === 0) return <></>
    return (
      <div className='token-content'>
        <div className="token-content__title">
          <div className="title-text">
            {titleLeft}
          </div>

          <div className="title-price">
            {titleRight}
          </div>
        </div>

        <div id={isToken ? 'scroll-content-token' : 'scroll-content-pool' } className="token-content__tokens">
          {isToken ? renderTokenItem(data) : renderPoolItem(data)}
        </div>
      </div>
    )
  }

  // useEffect(() => {
  //   isWindow && window.addEventListener('resize', handleResize)
  //   loadInitSearch()
  // }, [])

  useEffect(() => {
    if (isOpenSearch || isOpenSearchWeb) {
      if (scrollRef.current) {
        scrollRef.current.scrollTo(0, 0)
      }
    }
    if (getLength(tokenRef.current) === 0 && getLength(poolsRef.current) === 0) {
      debounceSearch('')
    } else {
      setTokens(tokenRef.current)
      setPools(poolsRef.current)
    }
  }, [isOpenSearch, isOpenSearchWeb])

  const onResetScroll = () => {
    const tokenElement = document.getElementById('scroll-content-token')
    const poolElement = document.getElementById('scroll-content-pool')
    if (tokenElement) {
      tokenElement.scrollTop = 0
    }
    if (poolElement) {
      poolElement.scrollTop = 0
    }
  }

  useEffect(() => {
    if (isOpenSearch || isOpenSearchWeb || getLength(txtSearch) !== 0) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpenSearch, isOpenSearchWeb, txtSearch])

  useEffect(() => {
    if (window.location.pathname === '/tokens' || window.location.pathname === '/pools') {
      setChooseUrl('/info')
    } else {
      setChooseUrl(window.location.pathname)
    }
  }, [window.location.pathname])

  const debounceSearch = useCallback(debounce(async (value) => {
    setIsLoading(true)
    // if (getLength(value) > 0) {
    const res = await BaseAPI.getData('saros/search', { keyword: value || '' })
    setTokens(get(res, 'coin', []))
    setPools(get(res, 'pool', []))
    if (!value) {
      tokenRef.current = get(res, 'coin', [])
      poolsRef.current = get(res, 'pool', [])
    }

    setIsLoading(false)
    // }
  }, 300), [])

  const renderSearchMenuContent = () => {
    if (isLoading) {
      return (
        <div className={`box-content ${isLoading && 'box-content--loading'}`}>
          <PuffLoading/>
        </div>
      )
    }
    if (isEmptyDataToken) {
      return (
        <div className={`box-content ${isEmptyDataToken && 'box-content--empty'}`}>
          <EmptyData/>
        </div>
      )
    }
    return (
      <div className="box-content">
        {renderContent(tokens, t('tokens'), t('price'), true)}
        {renderContent(pools, t('pools'), t('liquidity'))}
      </div>
    )
  }

  return (
    <div className="header-wr">
      <div
        className={`header header--pool ${isLaunchPad && 'header--launchpad'} ${
          !isLaunchPadAll && isLaunchPadDetail && 'header--launchpad-all'
        }`}
      >

        <div className="box-left-header">
          <Link to='/' className="header__logo" onClick={() => { setIsOpenMenu(false) }}>
            {!isOpenSearch && renderLogo()}
          </Link>

          {!isOpenSearch && renderRightHeader()}
        </div>

        <div className={`right-container ${isOpenSearch && 'right-container--mobile-search-active'}`}>
          {/* {!isOpenSearch && <div className="header__connect-mobile">
            <ButtonConnected />
          </div>} */}

          {!isOpenMenu && <div className='header-search'>
            {/* <button
              className={`btn-cancel ${isOpenSearch && 'btn-cancel--show'}`}
              onClick={handleCloseSearch}
            >
              {t('cancel')}
            </button> */}

            <Icon backgroundClassName={`btn-cancel ${isOpenSearch && 'btn-cancel--show'}`} onClick={handleCloseSearch} name="web_close"/>

            <img
              src={images.iconClear}
              alt=""
              className={`icon-head-clear ${isOpenSearch && getLength(txtSearch) > 0 && 'icon-head-clear--active'}`}
              onClick={handleClear}
            />

            <input
              type="text"
              placeholder={t('searchPoolOrToken')}
              className={`
                input-search
                ${isOpenSearch && 'input-search--active-mobile'} 
                ${(getLength(txtSearch) > 0 || isOpenSearchWeb) && 'input-search--active-web'}`
              }
              onChange={handleChangeSearch}
              ref={inputSearchRef}
              value={txtSearch}
              onBlur={onBlurSearch}
              onFocus={onFocusSearch}
            />

            {!isOpenMenu && (
              widthScreen < 900
                ? <img src={images.iconSearchGrey} className='icon-head-search' onClick={handleFocusInput} alt="" />
                : getLength(txtSearch) === 0
                  ? <img src={images.iconSearchGrey} className='icon-head-search' onClick={handleFocusInput} alt="" />
                  : <img src={images.iconClear} alt="" className='icon-head-clear-web' onClick={handleClear} />
            )}

            <div className={`dropdown-search ${isOpenSearchWeb && 'dropdown-search--active'}`}>
              <div className="box-search-item">
                {renderSearchMenuContent()}
                {/* {!isLoading
                  ? isEmptyDataToken
                    ? <div className="box-content">
                      {renderContent(tokens, t('tokens'), t('price'), true)}
                      {renderContent(pools, t('pools'), t('liquidity'))}
                    </div>
                    : <div className={`box-content ${isEmptyDataToken && 'box-content--empty'}`}>
                      <img src={images.iconEmptyTable} className='icon-empty-data' alt="" />
                      {t('emptyData')}
                    </div>
                  : <div className={`box-content ${isLoading && 'box-content--loading'}`}>
                    <PuffLoading />
                  </div>
                } */}

              </div>
            </div>
          </div>}

          <div className="header__connect">
            <ButtonConnected />
          </div>

          <PageDropdown
            isAsyncChange
            isWaitingChange={isChangingLang}
            tooltipText={t('language')}
            toggleclassName="lang-toggle"
            menuClassName="menu-language"
            dropdownItemClassName="item-curency"
            options={LIST_LANG}
            defaultValue={selectedLang}
            onChange={onChangeLanguage}
          />

          <PageDropdown
            isAsyncChange
            isWaitingChange={isChangingCurrency}
            tooltipText={t('currency')}
            toggleclassName="cur-toggle"
            menuClassName="menu-language"
            dropdownItemClassName="item-curency"
            options={LIST_CURRENCY}
            defaultValue={currencyRedux}
            onChange={onChangeCurrency}
          />

          {!isOpenSearch && <img onClick={handleOpenMenu} className='icon-menu' src={images[isOpenMenu ? 'iconClose2' : 'iconMenu']} alt="" />}
          <Tooltip
            className='icon-theme-container'
            content={t(isDarkMode ? 'dark' : 'light')}
          >
            <div onClick={handleMode}>
              <img src={images[isDarkMode ? 'iconMoon' : 'iconSun']} alt="" className="icon-theme" />
            </div>
          </Tooltip>

          <NotiDropdown/>

          <SidebarMenuMobile
            menus={menus}
            isOpen={isOpenMenu}
            isOpenSearch={isOpenSearch}
            chooseUrl={chooseUrl}
            handleNavigateUrl={handleNavigateUrl}
            handleOpenMenu={handleOpenMenu}
            handleMode={handleMode}

            currencyOptions={LIST_CURRENCY}
            currencyValue={currencyRedux}
            langOptions={LIST_LANG}
            langValue={selectedLang}
            onChangeLang={onChangeLanguage}
            onChangeCurrency={onChangeCurrency}
          />

          <SidebarMenuTablet
            handleMode={handleMode}
            currencyOptions={LIST_CURRENCY}
            currencyValue={currencyRedux}
            langOptions={LIST_LANG}
            langValue={selectedLang}
            onChangeLang={onChangeLanguage}
            onChangeCurrency={onChangeCurrency}

          />

          <SearchMobile
            isOpenSearch={isOpenSearch}
            tokens={tokens}
            pools={pools}
            onCloseSearch={handleCloseSearch}
            handleGoTo={handleGoTo}
            checkEmptyDataTokens={!isEmptyDataToken}
            txtSearch={txtSearch}
            onChangeSearch={handleChangeSearch}
            isLoading={isLoading}
          />

        </div>

      </div>
    </div>
  )
}

export default Header
