import { Icon } from 'components/Icon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'
import cn from 'clsx'

const EmptyData = ({ content, size = 'md', isNoContent, className }) => {
  const { t } = useTranslation()
  return (
    <div className={cn(className, 'empty-data-global')}>
      <Icon name="web_empty" className={`icon-${size}`} />
      {isNoContent
        ? null
        : (
          content || t('emptyData')
        )}
    </div>
  )
}

export default EmptyData
