import React, { useState, useContext, useEffect, useMemo } from 'react'
import cn from 'clsx'

import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'

import {
  convertWeiToBalance,
  getLength,
  nFormatter,
  upperCase
} from 'common/functions'
import { get } from 'lodash'
import Badge from 'components/Badge'
import ManagerInfoLiquidity from './Components/ManagerInfoLiquidity'
import LiquidityForm from 'components/LiquidityForm'
import SwitchButton from 'components/SwitchButton/SwitchButton'
import PuffLoading from 'components/common/PuffLoading'
import { LiquidityContext } from 'context/liquidity/liquidityContext'
import TokenImage from 'components/common/TokenImage'
import EmptyLiquidity from 'pages/NewLiquidityScreen/Components/EmptyLiquidity'
import { sarosSwapType } from 'common/constants'

function NewLiquidityScreen () {
  const { t } = useTranslation()
  const liquidityContext = useContext(LiquidityContext)
  const {
    listLiquidity,
    isLoading,
    liquidityToken,
    listToken,
    handleToggleFarming,
    isFarming,
    handlePushStateUrl,
    setLiquidityToken,
    isShortcut,
    setSelectedDataPool
  } = liquidityContext
  const isEmptyData = getLength(listLiquidity) <= 0

  const isPoolDetail = useMemo(() => {
    const { token0, token1 } = liquidityToken
    return token0 && token1
  }, [liquidityToken])

  const handleChoosePool = (poolInfo) => () => {
    if (!poolInfo) {
      handlePushStateUrl({
        token0Mint: null,
        token1Mint: null,
        typeLiquidity: sarosSwapType.addLiquidiTy
      })
      setSelectedDataPool(null)
      return setLiquidityToken({ token0: null, token1: null })
    }
    const token0 = get(poolInfo, 'token0')
    const token1 = get(poolInfo, 'token1')
    setLiquidityToken({ token0, token1 })
    handlePushStateUrl({
      token0Mint: get(token0, 'mintAddress'),
      token1Mint: get(token1, 'mintAddress'),
      typeLiquidity: sarosSwapType.addLiquidiTy
    })

    setSelectedDataPool(poolInfo)
    // setDataPoolInfo(poolInfo)
  }

  const renderListLiquidity = () => {
    if (isLoading) {
      return (
        <div className="empty-content text-base">
          <PuffLoading isCenter />
        </div>
      )
    }
    if (isEmptyData && !isLoading) {
      return <EmptyLiquidity content={t('noLiquidity')} />
    }
    return listLiquidity.map((item, i) => {
      const token0 = get(item, 'token0')
      const token1 = get(item, 'token1')
      const renderName = `${upperCase(get(token0, 'symbol'))} - ${upperCase(
        get(token1, 'symbol')
      )}`
      const amount = convertWeiToBalance(
        get(item, 'userInfo.amount', '').toString(),
        get(item, 'poolAccountInfo.decimals', '')
      )
      const isFaming = get(item, 'userInfo.dataFarm')

      return (
        <div
          className="info-liquidity-item text-base color-normal cursor-pointer"
          key={i}
          onClick={handleChoosePool(item)}
        >
          {isFaming && (
            <Badge
              variant="success"
              className="info-liquidity-item__status text-xs"
            >
              {t('farming')}
            </Badge>
          )}

          <div className="info-liquidity-item__info">
            <div className="item-left__token mr-2">
              <TokenImage
                src={get(token0, 'icon')}
                className="token-icon token-icon--token-1 img-24 mr-2"
              />
              <TokenImage
                src={get(token1, 'icon')}
                className="token-icon token-icon--token-2 img-24 "
              />
            </div>

            <div className="item-left__token-name">{renderName}</div>
          </div>

          <div className="info-liquidity-item__value">
            ~{nFormatter(amount)}
          </div>
        </div>
      )
    })
  }

  if (isShortcut) {
    return (
      <div className={styles['add-liquid-shortcut']}>
        <LiquidityForm listLiquidity={listLiquidity} listToken={listToken} />
      </div>
    )
  }

  return (
    <div className={cn([styles.liquidityContainer])}>
      <div className="grid-liquidity-content">
        <div className="liquidity-info-wrapper">
          <div className="liquidity-info-new box-wrapper">
            <div className="liquidity-info-new__title text-l color-theme">
              {isPoolDetail
                ? (
                  <div
                    className="liquidity-info-new__title--manager cursor-pointer"
                    onClick={handleChoosePool(false)}
                  >
                    <span className="icon-web_arrow_left color-grey mr-2"></span>{' '}
                    {t('managePool')}
                  </div>
                )
                : (
                  <>
                    <div className="title-left">
                      {t('yourLiquidity')}

                      <div className="number-item text-base ml-2">
                        {getLength(listLiquidity)}
                      </div>
                    </div>

                    <div className="title-right text-sm color-grey">
                      <SwitchButton
                        className="mr-2"
                        onToggle={handleToggleFarming}
                        isToggle={isFarming}
                      />
                      {t('farming')}
                    </div>
                  </>
                )}
            </div>

            <div
              className={`liquidity-info-new__content text-sm color-grey ${
                isEmptyData && 'liquidity-info-new__content--no-data'
              }`}
            >
              {isPoolDetail
                ? (
                  <ManagerInfoLiquidity />
                )
                : (
                  renderListLiquidity()
                )}
            </div>
          </div>
        </div>

        <LiquidityForm listLiquidity={listLiquidity} listToken={listToken} />
      </div>
    </div>
  )
}

export default NewLiquidityScreen
