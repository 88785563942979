import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import VerticalSlider from './components/VerticalSlider'
import images from 'assets/images'
import { Fade } from 'react-awesome-reveal'
import useEffectCustom from 'hooks/useEffectCustom'

const DUMMY_LIST = [
  {
    name: 'permissionless',
    description: 'mission1',
    image: images.permissionless
  },
  {
    name: 'capitalEffiency',
    description: 'mission2',
    image: images.capitalEffiency

  },
  {
    name: 'friendlyUI',
    description: 'mission3',
    image: images.friendly
  },
  {
    name: 'lowCost',
    description: 'mission4',
    image: images.lowCost
  },
  {
    name: 'scalability',
    description: 'mission5',
    image: images.scalability

  },
  {
    name: 'composability',
    description: 'mission6',
    image: images.composability
  }
]

const MissionList = () => {
  const { t } = useTranslation()
  const [hoverItem, setHoverItem] = useState(DUMMY_LIST[0])

  const handleChangeHoverItem = (val) => {
    const findItem = DUMMY_LIST[val]
    setHoverItem(findItem)
    const element = document.getElementById('box-image')
    if (element) {
      element.classList.remove('reveal-down-animation')
      setTimeout(() => {
        element.classList.add('reveal-down-animation')
      }, 300)
    }
  }

  useEffect(() => {
    const element = document.getElementById('box-image')
    if (element) {
      element.classList.add('reveal-down-animation')
    }
  }, [])

  return (
    <div className={styles.missionListContainer}>
      <div className='small-title'>{t('ourMission')}</div>
      <div className='head-title'>{t('landingMissionDescription')}</div>
      <div className='list-content'>
        <div className='list-content__left'>
          <VerticalSlider onChangeMenu={handleChangeHoverItem}/>
        </div>
        <div className='list-content__right'>
          <div id="box-image" className='box-image'>
            <img src={hoverItem.image} alt="" />
          </div>
          <div className='description '> {t(hoverItem.description)}</div>

        </div>
      </div>
    </div>
  )
}

export default MissionList
