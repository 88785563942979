import BN from 'bn.js'

export const ZERO = new BN(0)
export const ONE = new BN(1)
export const TWO = new BN(2)
export const U64_MAX = TWO.pow(new BN(64)).sub(ONE)

export function getAmountDeltaA (
  currSqrtPrice,
  targetSqrtPrice,
  currLiquidity,
  roundUp
) {
  const [sqrtPriceLower, sqrtPriceUpper] = toIncreasingPriceOrder(
    currSqrtPrice,
    targetSqrtPrice
  )
  const sqrtPriceDiff = sqrtPriceUpper.sub(sqrtPriceLower)

  const numerator = currLiquidity.mul(sqrtPriceDiff).shln(64)
  const denominator = sqrtPriceLower.mul(sqrtPriceUpper)

  const quotient = numerator.div(denominator)
  const remainder = numerator.mod(denominator)

  const result =
    roundUp && !remainder.eq(ZERO) ? quotient.add(new BN(1)) : quotient

  return result
}

// eslint-disable-next-line camelcase
function fromX64_BN (num) {
  return num.div(new BN(2).pow(new BN(64)))
}

export function getAmountDeltaB (
  currSqrtPrice,
  targetSqrtPrice,
  currLiquidity,
  roundUp
) {
  const [sqrtPriceLower, sqrtPriceUpper] = toIncreasingPriceOrder(currSqrtPrice, targetSqrtPrice)
  const sqrtPriceDiff = sqrtPriceUpper.sub(sqrtPriceLower)
  const result = fromX64_BN(currLiquidity.mul(sqrtPriceDiff))
  const shouldRound = roundUp && result.and(U64_MAX).gt(ZERO)
  return shouldRound ? result.add(ONE) : result
}

function toIncreasingPriceOrder (sqrtPrice0, sqrtPrice1) {
  if (sqrtPrice0.gt(sqrtPrice1)) {
    return [sqrtPrice1, sqrtPrice0]
  } else {
    return [sqrtPrice0, sqrtPrice1]
  }
}
