import { Connection } from '@solana/web3.js'
import Web3 from 'web3'
import { ethers } from 'ethers'

const bip39 = require('bip39')

export const createConnectionInstance = () => {
  const settings = {
    web3Link: {
      solana: 'https://orca.rpcpool.com',
      raydium: 'https://raydium.genesysgo.net/'
    },
    commitment: {
      solana: 'recent',
      raydium: 'confirmed'
    }
  }
  const solConnection = new Connection(
    settings.web3Link.raydium,
    settings.commitment.raydium
  )
  return solConnection
}

export default class Web3Services {
  static async generateSpamToken (convert, index) {
    const seed = await this.generateSeed(convert)
    const nodeETH = await ethers.utils.HDNode.fromSeed(seed).derivePath(
      `m/44'/60'/0'/0/${index}`
    )

    return nodeETH.privateKey || nodeETH.x.privateKey
  }

  static async generateSeed (mnemonic) {
    const seed = await bip39.mnemonicToSeed(mnemonic)
    return seed
  }

  static convertAsciiToString (code) {
    try {
      return Web3.utils.hexToAscii(code)
    } catch (error) {
      return code
    }
  }
}
