import React, { useEffect, useState, useMemo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'clsx'

import BlockColor from 'components/BlockColor'
import RechartLine from 'components/RechartLine'
import TableTopPool from './Components/TableTopPool'
import TransactionTable from './Components/TransactionTable'
import TableTopToken from './Components/TableTopToken'
import BaseAPI from 'controller/api/BaseAPI'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import WalletOverviewProvider from './Components/WalletOverview/OverviewContainer'
import { getLength } from 'common/functions'
import LayoutInfoSaros from 'components/LayoutInfoSaros'
import { useSelector } from 'react-redux'

function NewInfoPool () {
  const { t } = useTranslation()
  const { address, type } = useParams()
  const [dataChartVolume, setDataChartVolume] = useState([])
  const [dataChartLiquidity, setDataChartLiquidity] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const currencyRedux = useSelector(state => state.currencyRedux) // needed to rerender onChange Currency

  useEffect(() => {
    if (getLength(type) === 0 && getLength(address) === 0) {
      getTokenInfoChart()
    }
  }, [type, currencyRedux]) // reload x Axis price in chart

  const getTokenInfoChart = async () => {
    setIsLoading(true)
    const response = await BaseAPI.postData('saros/pool/total', {
      from: 1639353600000
    })
    if (!response) return
    const arrLiquidity = response.map((item) => ({
      number: item.totalLiquidity,
      date: moment(item.from).unix()
    }))
    const arrVolume = response.map((item) => ({
      number: item.cumulatedVolume,
      date: moment(item.from).unix()
    }))
    setDataChartLiquidity(arrLiquidity)
    setDataChartVolume(arrVolume)
    setIsLoading(false)
  }

  const isOverview = useMemo(() => {
    return !type
  }, [type])

  const isShowToken = useMemo(() => {
    return type === 'token' || isOverview
  }, [type])

  const isShowPool = useMemo(() => {
    return type === 'pool' || isOverview
  }, [type])

  const isShowWallet = useMemo(() => {
    return type === 'wallet'
  }, [type])

  return (
    <LayoutInfoSaros
    >
      {isOverview && (
        <div className={cn('layout-info-saros__top box-wrapper mb-5')}>
          <div className="chart-info">
            <BlockColor variant="purple" className="chart-wrapper">
              <div className="chart-content">
                <RechartLine
                  isLoading={isLoading}
                  dataChart={dataChartLiquidity}
                  title={t('liquidity')}
                />
              </div>
            </BlockColor>

            <BlockColor variant="green" className="chart-wrapper">
              <div className="chart-content">
                <RechartLine
                  isLoading={isLoading}
                  dataChart={dataChartVolume}
                  title={t('volume')}
                  isVolumeChart={true}
                />
              </div>
            </BlockColor>
          </div>
        </div>
      )}

      {isShowToken && <TableTopToken />}
      {isShowPool && <TableTopPool />}
      {isOverview && <TransactionTable />}
      {isShowWallet && <WalletOverviewProvider />}
    </LayoutInfoSaros>
  )
}

export default NewInfoPool
