import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
import { Icon } from 'components/Icon'
import { useTranslation } from 'react-i18next'
import ListToken from 'components/ListToken'
import {
  formatNumberBro,
  getLength,
  roundingNumber,
  upperCase
} from 'common/functions'
import get from 'lodash/get'
import images from 'assets/images'
import PuffLoading from 'components/common/PuffLoading'
import NumberFormat from 'react-number-format'
import { AppContext } from 'context/appContext'
import useScreenWidth from 'hooks/useScreenWidth'

const InputTokenAmount = ({
  id,
  valueInput = '',
  isShowHalf,
  onchangeInput,
  isLoadingBalance,
  coinSelected,
  onChangeToken,
  className,
  isBase,
  isDisabledInput,
  tokenList,
  coinFilter,
  isLoadingListToken = false,
  isDisableMaxAmount,
  isShortcut,
  balance,
  type = 'number',
  onFocusInput
}) => {
  const { t } = useTranslation()
  const [isFocus, setIsFocus] = useState(false)

  const { width } = useScreenWidth()
  const isMobile = width < 576

  const appContext = useContext(AppContext)
  const { setIsOpenListTokenMobile } = appContext

  const newBalance = formatNumberBro({
    number: balance,
    mantissa: 4
  }).toString()

  const handleSelectedToken =
    ({ isBase, coinSelected }) =>
      () => {
        onChangeToken && onChangeToken({ isBase, coinSelected })
        window.closeModal()
        if (isMobile) {
          window.closeListTokenMobile()
        }
      }

  const handleShowListTokenMobile = () => {
    window.showListTokenMobile({
      content: (
        <ListToken
          coinFilter={coinFilter}
          handleSelectedToken={handleSelectedToken}
          lisToken={tokenList}
          isBase={isBase}
          isLoadingListToken={isLoadingListToken}
          isShortcut={isShortcut}
        />
      )
    })
  }
  const handleShowListToken = () => {
    window.openModal({
      title: t('coin98NotInstall'),
      isCustomModal: true,
      className: styles.listTokenModal,
      isFullHeight: true,
      content: (
        <ListToken
          coinFilter={coinFilter}
          handleSelectedToken={handleSelectedToken}
          lisToken={tokenList}
          isBase={isBase}
          isLoadingListToken={isLoadingListToken}
          isShortcut={isShortcut}
        />
      ),
      onCancel: () => {}
    })
  }

  const handleChangeAmountInput = (value, focusInput = isFocus) => {
    if (!focusInput) return
    const valueInputSplit = value.split('.')
    const numberInt = valueInputSplit[0]
    const numberDecimal = get(valueInputSplit, '[1]')
    if (getLength(numberInt) > 11 || getLength(numberDecimal) > 9) {
      const newNumber = roundingNumber(value, 4)
      return formatValueInput(newNumber)
    }
    formatValueInput(value)
  }

  const formatValueInput = (amount) => {
    onchangeInput &&
      onchangeInput({ isBase, value: amount, tokenInfo: coinSelected })
  }

  const validateInput = (value) => {
    const amount = get(value, 'floatValue', '').toString()
    const valueInputSplit = amount.split('.')
    const numberInt = valueInputSplit[0]
    const numberDecimal = get(valueInputSplit, '[1]')

    if (getLength(numberInt) > 11 || getLength(numberDecimal) > 9) {
      return false
    }

    return true
  }

  const handleMaxAmount = () => {
    if (!coinSelected) return
    handleChangeAmountInput(balance, true)
  }

  const handleHalfAmount = () => {
    if (!coinSelected) return
    const halfNum = parseFloat(balance) / 2
    handleChangeAmountInput(halfNum.toString(), true)
  }

  const onFocus = (e) => {
    onFocusInput && onFocusInput(e)

    setIsFocus(true)
  }

  const onBlur = () => {
    setIsFocus(false)
  }

  const renderIcon = useMemo(() => {
    const icon = get(coinSelected, 'icon', '')
    const symbol = get(coinSelected, 'symbol', '')
    if (icon) return icon
    if (symbol === 'SOL') return images.iconSolana
    return images.iconEmptyToken
  }, [coinSelected])

  const renderTokenBalanceIcon = useMemo(() => {
    return upperCase(get(coinSelected, 'symbol', ''))
  }, [coinSelected])

  const isResizeBalance = getLength(newBalance) > 8

  return (
    <div id={id} className={cn(styles.selectContainer, className)}>
      <div className="d-flex align-items-center justify-content-between">
        <NumberFormat
          disabled={isDisabledInput}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder="0.0"
          onValueChange={(event) => handleChangeAmountInput(event.value)}
          thousandsGroupStyle="thousand"
          value={valueInput}
          prefix=""
          decimalSeparator="."
          displayType="input"
          isAllowed={validateInput}
          type="text"
          thousandSeparator={true}
          className={cn(
            'balance-input',
            isResizeBalance && 'balance-input--resize',
            isDisabledInput && 'balance-input--disabled'
          )}
        />

        <div
          onClick={
            isShortcut
              ? handleShowListToken
              : isMobile
                ? handleShowListTokenMobile
                : handleShowListToken
          }
          className="token-select"
        >
          <img src={renderIcon} className="img-24 rounded-circle" alt="" />
          <div className="coin-symbol bold ml-2 mr-1 uppercase">
            {get(coinSelected, 'symbol', '')}
          </div>
          <Icon name="web_drop_down" onClick={() => {}} />
        </div>
      </div>

      <div>
        <div className="d-flex align-items-center justify-content-end mt-1">
          <span className="text-sm color-grey">{t('balance')}: </span>
          <div
            className={cn(
              isDisableMaxAmount ? 'cursor-not-allowed ' : 'cursor-pointer',
              'color-theme text-sm ml-1 d-flex align-items-center'
            )}
          >
            <>
              {isLoadingBalance
                ? (
                  <PuffLoading size={20} />
                )
                : (
                  <span onClick={isDisableMaxAmount ? () => {} : handleMaxAmount}>
                    {newBalance}
                  </span>
                )}
              {isShowHalf && (
                <>
                  <div className="vertical-divider ml-2 mr-2" />
                  <span
                    onClick={isDisableMaxAmount ? () => {} : handleHalfAmount}
                    className="color-theme"
                  >
                    {t('half')}
                  </span>
                </>
              )}
            </>

          </div>
        </div>
      </div>
    </div>
  )
}

export default InputTokenAmount
