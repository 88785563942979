import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import styles from './style.module.scss'
import cn from 'clsx'
import { debounce } from 'lodash'
import useScreenWidth from 'hooks/useScreenWidth'
const CountdownCircle = ({
  className,
  onComplete,
  size = 18,
  strokeWidth = 2,
  trailStrokeWidth = 2,
  countTime = 20
}) => {
  const [remainingTime, setRemainingTime] = useState(countTime)
  const [isDisableReload, setIsDisableReload] = useState(false)
  const INTERVAL = 5000
  const timer = useRef()

  const { width } = useScreenWidth()
  const isLargeScreen = width >= 1900

  // Add once click to the existing state value
  // const clickOnce = () => {
  //   setRemainingTime(20)
  //   onComplete && onComplete()
  // }

  const onForceReload = () => {
    setIsDisableReload(true)
    clearTimeout(timer.current)
    setRemainingTime(0.5)

    timer.current = setTimeout(async () => {
      // onComplete && onComplete()
      setRemainingTime(20)
    }, 500)

    setTimeout(() => {
      setIsDisableReload(false)
    }, 4500)
  }

  const circleSize = useMemo(() => {
    if (size === 18) {
      return isLargeScreen ? 22 : size
    }
    return size
  }, [size, isLargeScreen])

  const debouncedClick = useCallback(
    debounce(onForceReload, INTERVAL, {
      leading: true,
      trailing: false,
      maxWait: INTERVAL
    }),
    []
  )

  return (
    <div
      className={cn(
        className,
        styles.cdWrapper,
        isDisableReload && styles.disabledClick
      )}
      onClick={debouncedClick}
    >
      <CountdownCircleTimer
        isPlaying
        size={circleSize}
        strokeWidth={strokeWidth}
        trailStrokeWidth={trailStrokeWidth}
        duration={remainingTime}
        colors={['#bbbbbb']}
        trailColor={['#6946e2']}
        onComplete={() => {
          onComplete && onComplete()
          return { shouldRepeat: true } // repeat animation in 1.5 seconds
        }}
        // colorsTime={[7, 5, 2, 0]}
      ></CountdownCircleTimer>
    </div>
  )
}

export default CountdownCircle
