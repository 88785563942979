import React, { useState, useEffect, useMemo, useCallback } from 'react'
import RechartLine from 'components/RechartLine'
import BaseAPI from 'controller/api/BaseAPI'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import styles from './style.module.scss'
import cn from 'clsx'
import { listStableCoin } from 'common/constants'
import moment from 'moment'
import MarketScan from 'common/MarketScan'
import useSarosSwap from 'hooks/swap/useSarosSwap'
import { isEmpty } from 'lodash'
import { formatNumberBro, nFormatter } from 'common/functions'
import ReduxServices from 'common/redux'
import { useSelector } from 'react-redux'

const arrTimes = [
  {
    name: '24h',
    value: '1D'
  },
  {
    name: '1W',
    value: '1W'
  },
  {
    name: '1M',
    value: '1M'
  }
]

let cacheCgkDetail = {}

const TIME_RANGE = {
  '1D': 1,
  '1W': 7,
  '1M': 30,
  '1Y': 365
}

const SwapChart = ({ isVisble, fromCoin, toCoin }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [dataChartLiquidity, setDataChartLiquidity] = useState([])

  const { t } = useTranslation()
  const { getDetailCgk } = useSarosSwap({ isFetchPool: false })
  const [detailCgk, setDetailCgk] = useState({})
  const [timeChoose, setTimeChoose] = useState('1D')
  const currencyRedux = useSelector(state => state.currencyRedux)

  const [dayRequest, setDayRequest] = useState(1)

  const newPairCoin = useMemo(() => {
    if (listStableCoin.includes(get(toCoin, 'mintAddress'))) {
      return fromCoin
    }
    return toCoin
  }, [toCoin, fromCoin])

  useEffect(() => {
    // TRIGGER RERENDER CHART
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
  }, [currencyRedux])

  useEffect(() => {
    if (isVisble) {
      loadData({
        address: get(newPairCoin, 'mintAddress'),
        type: timeChoose,
        timeFrom: dayRequest,
        timeTo: moment().unix()
      })

      getTokenDetail(get(newPairCoin, 'id'))
    }
  }, [newPairCoin, dayRequest, isVisble])

  const getTokenDetail = useCallback(async (id) => {
    const cacheCgkDetailId = get(cacheCgkDetail, 'id')
    if (cacheCgkDetailId === id) {
      setDetailCgk(cacheCgkDetail)
      return
    }
    const res = await getDetailCgk({ id: id })
    cacheCgkDetail = res
    setDetailCgk(res)
  }, [newPairCoin?.id])

  const handleChooseTime = (val) => () => {
    setTimeChoose(val)
    setDayRequest(TIME_RANGE[val])
  }

  const renderTimeList = () => {
    return (
      <div className='d-flex'>
        {arrTimes.map((item, i) => {
          return (
            <div
              className={`item-time ${timeChoose === get(item, 'value') && 'item-time--active'}`}
              key={i}
              onClick={handleChooseTime(get(item, 'value'))}
            >
              {get(item, 'name')}
            </div>
          )
        })}
      </div>
    )
  }

  const loadData = async ({ address, type, timeFrom, timeTo }) => {
    const resCgk = await MarketScan.getChartCoin(get(newPairCoin, 'id', ''), timeFrom)

    let newData = get(resCgk, 'prices', []).map(it => {
      return {
        number: it[1],
        date: Number(it[2]) / 1000, // miliseconds -> seconds
        parseDate: it[0]
      }
    }).sort((a, b) => a.time - b.time)

    if (timeFrom === 1) {
      newData = uniqBy(newData, it => it.parseDate)
    }

    setDataChartLiquidity(newData)
    setIsLoading(false)
  }

  if (!isVisble) return null

  return (
    <div className={cn(styles.swapChartContainer, 'box-wrapper')}>

      <div className='chart-height'>
        <RechartLine
          dataChart={dataChartLiquidity}
          isSwapChart
          isHideTitle
          renderRight={renderTimeList()}
          timeChoose={timeChoose}
          coinInfo={newPairCoin}
          title={t('liquidity')}
          isLoading={isLoading}
          detailCgk={detailCgk}
        />
      </div>

      <div className='list-info-box'>
        <div className='chart-info-box'>
          <div className='text-l bold'>{ReduxServices.formatPrice(get(detailCgk, 'market_data.market_cap.usd'), true)}</div>
          <div className='text-xs color-grey'>{t('marketCap')}</div>
        </div>
        <div className='chart-info-box'>
          <div className='text-l bold'>{ReduxServices.formatPrice(get(detailCgk, 'market_data.total_supply'), true)}</div>
          <div className='text-xs color-grey'>{t('totalSupply')}</div>
        </div>
        <div className='chart-info-box'>
          <div className='text-l bold'>{ReduxServices.formatPrice(get(detailCgk, 'market_data.atl.usd'))}</div>
          <div className='text-xs color-grey'>{t('allTimeLow')}</div>
        </div>
        <div className='chart-info-box'>
          <div className='text-l bold'>{ReduxServices.formatPrice(get(detailCgk, 'market_data.ath.usd'))}</div>
          <div className='text-xs color-grey'>{t('allTimeHigh')}</div>
        </div>
      </div>

    </div>

  )
}

export default SwapChart
