import get from 'lodash/get'
import BaseAPI from 'controller/api/BaseAPI'
import { NATIVE_SOL_RAYDIUM } from 'common/raydium/constants'
import { NATIVE_SOL } from 'common/solana'
import { getLength, setItemStorage } from 'common/functions'
import { chainType, DECIMAL_LP } from 'common/constants'
import { cloneDeep, compact, uniqBy } from 'lodash'
import BaseAdapter from 'controller/api/BaseAdapter'
import { useDispatch, useSelector } from 'react-redux'
import StoreActions from 'controller/redux/actions/storeActions'

const UI_FEE_PERCENT = 0.1

const useSarosSwap = () => {
  const recentSwapRedux = useSelector((state) => state.recentSwapRedux)
  const dispatch = useDispatch()

  const findPoolLiquidityDevelop = async ({
    fromCoin,
    toCoin,
    slippage = 0.5,
    amount = 1,
    listAmm
  }) => {
    // new flow swap  --------------------**------------------**---------------
    let base
    let pair
    if (fromCoin && toCoin) {
      const getBaseData = BaseAPI.postData('saros/swap/getPairsV2', {
        token0: fromCoin,
        token1: toCoin,
        amount,
        slippage: slippage * 100
      })

      const getPairData = BaseAPI.postData('saros/swap/getPairsV2', {
        token0: toCoin,
        token1: fromCoin,
        amount,
        slippage: slippage * 100
      })

      const [baseData, pairData] = await Promise.all([
        getBaseData,
        getPairData
      ])

      base = baseData
      pair = pairData
    }
    const formatData = formatPoolInfo({ base: base, pair: pair })

    return formatData
  }

  const findPoolLiquidity = async ({
    fromCoin,
    toCoin,
    slippage = 0.5,
    amount = 1
  }) => {
    // new flow swap  --------------------**------------------**---------------
    let base
    let pair

    if (fromCoin && toCoin) {
      const getBaseData = BaseAPI.postData('saros/swap/getPairs', {
        token0: fromCoin,
        token1: toCoin,
        amount,
        slippage: slippage * 100
      })

      const getPairData = BaseAPI.postData('saros/swap/getPairs', {
        token0: toCoin,
        token1: fromCoin,
        amount,
        slippage: slippage * 100
      })

      const [baseData, pairData] = await Promise.all([
        getBaseData,
        getPairData
      ])
      base = baseData
      pair = pairData
    }
    const swapRate = {
      base: get(base, 'amount'),
      pair: get(pair, 'amount')
    }
    const priceImpact = {
      base: get(base, 'impact'),
      pair: get(pair, 'impact')
    }

    return { swapRate, priceImpact }
  }

  const formatPoolInfo = (data) => {
    // this func is use only for "saros/swap/getPairsV2" format
    let amountOutBase
    let amountOutPair
    let impactOutBase
    let impactOutPair

    let listAmmCompareBase = []
    let listAmmComparePair = []

    let routerDataBase
    let routerDataPair

    let priceSaveBase
    let priceSavePair

    const { base, pair } = data

    if (base && pair) {
      amountOutBase = get(base, 'router.routerData.amount', '')
      impactOutBase = get(base, 'router.routerData.impact', '')
      amountOutPair = get(pair, 'router.routerData.amount', '')
      impactOutPair = get(pair, 'router.routerData.impact', '')

      listAmmCompareBase = get(base, 'AmmCompareData', '')
      listAmmComparePair = get(pair, 'AmmCompareData', '')

      priceSaveBase = get(base, 'youSave', '')
      priceSavePair = get(pair, 'youSave', '')

      routerDataBase = get(base, 'router.routerData', '')
      routerDataPair = get(pair, 'router.routerData', '')
    }

    return {
      // pool,

      ammCompareData: {
        base: listAmmCompareBase,
        pair: listAmmComparePair
      },

      swapRate: {
        base: amountOutBase,
        pair: amountOutPair
      },
      priceImpact: {
        base: impactOutBase,
        pair: impactOutPair
      },

      routerData: {
        base: routerDataBase,
        pair: routerDataPair
      },

      youSave: {
        base: priceSaveBase,
        pair: priceSavePair
      }
    }
  }

  const handlePushStateUrl = ({ toMint, fromMint }) => {
    let newToMint = toMint
    if ([NATIVE_SOL_RAYDIUM.mintAddress].includes(newToMint)) {
      newToMint = NATIVE_SOL.mintAddress
    }
    return window.history.pushState(
      '',
      '',
      `/swap?base=${fromMint}&pair=${toMint}`
    )
  }

  const getDetailCgk = async ({ id, symbol }) => {
    const response = await BaseAPI.getData('coin/cgk/detail', { id })
    return response
  }

  const calculateUiFee = (amountOut) => {
    if (!amountOut) return ''
    const uiFee = (parseFloat(amountOut) * UI_FEE_PERCENT) / 100
    return uiFee
  }

  const getListTokenSwap = async () => {
    try {
      const response = await BaseAPI.getData('saros/token/topMint')
      if (!response) return []
      const newListSolana = window.walletServices.tokenSolana
      const listToken = response
        .map((mint) => {
          const info = newListSolana.find(
            (token) => token.mintAddress === mint
          )
          return info
        })
        .filter((info) => !!info && info.decimals > DECIMAL_LP)
      setItemStorage(listToken, 'TOKENS_LIST')
      return listToken
    } catch (err) {
      return []
    }
  }

  const updateRecentSwapList = async (mint0Address, mint1Address) => {
    const cloneListSwapRecent = cloneDeep(recentSwapRedux)
    let newSwapRecent = []
    newSwapRecent = [
      {
        token0Address: mint0Address,
        token1Address: mint1Address
      }
    ]
      .concat(cloneListSwapRecent)
      .filter((item) => get(item, 'token0Address'))

    if (getLength(newSwapRecent) > 5) {
      newSwapRecent = newSwapRecent.slice(0, 5)
    }

    const formatSwapList = uniqBy(
      newSwapRecent,
      (it) => it.token0Address && it.token1Address
    )

    const resListSwap = await BaseAdapter.postData('favorite/take', {
      type: 'sarosRecentSwap',
      bonusValue: compact(formatSwapList)
    })
    if (resListSwap && resListSwap.success) {
      dispatch(StoreActions.setRecentSwapRedux(compact(formatSwapList)))
    }
  }

  const getFeeMintBurnServices = async () => {
    const response = await BaseAdapter.getData('eco/dollar')
    if (!response || !get(response, 'success', false)) {
      return {
        packageBurn: 0,
        packageMint: 0
      }
    }
    const packageBurn = get(response, 'data.package.burn', []).find(
      (item) => item.chain === chainType.solana
    )
    const packageMint = get(response, 'data.package.mint', []).find(
      (item) => item.chain === chainType.solana
    )

    return {
      packageBurn,
      packageMint
    }
  }

  return {
    getDetailCgk,
    handlePushStateUrl,
    findPoolLiquidity: findPoolLiquidityDevelop,
    updateRecentSwapList,
    formatPoolInfo,
    calculateUiFee,
    getListTokenSwap,
    getFeeMintBurnServices
  }
}

export default useSarosSwap
