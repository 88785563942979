export const KEY_ACTION = {
  SET_LANG: 'SET_LANG',
  SET_WALLET_REDUX: 'SET_WALLET_REDUX',
  SET_IS_SESSION: 'SET_IS_SESSION',
  SET_CONFIG: 'SET_CONFIG',
  SET_DEVICE_ID: 'SET_DEVICE_ID',
  SET_CONNECT_FIRST: 'SET_CONNECT_FIRST',
  SET_CHART: 'SET_CHART',
  SET_BALANCE_SOL: 'SET_BALANCE_SOL',
  SET_CONNECTED: 'SET_CONNECTED',
  SET_ACCOUNT_SOL: 'SET_ACCOUNT_SOL',
  SET_TOKEN_LIST: 'SET_TOKEN_LIST',
  SET_WALLET_ACTIVE: 'SET_WALLET_ACTIVE',
  SET_THEME_MODE: 'SET_THEME_MODE',
  SET_LOADING_POOL: 'SET_LOADING_POOL',
  SET_FARM_DATA: 'SET_FARM_DATA',
  SET_ADDRESS_OWNER: 'SET_ADDRESS_OWNER',
  SET_DATA_FARM_STAKE: 'SET_DATA_FARM_STAKE',
  SET_LIST_FAVORITE_TOKEN: 'SET_LIST_FAVORITE_TOKEN',
  SET_LIST_FAVORITE_POOL: 'SET_LIST_FAVORITE_POOL',
  SET_CURRENCY_REDUX: 'SET_CURRENCY_REDUX',
  SET_CURRENT_SWAP: 'SET_CURRENT_SWAP'
}

export const KEY_PAGE = {
  SET_FUNCTION: 'SET_FUNCTION',
  SET_APPSTATE: 'SET_APPSTATE'
}
