import images from 'assets/images'
import { toCountdownTime, upperCaseFirstLetter } from 'common/functions'
import TokenImage from 'components/common/TokenImage'
import { Icon } from 'components/Icon'
import { AppContext } from 'context/appContext'
import moment from 'moment'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

const NotiItem = ({ data = {} }) => {
  const { t } = useTranslation()

  const appContext = useContext(AppContext)
  const { isDarkMode } = appContext

  return (
    <div className={styles.notiItemContainer}>
      <div className='mr-2'>
        {/* <TokenImage src="" className="img-24 rounded-circle mr-2"/> */}
        <img
          className="img-24 rounded-circle mr-2 mt-1"
          src={images[isDarkMode ? 'iconSarosWhite' : 'iconSaros']}
          alt=""
        />
      </div>
      <div className='noti-info'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='color-theme noti-info__title'>{t(data.type)}</div>
          <div className='text-xs'>{moment(data.createdAt).fromNow()}</div>
        </div>
        <div className='text-xs'>
          {upperCaseFirstLetter(data.message)}
        </div>
      </div>
    </div>
  )
}

export default NotiItem
