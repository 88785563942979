export default {
  shareSnapshot: 'Share Snapshot',
  earliest: 'Sớm nhất',
  latest: 'Muộn nhất',
  rank: 'Cấp bậc',
  share: 'Chia sẻ',
  sarosTitle: 'Saros Finance | A DeFi Super-Network Built on Solana',
  totalLiquidity: 'Tổng Thanh Khoản',
  loadingPieTooltip: 'Dữ liệu sẽ tự động làm mới sau {{number}} giây. Nhấp vào Hình tròn này để tìm nạp dữ liệu mới nhất',
  totalVolume: 'Tổng Khối Lượng',
  totalTradingVolume: 'Tổng Khối Lượng Giao Dịch',
  totalUser: 'Tổng Người Dùng',
  newestSnapshot: 'Snapshot Mới Nhất',
  snapshotRanking: 'Thứ Hạng Snapshot',
  myRank: 'Cấp Bậc Của Tôi',
  recentSwap: 'Swap Gần Đây',
  joinDate: 'Ngày tham gia',
  reward: 'Phần Thưởng',
  compare: 'So Sánh',
  tradingVolume: 'Khối Lượng Giao Dịch',
  popular: 'Thịnh hành',
  poolShare: 'Pool Share',
  lpValue: 'Giá trị LP',
  token1Amount: 'Số lượng token 1',
  token2Amount: 'Số lượng token 2',
  pair: 'Pair',
  tokenValue: 'Giá trị token',
  status: 'Trạng thái',
  swap: 'Swap',
  addLiquidity: 'Cung cấp thanh khoản',
  removeLiquidity: 'Rút thanh khoản {{token0}} - {{token1}}',
  stake: 'Stake',
  unStake: 'Unstake',
  harvest: 'Thu hoạch',
  stakeFarm: 'Stake Farm',
  unStakeFarm: 'Unstake Farm',
  harvestFarm: 'Bạn muốn thu hoạch?',
  joinSnapshot: 'Tham gia Snapshot',
  endSnapshot: 'Camp {{snapshot}} snapshot đã kết thúc',
  shareSwap: 'Share Swap',
  shareFarm: 'Share Farm',
  shareStake: 'Share Stake',
  sarosLandingTitle: 'Giao thức DeFi hợp nhất trên <span class="color-theme">Solana</span>',
  sarosLandingDescription: 'Nền tảng phi tập trung nơi bạn có thể swap, stake và farm với chi phí thấp, hiệu quả cao và trải nghiệm người dùng tuyệt vời.',
  defiSuiteDescription: 'DeFi với sự tổng hoà đáng kinh ngạc',
  landingSwapDescription: 'Swap bất cứ token nào trên Solana một cách dễ dàng',
  landingStakeDescription: 'Stake và earn token. Không có tổn thất tạm thời',
  landingFarmDescription: 'Cung cấp thanh khoản và farm để tối đa hoá lợi nhuận',
  landingSnapshotDescription: 'Tham gia các hoạt động cực hot trên Saros',
  permissionless: 'Permissionless',
  capitalEffiency: 'Hiệu quả sử dụng vốn',
  friendlyUI: 'Giao diện thân thiện',
  lowCost: 'Chi phí thấp',
  scalability: 'Khả năng tương thích',
  composability: 'Khả năng kết hợp',
  mission1: 'Bất kỳ ai cũng có thể tạo pool thanh khoản trên SarosSwap',
  mission2: 'Tối ưu công thức định giá để hiệu quả sử dụng vốn tốt hơn',
  mission3: 'Giao diện đỉnh cao',
  mission4: 'Phí giao dịch trung bình đặc biệt thấp',
  mission5: 'Giao dịch quy mô lớn đặc biệt nhanh với độ trễ thấp',
  mission6: 'Khả năng mở rộng và Hiệu suất cao - Duy trì chất lượng bảo mật và tính phi tập trung',
  landingMissionDescription: 'Giải quyết vấn đề và Đưa ra giải pháp tốt nhất',
  ourBackers: 'Backers',
  buildTogether: 'Hãy cùng nhau xây dựng',
  sort: 'Lọc',
  address: 'Địa chỉ',
  tradingApr: 'APR Giao dịch',
  farmApr: 'Farm APR',
  totalValueAllTime: 'All Time TVL',
  topVolume24h: 'Top khối lượng giao dịch 24h',
  topLiquidity: 'Top Thanh khoản',
  copyLink: 'Sao chép đường dẫn',
  language: 'Ngôn ngữ',
  currency: 'Tiền tệ',
  youWillBurn: 'Bạn burn',
  value: 'Giá trị',
  token: 'Token',
  txsFailed: 'Giao dịch không thành công',
  viewExplorer: 'Chi tiết',
  lightDark: 'Sáng/Tối',
  changeWallet: 'Đổi ví',
  half: 'Một nửa',
  learnMore: 'Xem thêm',
  ourProducts: 'Sản phẩm',
  ourMission: 'Sứ mệnh',
  tradingNow: 'Giao dịch ngay',
  supplyLiquidity: 'Cung cấp thanh khoản',
  stakeTokens: 'Stake Token',
  earnFees: 'Hưởng phí',
  totalSupply: 'Tổng cung',
  marketCap: 'Vốn hoá',
  swapNow: 'Swap ngay',
  launchApp: 'Mở App',
  exploreSolanaEcosystem: 'Tìm hiểu thêm về các token trên Solana',
  copied: 'Đã sao chép',
  snapshotDescription: 'Tham gia các giải đấu Trading với giải thưởng siêu hấp dẫn cùng Saros',
  activityCampaigns: 'Các Hoạt Động',
  addressAlreadyJoin: 'Địa chỉ ví này đã tham gia',
  get: 'Nhận',
  campEnd: 'Hoạt động đã kết thúc',
  noWalletFound: 'Không tìm thấy địa chỉ ví',
  snapshotCampaign: 'Hoạt động Snapshot',
  participated: 'Đã tham gia',
  upTo: 'Lên tới',
  totalReward: 'Tổng giải thưởng',
  top100TradingVolume: 'Top 100 khối lượng giao dịch',
  duration: 'Thời gian',
  joinNow: 'Tham gia ngay',
  upComing: 'Sắp diễn ra',
  sarosFinance: 'Saros Finance',
  onGoing: 'Đang diễn ra',
  joinSuccess: 'Tham gia Snapshot thành công',
  myRewards: 'Phần thưởng của tôi',
  snapshot: 'Snapshot',
  joinSnapshotDescription: 'Bạn có muốn tham gia Snapshot này?',
  searchByAddress: 'Tìm kiếm theo địa chỉ',
  searchByNameOrAddress: 'Tìm kiếm theo tên hoặc địa chỉ',
  alreadyJoinedSnapshot: 'Bạn đã tham gia hoạt động này',
  joined: 'Đã tham gia',
  USD: 'USD',
  JPY: 'JPY',
  AUD: 'AUD',
  BRL: 'BRL',
  CAD: 'CAD',
  CHF: 'CHF',
  CLP: 'CLP',
  CNY: 'CNY',
  CZK: 'CZK',
  DKK: 'DKK',
  EUR: 'EUR',
  GBP: 'GBP',
  HKD: 'HKD',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  INR: 'INR',
  ISK: 'ISK',
  KRW: 'KRW',
  MXN: 'MXN',
  MYR: 'MYR',
  NOK: 'NOK',
  NZD: 'NZD',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  RUB: 'RUB',
  SEK: 'SEK',
  SGD: 'SGD',
  THB: 'THB',
  TRY: 'TRY',
  TWD: 'TWD',
  ZAR: 'ZAR',
  VND: 'VND',
  EN: 'Tiếng Anh',
  VI: 'Tiếng Việt',
  yourReward: 'Phần thưởng của bạn',
  poolToken: 'Pool Token',
  farming: 'Farming',
  managerPool: 'Quản lý Pool',
  managePool: 'Quản lý Pool',
  allTimeLow: 'All Time Low',
  allTimeHigh: 'All Time High',
  yourPoolShare: 'Pool Share của bạn',
  yourPoolToken: 'Pool Token của bạn',
  yourLpFarming: 'LP đang Farm của bạn',
  deposited: 'Deposited',
  reviewOrder: 'Kiểm tra lệnh',
  save: 'Lưu',
  stakeLpToken: 'Stake LP Token',
  estFee: 'Ước lượng phí',
  stakeNow: 'Stake ngay',
  completeOrderFarm: 'Lệnh của bạn đã được hoàn tất',
  viewTransaction: 'Xem chi tiết lệnh',
  totalShare: 'Tổng',
  topToken: 'Top Token',
  topTokens: 'Top Token',
  selectToken: 'Chọn Token',
  removeLiquidityActions: 'Rút thanh khoản',
  selectLiquidity: 'Chọn thanh khoản để rút',
  amountTokenMoreThan: 'Số lượng 2 token lớn hơn 0',
  amountTokenNotEnough: 'Không đủ số lượng 2 token',
  trending: 'Thịnh hành',
  gainer: 'Tăng',
  recentlyAdded: 'Mới được thêm gần đây',
  startEarn: 'BẮT ĐẦU EARN',
  viewTokeInfo: 'XEM THÔNG TIN TOKEN',
  enoughLP: 'Số dư không đủ để Stake',
  farmEnded: 'Farm đã kết thúc',
  farmDesc: 'Stake LP token để nhận về phần thưởng các loại',
  earn: 'Earn',
  unclaimDesc: 'Phần thưởng chưa nhận',
  viewContract: 'Xem contract',
  unStakeLP: 'Unstake {{token}} Token',
  stakeLP: 'Stake {{token}} Token',
  stakeSuccess: 'Stake thành công',
  unStakeSuccess: 'Unstake thành công',
  harvestSuccess: 'Thu hoạch thành công',
  harvestFailed: 'Thu hoạch không thành công',
  totalReceive: 'Tổng nhận',
  endBlock: 'Kết thúc ở block',
  connectToSee: 'Kết nối ví để xem Pool của bạn',
  farmEnd: 'Farm đã kết thúc',
  createAccount: 'Tạo tài khoản',
  search: 'Tìm kiếm',
  sarosPool: 'Saros Pool',
  permissionLessPool: 'Permissionless Pool',
  priceImpactMustBeLessThan: 'Price impact phải dưới 50%',
  fees: 'Phí',
  apr: 'APR',
  notFoundScreen: '404 Không tìm thấy màn hình',
  emptyData: 'Dữ liệu trống',
  emptyDataTable: 'Bảng dữ liệu trống',
  backTo: 'Quay lại',
  youAreAddLiquidity: 'Bạn sẽ nhận',
  pooled: 'Pooled {{symbol}}',
  youAreTheFirst: 'Bạn là người cung cấp thanh khoản đầu tiên',
  theRatioOf: 'Tỉ lệ token bạn cung cấp sẽ quyết định giá trị của pool',
  onceYouAre: 'Nếu bạn hài lòng với tỉ giá hiện tại, vui lòng ấn Supply để kiểm tra lại',
  removeLiquidityDesc: 'Nhận {{token0}} và {{token1}}',
  balance: 'Số dư',
  youWillReceive: 'Bạn sẽ nhận',
  name: 'Tên',
  price: 'Giá',
  priceChange: 'Thay đổi giá',
  lpRewardFee: 'Phần thưởng phí LP 24h',
  lpRewardApr: 'Phần thưởng LP APR',
  add: 'Thêm',
  create: 'Tạo',
  transaction24h: 'Giao dịch 24h',
  confirmRemove: 'Xác nhận rút',
  lpTokensIn: 'LP Token trong ví của bạn',
  confirmCreate: 'Xác nhận tạo',
  comingSoon: 'Sắp diễn ra',
  lpTokens: 'LP Token',
  overview: 'Tổng quan',
  pools: 'Pool',
  tokens: 'Token',
  wallets: 'Ví',
  loading: 'Đang tải',
  inputAmount: 'Điền số lượng',
  pleaseResetWallet: 'Vui lòng khôi phục ví',
  totalLp: 'Tổng LP',
  trendingPools: 'Pool thịnh hành',
  allPools: 'Tất cả Pool',
  remove: 'Rút',
  addLiquidityInstead: 'Cung cấp thanh khoản',
  enterAmount: 'Điền số lượng',
  createPoolAndSupply: 'Tạo Pool và cung cấp',
  confirmSupply: 'Xác nhận cung cấp',
  youAreCreatingPool: 'Bạn đang tạo một Pool',
  Курс: 'Tỉ giá',
  tokenPool: '{{symbol}} Pool Token',
  youWillReceived: 'Bạn sẽ nhận',
  supply: 'Cung cấp',
  shareOfPool: 'Share của Pool',
  priceAndPool: 'Giá và Pool Share',
  amount: 'Số lượng',
  addLiquidityDesc: 'Cung cấp thanh khoản để nhận LP Token',
  findOtherLpToken: 'Tìm LP Tokens khác',
  dontSeePool: 'Không tìm Pool đã tham gia trước đó?',
  noLiquidity: 'Không tìm thấy thanh khoản',
  removeLiquidityTo: 'Rút thanh khoản để nhận về token',
  yourLiquidity: 'Thanh khoản của bạn',
  success: 'Thành công',
  yourOrderHasBeen: 'Lệnh của bạn đã được hoàn tất',
  estimateGasFee: 'Ước lượng phí',
  saveSetting: 'Lưu cài đặt',
  close: 'Đóng',
  viewTxs: 'Xem giao dịch',
  transactionSubmitted: 'Giao dịch đã được gửi',
  confirm: 'Xác nhận',
  estimate: 'Ước lượng',
  slippageTolerance: 'Khả năng trượt giá',
  setting: 'Cài đặt',
  convert: 'Chuyển đổi',
  convertWSOL: 'Chuyển đổi SOL sang wSOL',
  priceImpactMustLessThan: 'Price impact phải dưới 50%',
  minimumBalance: 'Số dư tối thiểu được đề nghị là 0.05 SOL',
  exceedsLimit: 'Vượt quá khả năng trượt giá',
  setup: 'Cài đặt',
  setupDone: 'Hoàn tất cài đặt',
  dueLimitSol: 'Do giới hạn về khối lượng giao dịch trên Solana, khởi tạo tài khoản trong ví trước khi swap là bắt buộc.',
  topPool: 'Top Pool',
  topPools: 'Top Pool',
  inputSearchText: 'Điền nội dung tìm kiếm',
  pool: 'Pool',
  liquidity: 'Thanh khoản',
  volume24h: 'Khối lượng 24h',
  volume7d: 'Khối lượng 7d',
  fee24h: 'Phí 24H',
  yearFee: 'Phí 1 năm / Thanh khoản',
  backToPool: 'Quay trở lại pool',
  totalTokenLocked: 'Tổng số token đã khoá',
  volume: 'Khối lượng',
  transaction: 'Giao dịch',
  transactions: 'Giao dịch',
  all: 'Tất cả',
  adds: 'Thêm',
  removes: 'Rút',
  action: 'Hành động',
  totalValue: 'Tổng giá trị',
  totalAmount: 'Tổng số lượng',
  tokenAmount: 'Số lượng token',
  tokenAmountFrom: 'Số lượng token từ',
  tokenAmountTo: 'Số lượng token tới',
  account: 'Tài khoản',
  time: 'Thời gian',
  farm: 'Farm',
  staking: 'Stake',
  poolNotFound: 'Không tìm thấy Pool',
  gasSolNotEnough: 'Số dư không đủ',
  impactLarge: 'Price Impact {{number}}%\\nBạn có muốn tiếp tục?',
  tradeDone: 'Token đã được nạp vào ví của bạn',
  received: '{{symbol}} Đã nhận!',
  swapConfirmation: 'Xác nhận Swap',
  txsFee: 'Phí: {{amount}} ~ {{amountUSD}}',
  sizeTooSmall: 'Khối lượng quá nhỏ',
  tradeErrFund: 'Số dư không đủ',
  timeOutTxs: 'Giao dịch đã hết thời gian chờ hoặc chưa được xác nhận. Vui lòng đợi trong giây lát và thử lại sau.',
  feeErr: 'Không đủ số dư hoặc phí gas',
  amountErr: 'Không được nhiều hơn số tiền có thể chi tiêu',
  yourPassword: 'Mật khẩu của bạn',
  password: 'Mật khẩu',
  pleaseWaitOrder: 'Vui lòng đợi trong giây lát để lệnh được hoàn tất.',
  txsFail: 'Vui lòng thử lại trong vài phút.',
  unlockWallet: 'Mở khoá ví',
  wrongPass: 'Mật khẩu không chính xác',
  tryLeft: 'Bạn còn {{num}} lần thử',
  walletHasBeenReset: 'Ví của bạn đã được làm mới',
  onlyOneChanceReset: 'Bạn chỉ còn một cơ hội. Ví của bạn sẽ được làm mới nếu mật khẩu không hợp lệ.',
  unlock: 'Mở khoá',
  youPay: 'Bạn trả',
  youReceive: 'Bạn nhận được (Ước tính)',
  previewOrder: 'Kiểm tra lệnh',
  swappingToken: 'Đang swap token...',
  depositToken: '{{symbol}} sẽ được gửi vào ví của bạn sau khi giao dịch hoàn tất',
  disconnectWallet: 'Ngắt kết nối ví?',
  disconnect: 'Ngắt kết \bnối',
  chooseWallet: 'Vui lòng chọn ví của bạn',
  noWallet: 'Không có ví',
  coin98NotInstall: 'Ví Coin98 chưa được cài đặt.',
  installCoin98: 'Cài đặt Ví Coin98 để quản lý.',
  connectWallet: 'Kết nối ví',
  searchByToken: 'Tìm kiếm theo tên token, ticker hoặc địa chỉ',
  gasNotEnough: 'Không đủ {{name}} làm phí gas.',
  tooLarge: 'Bạn đã gửi quá nhiều giao dịch, vui lòng cân nhắc giảm tần suất.',
  searchPoolOrToken: 'Tìm kiếm pool hoặc token',
  cancel: 'Huỷ',
  sarosStake: 'Stake',
  sarosFarm: 'Farm',
  stakeDesc: 'Stake token để nhận về phần thưởng các loại',
  farmingDescription: 'Stake LP token để nhận C98',
  stakeTotal: 'Tổng Deposit',
  myReward: 'Phần thưởng của tôi',
  totalValueStaked: 'Tổng giá trị đã Stake',
  active: 'Đang hoạt động',
  completed: 'Hoàn tất',
  stakeOnly: 'Đã Stake - Riêng',
  staked: 'Đã Stake',
  searchFarm: 'Tìm kiếm Farm',
  historyTransactions: 'Lịch sử giao dịch',
  default: 'Mặc định',
  tradingView: 'Cửa sổ giao dịch',
  verify: 'Xác nhận',
  verified: 'Đã xác nhận',
  farmNow: 'Farm ngay',
  enable: 'Cho phép',
  viewOnBlock: 'Xem giao dịch trên block explorer',
  pairInfo: 'Thông tin chi tiết cặp',
  getC98BNB: 'Cung cấp C98 - BNB LP',
  copyAddress: 'Sao chép địa chỉ',
  copyAddressFail: 'Sao chép địa chỉ không thành công',
  dark: 'Tối',
  light: 'Sáng',
  openChart: 'Mở biểu đồ',
  hideChart: 'Ẩn biểu đồ',
  closeChart: 'Đóng biểu đồ',
  history: 'Lịch sử',
  priceImpact: 'Price impact',
  swapFee: 'Phí swap',
  rate: 'Tỉ giá',
  uiFee: 'Phí UI',
  smartRoute: 'Smart route',
  bestMatch: 'Tốt nhất',
  totalAddressActive: 'Tổng các ví đang hoạt động',
  liquidityProviders: 'Người cung cấp thanh khoản',
  accountFarming: 'Tài khoản đang Farm',
  accountStake: 'Tài khoản đang Stake',
  backToListWallet: 'Quay trở lại danh sách ví',
  totalValueLocked: 'Tổng giá trị tài sản đã khoá',
  poolFarming: 'Pool đang Farm',
  poolStaked: 'Pool đã Stake'
}
