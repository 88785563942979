import { CURRENCY_SYMBOL } from 'common/constants'
import { get } from 'lodash'
import React, { useEffect, useMemo, useRef } from 'react'
import CountUp from 'react-countup'
import { useSelector } from 'react-redux'

function CountUpComponent ({
  endNum,
  decimals = 2,
  isCurrency = false,
  prefixValue,
  className,
  duration = 0.5,
  suffix,
  isCheckAprByAmount
}) {
  const previousValue = useRef(0)

  const currencyRedux = useSelector(state => state.currencyRedux)
  const isVND = get(currencyRedux, 'label') === 'VND'

  useEffect(() => {
    previousValue.current = endNum || 0
  }, [endNum])

  const renderPrefix = useMemo(() => {
    if (isCheckAprByAmount) return ''
    const isVND = get(currencyRedux, 'label') === 'VND'
    const currencyIcon = CURRENCY_SYMBOL[get(currencyRedux, 'label', 'USD')]
    if (!isCurrency) {
      return ''
    }
    if (isVND) {
      return ''
    }
    return currencyIcon
  }, [currencyRedux, isCurrency])

  const renderSuffix = useMemo(() => {
    if (!isCurrency) return suffix
    const isVND = get(currencyRedux, 'label') === 'VND'
    const currencyIcon = CURRENCY_SYMBOL[get(currencyRedux, 'label', 'USD')]
    if (isVND) return currencyIcon
    return suffix
  }, [isCurrency, currencyRedux])

  return (
    <div className={className}>
      <CountUp
        start={previousValue.current}
        duration={duration}
        end={Number(endNum)}
        decimals={(isCurrency && isVND) ? 0 : decimals}
        separator=","
        prefix={renderPrefix || ''}
        suffix={renderSuffix || ''}
      />
    </div>

  )
}

export default CountUpComponent
