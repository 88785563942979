import Badge from 'components/Badge'
import Button from 'components/common/Button'
import CountUpComponent from 'components/CountUpComponent'
import SocialNetworkLink from 'components/SocialNetworkLink'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import { Link, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import moment from 'moment'
import ModalJoinSnapshot from '../ModalJoinSnapshot'
import cn from 'clsx'
import BaseAPI from 'controller/api/BaseAPI'
import { useSelector } from 'react-redux'
import { showNotificationToast, upperCase } from 'common/functions'
import useScreenWidth from 'hooks/useScreenWidth'

const CardSnapshot = ({ data, isJoinedCamp }) => {
  const { t } = useTranslation()
  const addressWalletActive = useSelector(state => state.accountSol)
  const history = useHistory()
  const { width } = useScreenWidth()
  const isMobile = width < 576
  const isRequiredAuthen = get(data, 'isRequiredAuthen')

  const description = get(data, 'description')
  const name = get(data, 'name')
  const owner = get(data, 'owner')
  const logo = get(data, 'logo')
  const background = get(data, 'image')
  const reward = get(data, 'reward')
  const isUpTo = true
  const listSocial = get(data, 'social')
  const campID = get(data, 'id')
  const startTimeStamp = get(data, 'start')
  const endTimeStamp = get(data, 'end')
  const toDay = new Date().getTime()
  const isGoing = startTimeStamp < toDay && endTimeStamp > toDay
  const isEnd = endTimeStamp <= toDay
  const rewardToken = get(data, 'tokenInfo', {})

  const status = isGoing ? 'onGoing' : (isEnd ? 'completed' : 'upComing')

  const badgeStatus = useMemo(() => {
    if (status === 'onGoing') {
      return 'success'
    }
    if (status === 'completed') {
      return 'danger'
    }
    if (status === 'upComing') {
      return 'warning'
    }
    return 'success'
  }, [status])

  const onCloseModal = () => {
    window.closeModal()
  }

  const onNavigateToSnapshotDetail = () => {
    if (isMobile) {
      return history.push(`/snapshot/${campID}`)
    }
    return () => {}
  }

  const onJoin = async () => {
    try {
      const res = await BaseAPI.postData('snapshot/participant', {
        id: campID,
        address: addressWalletActive
      })

      if (res?.errMess) {
        showNotificationToast(t(res?.errMess), 'error', 2000)
        window.closeModal()
      } else {
        showNotificationToast(t('joinSuccess'), 'success', 2000)
        setTimeout(() => {
          history.push(`snapshot/${campID}`)
        }, 100)
      }
      window.closeModal()
    } catch (error) {
      showNotificationToast(t(error), 'error', 2000)
      window.closeModal()
    }
  }

  const onConfirmJoinSnapshotCamp = async (e) => {
    e.preventDefault()

    if (!addressWalletActive && !isRequiredAuthen) {
      return showNotificationToast(t('noWalletFound'), 'error', 2000)
    }

    // const isJoined = await BaseAPI.getData('snapshot/participant/campaign', {
    //   id: campID,
    //   address: addressWalletActive
    // })

    // if (isJoined) {
    //   window.openModal({
    //     isCustomModal: true,
    //     content: <ModalJoinSnapshot isJoined={true} title={name} onConfirm={onCloseModal}/>

    //   })
    // } else {

    // }
    window.openModal({
      isCustomModal: true,
      content: <ModalJoinSnapshot title={name} onCancel={onCloseModal} onConfirm={onJoin}/>
    })
  }

  return (
    <div className={styles.cardContainer}>
      <div
        className='card-image'
        style={ { backgroundImage: `url(${background})` } }
        onClick={onNavigateToSnapshotDetail}
      >
        <Badge className="status-badge" variant={badgeStatus}>{t(status)}</Badge>

        <div className='card-hover-container'>
          <SocialNetworkLink className="list-link" listSocial={listSocial}/>
          <Link to={`/snapshot/${campID}`} className='w-100'>
            <a>
              <div className='card-hover-content'>
                <div className='card-hover-content__description'>
                  {description}
                </div>
                <Button
                  isDisable={status === 'completed' || isJoinedCamp}
                  onClick={onConfirmJoinSnapshotCamp} className="card-hover-content__button">
                  {t(status === 'completed' ? 'campEnd' : isJoinedCamp ? 'joined' : 'joinNow')}
                </Button>
              </div>
            </a>

          </Link>
        </div>

      </div>

      <div className='card-info'>
        <Link to={`/snapshot/${campID}`} className='w-100'>
          <a>
            <div className='d-flex align-items-center cursor-pointer'>
              <figure className='coin-image'>
                <img src={logo} className='' alt="" />
              </figure>
              <div className='coin-info-text'>
                <div className='text-l bold coin-info-text--name'>{name}</div>
                <div className='color-grey text-sm coin-info-text--owner'>{owner}</div>
              </div>
            </div>
          </a>
        </Link>
        <div className='reward-info'>
          <div className='text-sm color-grey'>{t('totalReward')}</div>
          <div className='d-flex align-items-center color-theme reward-info__text bold mt-3'>
            <div className={!isUpTo && 'd-none'}>{t('upTo')}</div>
            <CountUpComponent
              className="ml-2"
              decimals={0}
              endNum={reward}
              prefixValue=""
            />
            <div className={cn('ml-2', !isUpTo && 'd-none')}>{upperCase(rewardToken.symbol)}</div>
          </div>
          <div className='color-grey text-sm mt-3'>{t('duration')}</div>
          <div className='date-container'>
            <div className='date'>
              <div className='date__day'>{moment(startTimeStamp).format('DD')}</div>
              <div className='date__month'>{moment(startTimeStamp).format('MMM')}</div>
            </div>
            <div className='bold'>-</div>
            <div className='date'>
              <div className='date__day'>{moment(endTimeStamp).format('DD')}</div>
              <div className='date__month'>{moment(endTimeStamp).format('MMM')}</div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default CardSnapshot
