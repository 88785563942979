import React from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
import { Icon } from 'components/Icon'

const TokenFavorite = ({ children, onRemove, onClick, isPopup }) => {
  const handleClick = () => {
    onClick && onClick()
  }
  const handleRemove = (event) => {
    onRemove && onRemove(event)
  }

  return (
    <div
      onClick={handleClick}
      className={cn(
        styles['token-favorite'],
        styles[`${isPopup ? '' : 'token-favorite--list-pinned'}`],
        'cursor-pointer'
      )}
    >
      <div className={styles['icon-close']}>
        <Icon onClick={handleRemove} name="web_close_circle_bold" />
      </div>
      {children}
    </div>
  )
}

export default TokenFavorite
