import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import { WalletOverviewContext } from './Context/WalletOverviewContext'
import get from 'lodash/get'
import BaseAdapter from 'controller/api/BaseAdapter'
import { useParams } from 'react-router-dom'
import DetailWalletInfo from './DetailWalletInfo'
import CountUpComponent from 'components/CountUpComponent'
import OverviewTable from './OverviewTable'
import TransactionTable from '../TransactionTable'

const WalletOverview = () => {
  const { t } = useTranslation()
  const { address } = useParams()

  const [overviewStats, setOverviewStats] = useState()

  useEffect(() => {
    loadingOverviewStats()
  }, [])

  const loadingOverviewStats = async () => {
    const res = await BaseAdapter.getData('saros/wallet/address/statictical')
    if (res) {
      setOverviewStats(res.data)
    }
  }

  return (
    <div className={styles.walletOverviewContainer}>

      <div className='list-info-box'>
        <div className='chart-info-box'>
          <div className='text-num'>
            <CountUpComponent endNum={ get(overviewStats, 'totalAddressActive')} decimals={0} />
          </div>
          <div className='text-xs color-grey'>{t('totalAddressActive')}</div>
        </div>
        <div className='chart-info-box'>
          <div className='text-num'>
            <CountUpComponent endNum={ get(overviewStats, 'liquidityProvider')} decimals={0} />

          </div>
          <div className='text-xs color-grey'>{t('liquidityProviders')}</div>
        </div>
        <div className='chart-info-box'>
          <div className='text-num'>
            <CountUpComponent endNum={ get(overviewStats, 'accountFarming')} decimals={0} />
          </div>
          <div className='text-xs color-grey'>{t('accountFarming')}</div>
        </div>
        <div className='chart-info-box'>
          <div className='text-num'>
            <CountUpComponent endNum={ get(overviewStats, 'accountStaked')} decimals={0} />

          </div>
          <div className='text-xs color-grey'>{t('accountStake')}</div>
        </div>
      </div>

      {address && <DetailWalletInfo/>}

      <OverviewTable address={address}/>

      {address && <TransactionTable typeBoard='wallets' address={address} />}

    </div>
  )
}

export default WalletOverview
