import Modal from 'components/modals/Modal'
import React, { useEffect, useState } from 'react'
import { generateId } from 'common/functions/utils'
const ModalServices = () => {
  const [modals, setModals] = useState({})

  const handleOpenModal = async (props) => {
    window.requestAnimationFrame(() => {
      const { name } = props

      const finalName = name || generateId()

      const newModals = { ...props, name: finalName, isOpen: true }
      setModals(newModals)
    })
  }

  const handleCloseModal = () => {
    window.requestAnimationFrame(() => {
      const updateModals = { ...modals }
      updateModals.isOpen = false
      setModals(updateModals)
    })
  }

  const handleDefaultClose = (name, callback) => () => {
    handleCloseModal()
    if (typeof callback === 'function') {
      callback()
    }
  }

  useEffect(() => {
    window.openModal = handleOpenModal
    window.closeModal = handleCloseModal
  }, [])

  const renderModals = modals ? <Modal {...modals} onClose={handleDefaultClose(modals.name, modals.onClose)}/> : null

  return (
    <>
      {renderModals}
    </>
  )
}

export default React.memo(ModalServices)
