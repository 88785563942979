import React, { useState, useEffect, useMemo, useContext } from 'react'
import cn from 'clsx'
import CustomTable from 'components/CustomTable'
import { formatBilion, formatNumberBro, formatPrice, getLength, nFormatter } from 'common/functions'
import { COIN_IMAGE } from 'common/constants'
import ReactPaginate from 'react-paginate'
import PageDropdown from 'components/PageDropdown'

import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import CountdownCircle from 'components/CountdownCircle'
import { useHistory } from 'react-router-dom'
import BaseAPI from 'controller/api/BaseAPI'
import { cloneDeep, compact, get } from 'lodash'
import Pagination from 'components/Pagination'
import TokenImage from 'components/common/TokenImage'
import { Icon } from 'components/Icon'
import BaseAdapter from 'controller/api/BaseAdapter'
import StoreActions from 'controller/redux/actions/storeActions'
import { useSelector, useDispatch } from 'react-redux'

import useFavoritePool from 'hooks/pools/useFavoritePool'
import ReduxServices from 'common/redux'
import Tooltip from 'components/Tooltip'

const LIMIT = 10
const SAROS_POOL = 'SAROS_POOL'
const PERMISSION_POOL = 'PERMISSION_POOL'

function TableTopPool ({ type = 'pool', address }) {
  const { t } = useTranslation()

  const POOL_SORT = [
    {
      label: t('liquidity'),
      value: 'totalLiquidity'
    },
    {
      label: t('apr'),
      value: 'apr'
    },
    {
      label: t('volume24h'),
      value: 'volume24h'
    },
    {
      label: t('volume7d'),
      value: 'volume7d'
    },
    {
      label: t('fees'),
      value: 'volume24h'
    }
  ]
  const history = useHistory()
  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const accountSol = useSelector(state => state.accountSol)
  const listFavoritePoolRedux = useSelector(state => state.listFavoritePoolRedux)
  const currencyRedux = useSelector(state => state.currencyRedux) // needed to rerender onChange Currency

  const dispatch = useDispatch()

  const [listTopPool, setListTopPool] = useState([])

  const { onChangeListPoolFavor } = useFavoritePool()

  const [poolSort, setPoolSort] = useState('totalLiquidity')
  const [isLoading, setIsLoading] = useState()
  const [total, setTotal] = useState()
  const [currPage, setCurrPage] = useState(1)

  useEffect(() => {
    getListTopPool()
  }, [currPage, poolSort])

  // useEffect(() => {
  //   getListPoolFavor()
  // }, [])

  const getListTopPool = async (isTriggerLoad = true) => {
    setIsLoading(isTriggerLoad)
    const params = {
      page: currPage,
      size: LIMIT,
      sort: poolSort
    }
    const urlFetch = {
      token: {
        // [SAROS_POOL]: 'saros/token/pool/all',
        [SAROS_POOL]: 'saros/pool/filter',
        [PERMISSION_POOL]: 'saros/token/pool/all'
      },
      pool: {
        // [SAROS_POOL]: 'saros/pool/top/all',
        [SAROS_POOL]: 'saros/pool/filter',
        [PERMISSION_POOL]: 'saros/pool/top/all'

      }
    }

    if (type === 'token') {
      params.tokenAddress = address
    }

    if (type === 'pool') {
      params.type = 'top'
    }

    const response = await BaseAdapter.getData(urlFetch[type][SAROS_POOL], params)
    setIsLoading(false)
    if (!response) return

    setTotal(get(response, 'data.total'))
    setListTopPool(get(response, 'data.data'))
  }

  const handleFavoritePool = (info) => async (event) => {
    event.stopPropagation()
    onChangeListPoolFavor(info)
  }

  const isFavorite = (poolAddress) => {
    // const listMint = listFavorite.map((item) => item.mintAddress)
    if (!poolAddress) return false
    return listFavoritePoolRedux?.includes(poolAddress)
  }

  const columnHeader = [
    {
      title: '',
      dataIndex: 'favorite',
      width: 2,
      hide: !accountSol,
      render: (val) => {
        const { info } = val
        const poolAddress = get(info, 'poolAddress')
        return (
          <Icon
            onClick={handleFavoritePool(info)}
            className={cn(
              'text-l',
              isFavorite(poolAddress) ? 'color-theme' : ''
            )}
            name={`${
              isFavorite(poolAddress) ? 'web_like_active' : 'web_like'
            } `}
          />
        )
      }
    },
    {
      title: '',
      dataIndex: 'index',
      width: 2,
      hide: widthResize <= 768,
      render: (val) => {
        return <div>{val}</div>
      }
    },
    {
      title: t('name'),
      dataIndex: 'name',
      width: 20,
      render: (val) => {
        const { token0, token1 } = val
        return (
          <div className='d-flex align-items-center uppercase'>
            <TokenImage src={get(token0, 'icon')} alt="" className="token-img token-img--token-1 img-24 mr-2" />
            <TokenImage src={get(token1, 'icon')} alt="" className="token-img token-img--token-2 img-24 mr-2" />
            {get(token0, 'symbol')}/{get(token1, 'symbol')}
          </div>
        )
      }
    },
    {
      title: t('liquidity'),
      dataIndex: 'liquidity',
      width: 15,
      align: 'right',
      hide: widthResize <= 768,
      render: (val) => (<span>{val}</span>)
    },
    {
      title: t('volume24h'),
      dataIndex: 'volume24h',
      align: 'right',
      width: 15,
      render: (val) => (<span>{val}</span>)

    },
    {
      title: t('volume7d'),
      dataIndex: 'volume7d',
      align: 'right',
      width: 15,
      hide: widthResize <= 768,
      render: (val) => (<span>{val}</span>)

    },
    {
      title: t('fees'),
      dataIndex: 'fee24h',
      width: 15,
      align: 'right',
      hide: widthResize <= 768
    },
    {
      title: 'APR',
      dataIndex: 'feeAPR',
      width: 15,
      align: 'right',
      hide: widthResize <= 768,
      render: (val) => {
        return <div>{formatNumberBro({ number: val, mantissa: 2 })}%</div>
      }
    }
    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   width: 15,
    //   align: 'right',
    //   hide: widthResize <= 768,
    //   render: () => {
    //     return <span className='color-theme'>{t('addLiquidity')}</span>
    //   }
    // }
  ]

  const tablePoolData = useMemo(() => {
    if (getLength(listTopPool) === 0) return []
    return listTopPool.map((item, index) => {
      return {
        key: get(item, 'poolAddress'),
        index: LIMIT * (currPage - 1) + index + 1,
        name: item.info,
        liquidity: ReduxServices.formatPrice(item.liquidity),
        volume24h: ReduxServices.formatPrice(item.volume24h),
        volume7d: ReduxServices.formatPrice(item.volume7d),
        fee24h: ReduxServices.formatPrice(item.fee24h),
        feeAPR: item.feeAPR,
        poolAddress: get(item, 'poolAddress'),
        favorite: { poolAddress: get(item, 'poolAddress'), info: { ...item } }
      }
    })
  }, [listTopPool, currencyRedux])

  const onChangePage = (val) => {
    const { selected } = val
    setCurrPage(selected + 1)
  }

  const handleResizeWidth = () => {
    setWidthResize(window.innerWidth)
  }
  const onReloadTableData = () => {
    getListTopPool(false)
  }

  const onChooseItem = (row) => {
    history.push(`/pool/${get(row, 'poolAddress')}`)
  }

  const onChangePoolSort = (val) => {
    setPoolSort(val.value)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResizeWidth)

    return () => {
      window.removeEventListener('resize', handleResizeWidth)
    }
  }, [])

  return (

    <div className={cn(styles['table-pool'], 'box-wrapper mb-5')}>
      <div className="table-pool__title mb-4">
        {t('topPools')}

        <div className='d-flex align-items-center'>
          <PageDropdown
            className="pool-toggle-down mr-2"
            menuClassName="pool-menu-down"
            options={POOL_SORT}
            onChange={onChangePoolSort}
          />
          <Tooltip align="right" className="ml-3" content={t('loadingPieTooltip', { number: 20 })} isLongContent>
            <CountdownCircle onComplete={onReloadTableData}/>
          </Tooltip>

        </div>
      </div>

      <div className="table-container">
        <CustomTable
          isLoading={isLoading}
          columns={columnHeader}
          dataSource={tablePoolData}
          onClickRow={onChooseItem}
          className="table-height"
        />

        {
          getLength(tablePoolData) > 0 &&
          <Pagination
            totalPage={total}
            handleSelectedPage={onChangePage}
          />
        }
      </div>
    </div>

  )
}

export default TableTopPool
