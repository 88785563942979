import images from 'assets/images'
import { FarmContext } from 'context/farm/farmContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

const FarmEnd = () => {
  const { t } = useTranslation()
  const farmContext = useContext(FarmContext)
  const { isStakeScreen } = farmContext
  return (
    <div className='farm-end-state'>
      <div className="farm-end-state__content">
        <img src={!isStakeScreen ? images.logoFarm : images.logoStake} alt="" />
        <p className="description">
          {t('farmEnded')}
        </p>
      </div>
    </div>
  )
}

export default FarmEnd
