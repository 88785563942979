import React from 'react'
import images from 'assets/images'
import { Fade } from 'react-awesome-reveal'
import { useSelector } from 'react-redux'
import Footer from 'components/Footer'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'

const Sponsors = () => {
  const { t } = useTranslation()

  const listSponsor = [
    images.logoSolanaDark,
    // images.alamedaSearchDark,
    images.hashedDark,
    images.spartanDark,
    images.bgvDark,
    images.asymmVenturesDark

  ]

  const listSponsor2 = [
    images.impossipleFinanceDark,
    images.logoGenblockDark,
    images.K300Dark,
    images.cryptomindDark,
    images.kyrosDark,
    images.evernewDark
  ]

  const listSponsorMobile = [
    images.logoSolanaDark,
    images.alamedaSearchDark,
    images.hashedDark,
    images.spartanDark,
    images.bgvDark,
    images.impossipleFinanceDark,
    images.asymmVenturesDark,
    images.logoGenblockDark,
    images.K300Dark,
    images.cryptomindDark,
    images.kyrosDark,
    images.evernewDark
  ]

  return (
    <div className={styles.sponsorContainer}>
      <div className="sponsors">
        <div className='top-image'>
          <img src={images.sarosAtom} alt="" />
        </div>
        <div className='bottom-image'>
          <img src={images.sarosAtom} alt="" />
        </div>
        <Fade className="head-small-title" duration="500" direction="up">
          <div>{t('buildTogether')}</div>
        </Fade>
        <Fade className="head-title" duration="500" direction="up">
          <div >{t('ourBackers')}</div>
        </Fade>
        <div className="sponsor-list">
          <div className="box-list">
            <div className="box-list__logo">
              {listSponsor.map((logo, index) => (
                <div key={index} className="logo-item">
                  <Fade duration="500" direction="up">
                    <img src={logo} alt="" />
                  </Fade>
                </div>
              ))}
            </div>

            <div className="box-list__logo2">
              {listSponsor2.map((logo, index) => (
                <div key={index} className="logo-item2">
                  <Fade duration="500" direction="up">
                    <img src={logo} alt="" />
                  </Fade>
                </div>
              ))}
            </div>
            <div className="box-list__mobile">
              {listSponsorMobile.map((logo, index) => (
                <div key={index} className="logo-item-mobile">
                  <Fade duration="500" direction="up">
                    <img src={logo} alt="" />
                  </Fade>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sponsors
