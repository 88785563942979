/* eslint-disable multiline-ternary */
import images from 'assets/images'
import { convertWeiToBalance, upperCase } from 'common/functions'
import Button from 'components/common/Button'
import CountUpComponent from 'components/CountUpComponent'
import { Icon } from 'components/Icon'
import { FarmContext } from 'context/farm/farmContext'
import { get } from 'lodash'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import StakeModal from '../../Modals/StakeModal'
import styles from './style.module.scss'
import FarmEnd from 'pages/NewFarmScreen/Components/FarmEnd'
import useStatusTxsHash from 'hooks/useStatusTxsHash'
import cn from 'clsx'
import ReduxServices from 'common/redux'

const FarmBoxController = ({ isLoadingLp = false, dataFarm }) => {
  const { t } = useTranslation()
  const statusHash = useStatusTxsHash()
  const farmContext = useContext(FarmContext)
  const {
    isLoadingUser,
    UN_STAKE,
    STAKE,
    updatePoolInfoLocal,
    onOpenHarvestModal
  } = farmContext

  const [isLoadingStake, setIsLoadingStake] = useState(false)
  const [isLoadingUnStake, setIsLoadingUnStake] = useState(false)

  const isStakeScreen = get(dataFarm, 'isStakeScreen')
  const token0 = get(dataFarm, 'token0')
  const token1 = get(dataFarm, 'token1')
  const getLpNameStaked = `${upperCase(
    get(token0, 'symbol', '')
  )} - ${upperCase(get(token1, 'symbol', ''))} Lp`
  const getLpName = `${upperCase(get(token0, 'symbol', ''))} - ${upperCase(
    get(token1, 'symbol', '')
  )} LP`
  const isActive = get(dataFarm, 'isActive', false)
  const isCheckAprByAmount = get(dataFarm, 'isCheckAprByAmount', false)
  const decimalTokenStake = get(dataFarm, 'decimalTokenStake', 6)
  const amountUserStakedWei = get(dataFarm, 'userInfo.amountUserStaked', 0)
  const amountUserStaked = convertWeiToBalance(
    amountUserStakedWei,
    decimalTokenStake
  )
  const userBalanceWei = get(dataFarm, 'userInfo.balance', 0)
  const userBalance = convertWeiToBalance(userBalanceWei, decimalTokenStake)
  const priceLp = get(dataFarm, 'priceLp', 0)
  const amountUserStakedUsd =
    parseFloat(amountUserStaked) * parseFloat(priceLp)
  const rewards = get(dataFarm, 'rewards', [])
  const totalRewardsUSD = rewards.reduce((total, curr) => {
    const price = parseFloat(get(curr, 'price', 0))
    const amount = parseFloat(get(curr, 'earn', 0))
    const amountUSD = price * amount
    let totalUsd = total + amountUSD
    if (isCheckAprByAmount) {
      totalUsd = total + amount
    }
    if (Number(totalUsd) < 0.001) {
      return 0
    }
    return totalUsd
  }, 0)
  const stakingToken = get(dataFarm, 'stakingToken')
  const onCloseModal = () => {
    window.closeModal()
  }

  const onOpenStakeModal = (type) => () => {
    if (isLoadingStake || isLoadingUnStake) return
    if (type === STAKE && parseFloat(userBalanceWei) <= 0) {
      return statusHash.handleTxsFailed({ message: t('tradeErrFund') })
    }
    const balance = type === STAKE ? userBalance : amountUserStaked
    const setLoading = (boolean) => {
      if (type === UN_STAKE) {
        return setIsLoadingUnStake(boolean)
      }
      return setIsLoadingStake(boolean)
    }
    window.openModal({
      isCustomModal: true,
      content: (
        <StakeModal
          initData={updatePoolInfoLocal}
          isUnStake={type === UN_STAKE}
          balance={balance}
          dataFarm={dataFarm}
          type={type}
          onCancel={onCloseModal}
          setLoading={setLoading}
        />
      )
    })
  }

  return (
    <div className={styles.controllerContent}>
      <div>
        <div className="text-sm color-grey semibold">
          {t('unclaimDesc')}
        </div>
        <div className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-xl semibold earning-line">
            <CountUpComponent
              isCheckAprByAmount={isCheckAprByAmount}
              endNum={ReduxServices.getCurrencyPrice(totalRewardsUSD)}
              isCurrency
            />
            {isCheckAprByAmount && (
              <span className='uppercase'>{get(stakingToken, 'symbol')}</span>
            )}
          </div>
          <Button
            isDisable={parseFloat(totalRewardsUSD) <= 0}
            className="harvest-btn"
            onClick={onOpenHarvestModal({ dataFarm })}
          >
            {t('harvest')}
          </Button>
        </div>
      </div>
      {!isActive && amountUserStakedWei <= 0 ? (
        <FarmEnd />
      ) : (
        <>

          <div className="line-stake">
            <div>
              {isStakeScreen ? (
                <div className="line-stake__label">{t('startEarn')} </div>
              ) : (
                <div
                  className={cn(
                    amountUserStakedWei <= 0 && 'opacity-0 invisible',
                    'd-flex aligns-items-center text-sm capitalize color-grey semibold'
                  )}
                >
                  <Link
                    className="label-link cursor-pointer"
                    to={`/pool/${get(dataFarm, 'poolLpAddress')}`}
                  >
                    {getLpNameStaked}
                  </Link>
                  {t('staked')}
                </div>
              )}

              {amountUserStakedWei <= 0 ? (
                <Button
                  isLoading={isLoadingUser || isLoadingStake}
                  isFullWidth
                  isDisable={userBalanceWei <= 0}
                  className="line-stake__actions"
                  onClick={onOpenStakeModal(STAKE)}
                >
                  {userBalanceWei <= 0 ? t('tradeErrFund') : t('stake')}
                </Button>
              ) : (
                <>
                  <div className="line-stake__add-stake">
                    <div className="stake-amount">
                      <p className='amount-line'>
                        <CountUpComponent endNum={amountUserStaked} />
                        {isCheckAprByAmount && (
                          <span className='uppercase amount-line__symbol'>{get(stakingToken, 'symbol')}</span>
                        )}
                      </p>
                      {!isCheckAprByAmount && (
                        <span className="stake-amount__price">
                          <CountUpComponent
                            endNum={ReduxServices.getCurrencyPrice(
                              amountUserStakedUsd
                            )}
                            isCurrency
                          />
                        </span>
                      )}

                    </div>
                    <div className="stake-deposit">
                      <div
                        onClick={onOpenStakeModal(STAKE)}
                        className={`stake-deposit__item ${
                          isLoadingStake || isLoadingUnStake
                            ? 'stake-deposit__item--disabled'
                            : ''
                        }`}
                      >
                        {isLoadingStake ? (
                          <BounceLoader size={16} color="#bbbbbb" />
                        ) : (
                          <Icon
                            name="web_add"
                            className="icon-control text-xl"
                          />
                        )}
                      </div>
                      <div
                        onClick={onOpenStakeModal(UN_STAKE)}
                        className={`stake-deposit__item ${
                          isLoadingUnStake || isLoadingStake
                            ? 'stake-deposit__item--disabled'
                            : ''
                        }`}
                      >
                        {isLoadingUnStake ? (
                          <BounceLoader size={16} color="#bbbbbb" />
                        ) : (
                          <Icon
                            name="web_remove"
                            className="icon-control text-xl"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div>
              {isStakeScreen ? (
                <div className="line-stake__get-lp">
                  <Link
                    className="d-flex align-items-center justify-content-center mt-4 lp-link"
                    to={`/token/${get(dataFarm, 'stakingToken.address')}`}
                  >
                    {t('viewTokeInfo')}
                  </Link>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center text-sm mt-4">
                  <span className="color-grey">{t('get')}</span>
                  <Link
                    className="lp-link ml-2 semibold"
                    to={`liquidity/add?token0=${get(
                      dataFarm,
                      'token0.address'
                    )}&token1=${get(dataFarm, 'token1.address')}`}
                  >
                    {getLpName}
                  </Link>
                  <Link
                    className="lp-link ml-2 semibold"
                    to={`liquidity/add?token0=${get(
                      dataFarm,
                      'token0.address'
                    )}&token1=${get(dataFarm, 'token1.address')}`}
                  >
                    <Icon name="explore_link" className="text-base ml-2" />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default FarmBoxController
