import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import images from 'assets/images'
import { FarmContext } from 'context/farm/farmContext'
import { get } from 'lodash'
import CountUpComponent from 'components/CountUpComponent'
import cn from 'clsx'
import useFarmHook from 'hooks/farm/useFarmHook'
import { useSelector } from 'react-redux'
import VideoContainer from 'components/VideoContainer'
import useScreenWidth from 'hooks/useScreenWidth'
import { CURRENCY_SYMBOL } from 'common/constants'
import ReduxServices from 'common/redux'

const FarmBanner = () => {
  const { t } = useTranslation()
  const farmContext = useContext(FarmContext)
  const farmHook = useFarmHook()
  const { width } = useScreenWidth()
  const isMobile = width < 576
  const accountSol = useSelector((state) => state.accountSol)

  const currencyRedux = useSelector(state => state.currencyRedux)

  const currencyIcon = CURRENCY_SYMBOL[get(currencyRedux, 'label', 'USD')]
  const isVND = currencyIcon === 'VND'
  const currencyRate = get(currencyRedux, 'value', 1)

  const { isStakeScreen, dataFarmStake, totalValueStakedAllTime } = farmContext

  const TVLAllTime = get(
    totalValueStakedAllTime,
    `${isStakeScreen ? 'stake' : 'farm'}`
  )

  const [totalEarn, setTotalEarn] = useState(0)
  const [totalValue, setTotalValue] = useState(0)

  useEffect(() => {
    initData()
  }, [dataFarmStake, isStakeScreen, accountSol])

  const initData = () => {
    const listFarm = get(
      dataFarmStake,
      `${isStakeScreen ? 'stake' : 'farm'}`,
      []
    )
    const total = listFarm.reduce((totalUSD, item) => {
      const rewards = get(item, 'rewards', [])
      const totalEarn = farmHook.calculateTotalEarn(rewards)
      const totalEarnUSD = get(totalEarn, 'totalRewardsUSD', 0)
      totalUSD += totalEarnUSD
      return totalUSD
    }, 0)
    const totalLiquid = listFarm.reduce((total, curr) => {
      const isCheckAprByAmount = get(curr, 'isCheckAprByAmount', 0)
      const liquidityUsd = isCheckAprByAmount ? 0 : get(curr, 'liquidityUsd', 0)
      const totalUsd = total + liquidityUsd
      return totalUsd
    }, 0)
    setTotalValue(totalLiquid)
    setTotalEarn(total)
  }

  return (
    <div className={styles.bannerContainer}>
      <div className="left-banner">
        <div className="left-banner__title">
          {t(`${isStakeScreen ? 'sarosStake' : 'sarosFarm'}`)}
        </div>
        <div className="mt-2">
          {t(`${isStakeScreen ? 'stakeDesc' : 'farmDesc'}`)}
        </div>
        <div className="total-value-wrap">
          <div className="total-value-wrap__item">
            <div className="left-banner__description">
              {t('totalValueAllTime')}
            </div>
            <div className="left-banner__price">
              <CountUpComponent
                endNum={ReduxServices.getCurrencyPrice(TVLAllTime)}
                isCurrency
              />
            </div>
          </div>
          <div className="total-value-wrap__item">
            <div className="left-banner__description">
              {t('totalValueStaked')}
            </div>
            <div className="left-banner__price">
              <CountUpComponent
                endNum={ReduxServices.getCurrencyPrice(totalValue)}
                isCurrency
              />
            </div>
          </div>
        </div>

        <div key={isStakeScreen} className="flower-lottie">
          {
            isMobile
              ? (
                <img
                  className={cn('stake-video', isStakeScreen && 'farm-video')}
                  src={images[isStakeScreen ? 'sarosStakeGif' : 'sarosFarmGif']}
                />
              )
              : (
                <VideoContainer
                  className={cn('stake-video', isStakeScreen && 'farm-video')}
                  src={
                    images[isStakeScreen ? 'sarosStakeVideo' : 'sarosFarmVideo']
                  }
                />
              )
            // <video
            //   onContextMenu="return false;"
            //   className={cn('stake-video', isStakeScreen && 'farm-video')}
            //   loop
            //   muted
            //   autoPlay
            // >

            //   <source
            //     src={images[isStakeScreen ? 'sarosStakeVideo' : 'sarosFarmVideo']}
            //     type="video/webm"
            //   />

            // </video>
          }
        </div>
      </div>

      <div className="right-banner">
        <div className="top-image">
          <img src={images.sarosAtom} alt="" />
        </div>
        <div className="bottom-image">
          <img src={images.sarosAtom} alt="" />
        </div>
        <div className="images-ball">
          <div className="right-banner__content">
            <div className="content-text text-sm">{t('myRewards')}</div>
            <div className="content-text text-l color-theme bold mt-2">
              <CountUpComponent endNum={ReduxServices.getCurrencyPrice(totalEarn)} isCurrency />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FarmBanner
