import React, { useContext, useEffect, useMemo, useState } from 'react'
import cn from 'clsx'

import styles from './style.module.scss'
import RechartLine from 'components/RechartLine'
import {
  formatNumberBro,
  formatPrice,
  getLength,
  nFormatter,
  upperCase
} from 'common/functions'
import { useTranslation } from 'react-i18next'
import InfoDetailPool from './Components/InfoDetailPool'
import { Icon } from 'components/Icon'
import Button from 'components/common/Button'
import { Link, useHistory } from 'react-router-dom'
import TokenInfo from 'components/TokenInfo'
import BlockColor from 'components/BlockColor'
import ItemBlockInfo from 'components/ItemBlockInfo'
import TransactionTable from '../TransactionTable'
import RechartBar from 'components/RechartBar'
import { PoolInfoContext } from 'context/poolInfo/poolInfoContext'
import { get } from 'lodash'
import PuffLoading from 'components/common/PuffLoading'
import { SOL_SCAN_URL } from 'common/constants'
import PoolTabbar from '../PoolTabbar'
import NewSwapContent from 'pages/NewSwapScreen/NewSwapContent'
import NewLiquidityScreenContent from 'pages/NewLiquidityScreen/NewLiquidityScreenContent'
import ReduxServices from 'common/redux'
import { useSelector } from 'react-redux'
import BaseAPINew from 'controller/api/BaseApiNew'

const LIQUIDITY = 'LIQUIDITY'
const VOLUME = 'VOLUME'

function NewPoolInfoDetail () {
  const { t } = useTranslation()
  const history = useHistory()
  const poolInfoContext = useContext(PoolInfoContext)
  const {
    dataChartVolume,
    dataChartLiquidity,
    isLoading,
    poolInfo,
    address,
    isLoadingScreen,
    userInfoInPool
  } = poolInfoContext
  const [tabChoose, setTabChoose] = useState(VOLUME)
  const [selectedTabbar, setSelectedTabbar] = useState('pools')
  const [poolFarmInfo, setPoolFarmInfo] = useState([])
  const currencyRedux = useSelector(state => state.currencyRedux) // needed to rerender onChange Currency

  const token0 = get(poolInfo, 'info.token0')
  const token1 = get(poolInfo, 'info.token1')

  const LIST_TAB = [
    {
      name: t('volume'),
      type: VOLUME
    },
    {
      name: t('liquidity'),
      type: LIQUIDITY
    }
  ]

  useEffect(() => {
    getlistPoolFarm()
  }, [])

  const getlistPoolFarm = async () => {
    const response = await BaseAPINew.getData('saros/information', { size: 100 })
    if (getLength(response) === 0) return []

    const findItem = response.filter(item => item.poolLpAddress === address)
    setPoolFarmInfo(get(findItem, `[${findItem.length - 1}]`))
  }

  const isShowFarmButton = useMemo(() => {
    return poolFarmInfo && poolFarmInfo.poolLpAddress
  }, [poolFarmInfo])

  const handleChooseTab = (tab) => () => {
    setTabChoose(tab)
  }

  const onNavigateToPoolFarm = () => {
    const poolFarmAddress = get(poolFarmInfo, 'poolAddress')

    if (poolFarmAddress) {
      return window.open(`${window.location.origin}/farm?poolAddress=${poolFarmAddress}`)
    }
    return window.open(`${window.location.origin}/farm`)
  }

  const handleSelectTabbar = (val) => {
    setSelectedTabbar(val)
    if (val === 'overview') {
      history.push('/info')
    } else {
      history.push(`/info?type=${val}`)
    }
  }

  const renderUrlAddLiquidity = () => {
    const token0Mint = get(token0, 'mintAddress')
    const token1Mint = get(token1, 'mintAddress')
    return `/liquidity/add?token0=${token0Mint}&token1=${token1Mint}`
  }

  const renderUrlSwap = () => {
    const token0Mint = get(poolInfo, 'info.token0.mintAddress')
    const token1Mint = get(poolInfo, 'info.token1.mintAddress')
    return `/swap?base=${token0Mint}&pair=${token1Mint}`
  }

  const handleViewPoolOnScan = () => {
    const poolAddress = get(poolInfo, 'poolAddress')
    const urlExplorer = SOL_SCAN_URL.mainnet
    window.open(`${urlExplorer}/account/${poolAddress}`)
  }

  const formatPoolRate = (num) => {
    if (parseInt(num) > 10000) {
      return nFormatter(Number(num))
    }
    return formatNumberBro({
      number: num,
      mantissa: getLength(num) > 5 ? 3 : 4
    })
  }

  const handleOpenSwapShortcut = () => {
    window.openModalFirst({
      noIcon: true,
      contentClassName: 'modal-swap-shortcut',
      content: (
        <NewSwapContent
          shortcut1Mint={get(token1, 'mintAddress')}
          shortcut0Mint={get(token0, 'mintAddress')}
        />
      )
    })
  }

  const handleOpenAddLiquidShortcut = () => {
    window.openModalFirst({
      noIcon: true,
      contentClassName: 'modal-swap-shortcut',
      content: (
        <NewLiquidityScreenContent
          shortcut1Mint={get(token1, 'mintAddress')}
          shortcut0Mint={get(token0, 'mintAddress')}
        />
      )
    })
  }

  if (isLoadingScreen) {
    return (
      <div className={styles['pool-info-detail--loading']}>
        <PuffLoading isFullScreen />
      </div>
    )
  }

  return (
    <div className={cn(styles['pool-info-detail'], 'container-xl color-grey')}>
      <PoolTabbar
        onChangeTab={handleSelectTabbar}
        selectedTab={selectedTabbar}
      />

      <div className="info-token-container mt-5 mb-5">
        <div className="chart-container box-wrapper">
          {userInfoInPool && <InfoDetailPool />}

          <div className="chart-content">
            <div className="chart-content__list-tab">
              {LIST_TAB.map((tab, index) => (
                <div
                  key={index}
                  className={`item-tab ${
                    tabChoose === tab.type ? 'item-tab--active' : ''
                  }`}
                  onClick={handleChooseTab(tab.type)}
                >
                  {t(tab.name)}
                </div>
              ))}
            </div>
            {tabChoose === LIQUIDITY
              ? (
                <RechartLine
                  dataChart={dataChartLiquidity}
                  isHideTitle
                  isLoading={isLoading}
                />
              )
              : (
                <RechartBar
                  dataChart={dataChartVolume}
                  isHideTitle
                  isLoading={isLoading}
                />
              )}
          </div>
        </div>

        <div className="info-token-locked box-wrapper">
          <div
            className="info-token-locked__header text-sm mb-3"
          >
            {t('totalTokenLocked')}

            <Icon
              onClick={handleViewPoolOnScan}
              backgroundClassName="icon-setting"
              name="web_explorer"
              className="icon-scan text-xl"
            />
          </div>

          <div className="token-info mb-4">
            <TokenInfo tokenInfo={token0} />
            <TokenInfo tokenInfo={token1} />

            <div className="token-info__item">
              <div className="item-info-text text-xs ">
                1 {upperCase(get(token1, 'symbol'))} ={' '}
                {formatPoolRate(get(poolInfo, 'rate1'))}{' '}
                {upperCase(get(token0, 'symbol'))}
              </div>
              <div className="item-info-text text-xs ">
                1 {upperCase(get(token0, 'symbol'))} ={' '}
                {formatPoolRate(get(poolInfo, 'rate0'))}{' '}
                {upperCase(get(token1, 'symbol'))}
              </div>
            </div>
          </div>

          <div className="list-block-info">
            <BlockColor variant="pink">
              <ItemBlockInfo
                value={ReduxServices.formatPrice(get(poolInfo, 'volume24h'), true)}
                title="volume24h"
                icon="web_volume_exchange"
              />
            </BlockColor>

            <BlockColor variant="blue">
              <ItemBlockInfo
                value={ReduxServices.formatPrice(get(poolInfo, 'liquidityUsd'), true)}
                title="liquidity"
                icon="web_direct_inbox"
              />
            </BlockColor>

            <BlockColor variant="green">
              <ItemBlockInfo
                value={ReduxServices.formatPrice(get(poolInfo, 'fee24h'))}
                title="fee24h"
                icon="web_fee_percent"
              />
            </BlockColor>

            <BlockColor variant="purple">
              <ItemBlockInfo
                value={ReduxServices.formatPrice(get(poolInfo, 'volume7d'), true)}
                title="volume7d"
                icon="web_share_token"
              />
            </BlockColor>
          </div>

          <div className={cn('actions-btn', isShowFarmButton && 'actions-btn__grid')}>
            <div className='d-flex flex-column align-items-center'>
              <Button
                onClick={handleOpenAddLiquidShortcut}
                className={cn('actions-btn__item')}
                type="grey"
              >
                <Icon name="web_add_liquidity" className="text-2xl"/>
              </Button>
              <span className='actions-btn__description'>{t('addLiquidity')}</span>
            </div>

            {<div className='d-flex flex-column align-items-center'>
              <Button
                className={cn('actions-btn__item')}
                onClick={onNavigateToPoolFarm}
              >
                <Icon name="web_farming" className="text-2xl text-white"/>
              </Button>
              <span className='actions-btn__description'>{t('farm')}</span>
            </div>}

            <div className='d-flex flex-column align-items-center'>
              <Button
                onClick={handleOpenSwapShortcut}
                className={cn('actions-btn__item')}
              >
                <Icon name="web_swap" className="text-2xl text-white"/>
              </Button>
              <span className='actions-btn__description'>{t('swap')}</span>

            </div>

          </div>
        </div>
      </div>

      <TransactionTable typeBoard="pool" address={address} />
    </div>
  )
}

export default NewPoolInfoDetail
