import { getLength } from 'common/functions'
import ReduxServices from 'common/redux'
import CustomTable from 'components/CustomTable'
import BaseAdapter from 'controller/api/BaseAdapter'
import { get } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from './style.module.scss'

const SnapshotRanking = ({
  isLoading,
  listSnapshotRanking,
  isVisible,
  isYourSnapshotRank
}) => {
  const { t } = useTranslation()

  const tableHead = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      width: 2,
      render: (val) => {
        return val === 0 ? '-' : val
      }
    },
    {
      title: t('address'),
      dataIndex: 'address',
      width: 15
    },
    {
      title: t('tradingVolume'),
      dataIndex: 'tradingVolume',
      align: 'right',
      width: 15,
      render: (val) => {
        return ReduxServices.formatPrice(val)
      }
    },
    {
      title: t('joinDate'),
      dataIndex: 'joinDate',
      align: 'right',
      width: 15,
      render: (val) => {
        return moment(val).format('DD/MM HH:mm')
      }
    },
    {
      title: t('reward'),
      dataIndex: 'reward',
      align: 'right',
      width: 15
    }
  ]

  const tableData = useMemo(() => {
    return listSnapshotRanking.map((item) => ({
      rank: item.rank,
      address: item.address,
      tradingVolume: item.tradingVolume,
      joinDate: item.joinDate,
      reward: item.reward
    }))
  }, [listSnapshotRanking])

  if (!isVisible) return null

  return (
    <div className={styles.snapshotRankContainer}>
      <div className="table-snapshot-rank">
        <CustomTable
          isLoading={isLoading}
          className="snapshot-top-rank"
          loadingContainerClassName={'snapshot-top-rank'}
          columns={tableHead}
          dataSource={tableData}
        />
      </div>
    </div>
  )
}

export default SnapshotRanking
