import {
  PublicKey,
  SystemProgram,
  TransactionInstruction
} from '@solana/web3.js'
import { BorshCoder } from '@project-serum/anchor'
import CusdFactoryIdl from './CusdFactoryIdl'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { HashService } from 'common/pool/hashService'

const coder = new BorshCoder(CusdFactoryIdl)

export class CusdFactoryInstructionService {
  static createMinter (rootAddress, derivationPath, cusdFactoryProgramId) {
    const request = {
      derivationPath
    }

    const data = coder.instruction.encode('createMinter', request)

    const [minterAddress] = this.findMinterAddress(
      derivationPath,
      cusdFactoryProgramId
    )

    const keys = [
      { pubkey: rootAddress, isSigner: true, isWritable: true },
      { pubkey: minterAddress, isSigner: false, isWritable: true },
      { pubkey: SystemProgram.programId, isSigner: false, isWritable: false }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: cusdFactoryProgramId
    })
  }

  static setMinter (
    rootAddress,
    minterAddress,
    isActive,
    inputParams,
    feePercent,
    totalMintedLimit,
    perPeriodMintedLimit,
    cusdFactoryProgramId
  ) {
    const inputTokens = inputParams.map((param) => param.tokenAddress)
    const inputDecimals = inputParams.map((param) => param.decimals)
    const inputPercentages = inputParams.map((param) => param.percentage)
    const inputPriceFeeds = inputParams.map((param) => param.priceFeedAddress)
    const request = {
      isActive,
      inputTokens,
      inputDecimals,
      inputPercentages,
      inputPriceFeeds,
      feePercent,
      totalMintedLimit,
      perPeriodMintedLimit
    }

    const data = coder.instruction.encode('setMinter', request)

    const keys = [
      { pubkey: rootAddress, isSigner: true, isWritable: false },
      { pubkey: minterAddress, isSigner: false, isWritable: true }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: cusdFactoryProgramId
    })
  }

  static createBurner (rootAddress, derivationPath, cusdFactoryProgramId) {
    const request = {
      derivationPath
    }

    const data = coder.instruction.encode('createBurner', request)

    const [burnerAddress] = this.findBurnerAddress(
      derivationPath,
      cusdFactoryProgramId
    )

    const keys = [
      { pubkey: rootAddress, isSigner: true, isWritable: true },
      { pubkey: burnerAddress, isSigner: false, isWritable: true },
      { pubkey: SystemProgram.programId, isSigner: false, isWritable: false }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: cusdFactoryProgramId
    })
  }

  static createAppData (rootAddress, cusdFactoryProgramId) {
    const request = {}

    const data = coder.instruction.encode('createAppData', request)

    const [appDataAddress] = this.findAppDataAddress(cusdFactoryProgramId)

    const keys = [
      { pubkey: rootAddress, isSigner: true, isWritable: true },
      { pubkey: appDataAddress, isSigner: false, isWritable: true },
      { pubkey: SystemProgram.programId, isSigner: false, isWritable: false }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: cusdFactoryProgramId
    })
  }

  static setBurner (
    rootAddress,
    burnerAddress,
    isActive,
    outputParams,
    feePercent,
    totalBurnedLimit,
    perPeriodBurnedLimit,
    cusdFactoryProgramId
  ) {
    const request = {
      isActive,
      outputToken: outputParams.tokenAddress,
      outputDecimals: outputParams.decimals,
      outputPriceFeed: outputParams.priceFeedAddress,
      feePercent,
      totalBurnedLimit,
      perPeriodBurnedLimit
    }

    const data = coder.instruction.encode('setBurner', request)

    const keys = [
      { pubkey: rootAddress, isSigner: true, isWritable: false },
      { pubkey: burnerAddress, isSigner: false, isWritable: true }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: cusdFactoryProgramId
    })
  }

  static mint (
    userAddress,
    minterAddress,
    cusdTokenMintAddress,
    inputTokens,
    amount,
    userCusdTokenAddress,
    chainlinkProgramId,
    cusdFactoryProgramId
  ) {
    const extraAccounts = []
    const extraInstructions = []
    for (const inputToken of inputTokens) {
      const index1 = extraAccounts.findIndex(
        (meta) =>
          meta.pubkey.toBase58() === inputToken.priceFeedAddress.toBase58()
      )
      if (index1 === -1) {
        extraInstructions.push(extraAccounts.length)
        extraAccounts.push({
          pubkey: inputToken.priceFeedAddress,
          isSigner: false,
          isWritable: false
        })
      } else {
        extraInstructions.push(index1)
      }

      const index2 = extraAccounts.findIndex(
        (meta) =>
          meta.pubkey.toBase58() === inputToken.userTokenAddress.toBase58()
      )
      if (index2 === -1) {
        extraInstructions.push(extraAccounts.length)
        extraAccounts.push({
          pubkey: inputToken.userTokenAddress,
          isSigner: false,
          isWritable: true
        })
      } else {
        extraInstructions.push(index2)
      }

      const index3 = extraAccounts.findIndex(
        (meta) =>
          meta.pubkey.toBase58() === inputToken.poolTokenAddress.toBase58()
      )
      if (index3 === -1) {
        extraInstructions.push(extraAccounts.length)
        extraAccounts.push({
          pubkey: inputToken.poolTokenAddress,
          isSigner: false,
          isWritable: true
        })
      } else {
        extraInstructions.push(index3)
      }
    }

    const request = {
      amount,
      extraInstructions: Buffer.from(extraInstructions)
    }

    const data = coder.instruction.encode('mint', request)

    const [appDataAddress] = this.findAppDataAddress(cusdFactoryProgramId)
    const [rootSignerAddress] = this.findRootSignerAddress(
      cusdFactoryProgramId
    )

    const keys = [
      { pubkey: userAddress, isSigner: true, isWritable: false },
      { pubkey: appDataAddress, isSigner: false, isWritable: false },
      { pubkey: rootSignerAddress, isSigner: false, isWritable: false },
      { pubkey: cusdTokenMintAddress, isSigner: false, isWritable: true },
      { pubkey: minterAddress, isSigner: false, isWritable: true },
      { pubkey: userCusdTokenAddress, isSigner: false, isWritable: true },
      { pubkey: chainlinkProgramId, isSigner: false, isWritable: false },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
      ...extraAccounts
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: cusdFactoryProgramId
    })
  }

  static burn (
    poolCusdTokenAddress,
    userAddress,
    burnerAddress,
    cusdTokenMintAddress,
    userCusdTokenAddress,
    amount,
    outputToken,
    chainlinkProgramId,
    cusdFactoryProgramId

  ) {
    const extraAccounts = [
      { pubkey: outputToken.priceFeedAddress, isSigner: false, isWritable: false },
      { pubkey: outputToken.poolTokenAddress, isSigner: false, isWritable: true },
      { pubkey: outputToken.userTokenAddress, isSigner: false, isWritable: true }
    ]

    const request = {
      amount
    }

    const data = coder.instruction.encode('burn', request)

    const [appDataAddress] = this.findAppDataAddress(
      cusdFactoryProgramId
    )
    const [rootSignerAddress] = this.findRootSignerAddress(
      cusdFactoryProgramId
    )

    const keys = [
      { pubkey: userAddress, isSigner: true, isWritable: false },
      { pubkey: appDataAddress, isSigner: false, isWritable: false },
      { pubkey: rootSignerAddress, isSigner: false, isWritable: false },
      { pubkey: cusdTokenMintAddress, isSigner: false, isWritable: true },
      { pubkey: burnerAddress, isSigner: false, isWritable: true },
      { pubkey: poolCusdTokenAddress, isSigner: false, isWritable: true },
      { pubkey: userCusdTokenAddress, isSigner: false, isWritable: true },
      { pubkey: chainlinkProgramId, isSigner: false, isWritable: false },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
      ...extraAccounts
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: cusdFactoryProgramId
    })
  }

  static decodeBurnerData (
    data
  ) {
    return coder.accounts.decode('Burner', data)
  }

  static findMinterAddress (derivationPath, cusdFactoryProgramId) {
    return PublicKey.findProgramAddressSync(
      [HashService.sha256('Minter').slice(0, 8), derivationPath],
      cusdFactoryProgramId
    )
  }

  static setAppData (rootAddress, limit, cusdFactoryProgramId) {
    const request = {
      limit
    }

    const data = coder.instruction.encode('setAppData', request)

    const [appDataAddress] = this.findAppDataAddress(cusdFactoryProgramId)

    const keys = [
      { pubkey: rootAddress, isSigner: true, isWritable: false },
      { pubkey: appDataAddress, isSigner: false, isWritable: true }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: cusdFactoryProgramId
    })
  }

  static findAppDataAddress (cusdFactoryProgramId) {
    return PublicKey.findProgramAddressSync(
      [
        HashService.sha256('Program').slice(0, 8),
        HashService.sha256('AppData').slice(0, 8)
      ],
      cusdFactoryProgramId
    )
  }

  static findBurnerAddress (derivationPath, cusdFactoryProgramId) {
    return PublicKey.findProgramAddressSync(
      [HashService.sha256('Burner').slice(0, 8), derivationPath],
      cusdFactoryProgramId
    )
  }

  static findRootSignerAddress (cusdFactoryProgramId) {
    return PublicKey.findProgramAddressSync(
      [
        HashService.sha256('Signer').slice(0, 8),
        HashService.sha256('Root').slice(0, 8)
      ],
      cusdFactoryProgramId
    )
  }

  static decodeMinterData (data) {
    return coder.accounts.decode('Minter', data)
  }
}
