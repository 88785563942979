import { PublicKey } from '@solana/web3.js'
import { BorshService } from 'common/pool/borshService'
import { SarosSwapInstructionService } from 'common/pool/saros_swap/sarosSwapIntructions'
import BaseAPI from 'controller/api/BaseAPI'
import RPCSolana from 'controller/api/rpcSolana'
import { chunk, get } from 'lodash'
import SaberSwapService from './amm/saber'
import SarosAmmService, { SAROS_SWAP_PROGRAM_ADDRESS_V1 } from './amm/saros'
import {
  ALDRIN_POOLS_PROGRAM_ADDRESS,
  CURVE,
  POOL_LAYOUT,
  POOL_V2_LAYOUT
} from './amm/aldrin'
import base58 from 'bs58'
import { accountDiscriminator } from './utils'
import { genConnectionSolana } from 'common/solana'
import MercurialSwapService, { MERCURIAL_PROGRAM_ID } from './amm/mercurial'
import CropperSwapService from './amm/cropper'
import OrcaWhirlpoolSwapService from './amm/orca_whirpools'
import { TokenProgramInstructionService } from 'common/pool/tokenProgramInstructionService'
import { BN } from 'bn.js'
import { orcaPoolConfigs } from 'common/orca/pool/poolOrca'

export const SAROS_AMM = 'saros'
export const ORCA_AMM = 'orca'
export const RAYDIUM_AMM = 'raydium'
export const SABER_AMM = 'saber'
export const ALDRIN_AMM = 'aldrin'
export const CREMA_AMM = 'crema'
export const CROPPER_AMM = 'cropper'
export const WHIRL_POOL = 'orca_whirpools'
export const MERCURIAL = 'mercurial'

// export interface Router {
//   address: Publickey
//   poolAddress: PublicKey
//   poolAuthority: PublicKey
//   poolSource: PublicKey
//   poolDestination: PublicKey
//   token0Mint: string
//   token1Mint: string
//   token0Account: Publickey
//   token1Account: Publickey
//   amountIn: BN
//   amountOut: BN,
//   sourceToken: PublicKey, -> account mint
//   destinationToken: PublicKey, -> account mint
//   type: string
//   address: publicKey
// }

// ______________ E-N-D____________
export const API_URL = 'https://api.aldrin.com/graphql'

export default class LiquidityAmm {
  static async getAllAmm () {
    // const getPoolTokenOrca = BaseAPI.getData('ammMarket/pool/tokens')
    const getPoolTokenOrca = { orca: Object.values(orcaPoolConfigs) }
    // const getPoolSaros = BaseAPI.getData('saros/pool/all')
    // const getLiquidityRaydium = LiquidityAmm.getPoolRaydium()
    // const getPoolSaber = LiquidityAmm.fetchApi(
    //   'https://raw.githubusercontent.com/saber-hq/saber-registry-dist/master/data/pools-info.mainnet.json'
    // )
    // const getPoolAldrin = LiquidityAmm.getPoolAldrinV2()
    // const getPoolCrema = LiquidityAmm.getPoolCrema()
    // const getPoolCropper = LiquidityAmm.getPoolCropper()
    // const getWhirPoolOrca = LiquidityAmm.fetchApi(
    //   'https://mainnet-zp2-v2.orca.so/pools'
    // )
    // const getPoolMercurial = LiquidityAmm.getPoolMercurial()
    const [
      resPoolToken
      // resPoolSaros
      // resPoolRaydium,
      // resPoolSaber,
      // resPoolAldrin,
      // resPoolCrema,
      // resPoolCropper
      // resWhirPool,
      // resMercurial
    ] = await Promise.all([
      getPoolTokenOrca
      // getPoolSaros
      // getLiquidityRaydium,
      // getPoolSaber,
      // getPoolAldrin,
      // getPoolCrema,
      // getPoolCropper
      // getWhirPoolOrca,
      // getPoolMercurial
    ])
    const [
      orcaPool
      // raydiumPool,
      // sarosPool
      // saberPool,
      // aldrinPool,
      // cremaPool,
      // cropperPool
      // whirlpool,
      // mercurialPool
    ] = await Promise.all([
      this.convertPoolOrca(get(resPoolToken, 'orca', []))
      // this.convertPoolRaydium(resPoolRaydium),
      // this.convertPoolSaros(resPoolSaros)
      // this.convertPoolSaber(get(resPoolSaber, 'pools', [])),
      // this.convertPoolAldrin(resPoolAldrin),
      // this.convertPoolCrema(resPoolCrema),
      // this.convertPoolCropper(resPoolCropper)
      // this.convertWhirPoolOrca(resWhirPool),
      // this.convertMercurialPool(resMercurial)
    ])

    return {
      // saros: sarosPool,
      orca: orcaPool
      // raydium: raydiumPool,
      // saber: saberPool,
      // aldrin: aldrinPool,
      // crema: cremaPool,
      // cropper: cropperPool
      // whirlpool,
      // mercurial: mercurialPool
    }
  }

  static async getPoolRaydium () {
    const response = await LiquidityAmm.fetchApi(
      'https://api.raydium.io/v2/sdk/liquidity/mainnet.json'
    )
    const official = get(response, 'official', [])
    const unOfficial = get(response, 'unOfficial', [])
    const listAmm = [...official, ...unOfficial].filter(
      (amm) => amm.version === 4
    )
    return listAmm
  }

  static async getPoolCropper () {
    const response = await LiquidityAmm.fetchApi(
      'https://api.cropper.finance/pool/'
    )
    if (!response) return []
    return response
  }

  static async getPoolAldrinV2 () {
    const connection = genConnectionSolana()
    const searchFilters = [
      { dataSize: POOL_V2_LAYOUT.span },
      {
        memcmp: {
          offset: 0,
          bytes: base58.encode(await accountDiscriminator('Pool'))
        }
      }
    ]

    const accounts = await connection.getProgramAccounts(
      ALDRIN_POOLS_PROGRAM_ADDRESS,
      {
        filters: searchFilters
      }
    )

    return accounts.map((p) => {
      const {
        account: { data },
        pubkey
      } = p
      const account = POOL_LAYOUT.decode(data)
      return {
        ...account,
        poolPublicKey: pubkey,
        poolVersion: 1,
        curveType: CURVE.PRODUCT
      }
    })
  }

  static async convertWhirPoolOrca (poolList) {
    const arrPoolAddress = poolList.map(
      (item) => new PublicKey(get(item, 'address', ''))
    )
    const arrChunk = chunk(arrPoolAddress, 100)
    const arrTemp = await this.fetchMultipleAccount(arrChunk)
    const dataPool = arrTemp.map((item, index) => {
      const buffer = Buffer.from(get(item, 'data[0]'), 'base64')
      const poolInfo = buffer
        ? OrcaWhirlpoolSwapService.decodePoolAccount(buffer)
        : null
      const poolAddress = arrPoolAddress[index]
      const token0Account = new PublicKey(
        get(poolInfo, 'tokenVaultA', '').toString()
      )
      const token1Account = new PublicKey(
        get(poolInfo, 'tokenVaultB', '').toString()
      )
      const token0Mint = get(poolInfo, 'tokenMintA', '').toString()
      const token1Mint = get(poolInfo, 'tokenMintB', '')
      const poolAuthority = poolAddress // -> fake to fill full info, this no need,
      return {
        ...poolList[index],
        poolAddress,
        address: poolAddress,
        token0Account,
        token1Account,
        token0Mint: token0Mint.toString(),
        token1Mint: token1Mint.toString(),
        poolSource: token0Account,
        poolDestination: token1Account,
        poolAuthority,
        type: WHIRL_POOL
      }
    })
    return dataPool
  }

  static async convertPoolCropper (poolList) {
    const arrChunk = chunk(poolList, 100)
    const arrTemp = await this.fetchMultipleAccount(arrChunk)
    const dataPool = arrTemp.map((item, index) => {
      const buffer = Buffer.from(get(item, 'data[0]'), 'base64')
      const poolInfo = buffer
        ? CropperSwapService.decodePoolAccount(buffer)
        : null
      const poolAddress = new PublicKey(poolList[index])
      const token0Account = new PublicKey(get(poolInfo, 'tokenA'))
      const token1Account = new PublicKey(get(poolInfo, 'tokenB'))
      const token0Mint = get(poolInfo, 'tokenAMint')
      const token1Mint = get(poolInfo, 'tokenBMint')
      const poolAuthority = poolAddress // -> fake to fill full info, this no need,

      return {
        ...item,
        poolAddress,
        address: poolAddress,
        token0Account,
        token1Account,
        token0Mint: token0Mint.toString(),
        token1Mint: token1Mint.toString(),
        poolSource: token0Account,
        poolDestination: token1Account,
        poolAuthority,
        type: CROPPER_AMM
      }
    })
    return dataPool
  }

  static async convertPoolCrema (poolList) {
    const dataPool = poolList.map((pool) => {
      const poolAddress = new PublicKey(get(pool, 'swap_account'))
      const token0Account = new PublicKey(
        get(pool, 'token_a.swap_token_account')
      )
      const token1Account = new PublicKey(
        get(pool, 'token_b.swap_token_account')
      )
      const token0Mint = get(pool, 'token_a.token_mint')
      const token1Mint = get(pool, 'token_b.token_mint')
      const poolAuthority = poolAddress // -> fake to fill full info, this no need,
      return {
        ...pool,
        poolAddress,
        token0Account,
        token1Account,
        token0Mint,
        token1Mint,
        poolAuthority,
        address: poolAddress,
        poolSource: token0Account,
        poolDestination: token1Account,
        type: CREMA_AMM
      }
    })
    return dataPool
  }

  static async convertMercurialPool (poolList) {
    const mintDefaultMercurial = '11111111111111111111111111111111'
    const arrAccount = poolList.map((item) => item.tokenAccounts).flat()
    const listPoolData = await this.fetchMultipleAccount([arrAccount])
    const listAccountTokenInPool = listPoolData.map((item, index) => {
      const buffer = Buffer.from(get(item, 'data[0]'), 'base64')
      const bufferLength = buffer.byteLength
      const accountInfo =
        bufferLength > 21
          ? TokenProgramInstructionService.decodeTokenAccountInfo(buffer)
          : {}
      accountInfo.address = arrAccount[index]
      return accountInfo
    })

    const newListDatPool = poolList.map((poolInfo, index) => {
      const token0Account = listAccountTokenInPool.find(
        (item) =>
          get(poolInfo, 'tokenAccounts[0]', '').toString() ===
          item.address.toString()
      )
      const token1Account = listAccountTokenInPool.find(
        (item) =>
          get(poolInfo, 'tokenAccounts[1]', '').toString() ===
          item.address.toString()
      )
      const token2Account = listAccountTokenInPool.find(
        (item) =>
          get(poolInfo, 'tokenAccounts[2]', '').toString() ===
          item.address.toString()
      )
      const token3Account = listAccountTokenInPool.find(
        (item) =>
          get(poolInfo, 'tokenAccounts[3]', '').toString() ===
          item.address.toString()
      )
      const amountPools = [
        get(token0Account, 'amount', new BN(0)),
        get(token1Account, 'amount', new BN(0)),
        get(token2Account, 'amount', new BN(0)),
        get(token3Account, 'amount', new BN(0))
      ]
      return {
        ...poolInfo,
        amountPools,
        token0Account,
        token1Account,
        token2Account,
        token3Account,
        token0Mint: get(token0Account, 'mint', mintDefaultMercurial).toString(),
        token1Mint: get(token1Account, 'mint', mintDefaultMercurial).toString(),
        token2Mint: get(token2Account, 'mint', mintDefaultMercurial).toString(),
        token3Mint: get(token3Account, 'mint', mintDefaultMercurial).toString()
      }
    })

    return newListDatPool
  }

  static async getPoolCrema () {
    const response = await LiquidityAmm.fetchApi(
      'https://api.crema.finance/config?name=swap-pairs'
    )
    return get(response, 'data', [])
  }

  static async getPoolMercurial () {
    const listPoolAddress = [
      new PublicKey('SWABtvDnJwWwAb9CbSA3nv7nTnrtYjrACAVtuP3gyBB'),
      new PublicKey('SoLw5ovBPNfodtAbxqEKHLGppyrdB4aZthdGwpfpQgi'),
      new PublicKey('USD42Jvem43aBSLqT83GZmvRbzAjpKBonQYBQhni7Cv'),
      new PublicKey('LiDoU8ymvYptqxenJ4YpcURBchn4ef63tcbdznBCKJh'),
      new PublicKey('MAR1zHjHaQcniE2gXsDptkyKUnNfMEsLBVcfP7vLyv7'),
      new PublicKey('BUSDXyZeFXrcEkETHfgGh5wfqavmfC8ZJ8BbRP33ctaG'),
      new PublicKey('UXD3M3N6Hn1JjbxugKguhJVHbYm8zHvdF5pNf7dumd5'),
      new PublicKey('USDHyeagFLn8Ct6JfQ7CAV9aoecKjwWmWXL6oNNoL7W'),
      new PublicKey('USD4WhBLkQsNm8bXMfxoKuMRtE281CYrPGcfJXZxQL9'),
      new PublicKey('aUSD4xgo21HepQ6WDrevCYSUksLVye4V91cxPGWbP82'),
      new PublicKey('fUSDc4pqrQcYqLfm2hkgop8edWnu7gNcd7qDwc1p1ty'),
      new PublicKey('aUSNkg1M8YoHEpAAf3XX4FXUUCNKRA16HhCFEYq39Jv'),
      new PublicKey('abUDCvrNgN9snpRKo2x73pSxaWP1m7gysnBeVmi2XPW')
    ]
    const listPool = await this.fetchMultipleAccount([listPoolAddress])
    const listDataPool = await Promise.all(
      listPool.map(async (item, index) => {
        const buffer = Buffer.from(get(item, 'data[0]'), 'base64')
        const poolInfo = buffer
          ? MercurialSwapService.decodePoolAccount(buffer)
          : null
        const poolAddress = listPoolAddress[index]

        const [authority] = await PublicKey.findProgramAddress(
          [poolAddress.toBuffer()],
          MERCURIAL_PROGRAM_ID
        )
        const poolAuthority = authority
        const poolSource = poolAddress // fake to fill data
        const poolDestination = poolAddress // fake to fill data

        return {
          ...poolInfo,
          poolAddress,
          address: poolAddress,
          poolAuthority,
          poolSource,
          poolDestination,

          type: MERCURIAL
        }
      })
    )

    return listDataPool
  }

  static convertPoolAldrin (poolList) {
    const arrTemp = poolList.map((item) => {
      const poolAddress = get(item, 'poolPublicKey')
      const poolAuthority = get(item, 'authority')
      const poolSource = get(item, 'baseTokenVault')
      const poolDestination = get(item, 'quoteTokenVault')
      const token0Mint = get(item, 'baseTokenMint', '')
      const token1Mint = get(item, 'quoteTokenMint', '')

      return {
        ...item,
        poolAddress,
        address: poolAddress,
        poolAuthority,
        poolSource,
        poolDestination,
        token0Account: poolSource,
        token1Account: poolDestination,
        token0Mint: token0Mint.toString(),
        token1Mint: token1Mint.toString(),
        type: ALDRIN_AMM
      }
    })

    return arrTemp
  }

  static async convertPoolRaydium (poolList) {
    const newList = poolList.map((item) => {
      const poolAddress = new PublicKey(get(item, 'id'))
      const poolAuthority = new PublicKey(get(item, 'authority'))
      const poolSource = new PublicKey(get(item, 'baseVault'))
      const poolDestination = new PublicKey(get(item, 'quoteVault'))
      const token0Mint = get(item, 'baseMint')
      const token1Mint = get(item, 'quoteMint')
      return {
        ...item,
        poolAddress,
        poolAuthority,
        poolSource,
        poolDestination,
        token0Mint,
        token1Mint,
        token0Account: poolSource,
        token1Account: poolDestination,
        type: RAYDIUM_AMM,
        address: poolAddress
      }
    })
    return newList
  }

  static async convertPoolOrca (poolList) {
    const newListFormat = Object.keys(poolList).map((key) => {
      const item = poolList[key]
      const ids = get(item, 'tokenIds', [])
      const token0Mint = ids[0]
      const token1Mint = ids[1]

      const address0 = get(item, `tokens.${token0Mint}.addr`)
      const address1 = get(item, `tokens.${token1Mint}.addr`)
      const data = {
        ...item,
        poolTokenMint: new PublicKey(item.poolTokenMint.toString()),
        feeAccount: new PublicKey(item.feeAccount.toString()),
        authority: new PublicKey(item.authority.toString()),
        address: new PublicKey(item.address.toString()),
        poolAddress: new PublicKey(item.address),
        token0Mint,
        token1Mint,
        token0Account: new PublicKey(address0),
        token1Account: new PublicKey(address1),
        type: ORCA_AMM,
        poolAuthority: new PublicKey(item.authority.toString()),
        poolSource: new PublicKey(address0),
        poolDestination: new PublicKey(address1)
      }
      return data
    })

    return newListFormat
  }

  static convertPoolSaros = async (poolList) => {
    const listAddress = poolList.map((item) => new PublicKey(item.poolAddress))
    const arrChunk = chunk(listAddress, 100)
    const arrTemp = await this.fetchMultipleAccount(arrChunk)

    const dataPool = arrTemp.map((item, index) => {
      const buffer = Buffer.from(get(item, 'data[0]'), 'base64')
      const poolInfo = buffer
        ? SarosSwapInstructionService.decodePoolData(buffer)
        : null
      const { token0Mint, token1Mint } = poolInfo

      return {
        ...poolInfo,
        poolAddress: listAddress[index],
        token0Mint: token0Mint.toString(),
        token1Mint: token1Mint.toString()
      }
    })

    const newListPool = await Promise.all(
      [...poolList].map(async (pool) => {
        const poolAddress = get(pool, 'poolAddress', '')
        const token0 = get(pool, 'token0', '')
        const token1 = get(pool, 'token1', '')
        const token0Mint = get(token0, 'mintAddress', '')
        const token1Mint = get(token1, 'mintAddress', '')
        const detailPool = dataPool.find(
          (item) =>
            token0Mint === item.token0Mint.toString() &&
            token1Mint === item.token1Mint.toString()
        )

        const [poolAuthorityAddress] =
          await SarosAmmService.findPoolAuthorityAddress(
            new PublicKey(poolAddress),
            SAROS_SWAP_PROGRAM_ADDRESS_V1
          )

        const poolSource = get(detailPool, 'token0Account')
        const poolDestination = get(detailPool, 'token1Account')

        return {
          ...detailPool,
          poolAddress,
          address: new PublicKey(poolAddress),
          poolAuthority: poolAuthorityAddress,
          poolSource,
          poolDestination,
          type: SAROS_AMM
        }
      })
    )
    return newListPool
  };

  static async convertPoolSaber (poolList) {
    const listAddress = poolList.map(
      (item) => new PublicKey(get(item, 'swap.config.swapAccount'))
    )
    const arrChunk = chunk(listAddress, 100)
    const arrTemp = await this.fetchMultipleAccount(arrChunk)
    const dataPool = arrTemp.map((item) => {
      const buffer = Buffer.from(get(item, 'data[0]'), 'base64')
      const poolInfo = buffer
        ? SaberSwapService.decodePoolAccount(buffer)
        : null
      return poolInfo
    })
    const newListPool = [...dataPool].map((pool, index) => {
      const poolAddress = listAddress[index].toString()
      const detailPool = poolList.find((item) => {
        const swapAccount = get(item, 'swap.config.swapAccount')
        return swapAccount === poolAddress
      })
      const token0Account = get(pool, 'tokenAccountA', '')
      const token1Account = get(pool, 'tokenAccountB', '')
      const token0Mint = get(pool, 'mintA', '')
      const token1Mint = get(pool, 'mintB', '')

      const poolAuthority = new PublicKey(
        get(detailPool, 'swap.config.authority')
      )
      const poolSource = get(pool, 'tokenAccountA')
      const poolDestination = get(pool, 'tokenAccountB')
      return {
        ...pool,
        poolAddress,
        token0Account,
        token1Account,
        token0Mint: token0Mint.toString(),
        token1Mint: token1Mint.toString(),
        poolAuthority,
        poolSource,
        poolDestination,
        address: poolAddress,
        type: SABER_AMM
      }
    })

    return newListPool
  }

  static async getPoolAccountInfoBaseLayout (connection, poolAddress, layout) {
    const accountInfo = await connection.getAccountInfo(poolAddress)

    const poolInfo = this.decodePoolAccount(layout, accountInfo.data)
    return poolInfo
  }

  static decodePoolBaseLayout (layout, data) {
    const dataDecoded = BorshService.deserialize(layout, data)

    return dataDecoded
  }

  static async fetchMultipleAccount (accounts) {
    const fetchAllData = await RPCSolana.buildRequest({
      bodyFetch: accounts,
      method: 'getMultipleAccounts'
    })
    if (!fetchAllData) return []
    const arrData = fetchAllData.map((data) => {
      const result = get(data, 'result', {})
      return get(result, 'value')
    })
    return arrData.flat()
  }

  static fetchApi = async (url) => {
    try {
      const response = await fetch(url)
      return response.json()
    } catch (err) {
      return null
    }
  };
}
