/* eslint-disable multiline-ternary */
import { sarosSwapType } from 'common/constants'
import {
  convertBalanceToWei,
  convertWeiToBalance,
  formatAmountInput,
  formatNumberBro,
  roundingNumber,
  upperCase
} from 'common/functions'
import Button from 'components/common/Button'
import TokenImage from 'components/common/TokenImage'
import SliderComponent from 'components/Slider'
import useLiquidity from 'hooks/liquidity/useLiquidity'
import { get } from 'lodash'
import EmptyLiquidity from 'pages/NewLiquidityScreen/Components/EmptyLiquidity'
import { LiquidityContext } from 'context/liquidity/liquidityContext'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import ModalConfirmLiquidity from 'pages/NewLiquidityScreen/Components/ModalConfirmLiquidity'

import styles from './style.module.scss'
import { AppContext } from 'context/appContext'
import { BounceLoader } from 'react-spinners'
import PuffLoading from 'components/common/PuffLoading'

function RemoveLiquidityNew () {
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const { handleGetAllAccountOwner } = appContext
  const liquidityContext = useContext(LiquidityContext)
  const liquidityHook = useLiquidity({ isFetchListToken: false })
  const {
    dataPoolInfo,
    setDataPoolInfo,
    initDataLiquidity,
    slippageValue,
    isLoadingDetailPool
  } = liquidityContext
  const [txtValue, setTxtValue] = useState('')
  const [userInfoInPool, setUserInfoInPool] = useState(null)
  const [amount, setAmount] = useState('')
  const [isLoadingButton, setIsLoadingButton] = useState(false)

  const token0 = get(dataPoolInfo, 'token0')
  const token1 = get(dataPoolInfo, 'token1')
  const token0Symbol = upperCase(get(token0, 'symbol'))
  const token1Symbol = upperCase(get(token1, 'symbol'))

  const amountToken0 = convertWeiToBalance(
    get(userInfoInPool, 'amountToken0InPool', 0).toString(),
    get(token0, 'decimals')
  )
  const amountToken1 = convertWeiToBalance(
    get(userInfoInPool, 'amountToken1InPool', 0).toString(),
    get(token1, 'decimals')
  )
  const decimals = get(dataPoolInfo, 'poolAccountInfo.decimals')
  const userInfo = get(dataPoolInfo, 'userInfo')
  const balance = convertWeiToBalance(
    get(userInfo, 'amount', '').toString(),
    decimals
  )

  const rate0 = get(dataPoolInfo, 'rate0')
  const rate1 = get(dataPoolInfo, 'rate1')

  useEffect(() => {
    setTxtValue('')
    setAmount('')
  }, [dataPoolInfo])

  const initDataRemove = (amount) => {
    const amountWei = convertBalanceToWei(amount, decimals)
    const dataInPool = liquidityHook.calculateTokenAmountInPool({
      dataPoolInfo: dataPoolInfo,
      lpInPool: parseFloat(amountWei)
    })
    const { amountToken0InPool, amountToken1InPool } = dataInPool
    const userInfoData = get(dataPoolInfo, 'userInfo')
    setUserInfoInPool({
      ...userInfoData,
      amountToken0InPool,
      amountToken1InPool
    })
  }

  const handleMax = () => {
    handleValueSlide(100)
  }

  const handleValueSlide = (value) => {
    const amount = (parseInt(value) / 100) * parseFloat(balance)
    setTxtValue(value)
    onChangeInput(amount.toString())
  }

  const onChangeInput = (value) => {
    const newAmount = formatAmountInput(value)
    if (!newAmount) {
      setAmount('')
      return setTxtValue(0)
    }
    const slide = (newAmount / balance) * 100
    setTxtValue(slide < 100 ? Math.floor(slide) : 100)
    setAmount(newAmount)
    initDataRemove(newAmount)
  }

  const handleRemoveLiquidity = () => {
    const liquidityToken = {
      token0,
      token1
    }
    const liquidityAmount = {
      token0Amount: amountToken0,
      token1Amount: amountToken1
    }

    window.openModal({
      noIcon: true,
      className: 'modal-remove-liquidity',
      noPadding: true,
      preventClose: true,
      content: (
        <ModalConfirmLiquidity
          setIsLoadingButton={setIsLoadingButton}
          initDataDefault={initDataDefault}
          dataPoolInfoLiquidity={dataPoolInfo}
          liquidityToken={liquidityToken}
          liquidityAmount={liquidityAmount}
          rate0={rate0}
          rate1={rate1}
          lpAmountReceive={parseFloat(amount)}
          type={sarosSwapType.removeLiquidity}
          slippage={slippageValue}
        />
      )
    })
  }

  const initDataDefault = async () => {
    handleGetAllAccountOwner()
    setDataPoolInfo(false)
    handleValueSlide(0)
    await initDataLiquidity()
  }

  const typeButton = useMemo(() => {
    const typeButton = {
      isDisable: false,
      text: 'remove'
    }
    if (!amount || parseFloat(amount) <= 0) {
      return {
        isDisable: true,
        text: 'inputAmount'
      }
    }

    if (amountToken0 <= 0 || amountToken1 <= 0) {
      return {
        isDisable: true,
        text: 'amountTokenMoreThan'
      }
    }

    if (parseFloat(amount) > parseFloat(balance)) {
      return {
        isDisable: true,
        text: 'tradeErrFund'
      }
    }
    return typeButton
  }, [amount, userInfoInPool])

  const renderContent = () => {
    return dataPoolInfo ? (
      <>
        <div className="remove-form mb-4">
          <NumberFormat
            placeholder="0.0"
            onValueChange={(event) => onChangeInput(event.value)}
            thousandsGroupStyle="thousand"
            value={amount}
            prefix=""
            decimalSeparator="."
            displayType="input"
            type="text"
            thousandSeparator={true}
            className="remove-form__input-value color-grey text-l"
          />

          <div className="remove-form__pool-token color-grey text-sm mt-1 mb-3">
            {t('poolToken')}:{' '}
            <span onClick={handleMax} className="color-theme cursor-pointer">
              {!userInfo ? (
                <BounceLoader size={16} color="#bbbbbb" />
              ) : (
                formatNumberBro({ number: balance, mantissa: 2 })
              )}
            </span>
          </div>
          <SliderComponent
            className="remove-slide"
            tipFormatter={(value) => <div>{`${value}%`}</div>}
            marks={{
              0: '',
              25: '',
              50: '',
              75: '',
              100: ''
            }}
            value={txtValue}
            onChange={handleValueSlide}
          />
        </div>

        <div className="remove-info-receive mb-4">
          <div className="remove-info-receive__title text-xm color-grey mb-3">
            {t('youWillReceive')}
          </div>

          <div className="item-info-receive mb-3">
            <div className="token-receive text-l">
              <TokenImage
                src={get(token0, 'icon')}
                className="img-24 rounded-circle mr-2"
              />

              <span className="name-token">
                {upperCase(get(token0, 'symbol'))}
              </span>
            </div>

            <div className="value-receive truncate-text-20 text-l">
              {formatNumberBro({ number: amountToken0 })}
            </div>
          </div>

          <div className="item-info-receive text-l">
            <div className="token-receive">
              <TokenImage
                src={get(token1, 'icon')}
                className="img-24 rounded-circle mr-2"
              />

              <span className="name-token">
                {upperCase(get(token1, 'symbol'))}
              </span>
            </div>

            <div className="value-receive truncate-text-20 text-l">
              {formatNumberBro({ number: amountToken1 })}
            </div>
          </div>
        </div>

        <div className="info-rate mb-5">
          <div className="info-rate__title text-sm color-normal">
            {t('rate')}
          </div>

          <div className="info-rate__content">
            1 {token1Symbol} = {formatNumberBro({ number: rate1, mantissa: 6 })}{' '}
            {token0Symbol} <br />1 {token0Symbol} ={' '}
            {formatNumberBro({ number: rate0, mantissa: 6 })} {token1Symbol}
          </div>
        </div>

        <Button
          isLoading={isLoadingButton}
          isDisable={get(typeButton, 'isDisable') || isLoadingButton}
          isFullWidth
          onClick={handleRemoveLiquidity}
        >
          {t(get(typeButton, 'text'))}
        </Button>
      </>
    ) : (
      <EmptyLiquidity content={t('selectLiquidity')} />
    )
  }

  return (
    <div className={styles['remove-liquidity-new']}>
      {isLoadingDetailPool ? <PuffLoading isCenter /> : renderContent()}
    </div>
  )
}

export default RemoveLiquidityNew
