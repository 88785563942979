import React, { useRef, useEffect, useState, useMemo } from 'react'
import { Line } from 'react-chartjs-2'

function LineChart ({ dataChart, percent, height }) {
  const refChart = useRef()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 500)
  }, [])

  const chartData = useMemo(() => {
    return {
      labels: dataChart,
      datasets: [
        {
          label: 'Hidden',
          fill: true,
          lineTension: 0,
          backgroundColor: 'transparent',
          borderColor: percent >= 0 ? '#78a74e' : '#FF5758',
          borderWidth: 0.8,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,0)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 0,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,0)',
          pointHoverBorderColor: 'rgba(220,220,220,0)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 0,
          spanGaps: true,
          data: dataChart
        }
      ]
    }
  }, [dataChart, percent])

  const options = {
    animation: false,
    hover: {
      animationDuration: 0
    },
    layout: {
      padding: {}
    },
    responsiveAnimationDuration: 0,
    plugins: {
      legend: {
        display: false
      }
    },

    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          display: false,
          drawBorder: false,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 5
        },
        grid: {
          display: false,
          offsetGridLines: false,
          drawBorder: false
        }
      },
      y: {
        beginAtZero: false,
        grid: {
          display: false,
          offsetGridLines: false,
          drawBorder: false
        },
        ticks: {
          display: false,
          maxTicksLimit: 5,
          stepSize: 0.01
        }
      }
    },
    chartArea: {
      backgroundColor: percent >= 0 ? '#78a74e' : '#FF5758'
    }
  }

  return (
    <>
      {isLoaded
        ? (
          <Line
            data={chartData}
            options={options}
          />
        )
        : (
          ''
        )}
    </>
  )
}

export default LineChart
