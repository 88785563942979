import React, { useState, useEffect, useMemo } from 'react'
import CountdownCircle from 'components/CountdownCircle'
import CustomTable from 'components/CustomTable'
import cn from 'clsx'
import { formatBilion, formatNumberBro, formatPrice, getItemStorage, getLength } from 'common/functions'
import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'
import { useHistory, Link } from 'react-router-dom'
import BaseAPI from 'controller/api/BaseAPI'
import get from 'lodash/get'
import TokenImage from 'components/common/TokenImage'
import Pagination from 'components/Pagination'
import { Icon } from 'components/Icon'
import { useSelector, useDispatch } from 'react-redux'
import { cloneDeep, compact } from 'lodash'
import BaseAdapter from 'controller/api/BaseAdapter'
import StoreActions from 'controller/redux/actions/storeActions'
import useFavoriteToken from 'hooks/pools/useFavoriteToken'
import ReduxServices from 'common/redux'
import Tooltip from 'components/Tooltip'

const LIMIT = 10

function TableTopToken ({ dataCol, dataSource }) {
  const { t } = useTranslation()
  const history = useHistory()
  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const dispatch = useDispatch()
  const listFavoriteTokenRedux = useSelector(
    (state) => state.listFavoriteTokenRedux
  ) || []
  const currencyRedux = useSelector(state => state.currencyRedux) // needed to rerender onChange Currency

  const { onChangeListTokenFavor } = useFavoriteToken()

  const accountSol = useSelector(state => state.accountSol)

  const [isLoadingToken, setIsLoadingToken] = useState()
  const [listTopToken, setListTopToken] = useState([])
  const [updatedListToken, setUpdatedListToken] = useState([])
  const [listCoinDetail, setListCoinDetail] = useState([])

  const [total, setTotal] = useState()
  const [currPage, setCurrPage] = useState(1)

  useEffect(() => {
    fetchCoinDetail()
  }, [])

  useEffect(() => {
    getListTopToken()
  }, [currPage])

  useEffect(() => {
    getListTokenPrice()
  }, [listTopToken])

  const fetchCoinDetail = async () => {
    let listCoinDetail = window.walletServices.coinGecko
    if (!listCoinDetail) {
      listCoinDetail = await window.walletServices.refreshFetchData(true)
    }
    setListCoinDetail(listCoinDetail)
  }

  const getListTopToken = async (isTriggerLoad = true) => {
    setIsLoadingToken(isTriggerLoad)
    const response = await BaseAPI.postData('saros/token/top', {
      page: currPage,
      size: LIMIT
    })
    if (!response) return

    setTotal(get(response, 'total'))
    setListTopToken(get(response, 'data'))
  }

  const getListTokenPrice = async () => {
    if (getLength(listTopToken) === 0) {
      setUpdatedListToken([])
      setIsLoadingToken(false)
      return
    }

    const updateListToken = await Promise.all(listTopToken.map(async (item, index) => {
      const tokenID = get(item, 'info.id')
      const newListCoinDetail = listCoinDetail || window.walletServices.coinGecko
      const detail = newListCoinDetail.find(coin => coin.id === tokenID)
      const price = get(detail, 'current_price', 0)
      const priceChange = get(detail, 'price_change_percentage_24h', 0)
      const mintAddress = get(item, 'info.mintAddress')
      return {
        // key: tokenID,
        index: LIMIT * (currPage - 1) + index + 1,
        info: {
          ...item.info,
          name: get(item, 'info.name'),
          symbol: get(item, 'info.symbol'),
          icon: get(item, 'info.icon'),
          address: mintAddress
        },
        price: price,
        volume24h: item.volume24h,
        priceChange: priceChange,
        liquidity: item.totalLiquidity,
        favorite: get(item, 'info.mintAddress')
      }
    }))

    setIsLoadingToken(false)
    setUpdatedListToken(updateListToken)
  }

  const onChangePage = (val) => {
    const { selected } = val
    setCurrPage(selected + 1)
  }

  const handleFavoriteToken = (mintAddress) => async (event) => {
    event.stopPropagation()
    onChangeListTokenFavor(mintAddress)
  }

  const isFavorite = (mintAddress) => {
    // const listMint = listFavorite.map((item) => item.mintAddress)
    if (!mintAddress) return false
    return listFavoriteTokenRedux?.includes(mintAddress)
  }

  const columnHeaderTopToken = [
    {
      title: '',
      dataIndex: 'favorite',
      width: 2,
      // hide: !accountSol,
      render: (val) => {
        const mintAddress = val
        return (
          <Icon
            onClick={handleFavoriteToken(mintAddress)}
            className={cn(
              'text-l',
              isFavorite(mintAddress) ? 'color-theme' : ''
            )}
            name={`${
              isFavorite(mintAddress) ? 'web_like_active' : 'web_like'
            } `}
          />
        )
      }
    },
    {
      title: '',
      dataIndex: 'index',
      width: 2,
      hide: widthResize <= 768,
      render: (val) => {
        return <div>{val}</div>
      }
    },
    {
      title: t('name'),
      dataIndex: 'info',
      width: 20,
      render: (val) => {
        const { icon, name, symbol, address } = val
        return (
          // <div className="token-name">
          //   <div className="token-name__img">
          //     <TokenImage src={icon} />
          //   </div>
          //   <div className="token-name__info">
          //     <div className='info-name-token'>{name}&nbsp;</div>
          //     <span className='info-name-symbol uppercase'>({symbol})</span>
          //   </div>
          // </div>
          <Link to={`/token/${address}`} className='raw-link'>
            <a className="token-name ">
              <div className="token-name__img">
                <TokenImage src={icon} />
              </div>
              <div className="token-name__info">
                <div className='info-name-token'>{name}&nbsp;</div>
                <span className='info-name-symbol uppercase'>({symbol})</span>
              </div>
            </a>
          </Link>
        )
      }
    },
    {
      title: t('price'),
      dataIndex: 'price',
      width: 15,
      align: 'right',
      render: (val) => (<span>{ReduxServices.formatPrice(val)}</span>)
    },
    {
      title: t('priceChange'),
      dataIndex: 'priceChange',
      align: 'right',
      width: 15,
      hide: widthResize <= 768,
      render: (val) => {
        const isPriceChangeUp = parseFloat(val) > 0
        return (
          <div
            className={`token-price-change ${
              isPriceChangeUp ? 'token-price-change--up' : ''
            }`}
          >
            {isPriceChangeUp
              ? (
                // <img src={images.iconGrowUp} alt="" />
                <Icon className="token-price-change--up" name="web_up_fill"/>
              )
              : (
                <Icon className="token-price-change--down" name="web_down_fill"/>

            // <img src={images.iconGrowDown} alt="" />
              )}
            {formatNumberBro({ number: val, mantissa: 2 })}%
          </div>
        )
      }
    },
    {
      title: t('volume24h'),
      dataIndex: 'volume24h',
      align: 'right',
      hide: widthResize <= 768,
      width: 15,
      render: (val) => {
        return (
          <div>{ReduxServices.formatPrice(val)}</div>
        )
      }
    },
    {
      title: t('liquidity'),
      dataIndex: 'liquidity',
      hide: widthResize <= 768,
      width: 15,
      align: 'right',
      render: (val) => {
        return <div>{ReduxServices.formatPrice(val)}</div>
      }
    }
  ]

  const handleClickRow = (row) => {
    const address = get(row, 'info.mintAddress')
    history.push(`/token/${address}`)
  }

  const onReloadTableData = () => {
    getListTopToken(false)
  }

  const handleResizeWidth = () => {
    setWidthResize(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResizeWidth)

    return () => window.removeEventListener('resize', handleResizeWidth)
  }, [])

  return (
    <div className={styles.topTokenContainer}>

      <div className={cn(styles['table-top-token'], 'box-wrapper mb-5')}>
        <div className='table-top-token__title mb-3 color-normal'>
          {t('topTokens')}
          <Tooltip align="right" className="ml-3" content={t('loadingPieTooltip', { number: 20 })} isLongContent>

            <CountdownCircle onComplete={onReloadTableData}/>
          </Tooltip>

        </div>

        <div className="table-top-token__content">
          <CustomTable
            isLoading={isLoadingToken}
            columns={columnHeaderTopToken}
            dataSource={updatedListToken }
            onClickRow={handleClickRow}
            className="table-height"
          />

          <Pagination
            totalPage={total}
            handleSelectedPage={onChangePage}
          />
        </div>
      </div>

    </div>

  )
}

export default TableTopToken
