import * as Tokens from './tokens'

const CurveType = {
  ConstantProduct: 0,
  ConstantPrice: 1,
  Stable: 2,
  Offset: 3
}

const fromFraction = (a, b) => {
  return a / b * 100
}

export const solUsdcPool = Object.freeze({
  address: String('EGZ7tiLeH62TPV1gL8WwbXGzEPa9zmcpVnnkPKKnrE2U'),
  nonce: 252,
  authority: String('JU8kmKzDHF9sXWsnoznaFDFezLsE5uomX2JkRMbmsQP'),
  poolTokenMint: String('APDFRM3HMr8CAGXwKHiu2f5ePSpaiEJhaURwhsRrUUt9'),
  poolTokenDecimals: 6,
  feeAccount: String('8JnSiuvQq3BVuCU3n4DrSTw9chBSPvEMswrhtifVkr1o'),
  tokenIds: [Tokens.solToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('ANP74VNsHwSrq9uUSjiSNyNWvf6ZPrKTmE4gHoNd13Lg')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('75HgnSvXbWKZBpZHveX68ZzAhDqMzNDS29X6BGLtxMo1')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const solUsdtPool = Object.freeze({
  address: String('Dqk7mHQBx2ZWExmyrR2S8X6UG75CrbbpK2FSBZsNYsw6'),
  nonce: 255,
  authority: String('2sxKY7hxVFrY5oNE2DgaPAJFamMzsmFLM2DgVcjK5yTy'),
  poolTokenMint: String('FZthQCuYHhcfiDma7QrX7buDHwrZEd7vL8SjS6LQa3Tx'),
  poolTokenDecimals: 6,
  feeAccount: String('BBKgw75FivTYXj85D2AWyVdaTdTWuSuHVXRm1Xu7fipb'),
  tokenIds: [Tokens.solToken.mint.toString(), Tokens.usdtToken.mint.toString()],
  tokens: {
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('DTb8NKsfhEJGY1TrA7RXN6MBiTrjnkdMAfjPEjtmTT3M')
    },
    [Tokens.usdtToken.mint.toString()]: {
      ...Tokens.usdtToken,
      addr: String('E8erPjPEorykpPjFV9yUYMYigEWKQUxuGfL2rJKLJ3KU')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const ethSolPool = Object.freeze({
  address: String('EuK3xDa4rWuHeMQCBsHf1ETZNiEQb5C476oE9u9kp8Ji'),
  nonce: 255,
  authority: String('DffrDbzPiswDJaiicBBo9CjqztKgFLrqXGwNJH4XQefZ'),
  poolTokenMint: String('71FymgN2ZUf7VvVTLE8jYEnjP3jSK1Frp2XT1nHs8Hob'),
  poolTokenDecimals: 6,
  feeAccount: String('unxKgWEc71ZiHwMqZs3VLqjcjmZhfTZEg94ZLGvjdMP'),
  tokenIds: [Tokens.ethToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.ethToken.mint.toString()]: {
      ...Tokens.ethToken,
      addr: String('7F2cLdio3i6CCJaypj9VfNDPW2DwT3vkDmZJDEfmxu6A')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('5pUTGvN2AA2BEzBDU4CNDh3LHER15WS6J8oJf5XeZFD8')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const ethUsdcPool = Object.freeze({
  address: String('FgZut2qVQEyPBibaTJbbX2PxaMZvT1vjDebiVaDp5BWP'),
  nonce: 253,
  authority: String('4dfCZR32xXhoTgMRhnViNaTFwiKP9A34TDjHCR3xM5rg'),
  poolTokenMint: String('3e1W6Aqcbuk2DfHUwRiRcyzpyYRRjg6yhZZcyEARydUX'),
  poolTokenDecimals: 6,
  feeAccount: String('DLWewB12jzGn4wXJmFCddWDeof1Ma4cZYNRv9CP5hTvX'),
  tokenIds: [Tokens.ethToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.ethToken.mint.toString()]: {
      ...Tokens.ethToken,
      addr: String('H9h5yTBfCHcb4eRP87fXczzXgNaMzKihr7bf1sjw7iuZ')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('JA98RXv2VdxQD8pRQq4dzJ1Bp4nH8nokCGmxvPWKJ3hx')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const raySolPool = Object.freeze({
  address: String('3THMPkPmcHJ54JtHRyazhs7UN7HbV5KiNJVLJs6hSPSC'),
  nonce: 255,
  authority: String('EUc3MtHPLL956pTDfM5q25jp5Fk9zW7omEzh1uyDY7s6'),
  poolTokenMint: String('5kimD5W6yJpHRHCyPtnEyDsQRdiiJKivu5AqN3si82Jc'),
  poolTokenDecimals: 6,
  feeAccount: String('65XNtnUsP1TMzKMGhMoD3GUFMNbmnZQwDaxDLE3jncUC'),
  tokenIds: [Tokens.rayToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.rayToken.mint.toString()]: {
      ...Tokens.rayToken,
      addr: String('GZaUNWf4ov6VZaD5MqZtc5pHB3mWTqczNUB4sstt8CSR')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('GNSZ1rr57QQ6qLcmZnhMcoBymenVezhNpzcFSfJP37h9')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const ropeSolPool = Object.freeze({
  address: String('DCENobjFZK59nLeMCVRkQtnkAWrJkWAVpmVnwVNc8gqH'),
  nonce: 251,
  authority: String('C2DDX95TK3uC9MQXhHp3LWRv9kWtFTp36Ub9VPCKKiCV'),
  poolTokenMint: String('ADrvfPBsRcJfGsN6Bs385zYddH52nuM5FA8UaAkX9o2V'),
  poolTokenDecimals: 6,
  feeAccount: String('88rKjeeDQYJxGVCG39znDvbxzjPc5H37XqHRQCSNJvED'),
  tokenIds: [Tokens.ropeToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.ropeToken.mint.toString()]: {
      ...Tokens.ropeToken,
      addr: String('HLHPVwgzYjTHmu1fmV9eZzdE8H3fZwhuCBRNNN2Z5miA')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('7Be3aStQmKgeXC1xqfJnA8qaGzw6keQTLqHYAJprZK2H')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const stepSolPool = Object.freeze({
  address: String('2sNtf8wLZMR7XJm2pzvvVUNPTNRFsGTiC5vgDSGv5QGD'),
  nonce: 255,
  authority: String('HzAJLVqZ7fnmc2LfRPtz2GHu93RpAPQsdDTg6DRoTTmf'),
  poolTokenMint: String('8nTzqDXHriG2CXKbybeuEh1EqDQMtrbYMFWcP7AkiDaP'),
  poolTokenDecimals: 6,
  feeAccount: String('5FEmPmAk72NycwzMXLD3hc2f47zRXocv4mvd3HEUZ5io'),
  tokenIds: [Tokens.stepToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.stepToken.mint.toString()]: {
      ...Tokens.stepToken,
      addr: String('35opuEpVvggzfV361hQVNXiC7KAKS1HCeDoVpfVybo8k')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('A3DSsYZJWHiwXSQb7P2AbEoaWhpauJLU1PVdTPnzV5s9')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const srmSolPool = Object.freeze({
  address: String('EJRXAkKyDhDgzdZz1Ss5CpWKWSK9xTR5S6GDLAer8mdh'),
  nonce: 255,
  authority: String('2pQbngWBSWUjBHWVWQ3tppKxW3Y5NzUcye1822itKyzZ'),
  poolTokenMint: String('9tf8rBSEQYG7AqL896fN2nZi1iYPqpWaLEdpbeQaC1Vy'),
  poolTokenDecimals: 6,
  feeAccount: String('APyc1s8wES4Q2FTqHN8jXZtRuWQyRWZ82u7EFfras2iZ'),
  tokenIds: [Tokens.srmToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.srmToken.mint.toString()]: {
      ...Tokens.srmToken,
      addr: String('2JCxZv6LaFjtWqBXSC2brsWE9WryS4Cp3VwwDeNGvLyv')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('EdhG3vQbTVVAARZB4AbhU2HsVbvfFqX2yhBAfFV22nzA')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const fttSolPool = Object.freeze({
  address: String('68Bg6yQxWm3mrUYk3XzMiF5ycE41HwPhyEdaB1cp6wuo'),
  nonce: 255,
  authority: String('BpshqwEmPXmJwJfFgTFafmXoHN8Lc7SouvFsh6jyYQAm'),
  poolTokenMint: String('EsYaDKJCmcJtJHFuJYwQZwqohvVMCrFzcg8yo3i328No'),
  poolTokenDecimals: 6,
  feeAccount: String('FWBCbjZnypLKz7uHGJXpBAEez2FurQXi9J3js7ZT1xDe'),
  tokenIds: [Tokens.fttToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.fttToken.mint.toString()]: {
      ...Tokens.fttToken,
      addr: String('3eVE92aEAsLYcBACXNu1yxoHVfTM8e8vmQC2zSApGRJX')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('BbsiNbFfJsRDwqF4JaiJ6sKecNuY4eWmEaDHcY6h6HuD')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const copeSolPool = Object.freeze({
  address: String('BRx4dJecxzeGYc1BskCWonfGCeMyv9G7tk66cf2QGhK6'),
  nonce: 254,
  authority: String('JAJr1D6BQHFj9qJ8pfXhvJgLfn9vTcviU9sTA8MhKzN4'),
  poolTokenMint: String('CzieDbGRdN1QGaGDNpSqzEA18bi881ccvkkGZi51pe1k'),
  poolTokenDecimals: 6,
  feeAccount: String('9X8VRnxk6EVKGA7TErdSZYFC8oLUM569pDbZTtycjvXw'),
  tokenIds: [Tokens.copeToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.copeToken.mint.toString()]: {
      ...Tokens.copeToken,
      addr: String('7v5GCdHH439SztxcqL4wpfWdPvv9EfMm8GYTHSUQoGoY')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('5tSgRUK6f2x1nAXA4gdcHNXiWdToqni9pr5xvq2Fq82u')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const oxySolPool = Object.freeze({
  address: String('Cq4EZrvuFQpsCz8L3gS6t7iQ7VWzTtqDgSxD4AgVAAfd'),
  nonce: 255,
  authority: String('FxxcJPunf6Vj9Ve5zyM9yatMbmkrzTQ1QSk4NqKW9DvK'),
  poolTokenMint: String('7tYCdLN84EnTMkxM7HNamWJx7F4xgKe9KiiWvLyWjbgT'),
  poolTokenDecimals: 6,
  feeAccount: String('Ch8i2A1GAspivXYfdme7vSxh1mhRjmRgeiKN8KpWhVqo'),
  tokenIds: [Tokens.oxyToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.oxyToken.mint.toString()]: {
      ...Tokens.oxyToken,
      addr: String('BoZQMfTLTPcXnevJxNFkECVbKesfhXnTUg4kxLgzV9BX')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('8ZrEcJbgg7BdoBga5RYDR8aMujLf5cAQp8zdPZqk7nNC')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const btcSolPool = Object.freeze({
  address: String('7N2AEJ98qBs4PwEwZ6k5pj8uZBKMkZrKZeiC7A64B47u'),
  nonce: 255,
  authority: String('GqnLhu3bPQ46nTZYNFDnzhwm31iFoqhi3ntXMtc5DPiT'),
  poolTokenMint: String('Acxs19v6eUMTEfdvkvWkRB4bwFCHm3XV9jABCy7c1mXe'),
  poolTokenDecimals: 6,
  feeAccount: String('4yPG4A9jB3ibDMVXEN2aZW4oA1e1xzzA3z5VWjkZd18B'),
  tokenIds: [Tokens.btcToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.btcToken.mint.toString()]: {
      ...Tokens.btcToken,
      addr: String('9G5TBPbEUg2iaFxJ29uVAT8ZzxY77esRshyHiLYZKRh8')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('5eqcnUasgU2NRrEAeWxvFVRTTYWJWfAJhsdffvc6nJc2')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const merSolPool = Object.freeze({
  address: String('UJBm2tHwDbQZpjQvaHozg5qkjh6prSKhnWheqR5T76Q'),
  nonce: 255,
  authority: String('C8HcMrC9WRqqXVbHRhZWjuZPbbWmszDobKFxevCtGhpT'),
  poolTokenMint: String('HiwRobjfHZ4zsPtqCC4oBS24pSmy4t8GGkXRbQj4yU6L'),
  poolTokenDecimals: 6,
  feeAccount: String('3Fdj69449GhiDmqyvWWTSafjRphGdiDhZ5i5rqfHBdio'),
  tokenIds: [Tokens.merToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.merToken.mint.toString()]: {
      ...Tokens.merToken,
      addr: String('DHdkRWTa9SRJNMtWZQYvNNbjrDP3n92EWLHezjFGPipb')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('DC5RwjB3VkXdt2PoWKTA4Ub9KbtcY8xXpmPNKsFjALwq')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const fidaSolPool = Object.freeze({
  address: String('4SUBbivGMvMhem3ajVtkmuPvL4GuQ8kfYTJFuQfG4F8t'),
  nonce: 255,
  authority: String('owuNLod7H14GpQCUodcdBBeD4LiZ2T5U9KpS2sAbTp6'),
  poolTokenMint: String('EYsNdtyu4gGTaGz8N5m5iQ3G1N6rDyMbR72B3CqbWW4W'),
  poolTokenDecimals: 6,
  feeAccount: String('J2s1GpduscTTvMYt3os8LdvT24uhr9bPnTbxSKZZhEma'),
  tokenIds: [Tokens.fidaToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.fidaToken.mint.toString()]: {
      ...Tokens.fidaToken,
      addr: String('9ofyx5yFzjH1XWmJzfiGCDfhq6ho8yFbszGQrrJXe54')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('55moYcq91pXbSRpL2DR8P3BehqSNWiJrdTn5SZFc2STn')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const mapsSolPool = Object.freeze({
  address: String('4rkgbbCPKk5zx3KiwcCNiSpNSSLgAkswKGfX7CJjGgtC'),
  nonce: 255,
  authority: String('8uuBxVsGf2bqH5t8mct5NfpgcTDb7czXuWVEm6Boia4x'),
  poolTokenMint: String('99pfC8fWymXgbq3CvrExhx3UxQDC1fMWEWLbNT83F45e'),
  poolTokenDecimals: 6,
  feeAccount: String('8w3gx1GQ1UN5sodEVP14qUwzCcgopHWUeWbT26hgK3xh'),
  tokenIds: [Tokens.mapsToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.mapsToken.mint.toString()]: {
      ...Tokens.mapsToken,
      addr: String('BqExNTYk7YdeiaREHqnqN2q1F3dBCTPhkwrrWBFD4F1m')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('HdEQ99E979aXn2xTcg5UXEfLynwFkqpPTxLaNkH7Nz7P')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const usdcUsdtPool = Object.freeze({
  address: String('F13xvvx45jVGd84ynK3c8T89UejQVxjCLtmHfPmAXAHP'),
  nonce: 255,
  authority: String('3cGHDS8uWhdxQj14vTmFtYHX3NMouPpE4o9MjQ43Bbf4'),
  poolTokenMint: String('H2uzgruPvonVpCRhwwdukcpXK8TG17swFNzYFr2rtPxy'),
  poolTokenDecimals: 6,
  feeAccount: String('B4RNxMJGRzKFQyTq2Uwkmpyjtew13n7KtdqZy6qgENTu'),
  tokenIds: [Tokens.usdcToken.mint.toString(), Tokens.usdtToken.mint.toString()],
  tokens: {
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('6uUn2okWk5v4x9Gc4n2LLGHtWoa9tmizHq1363dW7t9W')
    },
    [Tokens.usdtToken.mint.toString()]: {
      ...Tokens.usdtToken,
      addr: String('AiwmnLy7xPT28dqZpkRm6i1ZGwELUCzCsuN92v4JkSeU')
    }
  },
  curveType: CurveType.Stable,
  amp: 100,
  feeStructure: {
    traderFee: fromFraction(6, 10000),
    ownerFee: fromFraction(1, 10000)
  }
})

export const orcaSolPool = Object.freeze({
  address: String('2ZnVuidTHpi5WWKUwFXauYGhvdT9jRKYv5MDahtbwtYr'),
  nonce: 255,
  authority: String('2PH1quJj9MHQXATCmNZ6qQ2gZqM8R236DpKaz99ggVpm'),
  poolTokenMint: String('2uVjAuRXavpM6h1scGQaxqb6HVaNRn6T2X7HHXTabz25'),
  poolTokenDecimals: 6,
  feeAccount: String('4Zc4kQZhRQeGztihvcGSWezJE1k44kKEgPCAkdeBfras'),
  tokenIds: [Tokens.orcaToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.orcaToken.mint.toString()]: {
      ...Tokens.orcaToken,
      addr: String('AioST8HKQJRqjE1mknk4Rydc8wVADhdQwRJmAAYX1T6Z')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('73zdy95DynZP4exdpuXTDsexcrWbDJX9TFi2E6CDzXh4')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const orcaUsdcPool = Object.freeze({
  address: String('2p7nYbtPBgtmY69NsE8DAW6szpRJn7tQvDnqvoEWQvjY'),
  nonce: 254,
  authority: String('3fr1AhdiAmWLeNrS24CMoAu9pPgbzVhwLtJ6QUPmw2ob'),
  poolTokenMint: String('n8Mpu28RjeYD7oUX3LG1tPxzhRZh3YYLRSHcHRdS3Zx'),
  poolTokenDecimals: 6,
  feeAccount: String('7CXZED4jfRp3qdHB9Py3up6v1C4UhHofFvfT6RXbJLRN'),
  tokenIds: [Tokens.orcaToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.orcaToken.mint.toString()]: {
      ...Tokens.orcaToken,
      addr: String('9vYWHBPz817wJdQpE8u3h8UoY3sZ16ZXdCcvLB7jY4Dj')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('6UczejMUv1tzdvUzKpULKHxrK9sqLm8edR1v9jinVWm9')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const kinSolPool = Object.freeze({
  address: String('Ez52BLSoZw3MxWxMK4ADsJXqzUiYW9sUnwcrrQwQGdLT'),
  nonce: 253,
  authority: String('C6WisvFQzqxTBF3DV6RFbPMPBiVHE816CZHoctB3WzrW'),
  poolTokenMint: String('HEvnD66WcBfTajS9adUYnGRBMDehFtLySiFHSD6kEBWs'),
  poolTokenDecimals: 6,
  feeAccount: String('5bLeJU66qTopjZBa48BUd7EXWcj4UeCMfHjjrvt8Zcgs'),
  tokenIds: [Tokens.kinToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.kinToken.mint.toString()]: {
      ...Tokens.kinToken,
      addr: String('2Ssm5Dd1Zc5QpGSZzuqt3Ef4bADteuBbCGiEZJ5n48rV')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('HCS9EpKRxWDS9GCRFStNbPWgRQpvV6EyBWChJja2UbCm')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const samoSolPool = Object.freeze({
  address: String('DiAP9qmp5foN7fLTWfBWjo9KBS1jgvKUJLWi1ZhqKaja'),
  nonce: 255,
  authority: String('3uZcofBKVBYFrQ7jVjTFLEMWAQiZcih4AES5tKBqdo7m'),
  poolTokenMint: String('D6N9j8F2DhtzDtrdpT74y3u2YmYAzcggiLc3nTjqux9M'),
  poolTokenDecimals: 6,
  feeAccount: String('BYAjG645fRRHZ5JFnZKnXc4dzK9WppcoVWDMYj3zm3KF'),
  tokenIds: [Tokens.samoToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.samoToken.mint.toString()]: {
      ...Tokens.samoToken,
      addr: String('AFcbD7UTzk9d1njRxyDHNbQ5Q6miPNAE1GctjD96JYAi')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('6kYbPDsYLQUwptV7ZvQKG3gjNreEEgaWh2CM4DQPYTpq')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const liqUsdcPool = Object.freeze({
  address: String('AXSeEafwPUGSamiZWH8m2PJtvpDVtrofxvycNwxiysdh'),
  nonce: 254,
  authority: String('6Y5TnCwgifc8Z7QYo672nT9uNd2hcivVR1NT6oDkJx6P'),
  poolTokenMint: String('3PD9SZFwXKkXr4akLf4ofo37ZUMycwML89R2P3qxcbZG'),
  poolTokenDecimals: 6,
  feeAccount: String('FSVPcprrTkQLRtDACFEpa2rhEVx4kBvjPuQaxj572SaC'),
  tokenIds: [Tokens.liqToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.liqToken.mint.toString()]: {
      ...Tokens.liqToken,
      addr: String('CVspL8Tj5YdqntXJegNeDXHiBn3648QDNB7gex6D9MgY')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('8YzLsZ1FtsruswkBogEaXwmRTf5PMuyVcfSZXRAdi8qA')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const snyUsdcPool = Object.freeze({
  address: String('BDn3Fj9UXVi2mRVMR2jzpCrAZZphnwfkvFhs192yhCTu'),
  nonce: 255,
  authority: String('FvjsfbbzZAcrVdfrGtZUjGWAjWHXrfMG8Bjwc17vVSK3'),
  poolTokenMint: String('AZpo4BJHHRetF96v6SGinFZBMXM4yWMo4RA8C4PriDLk'),
  poolTokenDecimals: 6,
  feeAccount: String('DiULDJAYXdbtX8CfFsU2jCgHvQWT7u3gwRwpvQxfEMvr'),
  tokenIds: [Tokens.snyToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.snyToken.mint.toString()]: {
      ...Tokens.snyToken,
      addr: String('14RHMRzwx9Y4Z41qpr9sTwJZ58wXqV1R9WTkUA7ybmKG')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('Dw9D9T4bBC3oGdMqxE1xWfPSCJ27SYwWD8rFfUxU99QG')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const msolUsdcPool = Object.freeze({
  address: String('Hme4Jnqhdz2jAPUMnS7jGE5zv6Y1ynqrUEhmUAWkXmzn'),
  nonce: 255,
  authority: String('9Z7E42k46kxnBjAh8YGXDw3rRGwwxQUBYM7Ccrmwg6ZP'),
  poolTokenMint: String('8PSfyiTVwPb6Rr2iZ8F3kNpbg65BCfJM9v8LfB916r44'),
  poolTokenDecimals: 6,
  feeAccount: String('3W3Skj2vQsNEMhGRQprFXQy3Q8ZbM6ojdgiDCokVPWno'),
  tokenIds: [Tokens.msolToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.msolToken.mint.toString()]: {
      ...Tokens.msolToken,
      addr: String('GBa7G5f1FqAXEgByuHXsqsEdpyMjRgT9SNxZwmmnEJAY')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('7hFgNawzzmpDM8TTVCKm8jykBrym8C3TQdb8TDAfAVkD')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const slrsUsdcPool = Object.freeze({
  address: String('87Xz6RK1uzP5NEhSjMewZtDAZyg4V1tYAa1KHnvge17b'),
  nonce: 254,
  authority: String('5D9v9y6Kbswe6k1wnVceuqRXHMsRQJ8xzx8hadSH6EAM'),
  poolTokenMint: String('AtB4nUmdyQfuWWJ9xAHw9xyVnJFfSjSuVWkiYan8y86w'),
  poolTokenDecimals: 6,
  feeAccount: String('CLxeBxNq42AtmD43F5BTqCHTnkVHX8sP9cVPdtRkTL7D'),
  tokenIds: [Tokens.slrsToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.slrsToken.mint.toString()]: {
      ...Tokens.slrsToken,
      addr: String('CM7oGYHy1oxzHoum8Cxv4pwnndm6Jbs3NkBZkc6v9S9d')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('3QqPbMcUMZu3Rz762g7JgvpUxhRHPtE9HFk2MSDRmPqa')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const portUsdcPool = Object.freeze({
  address: String('4if9Gy7dvjU7XwunKxdnCcPsaT3yAHPXdz2XS1eo19LG'),
  nonce: 254,
  authority: String('BshtCZRCHj2RZYC7u5sW3ioRJo9ZiYA4T5p8muFwrKnb'),
  poolTokenMint: String('F8gPSpwVHj8FdAJAYULDuZBxFEJut87hUbARYYx3471w'),
  poolTokenDecimals: 6,
  feeAccount: String('5JZXUbCfaSo3y9PYq47Hj5Yc6hVFa4j7MkDzBJfMSRSN'),
  tokenIds: [Tokens.portToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.portToken.mint.toString()]: {
      ...Tokens.portToken,
      addr: String('2wuSqR5z2Guft2yt57Hx7K6i1AYNoUi8fjxHUeAgaKXo')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('AvP1Db3SyUxLGMSc4nSXjJkjm1kAjiLjog7cup19eWa3')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const sbrUsdcPool = Object.freeze({
  address: String('HiYggjP2fN53Jw46e5UuskqNP3HH98jceRxEgVoeRwNw'),
  nonce: 255,
  authority: String('ATkEV1nEkdp7zgaGpzFCsJ5WAyejcJbxqzGhQpfcDW4S'),
  poolTokenMint: String('CS7fA5n4c2D82dUoHrYzS3gAqgqaoVSfgsr18kitp2xo'),
  poolTokenDecimals: 6,
  feeAccount: String('7S3KKuvcHfcKWBGLDwmoTgtB97JE8LHruP8jbmQkGfH'),
  tokenIds: [Tokens.sbrToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.sbrToken.mint.toString()]: {
      ...Tokens.sbrToken,
      addr: String('DrJTQqNZqNCf2HDLpYg9zRCMRwnhZEVQuGjeaWtX6CA7')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('DEVLUv1uiUSukQoBdy9fDQyehi4N2Boojy8J2LQ8bK2E')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const scnsolUsdcPool = Object.freeze({
  address: String('6Gh36sNXrGWYiWr999d9iZtqgnipJbWuBohyHBN1cJpS'),
  nonce: 255,
  authority: String('GXWEpRURaQZ9E62Q23EreTUfBy4hfemXgWFUWcg7YFgv'),
  poolTokenMint: String('Dkr8B675PGnNwEr9vTKXznjjHke5454EQdz3iaSbparB'),
  poolTokenDecimals: 6,
  feeAccount: String('HsC1Jo38jK3EpoNAkxfoUJhQVPa28anewZpLfeouUNk7'),
  tokenIds: [Tokens.scnsolToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.scnsolToken.mint.toString()]: {
      ...Tokens.scnsolToken,
      addr: String('7xs9QsrxQDVoWQ8LQ8VsVjfPKBrPGjvg8ZhaLnU1i2VR')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('FZFJK64Fk1t619zmVPqCx8Uy29zJ3WuvjWitCQuxXRo3')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const psolUsdcPool = Object.freeze({
  address: String('GW1Xt9HHtvcnky8X7aBA3BoTgiirJKP5XwC5REFcZSsc'),
  nonce: 254,
  authority: String('GXueH9K1MzRncoTYbpLiXXC3WrKkmHUFxV5JEu8oADbw'),
  poolTokenMint: String('C2YzN6MymD5HM2kPaH7bzcbqciyjfmpqyVaR3KA5V6z1'),
  poolTokenDecimals: 6,
  feeAccount: String('BhHd49JYH3Hk6TV5kCjmUgf7fQSQKDjaWTokMmBhTx9o'),
  tokenIds: [Tokens.psolToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.psolToken.mint.toString()]: {
      ...Tokens.psolToken,
      addr: String('F7XioZaGe99nosYJQCahx25TKgdUGufYf6sudm1JSgu')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('BT14DfFyNS7qcBGc8TY4HAzDev4vvqsoFBJgjtQpdM2Z')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const msolSolPool = Object.freeze({
  address: String('9EQMEzJdE2LDAY1hw1RytpufdwAXzatYfQ3M2UuT9b88'),
  nonce: 250,
  authority: String('6cwehd4xhKkJ2s7iGh4CaDb7KhMgqczSBnyNJieUYbHn'),
  poolTokenMint: String('29cdoMgu6MS2VXpcMo1sqRdWEzdUR9tjvoh8fcK8Z87R'),
  poolTokenDecimals: 6,
  feeAccount: String('6j2tt2UVYMQwqG3hRtyydW3odzBFwy3pN33tyB3xCKQ6'),
  tokenIds: [Tokens.msolToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.msolToken.mint.toString()]: {
      ...Tokens.msolToken,
      addr: String('6xmki5RtGNHrfhTiHFfp9k3RQ9t8qgL1cYP2YCG2h179')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('Ew2coQtVGLeca31vqB2ssHntjzZgUy1ad9VuuAX8yw7p')
    }
  },
  curveType: CurveType.Stable,
  amp: 100,
  feeStructure: {
    traderFee: fromFraction(6, 10000),
    ownerFee: fromFraction(1, 10000)
  }
})

export const orcaPaiPool = Object.freeze({
  address: String('7LfLiCnoLPefaCVuh6z92TK2tPZUa9bPjW7gHT4jqrec'),
  nonce: 254,
  authority: String('AwUWHxHyQHomqCGJJvagiSDhb2xMqJUiE25qDytdMw49'),
  poolTokenMint: String('C7TH2jEJJaxVwwuvkbcDGfHUiZvEkkeYjyAcdTMi5ujb'),
  poolTokenDecimals: 6,
  feeAccount: String('DrC2aGWrUmsnK6yAphSXEs8GW5nKUCkityaG8Bikn5Ne'),
  tokenIds: [Tokens.orcaToken.mint.toString(), Tokens.paiToken.mint.toString()],
  tokens: {
    [Tokens.orcaToken.mint.toString()]: {
      ...Tokens.orcaToken,
      addr: String('HSUFpGyNXEogXQLgEMQ7aMTxE4HZneRaBovbi9btXepM')
    },
    [Tokens.paiToken.mint.toString()]: {
      ...Tokens.paiToken,
      addr: String('4c9rkBiqAY6fXpVvCbDwpDD44AGQ3MXSaCLcpmLUFtrX')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const orcaMsolPool = Object.freeze({
  address: String('49tTgthTYLMPEqozZNyEQifqkGYxHqqDie9YxVczS3iB'),
  nonce: 255,
  authority: String('9FQ9gDtS6uNr5SMPafuzkDit2rMftHfQuz5mg2X3TqHT'),
  poolTokenMint: String('CVapmQn7HaU1yMDW3q6oUV4hx6XoYv54T4zfGXkuJqkA'),
  poolTokenDecimals: 6,
  feeAccount: String('Hq9xxKdMavJd4teMZusF4PiGNGV3hxdcMZwAdngkHCg7'),
  tokenIds: [Tokens.orcaToken.mint.toString(), Tokens.msolToken.mint.toString()],
  tokens: {
    [Tokens.orcaToken.mint.toString()]: {
      ...Tokens.orcaToken,
      addr: String('7hoYJc4aqttctANrNe75gscdmQD9HcXZED6AjdDdZMQ9')
    },
    [Tokens.msolToken.mint.toString()]: {
      ...Tokens.msolToken,
      addr: String('7MuvRUFT1wWiL7uJKdZqNwk9Fmz2HJ36bEArhDTnyFij')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const scnsolSolPool = Object.freeze({
  address: String('2q6UMko5kTnv866W9MTeAFau94pLpsdeNjDdSYSgZUXr'),
  nonce: 255,
  authority: String('Gyd77CwV23qq937x9UDa4TDkxEeQF9tp8ifotYxqW3Kd'),
  poolTokenMint: String('APNpzQvR91v1THbsAyG3HHrUEwvexWYeNCFLQuVnxgMc'),
  poolTokenDecimals: 6,
  feeAccount: String('42Xzazs9EvjtidvEDrj3JXbDtf6fpTq5XHh96mPctvBV'),
  tokenIds: [Tokens.scnsolToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.scnsolToken.mint.toString()]: {
      ...Tokens.scnsolToken,
      addr: String('C8DRXUqxXtUgvgBR7BPAmy6tnRJYgVjG27VU44wWDMNV')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('DzdxH5qJ68PiM1p5o6PbPLPpDj8m1ZshcaMFATcxDZix')
    }
  },
  curveType: CurveType.Stable,
  amp: 100,
  feeStructure: {
    traderFee: fromFraction(6, 10000),
    ownerFee: fromFraction(1, 10000)
  }
})

export const atlasUsdcPool = Object.freeze({
  address: String('3V5sjXj1mrWjjB1Xt6Xwp554QwHE5fppGSxbk4GzAtEW'),
  nonce: 254,
  authority: String('8UYN675AJn5htWydDs724xqintBZ4XzsCWqMozUSDU8m'),
  poolTokenMint: String('FZ8x1LCRSPDeHBDoAc3Gc6Y7ETCynuHEr5q5YWV7uRCJ'),
  poolTokenDecimals: 6,
  feeAccount: String('CFN4DQ2p3qroX92pPNy3mov3Dw1aCNGLrU5AXHpHxbko'),
  tokenIds: [Tokens.atlasToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.atlasToken.mint.toString()]: {
      ...Tokens.atlasToken,
      addr: String('xotXsNCx4tBhnwhrajGTaVgKq1sfuMkeYHc77ZegCqE')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('8YswVYsTi66umBF2Bnkh4LB2VWMKPssDpe54VAgiuJZQ')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const polisUsdcPool = Object.freeze({
  address: String('CdKPtCb5fBRaGFS4bJgytfReeHuFyhpe9YUyWHPnEWZG'),
  nonce: 251,
  authority: String('8XB9V3VuHtPBzHqvxzcmpkpaoXNXjZMD8VBHC79SxcEL'),
  poolTokenMint: String('GteBdo9sqE7T41G8AJsaG9WHW48uXBwsLLznmu2TBdgy'),
  poolTokenDecimals: 6,
  feeAccount: String('3gZQ2YnrXbnRwJj5poffLirF7CwacatvtfGCNRFrbJdr'),
  tokenIds: [Tokens.polisToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.polisToken.mint.toString()]: {
      ...Tokens.polisToken,
      addr: String('EbXNEUiKxSU1vwwhrbVNVk3qX4o1yU3p75SQUUMfc1zH')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('CLCj9b1vdPutrkvZS8ACTM5q42SXB2Q7khnMLVxDMGEK')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const bopUsdcPool = Object.freeze({
  address: String('9pheQ8EX2wDKHZYm75G9haEbTyAcg8F2gFWybw2w6Vyc'),
  nonce: 253,
  authority: String('3rxJPYuiRijipJbciewUDacab2Wo3yAe1yWBGzmqn5f1'),
  poolTokenMint: String('2gXDJZ7XAtQEtf4PRSQZKoq1WMuu1H44tQanbMA3YVpu'),
  poolTokenDecimals: 6,
  feeAccount: String('2bei4349W8FUcu5gvP5Zt8yhkpwqcCngZxUkb3xRMJZJ'),
  tokenIds: [Tokens.bopToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.bopToken.mint.toString()]: {
      ...Tokens.bopToken,
      addr: String('HkHjLSaP9yyWTzMGD1DKyoc1Dfvhvw4vakRhyjcVUCKs')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('CqRoKMF4kh2o568nFdDNHs7cszBjrkQME4RtCuTqcjCe')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const samoUsdcPool = Object.freeze({
  address: String('Epvp7qMYAF21VVjacdB3VfKn6nnXQSF4rGYu8sD6Bkow'),
  nonce: 252,
  authority: String('AB4rTE2JiKFhnfynUQCovbW75CUxT9LxcJX2SDTbY9gy'),
  poolTokenMint: String('6VK1ksrmYGMBWUUZfygGF8tHRGpNxQEWv8pfvzQHdyyc'),
  poolTokenDecimals: 6,
  feeAccount: String('9U8UF7d8kBvsS25XoZnjmVQ9vGkP4BUnHJgfc615BvG1'),
  tokenIds: [Tokens.samoToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.samoToken.mint.toString()]: {
      ...Tokens.samoToken,
      addr: String('7jwHW4Lw3nVaSJXskN5pUoKU6YB9RBVfZtGBp3VbR43U')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('G7Gqjxk9EaJMeFfoFTSy9WfH8uurgQkbNQCREWAc56DZ')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const ninjaSolPool = Object.freeze({
  address: String('3ECUtPokme1nimJfuAkMtcm7QYjDEfXRQzmGC16LuYnz'),
  nonce: 255,
  authority: String('H8f9n2PfehUc73gRWSRTPXvqUhUHVywdAxcfEtYmmyAo'),
  poolTokenMint: String('4X1oYoFWYtLebk51zuh889r1WFLe8Z9qWApj87hQMfML'),
  poolTokenDecimals: 6,
  feeAccount: String('43ViAbUVujnYtJyzGP4AhabMYCbLsExenT3WKsZjqJ7N'),
  tokenIds: [Tokens.ninjaToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.ninjaToken.mint.toString()]: {
      ...Tokens.ninjaToken,
      addr: String('6Y9VyEYHgxVahiixzphNh4HAywpab9zVoD4S8q1sfuL8')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('9SxzphwrrDVDkwkyvmtag9NLgpjSkTw35cRwg9rLMYWk')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const slimUsdcPool = Object.freeze({
  address: String('8JPid6GtND2tU3A7x7GDfPPEWwS36rMtzF7YoHU44UoA'),
  nonce: 255,
  authority: String('749y4fXb9SzqmrLEetQdui5iDucnNiMgCJ2uzc3y7cou'),
  poolTokenMint: String('BVWwyiHVHZQMPHsiW7dZH7bnBVKmbxdeEjWqVRciHCyo'),
  poolTokenDecimals: 6,
  feeAccount: String('E6aTzkZKdCECgpDtBZtVpqiGjxRDSAFh1SC9CdSoVK7a'),
  tokenIds: [Tokens.slimToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.slimToken.mint.toString()]: {
      ...Tokens.slimToken,
      addr: String('ErcxwkPgLdyoVL6j2SsekZ5iysPZEDRGfAggh282kQb8')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('EFYW6YEiCGpavuMPS1zoXhgfNkPisWkQ3bQz1b4UfKek')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const whapiUsdcPool = Object.freeze({
  address: String('2Y1Jmpkf5wt1X5zcFHNBDoHxqjTXbMJfj1UFtrYQwUbG'),
  nonce: 254,
  authority: String('8K4eRHeyPhBGB9zCjKtyBHoPPZ75zLN64oxBF6GyF4Qg'),
  poolTokenMint: String('ELfBngAgvLEHVBuJQhhE7AW6eqLX7id2sfrBngVNVAUW'),
  poolTokenDecimals: 6,
  feeAccount: String('Bx3ZhEBFedDqCBzuzKVS4eMKTtW1MmHkcMGU45FcyxRT'),
  tokenIds: [Tokens.whapiToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.whapiToken.mint.toString()]: {
      ...Tokens.whapiToken,
      addr: String('DRYADMQevoJHDFCYbDQeS4p551MpsDN2d7CJU3LxfNHa')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('HzsECCX6RZ2ccbR3FarRSEfc5rkuETfywXJnRZut5JzU')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const copeUsdcPool = Object.freeze({
  address: String('DhGTKyT6RVkpvPxXAJodi4Z41RSvQZxV1f5eRvJ5bE4r'),
  nonce: 254,
  authority: String('Hu8AWoRBa7ZaYQFdHQyRHeEZGB16Me86fA5vAZzjVUmv'),
  poolTokenMint: String('HsauTv9s52Zv12eaDuSp6y7BEm4e4BHEyAsbdjyyWzPK'),
  poolTokenDecimals: 6,
  feeAccount: String('JEGcxfGxWJpRkGtvA6J6kEgTBebDz6kxURoeB3SX8vaW'),
  tokenIds: [Tokens.copeToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.copeToken.mint.toString()]: {
      ...Tokens.copeToken,
      addr: String('6N3P3etaUYGeBs2C67ZQTDRqHsExNsfj85dDWPwHtQBS')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('36VVz3eY8YmWBGskQVjvGGBfyUKHHCEDBgkFtzMpFqeU')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const sunnyUsdcPool = Object.freeze({
  address: String('3Ukqqshh3kZ8UPbcYYFSRaeJcsgttcmShtNNn12F1rj2'),
  nonce: 255,
  authority: String('7NP8DTzPdpbQofhNyhLW3j2khutmfy1kuFp2AjaD8rrp'),
  poolTokenMint: String('GHuoeq9UnFBsBhMwH43eL3RWX5XVXbSRYJymmyMYpT7n'),
  poolTokenDecimals: 6,
  feeAccount: String('CCuSVbnnq8SUj7cpPe7BbHLuKanyxfvfrwypzCBnaDdf'),
  tokenIds: [Tokens.sunnyToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.sunnyToken.mint.toString()]: {
      ...Tokens.sunnyToken,
      addr: String('F6nCAMYEFxsyRDVonQXLNufXgAHsgAa1Br8DhBoX3KAV')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('HWCTHmQppFSsKQEk1bHUqPC2WLaidgnfTG9MQGD4XKEt')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const grapeUsdcPool = Object.freeze({
  address: String('6MxUhBLXHCqpdYaFPTmw1D9fQ7zYnm9grZyJvpGiqr15'),
  nonce: 255,
  authority: String('SvWmpVVUkv8cwoRnBQ5Gqt2FFYjdpWLS665gE2ZLNQp'),
  poolTokenMint: String('EorFh8siFyLF1QTZ7cCXQaPGqyo7eb4SAgKtRH8Jcxjd'),
  poolTokenDecimals: 6,
  feeAccount: String('6vWYnRDEHu7kRLbA2dnBgEfbdba72iDMDD9k3munyPaP'),
  tokenIds: [Tokens.grapeToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.grapeToken.mint.toString()]: {
      ...Tokens.grapeToken,
      addr: String('686KiYDMMkbredNoWx8yqvAdKSiHuWSG3dnbL6yWYmZp')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('9i14ZKzaDmzKCAQb8hCv4h5GCo2Xiq83JcL7bofk4Ddj')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const abrUsdcPool = Object.freeze({
  address: String('rxwsjytcEBvXpXrXBL1rpsjhoh78imBn8WbxjKmLRge'),
  nonce: 252,
  authority: String('AcaxutE6Rh9vRxipTLdqinEdRK6R4ayUAAv2bZPh6UU9'),
  poolTokenMint: String('GMzPbaCuQmeMUm1opH3oSCgKUjVgJUW14myq99RVPGX5'),
  poolTokenDecimals: 6,
  feeAccount: String('7pPJGwd8Vq7aYmHaocQpQSfTn3UWYGKUgFkFhpMmRdDF'),
  tokenIds: [Tokens.abrToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.abrToken.mint.toString()]: {
      ...Tokens.abrToken,
      addr: String('6FRxhbY7bvSiDojPiqoidjTyDjxaUyCoPQk3ifEdfFbm')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('8aTapFecZRZmC2bTeKr2voHFW2twNvbrh8nWYdXYQWkZ')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const kuroUsdcPool = Object.freeze({
  address: String('HdeYs4bpJKN2oTb7PHxbqq4kzKiLr772A5N2gWjY57ZT'),
  nonce: 250,
  authority: String('2KRcBDQJWEPygxcMMFMvR6dMTVtMkJV6kbxr5e9Kdj5Q'),
  poolTokenMint: String('DRknxb4ZFxXUTG6UJ5HupNHG1SmvBSCPzsZ1o9gAhyBi'),
  poolTokenDecimals: 6,
  feeAccount: String('5XuLrZqpX9gW3pJw7274EYwAft1ciTXndU4on96ERi9J'),
  tokenIds: [Tokens.kuroToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.kuroToken.mint.toString()]: {
      ...Tokens.kuroToken,
      addr: String('DBckbD9CoRBFE8WdbbnFLDz6WdDDSZ7ReEeqdjL62fpG')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('B252w7ZkUX4WyLUJKLEymEpRkYMqJhgv2PSj2Z2LWH34')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const mediaUsdcPool = Object.freeze({
  address: String('5L2aVMnNsmrnkxU4B25ajb2pR5AJWBRfUa73wjasjyaB'),
  nonce: 255,
  authority: String('HX3JKg5HtboRw9nQRWm47rSJkBHczdcXwBgWASyHi3Wk'),
  poolTokenMint: String('2toFgkQDoPrTJYGDEVoCasPXuL9uQnjvXJaDwa9LHyTx'),
  poolTokenDecimals: 6,
  feeAccount: String('5BkyYnBWnzBWQKnU9AcUaDrmyhjLpAcFxCvVTTnRGyYk'),
  tokenIds: [Tokens.mediaToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.mediaToken.mint.toString()]: {
      ...Tokens.mediaToken,
      addr: String('BFAyLvCbMhgF7CQ9fsWWK46jD9mPXfBMDWvXgk5LTgsT')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('7CvBsWsfEif4sAo9dnsf1JKVAfBGcZUVTktqtxBSkgwB')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const tulipUsdcPool = Object.freeze({
  address: String('BNwrRN23RCoq5MAneJ6Cot7iN2FLtyt9rtcjaVfAXTLt'),
  nonce: 253,
  authority: String('EFZs7veYVdWBHt7RcAPvXQc46gDzccpZTxAcEm6NyXFg'),
  poolTokenMint: String('4SBx8GXu8HhcVHWydQv1vsDdZs3G93XSL9CtMBny6hS5'),
  poolTokenDecimals: 6,
  feeAccount: String('8BiqDTCBQ77qjGpED2was7C4iHJrQx9bXhzRt3Wz9xJG'),
  tokenIds: [Tokens.tulipToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.tulipToken.mint.toString()]: {
      ...Tokens.tulipToken,
      addr: String('5CKd5M2nXdPM1TMXxqK6Up6GZehKL5uU9Z9Ytm2sFCiz')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('HjMQnuxjVRodoaAg9WcNXb9TAssDaFNpgwcUUKNjWdh5')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const mngoUsdcPool = Object.freeze({
  address: String('Hk9ZCvmqVT1FHNkWJMrtMkkVnH1WqssWPAvmio5Vs3se'),
  nonce: 254,
  authority: String('5RyiYaHFDVupwnwxzKCRk7JY1CKhsczZXefMs3UUmx4Z'),
  poolTokenMint: String('H9yC7jDng974WwcU4kTGs7BKf7nBNswpdsP5bzbdXjib'),
  poolTokenDecimals: 6,
  feeAccount: String('FWKcKaMfaVezLRFr946MdgmpTZHG4A2GgqehAxjTyDAB'),
  tokenIds: [Tokens.mngoToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.mngoToken.mint.toString()]: {
      ...Tokens.mngoToken,
      addr: String('J8bQnhcNyixFGBskQoJ2aSPXPWjvSzaaxF4YPs96XHDJ')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('5yMoAhjfFaCPwEwKM2VeFFh2iBs5mHWLTJ4LuqZifsgN')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const stsolWstethPool = Object.freeze({
  address: String('B32UuhPSp6srSBbRTh4qZNjkegsehY9qXTwQgnPWYMZy'),
  nonce: 255,
  authority: String('EtwQJxu8wih29vMpdTa74K9W9tgtL4LT6hbWBkhHwvU5'),
  poolTokenMint: String('Eswigpwm3xsipkTqahGi2PEJsJcULQBwZgxhQpr6yBEa'),
  poolTokenDecimals: 6,
  feeAccount: String('74b9j23njRpt3PYPxoze2XS29ZgGmucziLB7WrsDpBdD'),
  tokenIds: [Tokens.stsolToken.mint.toString(), Tokens.wstethToken.mint.toString()],
  tokens: {
    [Tokens.stsolToken.mint.toString()]: {
      ...Tokens.stsolToken,
      addr: String('CeSEpgqc3zV8xDr7Q6PiwJju6a6e92wpAv7Kg6QyFfQB')
    },
    [Tokens.wstethToken.mint.toString()]: {
      ...Tokens.wstethToken,
      addr: String('Fb3XpEJgghTURUGd1wphWr93ruX5egnesfdZtjWCxJFy')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const sypUsdcPool = Object.freeze({
  address: String('5DnwMqYAGEKekYXJdN8Bue6vN1p5zrEnBpmd53jEK61S'),
  nonce: 255,
  authority: String('4NfadURWeSDPJBGcKQRt39mPhbG9M7EJx6FZDwwcFB9f'),
  poolTokenMint: String('qJxKN9BhxbYvRNbjfK2uAVWboto6sonj8XC1ZEW5XTB'),
  poolTokenDecimals: 6,
  feeAccount: String('57L2bEFecsAv4jnaM2PBaeAVyPZEYtTmXBi7eaG2xWXw'),
  tokenIds: [Tokens.sypToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.sypToken.mint.toString()]: {
      ...Tokens.sypToken,
      addr: String('6d19CQA1FP2MLLAzA7XoZEc9Agc32FaKUS175UVWLGtv')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('HpPnUHyo19VjmXbP6FbbKXu7WQCUEn6h7be76fZdHVmf')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const stsolWldoPool = Object.freeze({
  address: String('CqwyVxWeaiikQ3VhvEZSEmupmG1Wmc2FeaUjsCV492Sx'),
  nonce: 255,
  authority: String('213QoNt5dR56Ye2sx9cwPwpR3NpJUEStQXn8EbbWKkfJ'),
  poolTokenMint: String('74B9aMS7SA832xKngt5VLKmWAP3pa3qkUzWncTmQSsGF'),
  poolTokenDecimals: 6,
  feeAccount: String('D4kH4fcwwDtGMj4LpcynB977YVnmvDUcuDQoo5sqAgRz'),
  tokenIds: [Tokens.stsolToken.mint.toString(), Tokens.wldoToken.mint.toString()],
  tokens: {
    [Tokens.stsolToken.mint.toString()]: {
      ...Tokens.stsolToken,
      addr: String('GDprNAcXeR5GVGnCtkS5UqyPGMm2Sy5Lk15qqN36faMT')
    },
    [Tokens.wldoToken.mint.toString()]: {
      ...Tokens.wldoToken,
      addr: String('VCgdcsExfmxUDQwusLP2xqZ3ap7VuYyQMMHDPSva2hx')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const whethSolPool = Object.freeze({
  address: String('FcEro2uFpHcb7Z785CBs6q12KMJqUJKa8VTXPi4TTBMf'),
  nonce: 252,
  authority: String('HMxZz8fv2uR9suzAPRbJGNB3wZL1eT3eKL3cpYWUbM8K'),
  poolTokenMint: String('7aYnrdmdCRodDy2Czn6keUquUhjF1jPEmfwZPh488z8U'),
  poolTokenDecimals: 6,
  feeAccount: String('YCVJDKdHNi1mhJtWz7QGbBRreMmw1soeipz7wZbQKEK'),
  tokenIds: [Tokens.whethToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.whethToken.mint.toString()]: {
      ...Tokens.whethToken,
      addr: String('3uQytDKNd5H6XK8FhTei4wCUmj2eTbLTbiLAtWk2SmbA')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('GR3g8Wej3jmv92hYM1t22kaXog2xjkGjQ7V1XzLd1efT')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const whethUsdcPool = Object.freeze({
  address: String('4reGGLbesqpAeAZdAJv9hhgA2tgj45oGcyRuEvhATdMm'),
  nonce: 252,
  authority: String('8uLtzZ1iTLTCPsm3b4QttRmDXcFjhVHRuMS9VTVEwo7E'),
  poolTokenMint: String('7NPtjjAP7vhp4t5NCLyY4DY5rurvyc8cgZ2a2rYabRia'),
  poolTokenDecimals: 6,
  feeAccount: String('AVw52spXtzFh4bb5ghhpJaDbLx3XWuY85eQNDEo3X1yN'),
  tokenIds: [Tokens.whethToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.whethToken.mint.toString()]: {
      ...Tokens.whethToken,
      addr: String('9KpjcpKwhoFPbixvKDfcAhBQcVXk1CSBTGsJdzojDPRv')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('5HaG31FQS4McBVcHxVfwaKaWXE3VCGqvJ1ZDkTxs94cQ')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const mndeMsolPool = Object.freeze({
  address: String('vjHagYsgZwG9icyFLHu2xWHWdtiS5gfeNzRhDcPt5xq'),
  nonce: 255,
  authority: String('3HWcojnC1ruEMmsE92Ez1BoebdDXzYQa4USaeWX7eTuM'),
  poolTokenMint: String('5PHS5w6hQwFNnLz1jJFe7TVTxSQ98cDYC3akmiAoFMXs'),
  poolTokenDecimals: 6,
  feeAccount: String('46mdANZ2DCA2sTFchvD7WwbffbLQa4jCFkkRL23WuYG8'),
  tokenIds: [Tokens.mndeToken.mint.toString(), Tokens.msolToken.mint.toString()],
  tokens: {
    [Tokens.mndeToken.mint.toString()]: {
      ...Tokens.mndeToken,
      addr: String('2LferrWvYWtHFfdkmixzt9g3aKa3yBNfgbRrP1CcWMMp')
    },
    [Tokens.msolToken.mint.toString()]: {
      ...Tokens.msolToken,
      addr: String('GimsuZjYqMXM6xK6S3e9JpGvX6jaMPuNeR6s2piDESmy')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const wagUsdcPool = Object.freeze({
  address: String('B76e3wtCDTKBgKQjvx87EBkDLPGcCY9w1SGiwjD5kaK7'),
  nonce: 254,
  authority: String('FRUmMZDiZrDrwioiUYi3tdqF7SEBeT219bBu54PGxoCo'),
  poolTokenMint: String('Df6XNHMF3uRVZnz7LCEGiZVax6rXgz76owtVkBHEjSb6'),
  poolTokenDecimals: 6,
  feeAccount: String('BCuRKfsM99LJFCchKUBLBZ26UuziDewJDRkkKMwx2qnd'),
  tokenIds: [Tokens.wagToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.wagToken.mint.toString()]: {
      ...Tokens.wagToken,
      addr: String('8voSogytL9jLgE73GS3WuujBinKFRQJjvUFsVGYexWZd')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('HEP7mACuN13cT95eDAYTNjgwriqJnMQVhnyRctqnBRe4')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const msolUsdtPool = Object.freeze({
  address: String('Afofkb7JTc32rdpqiyc3RDmGF5s9N6W1ujcdYVfGZ5Je'),
  nonce: 251,
  authority: String('8vrC1FAnW6hQMwJuU5waZdRrBbDJTULqjpdc4GjDtKR6'),
  poolTokenMint: String('9cMWe4UYRPGAUUsTkjShJWVM7bk8DUBgxtwwH8asFJoV'),
  poolTokenDecimals: 6,
  feeAccount: String('7GPvi21QbwMyBoXU5Zqf8VhnuEh7VH4A1SRPgHJ36eE7'),
  tokenIds: [Tokens.msolToken.mint.toString(), Tokens.usdtToken.mint.toString()],
  tokens: {
    [Tokens.msolToken.mint.toString()]: {
      ...Tokens.msolToken,
      addr: String('RTXKRxghfWJpE344UG7UhKnCwN2Gyv6KnNSTFDnaASF')
    },
    [Tokens.usdtToken.mint.toString()]: {
      ...Tokens.usdtToken,
      addr: String('J15KntYr6iout4ce2kcD2QEdkVbLN4EHHFLfCtke3f6Y')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const msolWhethPool = Object.freeze({
  address: String('A71DQffTzgxBSzXjPL3tmf8XXTNtS5mR2D78Y8rmV2hk'),
  nonce: 250,
  authority: String('FPWpe7QEQnDMivnHksQW2uvcw9tvX1oxejKBX136WRkr'),
  poolTokenMint: String('5qoTq3qC4U7vFxo3iCzbXcaD1UEmDeCD63Dsuoct71oV'),
  poolTokenDecimals: 6,
  feeAccount: String('FSqUYVzF3XZzLcnj132eT6ed3bK95G1yG4MazcHZi99Q'),
  tokenIds: [Tokens.msolToken.mint.toString(), Tokens.whethToken.mint.toString()],
  tokens: {
    [Tokens.msolToken.mint.toString()]: {
      ...Tokens.msolToken,
      addr: String('Fcp5u8bL3V24MXjA4noSfMpcEAP2vSj1WTaA1ZNxACZL')
    },
    [Tokens.whethToken.mint.toString()]: {
      ...Tokens.whethToken,
      addr: String('DuBCBX3y2FjDWUn2ncK5EKQh229JiJ7HTCjYJhNC87K8')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const btcMsolPool = Object.freeze({
  address: String('8DRw5wQE1pyg6RB1UwypGNFgb2Pzp2hpyDDNwo76Lcc8'),
  nonce: 255,
  authority: String('3X1aLdyvcQNc8TvBMPiucMsRCnGMBnGsjJHpZEyCf3pn'),
  poolTokenMint: String('8nKJ4z9FSw6wrVZKASqBiS9DS1CiNsRnqwCCKVQjqdkB'),
  poolTokenDecimals: 6,
  feeAccount: String('AqiLHbUAy4UWWKGVVgbHsaUVCMg1zemNkgsYBPSirT92'),
  tokenIds: [Tokens.btcToken.mint.toString(), Tokens.msolToken.mint.toString()],
  tokens: {
    [Tokens.btcToken.mint.toString()]: {
      ...Tokens.btcToken,
      addr: String('6D3sxC6yEe84FUnF5Kpbgx6gN57N9poJCKAtrCeCWdJo')
    },
    [Tokens.msolToken.mint.toString()]: {
      ...Tokens.msolToken,
      addr: String('EPoVJLhi9QtVPVo8n31M5k5Knvb48j8zbYyRrUbrHwC5')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const ivnSolPool = Object.freeze({
  address: String('CFCivUWXBuULVNfJezj1fAhX6hdwVFi2BsCtpu6m96bR'),
  nonce: 255,
  authority: String('JGhNs5r7YNnJokzzXZWE3REKV8x4GiUvn2xSg7XGg59'),
  poolTokenMint: String('DfgCnzaiTXfPkAH1C1Z441b5MzjjTCEh134ioxqRZxYf'),
  poolTokenDecimals: 6,
  feeAccount: String('HwwgrSjJGFBtRN8h2daWnVLBciwoo79wNeKi6b5SZmE2'),
  tokenIds: [Tokens.ivnToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.ivnToken.mint.toString()]: {
      ...Tokens.ivnToken,
      addr: String('C5yDeB3jBz5yZPa6FgP6b7HNoFxLP63Pyzpaosnkikis')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('CCm846T6xj9VAhSAifuUJAXYCR3kaGp5KqhXFHCaeWUh')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const larixUsdcPool = Object.freeze({
  address: String('DaNULZAv2VyLk75pW3QD5szVzx5dT6iNvoih94Bttqf5'),
  nonce: 255,
  authority: String('FUVkG7fM3i8T49qV7WsJd68rBaYKvqTkAQCdftqTWWNj'),
  poolTokenMint: String('8sfThep3io4gvcGeuoAg1Rs8GDwKJjtcdAFHqQSSNAVE'),
  poolTokenDecimals: 6,
  feeAccount: String('AVb9Bvu4rjFYNCHygEnAYCjwnkgtC8C6UmJ7at3dsfdz'),
  tokenIds: [Tokens.larixToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.larixToken.mint.toString()]: {
      ...Tokens.larixToken,
      addr: String('AAjjSJsZM3AKK4h9cbGTHkquEZ2fWjgo9A9Pmrj2ynTH')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('82Fs8dSpMxPPfN1ULsXGFREHWz3JizREpTxwz2MaZ1n1')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const prtUsdcPool = Object.freeze({
  address: String('CRm5uRBsVmUVHVqKfCCvCUX1RLUkjfcBWAeMQ5D9VuCM'),
  nonce: 255,
  authority: String('A9eqdCXYys7jeoroMFEnkGLoYLa2q5gGbg8RSKHkR2ne'),
  poolTokenMint: String('6jCERp5hKj37PCXP3VTjCDJeoPuSpnMDMz5A6jWQv3yS'),
  poolTokenDecimals: 6,
  feeAccount: String('FHVidN2ZdGnVaCjYwLjLXrimPbVsaqsUEEiGcVZ6WAPq'),
  tokenIds: [Tokens.prtToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.prtToken.mint.toString()]: {
      ...Tokens.prtToken,
      addr: String('3oL2GjsUnQLjHw77p78CsRr7t94AVrtsCnW5uf6NYQ3g')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('2EiVwvsH5cvyk4W243zKoywkaEQb9Bwe9WGphRgBSqaP')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const jetUsdcPool = Object.freeze({
  address: String('ErWwp9HKjk5ZPLDt8SrHKH5PvSKTwFDdFo5E3zDuE5Be'),
  nonce: 253,
  authority: String('GYY1t5d4pZnJC4rMXGY9yKMyCzLqxRqbtSguD2KkxghH'),
  poolTokenMint: String('GBijunwxa4Ni3JmYC6q6zgaVhSUJU6hVX5qTyJDRpNTc'),
  poolTokenDecimals: 6,
  feeAccount: String('6NhybmW42rdWj5TcobNKQT6JaZispgngcfTDrCsgVq4Q'),
  tokenIds: [Tokens.jetToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.jetToken.mint.toString()]: {
      ...Tokens.jetToken,
      addr: String('GEtZSc8188t2cCAv21UGCyjvxCeyU5Co99GtRtyTkpdh')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('Bi95f8H7o7zHWuYysxDHEubPv4c3NhsHWhaesXJu91NC')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const stsolUsdcPool = Object.freeze({
  address: String('EfK84vYEKT1PoTJr6fBVKFbyA7ZoftfPo2LQPAJG1exL'),
  nonce: 252,
  authority: String('8PSN1CQxfyZ7T4sM3HM3RAgF2Y6VCf4tKSc8xY73Tnq5'),
  poolTokenMint: String('GtQ1NT7R5aaTiST7K6ZWdMhwDdFxsSFvVFhBo8vyHGAq'),
  poolTokenDecimals: 6,
  feeAccount: String('CJhL3UGesECFt6fvLB3csrGMuHf3M3G78pUzTopUiV8T'),
  tokenIds: [Tokens.stsolToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.stsolToken.mint.toString()]: {
      ...Tokens.stsolToken,
      addr: String('9SEBxqhP8sTAzmfiQfCPim1MqQXuDPb6fkGzJF7Z339i')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('G45yhM5mZ5RXZpLxGWLk3PVzdAp33z8aH6F9mLW8fQj3')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const wstethUsdcPool = Object.freeze({
  address: String('v51xWrRwmFVH6EKe8eZTjgK5E4uC2tzY5sVt5cHbrkG'),
  nonce: 254,
  authority: String('3Kk8rpjxpc9qv2pJPr1CbmyQqrTDPntpryXActLogQeD'),
  poolTokenMint: String('5a6Y1ephcbKSoyLMQyD1JWbtqawCy8p2FtRL9v3zhaG5'),
  poolTokenDecimals: 6,
  feeAccount: String('ACKiRmbiMaPEc73pz4dVMuJGPaa74Vx9sfYADjnHuzvo'),
  tokenIds: [Tokens.wstethToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.wstethToken.mint.toString()]: {
      ...Tokens.wstethToken,
      addr: String('5c4tzhRVaCxpmu8o3HrEZ8PWBDKSR6QNkBdQrUo9oe3e')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('AFNaWHH7ZGFjB7y7jmPM7jVs7QBAciffu7Z5tZidRHPR')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const auryUsdcPool = Object.freeze({
  address: String('6HSguUukDH9zqJBm6oAAmFkg1WK9dJ5iLgnppTCM6jHm'),
  nonce: 255,
  authority: String('9T1koZp2PNJgspcx3G22yLiChBUfYzAjs2dhj2kgw2LZ'),
  poolTokenMint: String('6mJqqT5TMgveDvxzBt3hrjGkPV5VAj7tacxFCT3GebXh'),
  poolTokenDecimals: 6,
  feeAccount: String('JCqbv7r3mtYhzruNFjc21X14fndDVBLMiaNQrsHVpWui'),
  tokenIds: [Tokens.auryToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.auryToken.mint.toString()]: {
      ...Tokens.auryToken,
      addr: String('413s6jiRbayD9didA4VnY8kQVgVBgkYNpYB2tyNf8sbh')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('GdPeogNxRWAZtUj7ZHc7fUpBuGHJosdbukiT2krFtXm8')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const avaxUsdcPool = Object.freeze({
  address: String('7c2CLgatf2TU36PgpS65WLmvWk94rmaHVf1Z1peZ7mcA'),
  nonce: 251,
  authority: String('Mq46N9EknnxHL9fRkJhS4Eg9YXRifHiWzFJTD11ePWC'),
  poolTokenMint: String('Hmfrtmo93DpSDmVNLQKcBS5D1ia5JatiRSok9ososubz'),
  poolTokenDecimals: 6,
  feeAccount: String('7JH76Kw4dHyC5szRXkx6MFkJ3BEViodfNy15uFJst1cX'),
  tokenIds: [Tokens.avaxToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.avaxToken.mint.toString()]: {
      ...Tokens.avaxToken,
      addr: String('5rU6M2jAXQMSmgrsn14BPoVVhoBdCU6y5cP7XMjN4ZYy')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('D28rzq246bcXBrYiCeALY86y8NwvCUmuJGNggvKsh4WR')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const fttUsdcPool = Object.freeze({
  address: String('8npdwWX2BR39kcFLtTJABbcjNq7NWQvipfqxgsfk9mTX'),
  nonce: 255,
  authority: String('8zU13KiLb1e87skt4rf8q1LhamEKKecyu6Xxb4Hqnm7e'),
  poolTokenMint: String('FwCombynV2fTVizxPCNA2oZKoWXLZgdJThjE4Xv9sjxc'),
  poolTokenDecimals: 6,
  feeAccount: String('C8D52rGuZcsBENhWtR9aqJVRU62cL7jyyEhxesKwc1k8'),
  tokenIds: [Tokens.fttToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.fttToken.mint.toString()]: {
      ...Tokens.fttToken,
      addr: String('SasuKsATA2ATrMfFfSJr86wAGVgdS69PkQT3jFASBB8')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('3wADiuUqoakdoYYYxKqwoA4VN3uWZy5UwvLePox1mEsK')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const rayUsdcPool = Object.freeze({
  address: String('2R2VhohRc5WoNHtRdwnjovAQaZRAmr1DE3QFW5jfgb6v'),
  nonce: 252,
  authority: String('9B9ZcYT8jDQ6XLe6gRLDCFv1zz3uHVKdbZT9DFhsYSQW'),
  poolTokenMint: String('4cXw2MYj94TFBXLL73fEpMCr8DPrW68JvrV8mzWgktbD'),
  poolTokenDecimals: 6,
  feeAccount: String('HURhvCRsrwwR5TiG75Hn274WwL76kaKgjgC6n9h4FEHj'),
  tokenIds: [Tokens.rayToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.rayToken.mint.toString()]: {
      ...Tokens.rayToken,
      addr: String('9ASj9zDg7cT6wtvn4euSUiZte8yN2U3Tn6cTVZvMHbU7')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('HGTxSWbb62nxk4oGkLkHUvrEzR5D4GKYRb8ZDcA2dpki')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const slndUsdcPool = Object.freeze({
  address: String('GhosXH9yZPxqSyTHqJtXQt6w65YfiGjKXcEXciX1P3z8'),
  nonce: 255,
  authority: String('ChmSHndtXRsYnFjYA2F7yRRsnyZ8kCpxSogTsCUgCEsh'),
  poolTokenMint: String('F59gkD7NnsdJbFKrRZsiBC8PAooN4c56T8QmahfW1iXN'),
  poolTokenDecimals: 6,
  feeAccount: String('GMipxN5pu6F6wwUrq6RhpqgcMjcKLTsnDTeNFCuUm5n7'),
  tokenIds: [Tokens.slndToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.slndToken.mint.toString()]: {
      ...Tokens.slndToken,
      addr: String('9RcdfprKxbTzp3erTJMwXKznNCLmbCUaKhibaTMXhToi')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('6wEh8r3Czc3nKkN6JXobShnLG7ZqA5Y5DREGzkirYR36')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const gofxUsdcPool = Object.freeze({
  address: String('C3b5AWQJiyar5g8EWu75zgDE26F55ZJWpqtFVCCVDQQQ'),
  nonce: 253,
  authority: String('3SphkwoHx3d13Eu9RehVVg4gGMZv7FEaDXvPqWbQF9bm'),
  poolTokenMint: String('7vnps4VE5RTGAr5fmPZu7fSrk2VnM4Up838grZfqmxqE'),
  poolTokenDecimals: 6,
  feeAccount: String('CT95CSNqi4nttNW84dDuA8Um7FLAC52PVUvuVRKeCHVK'),
  tokenIds: [Tokens.gofxToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.gofxToken.mint.toString()]: {
      ...Tokens.gofxToken,
      addr: String('5AhPVbtyiTV3SiNRJuq5z9xeaqqwoHQWqohR9HvjJkKS')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('6mtcbtTAadVEdnWJZmsq8woqLea7ef7k5WumVXSHr5KQ')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const woofUsdcPool = Object.freeze({
  address: String('HY6iq1dp7pHwstfmLFu8m2iEvRQtjX3N751jcN6KrQXU'),
  nonce: 255,
  authority: String('8JuHyxYBpemmryQq4bcA1GTuKaub9JJuLPoNNJpwX9aP'),
  poolTokenMint: String('9EjcYfHcG8f1mccpHyaAwpoxaUPiheC6KgLQjyD9aTb6'),
  poolTokenDecimals: 6,
  feeAccount: String('886Yu2fd1x8xpb4icdrhxZemdD8PN9RLdrq6Mn89wR4k'),
  tokenIds: [Tokens.woofToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.woofToken.mint.toString()]: {
      ...Tokens.woofToken,
      addr: String('8ju56eRfs9wg3GtoSFVoQoR6NnQn6gsWr6FkQyZ1EeBg')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('5WcqVktXr3Rr78MGGfcuwgB6azoN9EDCZLP4werGnfi4')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const sdogeUsdcPool = Object.freeze({
  address: String('8GoFKVkoxPyQKWXhoXs3ycGmp9rXYM8hz7eq1EUM1MJe'),
  nonce: 252,
  authority: String('DSuxdCkHQy6rAjFsL75tRu2UpHVQDoYsYFeZt15sVDYL'),
  poolTokenMint: String('CHTKUJGYRtBDqnxCFjxe5KEkZgxV98udbhuYYyzGxup5'),
  poolTokenDecimals: 6,
  feeAccount: String('2gCRgQEySFTXA5v99vRUJHAGhQPTPtHx16Rdgipz7Q1y'),
  tokenIds: [Tokens.sdogeToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.sdogeToken.mint.toString()]: {
      ...Tokens.sdogeToken,
      addr: String('ABQfmExcxJt2wE9JeLUxvhkpA2VkoQVvWKDvfusjq226')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('EDBXkzwxu6UXqBDnzb9AJ6Eggjyyct7SmYVoR8PYJkHd')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const catoUsdcPool = Object.freeze({
  address: String('GumfURfQvPaJ2E5ueCEKYJmymNQbV34gU9TmiKZYRkiv'),
  nonce: 255,
  authority: String('EjoLNSDggfWWE7BxwoL4tJHBEg1cFpdiyKeYTYCec2o2'),
  poolTokenMint: String('55r9txzQtmjTykmTXmBYZCVMg5z9squB8b5cSw2AhxA4'),
  poolTokenDecimals: 6,
  feeAccount: String('F6xCTe256cA6HTX5CYBkDtXoruHvjfbxeHNeqR9kR7oJ'),
  tokenIds: [Tokens.catoToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.catoToken.mint.toString()]: {
      ...Tokens.catoToken,
      addr: String('AGNHgSQuPd4EqjLTLJrXEVb3KCkjRxGVDTaag4drV1XX')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('FESKk2kj9oqdYR4dcaP4LyqDyWZt3NttgypRVFoyUQNs')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const oogiUsdcPool = Object.freeze({
  address: String('9tK2LaapwjxaUmfcAzY9zgC39M3wnaFX558y2Bb4oxWG'),
  nonce: 255,
  authority: String('EGuBsx6HAgAtf1ogzF1uXTUQgwRex61hnhvuZcMwQKUJ'),
  poolTokenMint: String('DSiHyHDn96bUQSZtizyCRLcQzrwohZeMpVu8rYJN1HzG'),
  poolTokenDecimals: 6,
  feeAccount: String('Acom6ebnmbFKQk3XeX5VHPiz8bd7kzfpUMsqHKJDJnry'),
  tokenIds: [Tokens.oogiToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.oogiToken.mint.toString()]: {
      ...Tokens.oogiToken,
      addr: String('GgfTGZ5DnAotnXKFM86vqffKQZ9nGgHaX1PDS7RTcKjQ')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('A3rzsPGtqowjKXfscYrPo1jvv2EVYpJwXQPGKxgvvStf')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const sonarUsdcPool = Object.freeze({
  address: String('Dzp3ZWyUZGXZYWfodXdLdXfiW7gwVZBdWBwQ28eB6cTH'),
  nonce: 253,
  authority: String('3HJw5YCXr69DzUcLSif4BicrMrXjC2jrEMpjQhQ3AwmB'),
  poolTokenMint: String('5MvQHx8eftU39JTucFsT315JFnQASuDQg3FqxTw7xcvN'),
  poolTokenDecimals: 6,
  feeAccount: String('J8tqP1N2fEgMTGhX2PTgRyj5ZFsFWsXneJpXyRjekKWA'),
  tokenIds: [Tokens.sonarToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.sonarToken.mint.toString()]: {
      ...Tokens.sonarToken,
      addr: String('DxGfntMLqAjtB287GkPPcfotUGcWdhtpzR6KukdBo39H')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('EVymxW4gFtxw7qjMYGFq4EupxHc1RteffwETa5MKTDPR')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const aptUsdcPool = Object.freeze({
  address: String('Fg3UabVqnfycMtkiTVoaia9eNafehtT9Y4TicH2iBtvK'),
  nonce: 255,
  authority: String('JDEYn1JsacdxoB4v4mbctFSVrSUPttacX3gxWphFHJKZ'),
  poolTokenMint: String('HNrYngS1eoqkjWro9D3Y5Z9sWBDzPNK2tX4rfV2Up177'),
  poolTokenDecimals: 6,
  feeAccount: String('41H5mWwsZKewJeV4wWiNjQ3U4VYBnwqCpzvAWt86baHd'),
  tokenIds: [Tokens.aptToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.aptToken.mint.toString()]: {
      ...Tokens.aptToken,
      addr: String('636crNdZTf46gFUKuedaBCZDBMLahf7KGud2LyTMskU5')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('DGEYFkEHyiuHWtHeCGiQGn1JbkGHqYrNwaP44miRbgxu')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const dflUsdcPool = Object.freeze({
  address: String('59wJQz25rr38YnD69eXsntbdE4LB7m39KjHqZUUaoyZ3'),
  nonce: 255,
  authority: String('E2kEN96wSW5bYML8qYLgxKM4pvdLjqhCRMyCvqYyVrXt'),
  poolTokenMint: String('AWrtTWG4Zgxw8D92bb3L3sQtGLD3zDztMPWsXSph8iBP'),
  poolTokenDecimals: 6,
  feeAccount: String('bZxTKZNF5gwnuXQ3xt8huUtsLAyFoh2NnQwmDDgbVxd'),
  tokenIds: [Tokens.dflToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.dflToken.mint.toString()]: {
      ...Tokens.dflToken,
      addr: String('EXZdhy56TjvArQRfDakjuQdT7DffwFEYdY5F39vn3Wbr')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('Bhch1NY65RNA8uFaj2s8Fzxu36SFMQiniyZidq4jzsTt')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const dflSolPool = Object.freeze({
  address: String('GxkXvW6dJP3KUNWuXi64V99uRsPFEFnuuasNTq6fvZfP'),
  nonce: 255,
  authority: String('H196f95DHGfhNkF3W9h4bjBqpL8g3qesEENHRsDqJshj'),
  poolTokenMint: String('9Y1vPaAsMz8X65DebMMnmBjbMo8i4jh4mcgiggZUUS3M'),
  poolTokenDecimals: 6,
  feeAccount: String('7L7kjeEo5oE9SCZ9vacmyJvfKKmjAevVpdktBGyxBxaF'),
  tokenIds: [Tokens.dflToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.dflToken.mint.toString()]: {
      ...Tokens.dflToken,
      addr: String('BpjpdhuMkPPW3HPx4U9tRmNAXdUeb49jxwFGy2PrSNCp')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('HcXqJthqq1UeGHA8xPeDLnWVJFcndoUnKnpULRCjyTzi')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const frktUsdcPool = Object.freeze({
  address: String('DXNct3MfHDuhKiXHY3AUXPtj5sQVY3feuDxkDRfCw1rL'),
  nonce: 254,
  authority: String('JBCdhgkmW6xHdZUZdKdf56qov9LtHixf47JpoCWm4qcn'),
  poolTokenMint: String('FnDxJPNk7pPmGHUbR4XUHmHevrkXHdna5D3sQKcAtjBL'),
  poolTokenDecimals: 6,
  feeAccount: String('DRn2F5MsWzLihtqsheuJmugJuHQGDR23cvqCLy7eCucw'),
  tokenIds: [Tokens.frktToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.frktToken.mint.toString()]: {
      ...Tokens.frktToken,
      addr: String('AUSeFBvVtLiZRppRu8Uvd8NjzCkQzaCi5Si8WWyko9p')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('C3RzSoNiBfTNbAZMyyuV2aLfwGQRyjvkjL3pf9iuvU3R')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const tttUsdcPool = Object.freeze({
  address: String('HD6pqxyqj4S7eS3orAyQyan5RpwpMPjKEDWgTyYXcfr1'),
  nonce: 254,
  authority: String('Gh1QqgpKGd6zhicNvBh1VLbycpoq6P7QBCZT8MdhWzN1'),
  poolTokenMint: String('FGgP1npQTsC5Q4xBmQtNYSh51NKqNwdxBZy8JCo3igcu'),
  poolTokenDecimals: 6,
  feeAccount: String('AFjaSrdgNJsFDSQTDbkBoBMLHt2qCt5GuPHWDTUV4nHt'),
  tokenIds: [Tokens.tttToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.tttToken.mint.toString()]: {
      ...Tokens.tttToken,
      addr: String('HbrvwSnL11K5EALN21FdjmWvG16VNcq5iyHEKjdHtyJe')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('CQvyngoqxCdgFQYjbT8EKved6hTDzmzdp8Xxxv5FkXmd')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const upsUsdcPool = Object.freeze({
  address: String('G67ZSkxE5N67x6A1WhcTaHiFHqRtRQUb3QHwjtPge1Xr'),
  nonce: 250,
  authority: String('Hj3vg6P2DBfJ6hhvCoucaGvL9cwsvUgeJWZRzuXTZ6hi'),
  poolTokenMint: String('E1U63VXhNiWoUkVvjrfLDdV1oJrwE6zLde3bohr6jCqz'),
  poolTokenDecimals: 6,
  feeAccount: String('Cu8SFyZKsHcUYhxECpt5Wr97CeHSXeDyNjs9kyDWtVJm'),
  tokenIds: [Tokens.upsToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.upsToken.mint.toString()]: {
      ...Tokens.upsToken,
      addr: String('CNw37MJdV6gBChtEtUgGrx3zWXz8t3bYxD7u5axPrwdJ')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('5iLVx3LwhWiqjaRyLaiVNsLbYBqy48fvs2sTY6q96nNy')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const fantUsdcPool = Object.freeze({
  address: String('95aDcNzL8tL17jzHAUQXnRwuCmYBWKUr4xfjV6AMVJY1'),
  nonce: 255,
  authority: String('GeTZHdp1sYgX6J73CBeqS5pjb7xfY8D9Rzf4E615oGMt'),
  poolTokenMint: String('GjG7JjTQfQpDxw4hWx4etP9oTaYCuCbPjsU8WaUT3xHB'),
  poolTokenDecimals: 6,
  feeAccount: String('AdSQYnzmJ4j2hbqT5aumXAf4a3T2AxmGHbCgHER8rf6K'),
  tokenIds: [Tokens.fantToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.fantToken.mint.toString()]: {
      ...Tokens.fantToken,
      addr: String('CyLaJ6Y382czGE75yFDg2fneKni6PjZHH9Jh4GZN2Nyo')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('7wHMKRPVsacb3JcBsUxLKLZkAPbX81VNc6Z6J9uvJ2J8')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const blockUsdcPool = Object.freeze({
  address: String('ExeW62iy4xEUo5b5XnDru5oQCDRDUmBaFNqE4V4u7uvm'),
  nonce: 255,
  authority: String('2fSbre92FnmkeUP5iT7jdFKNB3nfx5yQ5GEW2mjVigMi'),
  poolTokenMint: String('D8WjqtwC9CzBrQKfSf2ccCHFQuPYwyLv5KAy8WjT5vnf'),
  poolTokenDecimals: 6,
  feeAccount: String('BGSDZoQyH8QGJDvQ8toms4Pk4HWHaFEXSUvJ3AAYXxmF'),
  tokenIds: [Tokens.blockToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.blockToken.mint.toString()]: {
      ...Tokens.blockToken,
      addr: String('7uJ5SFRGzrnbspUThix8M2wFJNnS8vvSKbCEhKmqMjNm')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('4e5ZVp7u6YWEibveHscZmbmHpujtbJNUHFj14tqtAZKv')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const runUsdcPool = Object.freeze({
  address: String('9j4FdbK68xUDQVynTWHbNzBRAj7Q7Lywiqb82en4JzZs'),
  nonce: 253,
  authority: String('9RUMuXequVtf9UVYHoKJxwWfqyod478nBfX8H3DvSaKV'),
  poolTokenMint: String('34Ppq6R8NfYBwWwPY4cBK4Afyb8hHaASQFukCzH6cV4n'),
  poolTokenDecimals: 6,
  feeAccount: String('pPH4mFHFu1c3ZR59Zaun5jSCBRn6pU1GWSoVHD4LKUs'),
  tokenIds: [Tokens.runToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.runToken.mint.toString()]: {
      ...Tokens.runToken,
      addr: String('tg7YMVac4S2ZpVrbaDqrZgURyTBBrZCQUkQ6Fn6nnrX')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('G2fZDZKa5Y599xzqP1jVQ3EMCfiG6f4dMS6G1f7BKoQe')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const uxpUsdcPool = Object.freeze({
  address: String('eKuTPdMHmnVzLP63oWwdUH6bBAVcdvBfg4zvH2nWWCj'),
  nonce: 255,
  authority: String('DYwCP16u5pmLMspCb8tdTQaaF2rBvM2qvgzm5uYvPUHT'),
  poolTokenMint: String('HjR8JgqNKQVMvdryqJw5RJ4PCE9WGk8sgbEF7S9S3obv'),
  poolTokenDecimals: 6,
  feeAccount: String('HoaPae92LswzSbvnVbdkBAYFNanrvK3kFvWtYyUhpC2o'),
  tokenIds: [Tokens.uxpToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.uxpToken.mint.toString()]: {
      ...Tokens.uxpToken,
      addr: String('BWL9ocg8VEBSfrS7zjUBUidE9T8p95J3p5wF6C4Vaqrs')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('GQC4fM5E6dr9MsXvtMzAR4Z8iCduhyFvYCLdMAAHGCM2')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const btcUsdcPool = Object.freeze({
  address: String('2dwHmCoAGxCXvTbLTMjqAhvEFAHWUt9kZaroJJJdmoD4'),
  nonce: 253,
  authority: String('BwJ1vMtJiBy7dJaVToR1KUwVbBsGUTNN4QdKVSf8EEh1'),
  poolTokenMint: String('J3kvcay3N16FBdawgnqoJ9v9p6XCvyCLE2Z9F5RLvGkj'),
  poolTokenDecimals: 6,
  feeAccount: String('HR7c67SkeLvCpHrVSu7MiiAERQh6iD1NrCJsj3kWiZnK'),
  tokenIds: [Tokens.btcToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.btcToken.mint.toString()]: {
      ...Tokens.btcToken,
      addr: String('D3Wv78j9STkfJx3vhzoCzpMZ4RqCg8oaTNGzi1rZpdJg')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('HMFLg2GtbWSSEe92Vuf2LQdUpCacGj2m2PwvMqzwQFNi')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const mndeUsdcPool = Object.freeze({
  address: String('2yNwARmTmc3NzYMETCZQjAE5GGCPgviH6hiBsxaeikTK'),
  nonce: 253,
  authority: String('2ZzxuHxvPQtPU48Tffs8LD8sy17rgjBRQviRVxQ54fR6'),
  poolTokenMint: String('12Uj74zgUUoBe4yeackwQ4qYtFMr9fk1xL6q5Nha6t2N'),
  poolTokenDecimals: 6,
  feeAccount: String('6AvWhLSuAqTWUdpo4jF84s4gfgAjbRQv5vLf7mYNCgd8'),
  tokenIds: [Tokens.mndeToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.mndeToken.mint.toString()]: {
      ...Tokens.mndeToken,
      addr: String('AfF3SJFpyfU7iw9KtrwPyi6corJbyiC24JyVis7oxNVr')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('FT4GQqs5sEvqhsWm845VF1vmHjdQkrB1jdsGPJbzB4oB')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const chicksUsdcPool = Object.freeze({
  address: String('3abwmh8LDUsXMoDgNzZLq9QvKqKbhYND9N5aXh9yz7Dm'),
  nonce: 255,
  authority: String('HmNqp8F5Bp16dUTUihhL3TnyQxfzJ2GtTo9VEQx6bjB3'),
  poolTokenMint: String('71CBZeJ4tw38L9pSPoCz4fRsuWE64Fipyzotte7haoCS'),
  poolTokenDecimals: 6,
  feeAccount: String('4zRJg9Kod7bd9tR3mxQhxzAQUPqQmUGJbmrWpQ4XDGT4'),
  tokenIds: [Tokens.chicksToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.chicksToken.mint.toString()]: {
      ...Tokens.chicksToken,
      addr: String('84Ha1dXH8YAWXt3AC15RstnUCoWT3hg24tUGD1GtqGSm')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('GkonWV6urvzsnKwVmPXe89fTX1zBfv4xrAhx9JnxV9Nq')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const onesolUsdcPool = Object.freeze({
  address: String('CKKuMECwW75NBNRKtYJz1svCycRHt4KpXmcNnHXiHY73'),
  nonce: 253,
  authority: String('2TSCMpjN6ToLJy8baiFTc9UdB1PuHFsr24mos98Zp57v'),
  poolTokenMint: String('6MF5CHWAj5mS7FhpxiKz37CzR2eYTu236XpBKKMXCrGg'),
  poolTokenDecimals: 6,
  feeAccount: String('9Rw29Mvx6b2RMAbdZoqs3gBRMcfYjBYRyiegNZoaYrWS'),
  tokenIds: [Tokens.onesolToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.onesolToken.mint.toString()]: {
      ...Tokens.onesolToken,
      addr: String('2meoejmWQno2q6cbFMRytu7UMqMA8FTx651feSUHfTtC')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('n5CtiZDLm2xNxVU1o4KC98dNGiFb1sbuCazDihahbMy')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const wmpUsdcPool = Object.freeze({
  address: String('Cqy7PRGRx2QeTLZM3QmLGN68uHxEd9euj2eEeeZWnuvQ'),
  nonce: 255,
  authority: String('9vkbzRnjd9qpmunBUHqS8Fnt9DUEX5akJPm9movtfNxZ'),
  poolTokenMint: String('HDgxKmiA8Pv82fNguhVeMkZqQkos2YksFPoP1KttWxX8'),
  poolTokenDecimals: 6,
  feeAccount: String('Bfr66xExhYijJzc732abX7znkFnj4v3otQDFbHaz7RkK'),
  tokenIds: [Tokens.wmpToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.wmpToken.mint.toString()]: {
      ...Tokens.wmpToken,
      addr: String('3WubbpV7W82H6FERR62Bd8Z4fXFGaihtBNqnT1zkGr6f')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('8MxcExnMniQEDs361cZseukEau9Rmd3CmrCXxasmNM1x')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const unqUsdcPool = Object.freeze({
  address: String('3a3G1RjJe826EB1toShvPPWnyi2jXR53HP4vYb936kwj'),
  nonce: 254,
  authority: String('94V4mU6bWzU86tdzFbBCjkFngqXvf5MwXKhVLgJA83vL'),
  poolTokenMint: String('2VuGzaMrDnDyZfYvDwSXk38s7M2wpud7LDY3dGA1J9sy'),
  poolTokenDecimals: 6,
  feeAccount: String('9iHGnquXmnJnSATN4ijGj7Niz4StxXEMC8KqB1pT51uW'),
  tokenIds: [Tokens.unqToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.unqToken.mint.toString()]: {
      ...Tokens.unqToken,
      addr: String('Hf4gYkKNAPhm2RYrXrcP9YVL44VbYnsRsyUuBnZGzqPr')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('BZ9oTLGDL6tsvoooQ9qEBSM3sYKJB1v2e9b4en5ix1Mp')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const basisUsdcPool = Object.freeze({
  address: String('9wb29L97MmPp7Nw8oaqiAGkXceETGNQurhySiUNLv5wh'),
  nonce: 251,
  authority: String('786ezhfHqkmJUBmjrWYGpzPnVWR8zhy2V71qNws7D89z'),
  poolTokenMint: String('GoaAiajubRgeCFEz9L6mLnSmT2QFegoJDH5tpLfivpj'),
  poolTokenDecimals: 6,
  feeAccount: String('4FjEd37W9FExXq85nLeuNWuhUaTwkFdnqewt3E3qoYAh'),
  tokenIds: [Tokens.basisToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.basisToken.mint.toString()]: {
      ...Tokens.basisToken,
      addr: String('7QM71YvJm86bN9RLFoEvyDX8dBgLh2xjnabcHf4d1Q1y')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('AmP22dYrTsG2LrkQX9cLg79jUrzDJcmWqGEWmM3Mdn46')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const gstUsdcPool = Object.freeze({
  address: String('87E4KtN7F4LivKhjqXaoQAvS3a8HnM4DnMUrbMrkVvXY'),
  nonce: 244,
  authority: String('CwwMfXPXfRT5H5JUatpBctASRGhKW2SqLWWGU3eX5Zgo'),
  poolTokenMint: String('E6FUnQHGHJVJg7oExVr5Moeaj1QpdpZQF5odYjHXWPZb'),
  poolTokenDecimals: 6,
  feeAccount: String('BynpQprCNjcY2KHeffDKzquyKWvJxikty3donrMT4ZPU'),
  tokenIds: [Tokens.gstToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.gstToken.mint.toString()]: {
      ...Tokens.gstToken,
      addr: String('9r39vqrJuubgafaJ5aQyDWYAUQVJeyZyveBXeRqp7xev')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('7LFnr5YgUyEgPMCLGNQ9N7wM5MFRNqCuRawLZTe5q4c7')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const meanUsdcPool = Object.freeze({
  address: String('4jhCSYuGkLCiB8uvCaS34QCtPycBjayxEk27YCartTuW'),
  nonce: 255,
  authority: String('Fawkj9g4s1M26uZDF6QczEviyib2Ge3FaPdCG9TJLYcm'),
  poolTokenMint: String('F5BTnwuMA6rxftTdbZ33VWKr2wrr6DuQHnd4guKmPSYQ'),
  poolTokenDecimals: 6,
  feeAccount: String('EqhWQbxsa36o7YSrCNvJTV1yoTRTWxCiLKcG9pzafprc'),
  tokenIds: [Tokens.meanToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.meanToken.mint.toString()]: {
      ...Tokens.meanToken,
      addr: String('5KSCuA99i7PSF6KvvbvV5hDveGse7isqF6e7tFXPeMyn')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('13PtHDhQzswoFWg8Gr4yVrTmUEW4CWxeKFkLVERCmypB')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const aartUsdcPool = Object.freeze({
  address: String('8wVYTnchy3WbhJvKn4NhTbgtVm6Bfd6yvxR33DsSftse'),
  nonce: 255,
  authority: String('GAWAD19LVUgKmkWWfB4Xg92KMXuVR7M1gUpQPiFAAtxR'),
  poolTokenMint: String('HCtyJzFUtYecXrA52s4Y9atq4J1fhT3cYsTX17XVSFag'),
  poolTokenDecimals: 6,
  feeAccount: String('FmZkn9xAwpZULmF6rSrcz1pHiqqNnAXCUFWs1z5t9LSX'),
  tokenIds: [Tokens.aartToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.aartToken.mint.toString()]: {
      ...Tokens.aartToken,
      addr: String('Hy3FDHpTfzqtooUARjgUvYuRcFnjGH5WLftu77DyEnKJ')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('J4Cd9u1WuY1WnCScENuRyL2jGh7qD6vpRsWJG6ZsC5z9')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const shdwUsdcPool = Object.freeze({
  address: String('25bQ6UzZpgFgnU7MqZdqM9Axi6oJunytRL2LgXruDWZB'),
  nonce: 254,
  authority: String('BjnfpyU3Verx99dKcEJZpL1AqLTPrkAUcd44LpXcXVvn'),
  poolTokenMint: String('DJqqvzSuPaWThfzwMjXx7H2ZmHDdwxza6NtFudtuXcpc'),
  poolTokenDecimals: 6,
  feeAccount: String('9wmHbXURZ4zTPSj1KqoRSCdBRGUF7jrURzf7BB39cxM4'),
  tokenIds: [Tokens.shdwToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.shdwToken.mint.toString()]: {
      ...Tokens.shdwToken,
      addr: String('8ZVaNyNZQkcMzF7esuZoRgRo7Rc9eKEN18v4zw7Ng8JZ')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('H8A2xivBXr1RMCYmuhJ7dyEXJqPxaGDyQaaim8WucU7c')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const shdwSolPool = Object.freeze({
  address: String('E3fxkJGNNAWf5xXDfMdq5qofBVkQtLKxkP7gG6Up21Ts'),
  nonce: 253,
  authority: String('ByC5idkRdo2XdU5U6tSoSQmfq6spztUYMaSs2rrcJRPh'),
  poolTokenMint: String('2ws7g3LBPdctfKn42Di9qxzQtUJ8ZL1aEAX2rGEQMNqh'),
  poolTokenDecimals: 6,
  feeAccount: String('G9HR4sFJufdUovMGn4qc97r7fhgJCkTDnn4BT2wPWYar'),
  tokenIds: [Tokens.shdwToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.shdwToken.mint.toString()]: {
      ...Tokens.shdwToken,
      addr: String('9LQEB2SZQJxtLQStgXVNzgWU3LVkc4szK22iDHcSr4K9')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('F2qtMkEy3L78wpw64bckvRx5M4w12Zi7bimuPBnYzto1')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const scyUsdcPool = Object.freeze({
  address: String('Ci2T7KY8xXc3NmSr4JmbhZ2V7SfjZ7Fn2q6VxJsDD1Dg'),
  nonce: 255,
  authority: String('fmoBkLez7cSLnG1XNn6PTf6UH5Vq2hSAPrZ1NbvRppL'),
  poolTokenMint: String('99ZHUQsgxL7K6PHrGNi1gSwawwPr7UA5fbWrYoHQ6qhX'),
  poolTokenDecimals: 6,
  feeAccount: String('HHhxxFKyVt4xrYnJqAiywHCzuoCu45S4itkLKvop2fAe'),
  tokenIds: [Tokens.scyToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.scyToken.mint.toString()]: {
      ...Tokens.scyToken,
      addr: String('Hfa6kPY27pgnbXHMd1bKBaYMBoF8RRxUm2AmWHBWudcn')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('CYKEhyhe9JZqLzaM29o8urMu8riDNBE6ycZP6bLQtkbF')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const slcUsdcPool = Object.freeze({
  address: String('ByfDbpLVNVQHzU6E5dmLE1kcAKWAYRr9LPSGcVxxSRFF'),
  nonce: 255,
  authority: String('7G6i4eQxiJvXg7hPn5kMrso8AHQNsdfPZZdJM3NNJuYz'),
  poolTokenMint: String('E5kSBqTDxFLbLNQaVVtPtnhEYVLMCK2fVSEKoMKL98qR'),
  poolTokenDecimals: 6,
  feeAccount: String('CtBP4cFDLzm3KQCQRKXwwsMgYG9f6hbwuUswUZBzYvvY'),
  tokenIds: [Tokens.slcToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.slcToken.mint.toString()]: {
      ...Tokens.slcToken,
      addr: String('EnvNSVK9JRQ5jmsz7nvbhYmR3MHSeMvB8Mf1e4VwoyRe')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('CHuUSo3EMCqtGWvUGRj4crmDzV5YKdYvqrhHSEitAP8V')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const wustSolPool = Object.freeze({
  address: String('M3DfDvKZ9WEWYWcBuguvt3rYSbKqj66PgjW69DoEq9C'),
  nonce: 249,
  authority: String('5mYWcs7n1JqDVeYby897eEUNeEJsp2PNMg2Y2VMD5Jrf'),
  poolTokenMint: String('6c13xsmyk7UaHUWZ2rm1MM3ZdrQRSBkQ9waaG25ridVs'),
  poolTokenDecimals: 6,
  feeAccount: String('BSoUBwfNgXSHX8QoZUnjfu8wWKyBmjNyDXwsgW3UEff9'),
  tokenIds: [Tokens.wustToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.wustToken.mint.toString()]: {
      ...Tokens.wustToken,
      addr: String('3T7UL3ixF9S8XPjW7SEr9yYY8A5YWTDc7omgjKD2ULhb')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('91NfC8SunFRWF6BqEKnbnJCursfiDB2U3H6GGCqsFVxc')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const wustUsdcPool = Object.freeze({
  address: String('Awp1hdAi5xi3CTDsXxiZsygSacpZV2a3L8rNNvpNRVn4'),
  nonce: 255,
  authority: String('EtdLVaBnKzfKPqp8E2swCyQcjp8XjoT5FE72kqGxWCkq'),
  poolTokenMint: String('J1KfRtP5y2warpD7LdJhfBLPKoWwSqYuovdArSv1mpQ7'),
  poolTokenDecimals: 6,
  feeAccount: String('2onwniiH5fapr1EPsCDBnY92HGzoQpiHnNPWTmxngKbd'),
  tokenIds: [Tokens.wustToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.wustToken.mint.toString()]: {
      ...Tokens.wustToken,
      addr: String('GZWYWkbZ63teAW3tUaThq7k4NMp67TqwF94TqAMRSPx4')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('DMuVdnkRsqP7v8exbrdHNn7WoViNLjf4PVanEQtwgFZ6')
    }
  },
  curveType: CurveType.Stable,
  amp: 100,
  feeStructure: {
    traderFee: fromFraction(6, 10000),
    ownerFee: fromFraction(1, 10000)
  }
})

export const msolWustPool = Object.freeze({
  address: String('BpiM8jnpbha2TnTKp4AogyLYYA99Ks73GfDGueUWLakT'),
  nonce: 255,
  authority: String('DVSsnD9fid2o6nzqbET1LctDmNMHBthf8LS1u9ZPyebr'),
  poolTokenMint: String('68YVjgPnTUPcBqZyghqvD2WPNsrLKsjYTmBKJzHRr4qd'),
  poolTokenDecimals: 6,
  feeAccount: String('ETX7csSJYTMYFwLAC6oPDKXnYWdpkzsNXyG2JuxVV4tJ'),
  tokenIds: [Tokens.msolToken.mint.toString(), Tokens.wustToken.mint.toString()],
  tokens: {
    [Tokens.msolToken.mint.toString()]: {
      ...Tokens.msolToken,
      addr: String('8SuMMuDbSGVpWjp3fW5kzpMWuMhxcdQwGgLJiviP25UD')
    },
    [Tokens.wustToken.mint.toString()]: {
      ...Tokens.wustToken,
      addr: String('FL5QM8K2AYrqN52ssqr9GA3goYGkUiXQQxXfFFc9xD4f')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const wlunaWustPool = Object.freeze({
  address: String('C9NcP1mx51DLZVX8VtAk2Ldip5v6xCxZGmhx7Bxtqnz8'),
  nonce: 255,
  authority: String('CAsASHduARHrb6pFFYrD9vcqXWhEEj7Z6AatKYVG3dbA'),
  poolTokenMint: String('8Mh7drLbt3jFJYwp948XyvQscGLaLkChNcaH5wwaAoWA'),
  poolTokenDecimals: 6,
  feeAccount: String('J43pVTNEB3AQEeXopMWTEozjPeMHBkwETXQWr4YZDzn3'),
  tokenIds: [Tokens.wlunaToken.mint.toString(), Tokens.wustToken.mint.toString()],
  tokens: {
    [Tokens.wlunaToken.mint.toString()]: {
      ...Tokens.wlunaToken,
      addr: String('97aVH3M9cjZmfA5HVu9iJ9AnE41inBG1Sxqj8UQhR2jo')
    },
    [Tokens.wustToken.mint.toString()]: {
      ...Tokens.wustToken,
      addr: String('Av3RXECkvPCehJTJNbkWmUj3LmP63c4btzMYjbcYLkpm')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const stsolWustPool = Object.freeze({
  address: String('9F3J6RY7PTkDb3SUUpg725uXyCceBGCpZrtmYGJwgMwF'),
  nonce: 253,
  authority: String('wJydc21tAMxYDif8uvy5rWNGWDFNZnPPmqCvegyZRod'),
  poolTokenMint: String('HTZd53fYwYQRyAjiaPsZy9Gf41gobFdqkF4oKe3XLi95'),
  poolTokenDecimals: 6,
  feeAccount: String('5rCbmppxMBHwBjCkLUP6fireQ12cL8LRa26QRUimoxN6'),
  tokenIds: [Tokens.stsolToken.mint.toString(), Tokens.wustToken.mint.toString()],
  tokens: {
    [Tokens.stsolToken.mint.toString()]: {
      ...Tokens.stsolToken,
      addr: String('GFso9SAGakm8ZFa3rmuonuerbcQ8ZbACNZN7idkKR5nw')
    },
    [Tokens.wustToken.mint.toString()]: {
      ...Tokens.wustToken,
      addr: String('EZ7pJskN2a4pDknrdkLzGDHpzjbfgdBj3Tt594K9HZbL')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const jsolUsdcPool = Object.freeze({
  address: String('5pki97N9a6yEB3YQL3y8gvf4nSWnvEL9V6yms8Amnd9Z'),
  nonce: 255,
  authority: String('BqBc47X3wgrVzi8zPUMUvsaKWsj6JN4wKJ4ArGRKn2QG'),
  poolTokenMint: String('AzEoVuNJyo9ByoLRZ5t6vav2Zg24vULNVJM41PgCKUqR'),
  poolTokenDecimals: 6,
  feeAccount: String('9Qkje8PCpu4LrGwpudcN3sunWLVP6ZP61zNupaPKNRnr'),
  tokenIds: [Tokens.jsolToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.jsolToken.mint.toString()]: {
      ...Tokens.jsolToken,
      addr: String('D4Am1hMwzQqULAvmEjwJLDGwoyTgakRXrn7ayWHMhiB7')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('GznbQjBy2KKYYVLaJ6bM59GgekRsKT5QRi2NFMYNRcmf')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const daosolUsdcPool = Object.freeze({
  address: String('6TwZgp6ABN7pbXbxvaZriHbbxeSAcEgxhKYnqGwphiRD'),
  nonce: 255,
  authority: String('5jFXPUF5mN83WZkT5MJQFpFcZrP5zPZy1ZZwD29Le2Kh'),
  poolTokenMint: String('CCyDxjdW3G7hPTthTMPTZ4bnhFF19XG6rx2fNiKeRQww'),
  poolTokenDecimals: 6,
  feeAccount: String('JCnNHppa7kp9bRc3zfvfnwGqoVR8Li6sJT9pMztgwE9j'),
  tokenIds: [Tokens.daosolToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.daosolToken.mint.toString()]: {
      ...Tokens.daosolToken,
      addr: String('EZf2tVoxVgGaBz6ir5iBppxiqFSqBUob2dNpiEuXMYiJ')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('3v29nJzrWebKv3DefSY5WVq4kgHWykF7fdYVaCpohwks')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const orcaUsdtPool = Object.freeze({
  address: String('4YnaUPeZ2fYqpoLrCyprSai8LaDWZxmgb6cGfNHJmyP6'),
  nonce: 255,
  authority: String('35d4qNQiMtNV9Yb2kqk4tSd5M1yEqozWSQaYtjGHCA7b'),
  poolTokenMint: String('Gx4PoxenyQwhGGnKagAT35iVg4im1iKhJxDWqVhgu6tk'),
  poolTokenDecimals: 6,
  feeAccount: String('ABuGLBFTwcbLsqNsiCpZsFuNmHYwHEzfQR9SNpr4y3bP'),
  tokenIds: [Tokens.orcaToken.mint.toString(), Tokens.usdtToken.mint.toString()],
  tokens: {
    [Tokens.orcaToken.mint.toString()]: {
      ...Tokens.orcaToken,
      addr: String('D91z1TewbTQ3AVc9RThtTjiMVRqr94CP73XrsbHEhej8')
    },
    [Tokens.usdtToken.mint.toString()]: {
      ...Tokens.usdtToken,
      addr: String('Fy4AwXwAwitVdFwgWBdYkqRVv8QjigU86ssL6rqzgp8S')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const orcaWhethPool = Object.freeze({
  address: String('D67u6UEuFp9Q4Fu2gnu5thxBoVj2eRgDDDirdWsMVsgm'),
  nonce: 255,
  authority: String('4kwchkCnCaZPkBdnk85j9bJ9F1P883rwjbvsXGV7H5rM'),
  poolTokenMint: String('GsfyYHkSgC3Ta6aWR9MjB2sxoBrkGGeR2tAwXbpphf3'),
  poolTokenDecimals: 6,
  feeAccount: String('GdHXLai5M8FKedXUsECSzrV72nb1RTCCooFyTWT8WYmT'),
  tokenIds: [Tokens.orcaToken.mint.toString(), Tokens.whethToken.mint.toString()],
  tokens: {
    [Tokens.orcaToken.mint.toString()]: {
      ...Tokens.orcaToken,
      addr: String('EMxDw1NjdddQjPk9gCUN4iDMy8HJ5siKYMVjGbjuUCrh')
    },
    [Tokens.whethToken.mint.toString()]: {
      ...Tokens.whethToken,
      addr: String('DQyhcuEvE1K9DSd51agcNLg4CDrgthnKMVhU7q6R55LV')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const geneUsdcPool = Object.freeze({
  address: String('PQ1sBpkZYTkXo2aDRqWU2sEJGUj7yaeJ3GePoCRYU4V'),
  nonce: 253,
  authority: String('DvbTrW3tUfdgBDU5TFxfXjBpGUYivQP8MiSA5YmXHZGF'),
  poolTokenMint: String('7cuu94swKL5PtFQohKMAzyd1mjj65rgMW3GzLY31HCnK'),
  poolTokenDecimals: 6,
  feeAccount: String('AsR18ERwBhmTkjNpav9NDWdeUzvmDH2TXCwfy2kinat'),
  tokenIds: [Tokens.geneToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.geneToken.mint.toString()]: {
      ...Tokens.geneToken,
      addr: String('LiM9qAQi7ud4fCkAcGcJJzmY47od7vzzeqKBejATbJm')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('APQyCmCvyVFreK2JjFHinmja24jTHdwm4umNJo67Nu2v')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const cmfiUsdcPool = Object.freeze({
  address: String('AaJERSYSMtWxhRqZzBZ9JFr1gJkfSPgPEd3mBDT2AknQ'),
  nonce: 255,
  authority: String('ERMNbmKrBKk3e8ZBbVWNUTkrqamZYxnRFXvgsPUSLT5j'),
  poolTokenMint: String('85krvT9DxdYgoFLQDHTAGdvtNuLdAsc4xE5FkVLpN2aR'),
  poolTokenDecimals: 6,
  feeAccount: String('3HXU5ABkghqJ3iMEGWsGe88kvZtiEyDJ24ihgFTytRNx'),
  tokenIds: [Tokens.cmfiToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.cmfiToken.mint.toString()]: {
      ...Tokens.cmfiToken,
      addr: String('HEqNExFgmyZ4Es53pL2bfBi5pKcZwGzbvwm1UknYLD9s')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('GbK7B7ZGQNZYrEhADC8aV5HdPhYgMZ35f6c8Decie224')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const celoUsdcPool = Object.freeze({
  address: String('H2bkwBwizcotpWHbruYFKXfHvh29hhoZn52qHq2YZumW'),
  nonce: 254,
  authority: String('E812K73QyqSsGBeU3MLodqVs3gCKJjka4p4gP34hXtns'),
  poolTokenMint: String('HVLyX8mD8YvKgZJ4oB6rXJiCYMLpHKwB6iCiCjE1XwdT'),
  poolTokenDecimals: 6,
  feeAccount: String('35HW4HcFDzVnVsxBhPKptYfwX3HyJWEosv5T9sdBSu8t'),
  tokenIds: [Tokens.celoToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.celoToken.mint.toString()]: {
      ...Tokens.celoToken,
      addr: String('H6tFXr3BnF3r21Sv2ykLSbiE7wLxWpDcXroyxBReoXou')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('GnKnjXsmZKVhduVyLiR9Ngsxwo8spfxtruiPtqDqHLNJ')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const ftmUsdcPool = Object.freeze({
  address: String('4RCU8SoBzvYysNaQBAFpQMmt7LPQgxbto9uiuRjAJPpq'),
  nonce: 255,
  authority: String('HC6aGC7RsgVGonWs1MVy5Qit5R5ruEXZmeywpFwSCmdY'),
  poolTokenMint: String('Gpzd833qSmv3kXpQmxEaqkrZTXZaRjhNAoqhf61qAhTG'),
  poolTokenDecimals: 6,
  feeAccount: String('2cA9XLcSFJ9jgnYViJr5JtnhSpGNrPhebS4RaGm7DHmu'),
  tokenIds: [Tokens.ftmToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.ftmToken.mint.toString()]: {
      ...Tokens.ftmToken,
      addr: String('EULHGNmuJWcRHm5Xen9CiG4uqVZoemLorLvAQ7vR9vCi')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('71rJZyRkxxupdqZeLrNQnHjus11C1yEVwsrKgQax6TSx')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const btcOrcaPool = Object.freeze({
  address: String('CqXRyZ23dFwdbMLGEQLhQQRJrzkFB3wBsuH92rp7d3yw'),
  nonce: 255,
  authority: String('Fixb6XBzoSRJ4iCkHNX3xbr61yWXUh6SYyu5Jweg36VF'),
  poolTokenMint: String('DFpLFcQZqDKykyDePgip4r6MExVmBKWqTa12ezq6qxUY'),
  poolTokenDecimals: 6,
  feeAccount: String('47pxL1vidAiARAF45SoVnpqSet8EPckF9pB4d9zT1S3N'),
  tokenIds: [Tokens.btcToken.mint.toString(), Tokens.orcaToken.mint.toString()],
  tokens: {
    [Tokens.btcToken.mint.toString()]: {
      ...Tokens.btcToken,
      addr: String('8dbqoSTALCCshdKXX8Nb6AskytydXtHLyPoqu9Nb2ig3')
    },
    [Tokens.orcaToken.mint.toString()]: {
      ...Tokens.orcaToken,
      addr: String('7UAHjQQjYBrSKdtgbJK1SaUVgmBxcmMJKshvEiEhLheP')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const hbbUsdcPool = Object.freeze({
  address: String('Fpwa7a1r7kjax1nW25TVP3uiQc7XH9dv9n9UXMJRSTEx'),
  nonce: 254,
  authority: String('9odZioXkQTCZERzQXbDTfirMfbP9FtKqsP9LvHgm6wJr'),
  poolTokenMint: String('cL5WhffCYFRLM4We8VS2W684kM4pHyuvEDwp8Ddw48k'),
  poolTokenDecimals: 6,
  feeAccount: String('3nyqxtRPnYrwEHneWgbN1197aZoEaemnSNQ7rgJLv8hS'),
  tokenIds: [Tokens.hbbToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.hbbToken.mint.toString()]: {
      ...Tokens.hbbToken,
      addr: String('2vq1vt98xBPUmvbTC5nH4GtsjJVeA64kwv6CZc7xSAf6')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('B7f4A1Pp6RCjWYv5Lsrf4MecmZQDMekXh4MFQasCQuRB')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const hbbSolPool = Object.freeze({
  address: String('2aCJ1VTwfvLej1PJJAmiVCzuLGtjQ5bEo9DYkbwwe1C6'),
  nonce: 255,
  authority: String('8GRweHBrpXfeYpmryfkvEsW3DmoJKGDQLpcVfeNUqn8x'),
  poolTokenMint: String('FkKzu2HeMJZf4oHwoYPxLGVy3net5Jq8HAfnA5VqETgk'),
  poolTokenDecimals: 6,
  feeAccount: String('2Y1xXr1j6xg5r5Qz8LDqnU7N8T4GGu3Ju4AHGsfU2nXS'),
  tokenIds: [Tokens.hbbToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.hbbToken.mint.toString()]: {
      ...Tokens.hbbToken,
      addr: String('5k1Nj2TvG51p7s2ambWpmX6FraiSchoYS1wJgVw5f9Af')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('2s2vbHk4XzKQ9SQdPRyirY8JmBemhZ67EBx7xgF6tzq5')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const sbUsdcPool = Object.freeze({
  address: String('GPxcR4ci8RbLh4bVPi88mKwTEDeVxEKs3nBkKayghuuL'),
  nonce: 255,
  authority: String('6poTLvmbRU9FAJAnM5RmXMRTVFf2xWRKooCkStZQJf6U'),
  poolTokenMint: String('2Reqt4Sw9xNY8BoJ3EZLpFu5yVgNxFrbw8M3KiJpPn6o'),
  poolTokenDecimals: 6,
  feeAccount: String('EVRpMUxUGZGH15SB1hGhLzzz15XNopLZjSuTZSpcwMmt'),
  tokenIds: [Tokens.sbToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.sbToken.mint.toString()]: {
      ...Tokens.sbToken,
      addr: String('EoV9ub5aojyBFdYGhPQPQ6cDeesgsENrNYGN6L8CB6Nh')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('7kRmDjSorwKqqLAFupgGW8fEp6hywkt2bntw2gCrV5Sh')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const stsolUsdtPool = Object.freeze({
  address: String('EacW6utfAJk6z5QCbyqjJHdTN2TH5UFu9K5dh3DmhsLV'),
  nonce: 254,
  authority: String('CUQbwmFXySWXpBgycGTTj3gQNY8AnjD4DJRjgLeG1bVK'),
  poolTokenMint: String('4ni1nho89cDKAQ9ddbNQA9ieLYpzvJVmJpuogu5Ct5ur'),
  poolTokenDecimals: 6,
  feeAccount: String('H4VgN76Mri6ctLCkf6fnyfae9fCDC51nZVPa1oPjq1Da'),
  tokenIds: [Tokens.stsolToken.mint.toString(), Tokens.usdtToken.mint.toString()],
  tokens: {
    [Tokens.stsolToken.mint.toString()]: {
      ...Tokens.stsolToken,
      addr: String('BAMiBNk9j6Z9LLdZzzGScHDFQas58uLqW4GGX4ndq7K6')
    },
    [Tokens.usdtToken.mint.toString()]: {
      ...Tokens.usdtToken,
      addr: String('Ajf4bxNoKCyFVfV35sRTgGwZK1dfJJJVXgNFs7ncC5EF')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const seededUsdcPool = Object.freeze({
  address: String('3fyCss3Y9ATNnTSsN7VCasEb4H5SpSuN7VEoJnNWZU3x'),
  nonce: 251,
  authority: String('89TxEkGNHFcniBm5pXo8Jq6s1ZaBUKCRqgYS3eAr3jJ7'),
  poolTokenMint: String('H7gyTmNCDXkD8MGMqnxqoD8ANszjcju4tjT6ERZ5dakf'),
  poolTokenDecimals: 6,
  feeAccount: String('2ZTqrZTNumBMdyQm3cAiREHozzQp18KhEuGWiUVwJMRm'),
  tokenIds: [Tokens.seededToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.seededToken.mint.toString()]: {
      ...Tokens.seededToken,
      addr: String('Dn9LiTqcRiyxipCqvTEyT3ZwbWtf69bUcHQLWG89jpJa')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('Aq5xPmh3cZoyLN3KzVcAK8osAU7oAjo1k6qdw6gd5ovi')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const audioUsdcPool = Object.freeze({
  address: String('2arG3AznFJbLknAQUqk44PgeABZDHpBfcRsPmPWjjBPY'),
  nonce: 254,
  authority: String('ETCzw3Vp2n4aas7rFtxb554h67JPeyDBr7DKcHUgSAxp'),
  poolTokenMint: String('3hksYA17VxgiKSeihjnZkBbjc2CTbEBfvDCYgQhojTo5'),
  poolTokenDecimals: 6,
  feeAccount: String('H1Rcj6jAeV9v5QsJTo957tcWgYzve4PZEuSHGNmX1UFv'),
  tokenIds: [Tokens.audioToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.audioToken.mint.toString()]: {
      ...Tokens.audioToken,
      addr: String('FfpKRggDra1uSskyzAm6f4EXFhxw6ij8BkDwiWTs126x')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('ACBf9ksELg2buAFnDTJL9WnT5kZRcFN1Bvarox9JZVbq')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const mmaUsdcPool = Object.freeze({
  address: String('3HJ9QwRNsdHj3xaTvrzYyvvsY1gd23N8qGiSHaytKLLB'),
  nonce: 254,
  authority: String('58KyKR1fHXP2ewDC93TdRxPZ7W8FNYkLc53VH9XP32hD'),
  poolTokenMint: String('AaZRnJAnDyJyPD9uPJpJ8bzBGDCEi6jtBpUf92xErWPp'),
  poolTokenDecimals: 6,
  feeAccount: String('AfesEXaDzs4mnPQJyqNHzpZ8DMBpuEPtmi1aL5xwRjVm'),
  tokenIds: [Tokens.mmaToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.mmaToken.mint.toString()]: {
      ...Tokens.mmaToken,
      addr: String('BzccyLPYsbhhWjHnRLUn7Fpkao9reYRdjA8wkp7iY6re')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('6uJF6gMpCxUYvmQ8hmaz44MRyRYq5ykpsx5GYABbUK5A')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const onesolSolPool = Object.freeze({
  address: String('8CJhJomcLYoVw6pAQdNWPJ3yDXkS55y2gAxJ9uReuXEz'),
  nonce: 254,
  authority: String('9DV4t1XqqNiXLCdHfaEwAbVaT5Mth3z8VjYS6CG8CPQS'),
  poolTokenMint: String('9wPhuYapychVDSxmXqCZxy2Ka8Lmav4SHM72si8bfraV'),
  poolTokenDecimals: 6,
  feeAccount: String('6yqvsmFJrMhSv31Ne6yY8f4y2ZJvMMCwTjYTxhq1eKS5'),
  tokenIds: [Tokens.onesolToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.onesolToken.mint.toString()]: {
      ...Tokens.onesolToken,
      addr: String('532m3b4aSmWsrn4RfM9ByBRLJocdcHMwiscjADQYNjXG')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('2qu6tc58av5AGfrrBPdHxK2Cyi5CsDhjaZ8x8yw8UA1w')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const puffSolPool = Object.freeze({
  address: String('GrSE1LoQBr3p9y7HQxV8h5vc8fH4xkT4a6Nysckbu8zt'),
  nonce: 253,
  authority: String('79gfyi1Ct3m8RKkF618yxnA7w5JQV1NWhkMs6dXACgGq'),
  poolTokenMint: String('Eho8h1BcoG5QWU7X9FzJafw5ErKUXtR2LobAJJZfWff4'),
  poolTokenDecimals: 6,
  feeAccount: String('44XxHHYz2642Bc4sHdDuV2aEGbziugqN5tKnmBBBwPyA'),
  tokenIds: [Tokens.puffToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.puffToken.mint.toString()]: {
      ...Tokens.puffToken,
      addr: String('HYszSkhsPPNSj6Q5hRudc94kawCgUNeQ1DqrxJxtoxsK')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('AKJUQjqAHTw5rNYYv9Rvxxe23mwdNaqzt7pdriJy92sw')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const saoUsdcPool = Object.freeze({
  address: String('BWCT76eQ8jtrKkMm234ZY1QEXSfZYTrzHyXaE3DxeQfd'),
  nonce: 255,
  authority: String('7TXfpFGVASvgkLkuTSsqQJQTXypa31zqcHmPZNMaAJu'),
  poolTokenMint: String('4iyU77yZbg8iD344vbwruAuDAf9i1EVV3FhZJDnWStBE'),
  poolTokenDecimals: 6,
  feeAccount: String('BVSJLE6RZ2cvfXPjSZcj7fK1HLra2wtcC4jLCZAh6gFX'),
  tokenIds: [Tokens.saoToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.saoToken.mint.toString()]: {
      ...Tokens.saoToken,
      addr: String('8MNhNFCU366Y3dq3HRk5nKuKZuQLY3kffMZekadM2R8q')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('6qnLaADqFjtGsMsHjScFVmpVVNBbqaGNoBwBFy5BVRr8')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const srlyv2SolPool = Object.freeze({
  address: String('Df7DkQRXEpPM5basbYHi45268hmR8m7YrtraPdGgj6R6'),
  nonce: 255,
  authority: String('9DaRQeoEx3EjXYxhpZrcJ6no3bcAkfm9toWbngcAqSCB'),
  poolTokenMint: String('3dXdXg5HPyZ73GFC9LkSn3thdJUGeXWB8iSTHs5UcqiH'),
  poolTokenDecimals: 6,
  feeAccount: String('B3Ao2fEX2isX8UQ99EuPz3BDzUfQTPeYS7KVvbCnkrXm'),
  tokenIds: [Tokens.srlyv2Token.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.srlyv2Token.mint.toString()]: {
      ...Tokens.srlyv2Token,
      addr: String('AJzDsY4wnv8nWSWoBimY6hWJpWC54oEgmfbV7YGXsLww')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('qytd7KfK3pFVWog53xUVE8dqD1sBxa1H13VnF6ADGSd')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const zbcUsdcPool = Object.freeze({
  address: String('J4Xvy9twDDWqqwyfX6nrc2QFJeMJ6HD9kbAmcPhfYmFP'),
  nonce: 255,
  authority: String('9Ssocd3xbxm3xCKwz1g8d41rS6iWbmGyo25ZdYZHbDCp'),
  poolTokenMint: String('2LYgm6nGXmSSjfoEriPuYeGoNiWNxUs7n3rnTbDWN5c7'),
  poolTokenDecimals: 6,
  feeAccount: String('8H1bUAywEerLCYxLVDjM5EqXYXtoW5gbR1MoD1LrrnMV'),
  tokenIds: [Tokens.zbcToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.zbcToken.mint.toString()]: {
      ...Tokens.zbcToken,
      addr: String('8AcanN7p8KHfLxKBBMKuJUqqX2uUPz3mkp3iaNHHaZfZ')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('FwqAxdo6s5EtyVXqqU7sj3Q9n3eLuUkF2D4584MDoWLW')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const gmtUsdcPool = Object.freeze({
  address: String('46GcZFgznxUf6TpoCqJqzMpgMbbJPCAwNn8GThSt9qjC'),
  nonce: 255,
  authority: String('3HGGVGTXbqT49PG3L8JQYH4jCeP5CNBG6CpJniZ434an'),
  poolTokenMint: String('CFxQF5kNAtbbDj298Xr47Sf4mkSyuzWpRH97hrdQ6kxi'),
  poolTokenDecimals: 6,
  feeAccount: String('3pBqsnahNsm6p14FFjtMCGfD1VCQNcUEdNEeSwTGfE2q'),
  tokenIds: [Tokens.gmtToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.gmtToken.mint.toString()]: {
      ...Tokens.gmtToken,
      addr: String('BTpvbpTArnekGgbXRqjfSvp7gENtHXvZCAwuUKQNYMeN')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('DdBTJuiAXQQ7gLVXBXNPbVEG8g1avRxiJXhH5LhBytYW')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const novaUsdcPool = Object.freeze({
  address: String('GWG6gTW75GxMS7oUDoivjPrjcbsiR7fDMFKFw6pz2MuN'),
  nonce: 254,
  authority: String('Az4AA8tzbKJRZhunGUGVmtJyGUj763fiDyxbZiPeSVb4'),
  poolTokenMint: String('DdAFNvDxtEHCgKj3JAM64zCKfMWhs4J9wEmRrjUAFiME'),
  poolTokenDecimals: 6,
  feeAccount: String('7RPsLN9WHRL39sAs3oDjWzGHJ1bCJg36qmTqb4i6ZPFV'),
  tokenIds: [Tokens.novaToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.novaToken.mint.toString()]: {
      ...Tokens.novaToken,
      addr: String('5DCtLobmcNATqtgbv9pymXsXDqmVJ7NVi6GLLuPwmphk')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('Hym2QSjXpNZE8VubGbg63nPx1QGXtkmsucdBCFYKp6jj')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const hbbUsdhPool = Object.freeze({
  address: String('9GgFi1cavfTEQ8gy87S3VVeqZSFBhncpJvMBQ8vprAQw'),
  nonce: 255,
  authority: String('HsTk5QvXEQpdr1bB8nay55TZqecKUqNvGLM93vVRhHqj'),
  poolTokenMint: String('7n2YW9qLkhGFArdZPLoF4hYPE2zw7xCACkVPXrUWnLuo'),
  poolTokenDecimals: 6,
  feeAccount: String('AijCQCXg6pcLwfNW6DB9HNrwge2HRaXfdJLAXeBEhgKF'),
  tokenIds: [Tokens.hbbToken.mint.toString(), Tokens.usdhToken.mint.toString()],
  tokens: {
    [Tokens.hbbToken.mint.toString()]: {
      ...Tokens.hbbToken,
      addr: String('D9uCWM7yfWwUktQ2MjuiDmTYa4vE9ff5St7TaQ9uJPwe')
    },
    [Tokens.usdhToken.mint.toString()]: {
      ...Tokens.usdhToken,
      addr: String('AGGpY57ko5qcJJxRNgJSXTs3jrf6cPgux4q8B9R3nE52')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const takiSrlyv2Pool = Object.freeze({
  address: String('6AxGikaR6FvDAP3rEWYA5sjKim8ELceCfno7ZGaYYwsK'),
  nonce: 254,
  authority: String('13sT1XctPqMUoomDqyV55nEAupZXfyc7tohxTrhNXyKQ'),
  poolTokenMint: String('6atKbS2Xz5vu7cqWBNk8KYkuakRzckZ9nvtUKf2k8Sc3'),
  poolTokenDecimals: 6,
  feeAccount: String('3yvDrXwLK4z5P7BbPko7siDfxDLV31J58xzoCK9Wy5mb'),
  tokenIds: [Tokens.takiToken.mint.toString(), Tokens.srlyv2Token.mint.toString()],
  tokens: {
    [Tokens.takiToken.mint.toString()]: {
      ...Tokens.takiToken,
      addr: String('J8kiPsPrscD9oLg5JP3Dj7HyNSLh5s4zmfA7LLYNqYXS')
    },
    [Tokens.srlyv2Token.mint.toString()]: {
      ...Tokens.srlyv2Token,
      addr: String('FpdhjfJsJtMYy5zejmhFJNxtE59NDhgbXn3mfsEeKDmu')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const zigUsdcPool = Object.freeze({
  address: String('37b7oyhDTJh1VpjjEZqaC8VasvnyHHYHeiXLpa8Ht8Hm'),
  nonce: 255,
  authority: String('8D4DfRUgmEMuiFct6BCqSiUd7NgH4wtbU3kLB4Miy1YC'),
  poolTokenMint: String('5vhh9ZnD9vnahRhFLP1EUEyYRSzvJwgw9U2xygsSJSrp'),
  poolTokenDecimals: 6,
  feeAccount: String('AcWQv9cp2gn8BUTn7swwpPeAcGZ9gG64zzM5pYWnReGx'),
  tokenIds: [Tokens.zigToken.mint.toString(), Tokens.usdcToken.mint.toString()],
  tokens: {
    [Tokens.zigToken.mint.toString()]: {
      ...Tokens.zigToken,
      addr: String('BNx1dsmrFLX9r2hWf2duWaPL4m7yu1AkBw8X9YwEGqp7')
    },
    [Tokens.usdcToken.mint.toString()]: {
      ...Tokens.usdcToken,
      addr: String('jYirYPaPZKnsbEJSvWu3Pb6tWRcNxPQPL2RD1qTa7eK')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const srlySolPool = Object.freeze({
  address: String('FyGeyg6HEjBwTEGXF9o78XgKyMkmxSncLe2VECu3FPgo'),
  nonce: 253,
  authority: String('BDmEnikYwue2wtzxZFhr29avpQdX3rrQrvSjYJimeteH'),
  poolTokenMint: String('HfRgvhgscGX5GaP3rUrZAhh7gS4aJ2UQ7rNVX976rG6P'),
  poolTokenDecimals: 6,
  feeAccount: String('EcUL9q6rMrVsyqDR1hMMMyHM6Sqos93SKXBCEd3HgAa7'),
  tokenIds: [Tokens.srlyToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.srlyToken.mint.toString()]: {
      ...Tokens.srlyToken,
      addr: String('7vY5bs27YTWus7KiemXsfaY4E2EzbrV5gXvbx1RaWJ8B')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('JiKtEtLL96sUDQvM1oYXuNaWdHxjdcSgb9M3yqNYs8h')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const takiSrlyPool = Object.freeze({
  address: String('4VzdxTLqzgn5MGMTDsNMukZCvkAZkXLXhYENeKGRtdTR'),
  nonce: 255,
  authority: String('CMTEg8iJ131Fi6yJvsZ5ovtUZjfQWaEsZtTmZH15WjFH'),
  poolTokenMint: String('3xaK5aWWLNRB73xUVX3cusLhDp65mTvP4fwW5Jwxakgs'),
  poolTokenDecimals: 6,
  feeAccount: String('5Vey7hniN8uNjqBkaxrVpXFrsc5eEZdqFD1Xi5SJF6PS'),
  tokenIds: [Tokens.takiToken.mint.toString(), Tokens.srlyToken.mint.toString()],
  tokens: {
    [Tokens.takiToken.mint.toString()]: {
      ...Tokens.takiToken,
      addr: String('7p3UFVn1h2Eeo8zF4CETufa7JqXYMVXzKLn7vg39MQfX')
    },
    [Tokens.srlyToken.mint.toString()]: {
      ...Tokens.srlyToken,
      addr: String('J5V4k8nSGu3PBH8ej4E34KjCbGZx5Q7v2AMwr4Wt9Tgm')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const stsolSolPool = Object.freeze({
  address: String('71zvJycCiY2JRRwKr27oiu48mFzrstCoP6riGEyCyEB2'),
  nonce: 253,
  authority: String('5qpKgo2PSNSkmHGAa4oYrEZcYA3CQaFpA66nPVuP1qHX'),
  poolTokenMint: String('4jjQSgFx33DUb1a7pgPsi3FbtZXDQ94b6QywjNK3NtZw'),
  poolTokenDecimals: 6,
  feeAccount: String('7nxYhYUaD7og4rYce263CCPh9pPTnGixfBtQrXE7UUvZ'),
  tokenIds: [Tokens.stsolToken.mint.toString(), Tokens.solToken.mint.toString()],
  tokens: {
    [Tokens.stsolToken.mint.toString()]: {
      ...Tokens.stsolToken,
      addr: String('HQ2XUmQefvBdpN8nseBSWNP2D1crncodLL73AWnYBiSy')
    },
    [Tokens.solToken.mint.toString()]: {
      ...Tokens.solToken,
      addr: String('8y8X4JuZn1MckRo5J6rirpr2Dxj1RKQshj7VzuX6dMUw')
    }
  },
  curveType: CurveType.Stable,
  amp: 100,
  feeStructure: {
    traderFee: fromFraction(6, 10000),
    ownerFee: fromFraction(1, 10000)
  }
})

export const btcStsolPool = Object.freeze({
  address: String('Cus1XADrHBrgyD7hKLGARsHFtBgGWvjAA346KimbHXai'),
  nonce: 255,
  authority: String('Czqib5TfizVRvDksYBFfdDgHcBwdPPVejW9LYDBSXxu5'),
  poolTokenMint: String('HYp9v7cY4wAxSsa6ijztQQ3GQ8iTttuG5vu8JNBDHoNh'),
  poolTokenDecimals: 6,
  feeAccount: String('3SHTi6ybA2oAFooARTt1ETbNPSueofuBFhmEtjmrJFUw'),
  tokenIds: [Tokens.btcToken.mint.toString(), Tokens.stsolToken.mint.toString()],
  tokens: {
    [Tokens.btcToken.mint.toString()]: {
      ...Tokens.btcToken,
      addr: String('8WhAF65WbdsRAzGQF66oLsBZwbeXbU5WtxX7pA8SwjK')
    },
    [Tokens.stsolToken.mint.toString()]: {
      ...Tokens.stsolToken,
      addr: String('EnbNxeehxrAJXxyWupFcs7zsJN3Zob4nJmpFGS8ninm8')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

export const stsolWhethPool = Object.freeze({
  address: String('9FtrC9VkqssGygMafJjseqPQXnyorqHV3DyMPBfJrgtj'),
  nonce: 252,
  authority: String('4Y7EQYJ1WWKn8ZXC3Fc6KVnp5LPgqLxyV5Pk9eGm3cuh'),
  poolTokenMint: String('FWurFWADrgmhb6Y44LEaCMnEHS2Tu3QGqd9oBcZtr8gT'),
  poolTokenDecimals: 6,
  feeAccount: String('28t4Vixx6cE1yunwXNqQ2YU3pU9PbCscK9G3daRYuYCS'),
  tokenIds: [Tokens.stsolToken.mint.toString(), Tokens.whethToken.mint.toString()],
  tokens: {
    [Tokens.stsolToken.mint.toString()]: {
      ...Tokens.stsolToken,
      addr: String('FTrSPUnMKmdKkNekKvVUQY1nunr2kWwohMHmBusd79Vj')
    },
    [Tokens.whethToken.mint.toString()]: {
      ...Tokens.whethToken,
      addr: String('2teAyRLAU716sCj9v2634Wi4wcLqtRvV9upXaRvDSFm1')
    }
  },
  curveType: CurveType.ConstantProduct,
  feeStructure: {
    traderFee: fromFraction(25, 10000),
    ownerFee: fromFraction(5, 10000)
  }
})

/**
 * Mapping for OrcaPool parameters
 * Key: poolTokenMint
 */
export const orcaPoolConfigs = {
  APDFRM3HMr8CAGXwKHiu2f5ePSpaiEJhaURwhsRrUUt9: solUsdcPool,
  FZthQCuYHhcfiDma7QrX7buDHwrZEd7vL8SjS6LQa3Tx: solUsdtPool,
  '71FymgN2ZUf7VvVTLE8jYEnjP3jSK1Frp2XT1nHs8Hob': ethSolPool,
  '3e1W6Aqcbuk2DfHUwRiRcyzpyYRRjg6yhZZcyEARydUX': ethUsdcPool,
  '5kimD5W6yJpHRHCyPtnEyDsQRdiiJKivu5AqN3si82Jc': raySolPool,
  ADrvfPBsRcJfGsN6Bs385zYddH52nuM5FA8UaAkX9o2V: ropeSolPool,
  '8nTzqDXHriG2CXKbybeuEh1EqDQMtrbYMFWcP7AkiDaP': stepSolPool,
  '9tf8rBSEQYG7AqL896fN2nZi1iYPqpWaLEdpbeQaC1Vy': srmSolPool,
  EsYaDKJCmcJtJHFuJYwQZwqohvVMCrFzcg8yo3i328No: fttSolPool,
  CzieDbGRdN1QGaGDNpSqzEA18bi881ccvkkGZi51pe1k: copeSolPool,
  '7tYCdLN84EnTMkxM7HNamWJx7F4xgKe9KiiWvLyWjbgT': oxySolPool,
  Acxs19v6eUMTEfdvkvWkRB4bwFCHm3XV9jABCy7c1mXe: btcSolPool,
  HiwRobjfHZ4zsPtqCC4oBS24pSmy4t8GGkXRbQj4yU6L: merSolPool,
  EYsNdtyu4gGTaGz8N5m5iQ3G1N6rDyMbR72B3CqbWW4W: fidaSolPool,
  '99pfC8fWymXgbq3CvrExhx3UxQDC1fMWEWLbNT83F45e': mapsSolPool,
  // H2uzgruPvonVpCRhwwdukcpXK8TG17swFNzYFr2rtPxy: usdcUsdtPool,
  '2uVjAuRXavpM6h1scGQaxqb6HVaNRn6T2X7HHXTabz25': orcaSolPool,
  n8Mpu28RjeYD7oUX3LG1tPxzhRZh3YYLRSHcHRdS3Zx: orcaUsdcPool,
  HEvnD66WcBfTajS9adUYnGRBMDehFtLySiFHSD6kEBWs: kinSolPool,
  D6N9j8F2DhtzDtrdpT74y3u2YmYAzcggiLc3nTjqux9M: samoSolPool,
  '3PD9SZFwXKkXr4akLf4ofo37ZUMycwML89R2P3qxcbZG': liqUsdcPool,
  AZpo4BJHHRetF96v6SGinFZBMXM4yWMo4RA8C4PriDLk: snyUsdcPool,
  '8PSfyiTVwPb6Rr2iZ8F3kNpbg65BCfJM9v8LfB916r44': msolUsdcPool,
  AtB4nUmdyQfuWWJ9xAHw9xyVnJFfSjSuVWkiYan8y86w: slrsUsdcPool,
  F8gPSpwVHj8FdAJAYULDuZBxFEJut87hUbARYYx3471w: portUsdcPool,
  CS7fA5n4c2D82dUoHrYzS3gAqgqaoVSfgsr18kitp2xo: sbrUsdcPool,
  Dkr8B675PGnNwEr9vTKXznjjHke5454EQdz3iaSbparB: scnsolUsdcPool,
  C2YzN6MymD5HM2kPaH7bzcbqciyjfmpqyVaR3KA5V6z1: psolUsdcPool,
  '29cdoMgu6MS2VXpcMo1sqRdWEzdUR9tjvoh8fcK8Z87R': msolSolPool,
  C7TH2jEJJaxVwwuvkbcDGfHUiZvEkkeYjyAcdTMi5ujb: orcaPaiPool,
  CVapmQn7HaU1yMDW3q6oUV4hx6XoYv54T4zfGXkuJqkA: orcaMsolPool,
  APNpzQvR91v1THbsAyG3HHrUEwvexWYeNCFLQuVnxgMc: scnsolSolPool,
  FZ8x1LCRSPDeHBDoAc3Gc6Y7ETCynuHEr5q5YWV7uRCJ: atlasUsdcPool,
  GteBdo9sqE7T41G8AJsaG9WHW48uXBwsLLznmu2TBdgy: polisUsdcPool,
  '2gXDJZ7XAtQEtf4PRSQZKoq1WMuu1H44tQanbMA3YVpu': bopUsdcPool,
  '6VK1ksrmYGMBWUUZfygGF8tHRGpNxQEWv8pfvzQHdyyc': samoUsdcPool,
  '4X1oYoFWYtLebk51zuh889r1WFLe8Z9qWApj87hQMfML': ninjaSolPool,
  BVWwyiHVHZQMPHsiW7dZH7bnBVKmbxdeEjWqVRciHCyo: slimUsdcPool,
  ELfBngAgvLEHVBuJQhhE7AW6eqLX7id2sfrBngVNVAUW: whapiUsdcPool,
  HsauTv9s52Zv12eaDuSp6y7BEm4e4BHEyAsbdjyyWzPK: copeUsdcPool,
  GHuoeq9UnFBsBhMwH43eL3RWX5XVXbSRYJymmyMYpT7n: sunnyUsdcPool,
  EorFh8siFyLF1QTZ7cCXQaPGqyo7eb4SAgKtRH8Jcxjd: grapeUsdcPool,
  GMzPbaCuQmeMUm1opH3oSCgKUjVgJUW14myq99RVPGX5: abrUsdcPool,
  DRknxb4ZFxXUTG6UJ5HupNHG1SmvBSCPzsZ1o9gAhyBi: kuroUsdcPool,
  '2toFgkQDoPrTJYGDEVoCasPXuL9uQnjvXJaDwa9LHyTx': mediaUsdcPool,
  '4SBx8GXu8HhcVHWydQv1vsDdZs3G93XSL9CtMBny6hS5': tulipUsdcPool,
  H9yC7jDng974WwcU4kTGs7BKf7nBNswpdsP5bzbdXjib: mngoUsdcPool,
  Eswigpwm3xsipkTqahGi2PEJsJcULQBwZgxhQpr6yBEa: stsolWstethPool,
  qJxKN9BhxbYvRNbjfK2uAVWboto6sonj8XC1ZEW5XTB: sypUsdcPool,
  '74B9aMS7SA832xKngt5VLKmWAP3pa3qkUzWncTmQSsGF': stsolWldoPool,
  '7aYnrdmdCRodDy2Czn6keUquUhjF1jPEmfwZPh488z8U': whethSolPool,
  '7NPtjjAP7vhp4t5NCLyY4DY5rurvyc8cgZ2a2rYabRia': whethUsdcPool,
  '5PHS5w6hQwFNnLz1jJFe7TVTxSQ98cDYC3akmiAoFMXs': mndeMsolPool,
  Df6XNHMF3uRVZnz7LCEGiZVax6rXgz76owtVkBHEjSb6: wagUsdcPool,
  '9cMWe4UYRPGAUUsTkjShJWVM7bk8DUBgxtwwH8asFJoV': msolUsdtPool,
  '5qoTq3qC4U7vFxo3iCzbXcaD1UEmDeCD63Dsuoct71oV': msolWhethPool,
  '8nKJ4z9FSw6wrVZKASqBiS9DS1CiNsRnqwCCKVQjqdkB': btcMsolPool,
  DfgCnzaiTXfPkAH1C1Z441b5MzjjTCEh134ioxqRZxYf: ivnSolPool,
  '8sfThep3io4gvcGeuoAg1Rs8GDwKJjtcdAFHqQSSNAVE': larixUsdcPool,
  '6jCERp5hKj37PCXP3VTjCDJeoPuSpnMDMz5A6jWQv3yS': prtUsdcPool,
  GBijunwxa4Ni3JmYC6q6zgaVhSUJU6hVX5qTyJDRpNTc: jetUsdcPool,
  GtQ1NT7R5aaTiST7K6ZWdMhwDdFxsSFvVFhBo8vyHGAq: stsolUsdcPool,
  '5a6Y1ephcbKSoyLMQyD1JWbtqawCy8p2FtRL9v3zhaG5': wstethUsdcPool,
  '6mJqqT5TMgveDvxzBt3hrjGkPV5VAj7tacxFCT3GebXh': auryUsdcPool,
  Hmfrtmo93DpSDmVNLQKcBS5D1ia5JatiRSok9ososubz: avaxUsdcPool,
  FwCombynV2fTVizxPCNA2oZKoWXLZgdJThjE4Xv9sjxc: fttUsdcPool,
  '4cXw2MYj94TFBXLL73fEpMCr8DPrW68JvrV8mzWgktbD': rayUsdcPool,
  F59gkD7NnsdJbFKrRZsiBC8PAooN4c56T8QmahfW1iXN: slndUsdcPool,
  '7vnps4VE5RTGAr5fmPZu7fSrk2VnM4Up838grZfqmxqE': gofxUsdcPool,
  '9EjcYfHcG8f1mccpHyaAwpoxaUPiheC6KgLQjyD9aTb6': woofUsdcPool,
  CHTKUJGYRtBDqnxCFjxe5KEkZgxV98udbhuYYyzGxup5: sdogeUsdcPool,
  '55r9txzQtmjTykmTXmBYZCVMg5z9squB8b5cSw2AhxA4': catoUsdcPool,
  DSiHyHDn96bUQSZtizyCRLcQzrwohZeMpVu8rYJN1HzG: oogiUsdcPool,
  '5MvQHx8eftU39JTucFsT315JFnQASuDQg3FqxTw7xcvN': sonarUsdcPool,
  HNrYngS1eoqkjWro9D3Y5Z9sWBDzPNK2tX4rfV2Up177: aptUsdcPool,
  AWrtTWG4Zgxw8D92bb3L3sQtGLD3zDztMPWsXSph8iBP: dflUsdcPool,
  '9Y1vPaAsMz8X65DebMMnmBjbMo8i4jh4mcgiggZUUS3M': dflSolPool,
  FnDxJPNk7pPmGHUbR4XUHmHevrkXHdna5D3sQKcAtjBL: frktUsdcPool,
  FGgP1npQTsC5Q4xBmQtNYSh51NKqNwdxBZy8JCo3igcu: tttUsdcPool,
  E1U63VXhNiWoUkVvjrfLDdV1oJrwE6zLde3bohr6jCqz: upsUsdcPool,
  GjG7JjTQfQpDxw4hWx4etP9oTaYCuCbPjsU8WaUT3xHB: fantUsdcPool,
  D8WjqtwC9CzBrQKfSf2ccCHFQuPYwyLv5KAy8WjT5vnf: blockUsdcPool,
  '34Ppq6R8NfYBwWwPY4cBK4Afyb8hHaASQFukCzH6cV4n': runUsdcPool,
  HjR8JgqNKQVMvdryqJw5RJ4PCE9WGk8sgbEF7S9S3obv: uxpUsdcPool,
  J3kvcay3N16FBdawgnqoJ9v9p6XCvyCLE2Z9F5RLvGkj: btcUsdcPool,
  '12Uj74zgUUoBe4yeackwQ4qYtFMr9fk1xL6q5Nha6t2N': mndeUsdcPool,
  '71CBZeJ4tw38L9pSPoCz4fRsuWE64Fipyzotte7haoCS': chicksUsdcPool,
  '6MF5CHWAj5mS7FhpxiKz37CzR2eYTu236XpBKKMXCrGg': onesolUsdcPool,
  HDgxKmiA8Pv82fNguhVeMkZqQkos2YksFPoP1KttWxX8: wmpUsdcPool,
  '2VuGzaMrDnDyZfYvDwSXk38s7M2wpud7LDY3dGA1J9sy': unqUsdcPool,
  GoaAiajubRgeCFEz9L6mLnSmT2QFegoJDH5tpLfivpj: basisUsdcPool,
  E6FUnQHGHJVJg7oExVr5Moeaj1QpdpZQF5odYjHXWPZb: gstUsdcPool,
  F5BTnwuMA6rxftTdbZ33VWKr2wrr6DuQHnd4guKmPSYQ: meanUsdcPool,
  HCtyJzFUtYecXrA52s4Y9atq4J1fhT3cYsTX17XVSFag: aartUsdcPool,
  DJqqvzSuPaWThfzwMjXx7H2ZmHDdwxza6NtFudtuXcpc: shdwUsdcPool,
  '2ws7g3LBPdctfKn42Di9qxzQtUJ8ZL1aEAX2rGEQMNqh': shdwSolPool,
  '99ZHUQsgxL7K6PHrGNi1gSwawwPr7UA5fbWrYoHQ6qhX': scyUsdcPool,
  E5kSBqTDxFLbLNQaVVtPtnhEYVLMCK2fVSEKoMKL98qR: slcUsdcPool,
  '6c13xsmyk7UaHUWZ2rm1MM3ZdrQRSBkQ9waaG25ridVs': wustSolPool,
  J1KfRtP5y2warpD7LdJhfBLPKoWwSqYuovdArSv1mpQ7: wustUsdcPool,
  '68YVjgPnTUPcBqZyghqvD2WPNsrLKsjYTmBKJzHRr4qd': msolWustPool,
  '8Mh7drLbt3jFJYwp948XyvQscGLaLkChNcaH5wwaAoWA': wlunaWustPool,
  HTZd53fYwYQRyAjiaPsZy9Gf41gobFdqkF4oKe3XLi95: stsolWustPool,
  AzEoVuNJyo9ByoLRZ5t6vav2Zg24vULNVJM41PgCKUqR: jsolUsdcPool,
  CCyDxjdW3G7hPTthTMPTZ4bnhFF19XG6rx2fNiKeRQww: daosolUsdcPool,
  Gx4PoxenyQwhGGnKagAT35iVg4im1iKhJxDWqVhgu6tk: orcaUsdtPool,
  GsfyYHkSgC3Ta6aWR9MjB2sxoBrkGGeR2tAwXbpphf3: orcaWhethPool,
  '7cuu94swKL5PtFQohKMAzyd1mjj65rgMW3GzLY31HCnK': geneUsdcPool,
  '85krvT9DxdYgoFLQDHTAGdvtNuLdAsc4xE5FkVLpN2aR': cmfiUsdcPool,
  HVLyX8mD8YvKgZJ4oB6rXJiCYMLpHKwB6iCiCjE1XwdT: celoUsdcPool,
  Gpzd833qSmv3kXpQmxEaqkrZTXZaRjhNAoqhf61qAhTG: ftmUsdcPool,
  DFpLFcQZqDKykyDePgip4r6MExVmBKWqTa12ezq6qxUY: btcOrcaPool,
  cL5WhffCYFRLM4We8VS2W684kM4pHyuvEDwp8Ddw48k: hbbUsdcPool,
  FkKzu2HeMJZf4oHwoYPxLGVy3net5Jq8HAfnA5VqETgk: hbbSolPool,
  '2Reqt4Sw9xNY8BoJ3EZLpFu5yVgNxFrbw8M3KiJpPn6o': sbUsdcPool,
  '4ni1nho89cDKAQ9ddbNQA9ieLYpzvJVmJpuogu5Ct5ur': stsolUsdtPool,
  H7gyTmNCDXkD8MGMqnxqoD8ANszjcju4tjT6ERZ5dakf: seededUsdcPool,
  '3hksYA17VxgiKSeihjnZkBbjc2CTbEBfvDCYgQhojTo5': audioUsdcPool,
  AaZRnJAnDyJyPD9uPJpJ8bzBGDCEi6jtBpUf92xErWPp: mmaUsdcPool,
  '9wPhuYapychVDSxmXqCZxy2Ka8Lmav4SHM72si8bfraV': onesolSolPool,
  Eho8h1BcoG5QWU7X9FzJafw5ErKUXtR2LobAJJZfWff4: puffSolPool,
  '4iyU77yZbg8iD344vbwruAuDAf9i1EVV3FhZJDnWStBE': saoUsdcPool,
  '3dXdXg5HPyZ73GFC9LkSn3thdJUGeXWB8iSTHs5UcqiH': srlyv2SolPool,
  '2LYgm6nGXmSSjfoEriPuYeGoNiWNxUs7n3rnTbDWN5c7': zbcUsdcPool,
  CFxQF5kNAtbbDj298Xr47Sf4mkSyuzWpRH97hrdQ6kxi: gmtUsdcPool,
  DdAFNvDxtEHCgKj3JAM64zCKfMWhs4J9wEmRrjUAFiME: novaUsdcPool,
  '7n2YW9qLkhGFArdZPLoF4hYPE2zw7xCACkVPXrUWnLuo': hbbUsdhPool,
  '6atKbS2Xz5vu7cqWBNk8KYkuakRzckZ9nvtUKf2k8Sc3': takiSrlyv2Pool,
  '5vhh9ZnD9vnahRhFLP1EUEyYRSzvJwgw9U2xygsSJSrp': zigUsdcPool,
  HfRgvhgscGX5GaP3rUrZAhh7gS4aJ2UQ7rNVX976rG6P: srlySolPool,
  '3xaK5aWWLNRB73xUVX3cusLhDp65mTvP4fwW5Jwxakgs': takiSrlyPool,
  '4jjQSgFx33DUb1a7pgPsi3FbtZXDQ94b6QywjNK3NtZw': stsolSolPool,
  HYp9v7cY4wAxSsa6ijztQQ3GQ8iTttuG5vu8JNBDHoNh: btcStsolPool,
  FWurFWADrgmhb6Y44LEaCMnEHS2Tu3QGqd9oBcZtr8gT: stsolWhethPool
}
