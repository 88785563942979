import React, { useState, useRef, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import cn from 'clsx'
import styles from './style.module.scss'
const BannerImage = ({ className, src }) => {
  const [isLoad, setIsLoad] = useState(false)

  const imgRef = useRef()

  const onFinishLoad = () => {
    setIsLoad(true)
  }

  return (
    <div>
      {
        !isLoad &&
        <Skeleton
          className={className}
        />
      }

      <img ref={imgRef} className={cn(className, isLoad ? styles.showImage : styles.hideImage)} src={src} alt="" onLoad={onFinishLoad} />

    </div>
  )
}

export default BannerImage
