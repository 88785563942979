import React, { createContext, useContext, useEffect, useState } from 'react'
import get from 'lodash/get'
import { useHistory } from 'react-router-dom'
const LIST_TYPE = [
  {
    name: 'Liquidity Providers',
    value: 'liquidityProviders'
  },
  {
    name: 'Farm',
    value: 'farm'

  },
  {
    name: 'Stake',
    value: 'stake'

  }
]
const WalletOverviewContext = createContext()

const WalletOverviewContextProvider = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState('liquidityProviders')
  const [searchInputAddress, setSearchInputAddress] = useState('')

  const [lpContextInfo, setLPContextInfo] = useState({})
  const history = useHistory()

  const onNavigateToDetailWallet = (row) => {
    const address = get(row, 'account')
    history.push(`/info/wallet/${address}`)
  }

  return (
    <WalletOverviewContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
        searchInputAddress,
        setSearchInputAddress,
        lpContextInfo,
        setLPContextInfo,
        onNavigateToDetailWallet

      }}
    >
      {children}
    </WalletOverviewContext.Provider>
  )
}

export { WalletOverviewContext, WalletOverviewContextProvider }
