import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { getLength } from 'common/functions'
import { Link } from 'react-router-dom'

const SidebarDropdown = ({ menus, chooseUrl, handleOpenMenu }) => {
  const checkActiveUrl = (menu) => {
    const findIdx = menu.subMenu?.findIndex(item => chooseUrl.includes(item.url))
    return (findIdx >= 0) || (chooseUrl === menu.url)
  }

  useEffect(() => {
    const dropdown = document.getElementsByClassName('dropdown-btn')
    let i

    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener('click', function () {
        this.classList.toggle('active')
        const dropdownContent = this.nextElementSibling
        if (dropdownContent.style.display === 'block') {
          dropdownContent.style.display = 'none'
        } else {
          dropdownContent.style.display = 'block'
        }
      })
    }
  }, [])

  const renderItemMenu = (item) => {
    const isActve = checkActiveUrl(item)
    const isNew = item?.isNew
    return (
      <Link onClick={handleOpenMenu} to={item.url} className='menu-item'>{item.name}
        {isNew && <span className='new-menu'>NEW</span>}
      </Link>

    )
  }

  return (
    <div className={styles.sidebarDropdown}>

      <div className="sidenav">
        {menus.map(item => {
          const isActve = checkActiveUrl(item)
          const isSubmenu = getLength(item?.subMenu) > 0
          return (
            !isSubmenu
              ? renderItemMenu(item)
              : <>
                <button className="dropdown-btn">{item.name}
                  <i className="fa fa-caret-down"></i>
                </button>
                <div className="dropdown-container">
                  {item.subMenu.map((itemSub, index) => {
                    return (
                      <Link onClick={handleOpenMenu} key={index} to={itemSub.url}>{itemSub.name}</Link>
                    )
                  })}
                </div></>
          )
        })}
      </div>
    </div>
  )
}

export default SidebarDropdown
