import images from 'assets/images'
import React, { useState, useRef, useContext } from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
import useOnClickOutside from 'common/customHooks/useClickOutSide'
import ButtonConnected from 'components/common/ButtonConnected'
import { AppContext } from 'context/appContext'
import { useTranslation } from 'react-i18next'
import PageDropdown from 'components/PageDropdown'
import { Icon } from 'components/Icon'

const SidebarMenuTablet = ({ handleMode, currencyOptions, currencyValue, langOptions, langValue, onChangeLang, onChangeCurrency }) => {
  const [isOpenMenuTablet, setIsOpenMenuTablet] = useState(false)
  const dropdownRef = useRef()
  const appContext = useContext(AppContext)
  const { isDarkMode } = appContext
  const { t } = useTranslation()

  // const langRedux = useSelector(state => state.langRedux)
  // const currencyRedux = useSelector(state => state.currencyRedux)

  // const LIST_LANG = [
  //   {
  //     label: 'EN',
  //     value: 'en',
  //     render: (<div className='d-flex align-items-center'>
  //       <TokenImage src={`${window.location.origin}/Currency/USD.png`} className="token-img token-img--token-1 img-24 mr-3"/>
  //       <div className='currency-info'>
  //         <div className='d-flex align-items-center'>
  //           <div className={cn('text-sm')}>EN</div>
  //           <div className='text-xs color-grey ml-3'>{t('EN')}</div>
  //         </div>
  //         {langRedux === 'en' && <Icon name="web_tick_circle_check" className="color-theme mr-3"/>}
  //       </div>
  //     </div>)

  //   },
  //   {
  //     label: 'VI',
  //     value: 'vi',
  //     render: (<div className='d-flex align-items-center'>
  //       <TokenImage src={`${window.location.origin}/Currency/VND.png`} className="token-img token-img--token-1 img-24 mr-3"/>
  //       <div className='currency-info'>
  //         <div className='d-flex align-items-center'>
  //           <div className={cn('text-sm')}>VI</div>
  //           <div className='text-xs color-grey ml-3'>{t('VI')}</div>
  //         </div>
  //         {langRedux === 'vi' && <Icon name="web_tick_circle_check" className="color-theme mr-3"/>}
  //       </div>
  //     </div>)

  //   }

  // ]

  // const LIST_CURRENCY = CURRENCY_DATA.map(item => {
  //   const imageLink = `${window.location.origin}/Currency/${item}.png`
  //   const isActive = item === currencyRedux.label
  //   return {
  //     value: item,
  //     label: item,
  //     render: (
  //       <div className='d-flex align-items-center'>
  //         <TokenImage src={imageLink} className="token-img token-img--token-1 img-24 mr-3"/>
  //         <div className='currency-info'>
  //           <div>
  //             <div className={cn(isActive && 'color-theme', 'text-sm')}>{item} ({CURRENCY_SYMBOL[item]})</div>
  //             <div className='text-xs color-grey'>{t(item)}</div>
  //           </div>
  //           {isActive && <Icon name="web_tick_circle_check" className="color-theme mr-3"/>}
  //         </div>
  //       </div>
  //     )
  //   }
  // })

  useOnClickOutside(dropdownRef, () => {
    setIsOpenMenuTablet(false)
  })
  const onToggleMenuTablet = () => {
    setIsOpenMenuTablet(!isOpenMenuTablet)
  }
  return (
    <div ref={dropdownRef} className={styles.tabletMenuContainer}>
      <div className='icon-menu-tablet' onClick={onToggleMenuTablet}>
        <img src={images.iconMenu} alt="" />
      </div>

      <div className={cn('menu-content', isOpenMenuTablet && 'menu-content--open')}>
        <div className="menu-connect">
          <ButtonConnected />
        </div>
        <div className='d-flex justify-content-between mt-3'>
          <div className='d-flex align-items-center'>
            <img
              className="img-logo"
              src={images[isDarkMode ? 'iconSarosWhite' : 'iconSaros']}
              alt=""
            />
            <span>{t('theme')}</span>
          </div>
          <img src={images[isDarkMode ? 'iconMoon' : 'iconSun']} alt="" className="icon-theme-tablet" onClick={handleMode} />
        </div>

        <PageDropdown
          toggleclassName="toggle-lang-btn mt-3"
          prefix={<Icon name="web_global" className="text-l"/>}
          // menuClassName="menu-language"
          // dropdownItemClassName="item-curency"
          options={langOptions}
          defaultValue={langValue}
          onChange={onChangeLang}
        />

        <PageDropdown
          toggleclassName="toggle-lang-btn"
          prefix={<Icon name="web_market_cap" className="text-l"/>}
          // menuClassName="menu-language"
          dropdownItemClassName="item-curency"
          options={currencyOptions}
          defaultValue={currencyValue}
          onChange={onChangeCurrency}
        />

      </div>
    </div>
  )
}

export default SidebarMenuTablet
