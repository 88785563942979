import React, { useMemo, useState } from 'react'
import cn from 'clsx'

import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import {
  convertBalanceToWei,
  formatNumberBro,
  showNotificationToast,
  upperCase
} from 'common/functions'
import { sarosSwapPoolType, sarosSwapType } from 'common/constants'
import Button from 'components/common/Button'
import { Icon } from 'components/Icon'
import { get } from 'lodash'
import TokenImage from 'components/common/TokenImage'
import { SarosSwapService } from 'common/pool/saros_swap/sarosSwapServices'
import { genConnectionSolana, genOwnerSolana } from 'common/solana'
import { useSelector } from 'react-redux'
import { PublicKey } from '@solana/web3.js'
import BN from 'bn.js'

import {
  FEE_OWNER_LIQUIDITY,
  LIST_MINT_STABLE_COIN,
  SAROS_SWAP_PROGRAM_ADDRESS_V1
} from 'common/pool/constants'
import useStatusTxsHash from 'hooks/useStatusTxsHash'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import BaseAdapter from 'controller/api/BaseAdapter'
import useServices from 'controller/Library/useServices'
import { useWallet } from '@coin98-com/wallet-adapter-react'

function ModalConfirmLiquidity ({
  type,
  lpAmountReceive,
  rate0,
  rate1,
  percentShare,
  liquidityToken,
  liquidityAmount,
  dataPoolInfoLiquidity,
  initDataDefault,
  slippage = 0.5,
  setIsLoadingButton
}) {
  const { t } = useTranslation()
  const txsActions = useStatusTxsHash()
  const { sendRawTxn } = useServices()
  const { wallet } = useWallet()

  const [isAddLiquidity, setIsAddLiquidity] = useState(false)
  const accountSol = useSelector((state) => state.accountSol)
  const token0 = get(liquidityToken, 'token0')
  const token1 = get(liquidityToken, 'token1')
  const token0Amount = get(liquidityAmount, 'token0Amount', 0)
  const token1Amount = get(liquidityAmount, 'token1Amount', 0)
  const token0Symbol = upperCase(get(token0, 'symbol'))
  const token1Symbol = upperCase(get(token1, 'symbol'))
  const isRemoveLiquidity = sarosSwapType.removeLiquidity === type

  const isSaros = wallet?.adapter?.name === 'Saros'

  const onCloseModal = () => {
    window.closeModal()
  }

  const handleSetLoading = (status) => {
    setIsLoadingButton(status)
    setIsAddLiquidity(status)
  }

  const handleConfirm = async () => {
    handleSetLoading(true)
    const connection = genConnectionSolana()
    const owner = await genOwnerSolana(accountSol)
    const token0Mint = new PublicKey(get(token0, 'mintAddress'))
    const token1Mint = new PublicKey(get(token1, 'mintAddress'))
    const token0AmountWei =
      parseFloat(token0Amount) <= 0
        ? new BN(0)
        : new BN(convertBalanceToWei(token0Amount, get(token0, 'decimals')))
    const token1AmountWei =
      parseFloat(token1Amount) <= 0
        ? new BN(0)
        : new BN(convertBalanceToWei(token1Amount, get(token1, 'decimals')))

    const objActions = {
      [sarosSwapType.addLiquidiTy]: handleAddPoolLiquidity,
      [sarosSwapType.createPool]: handleCreateLiquidity,
      [sarosSwapType.removeLiquidity]: handleRemoveLiquidity
    }
    objActions[type]({
      connection,
      owner,
      token0Mint,
      token1Mint,
      token0AmountWei,
      token1AmountWei
    })
  }

  const handleRemoveLiquidity = async ({
    connection,
    owner,
    token0Mint,
    token1Mint,
    token0AmountWei,
    token1AmountWei
  }) => {
    try {
      const poolAddress = new PublicKey(get(dataPoolInfoLiquidity, 'id'))
      const lpTokenMint = get(
        dataPoolInfoLiquidity,
        'poolAccountInfo.lpTokenMint'
      )
      const lpAmountReceiveWei = convertBalanceToWei(
        lpAmountReceive,
        get(dataPoolInfoLiquidity, 'poolAccountInfo.decimals')
      )

      const response = await SarosSwapService.withdrawAllTokenTypesNew(
        sendRawTxn,
        connection,
        owner,
        owner,
        lpTokenMint,
        lpAmountReceiveWei,
        poolAddress,
        SAROS_SWAP_PROGRAM_ADDRESS_V1,
        token0Mint,
        token1Mint,
        token0AmountWei,
        token1AmountWei,
        slippage,
        (hash, poolAddress) => {
          handleTxsSuccess({ hash, poolAddress })
        },
        isSaros
      )
      const { isErr, data } = response
      if (isErr) {
        return handleTxsFailed(data)
      }
    } catch (err) {
      console.log({ err })
      handleTxsFailed()
    }
  }

  const handleCreateLiquidity = async ({
    connection,
    owner,
    token0Mint,
    token1Mint,
    token0AmountWei,
    token1AmountWei
  }) => {
    try {
      const isStableCoin =
        LIST_MINT_STABLE_COIN.includes(token0Mint.toString()) &&
        LIST_MINT_STABLE_COIN.includes(token1Mint.toString())
      const curveType = isStableCoin ? 1 : 0
      const curveParameter = new BN(isStableCoin ? 1 : 0)

      const callBack = (poolAddress) => (resSend) => {
        const { data, error, isError } = resSend
        if (isError) {
          return handleTxsFailed(error)
        } else {
          handleTxsSuccess({ hash: data, poolAddress })
        }
      }

      const response = await SarosSwapService.createPoolNew(
        sendRawTxn,
        connection,
        owner,
        FEE_OWNER_LIQUIDITY,
        token0Mint,
        token1Mint,
        token0AmountWei,
        token1AmountWei,
        curveType,
        curveParameter,
        TOKEN_PROGRAM_ID,
        SAROS_SWAP_PROGRAM_ADDRESS_V1,
        callBack
      )
    } catch (err) {
      console.log({ err })
    }
  }

  const handleAddPoolLiquidity = async ({
    connection,
    owner,
    token0Mint,
    token1Mint,
    token0AmountWei,
    token1AmountWei
  }) => {
    try {
      const poolAddress = new PublicKey(get(dataPoolInfoLiquidity, 'id'))
      const response = await SarosSwapService.depositAllTokenTypesNew(
        sendRawTxn,
        connection,
        owner,
        owner,
        new PublicKey(accountSol),
        token0Mint,
        token1Mint,
        token0AmountWei,
        token1AmountWei,
        parseInt(convertBalanceToWei(lpAmountReceive, 2)),
        poolAddress,
        SAROS_SWAP_PROGRAM_ADDRESS_V1,
        slippage,
        (hash, poolAddress) => {
          handleTxsSuccess({ hash, poolAddress })
        },
        isSaros
      )

      const { isErr, data } = response
      if (isErr) {
        return handleTxsFailed(data)
      }
    } catch (err) {
      console.log({ err })
      handleTxsFailed()
    }
  }

  const getNewData = ({ hash, poolAddress }) => {
    window.closeModal()
    BaseAdapter.postData('saros/liquidity', {
      hash,
      owner: accountSol,
      base: token0,
      pair: token1,
      size: token0Amount,
      sizeTo: token1Amount,
      type,
      poolAddress,
      poolType: sarosSwapPoolType.C98
    }).then((res) => {
      initDataDefault()
      txsActions.viewCompleteHash({ hash })
      window.closeModalFirst()
      handleSetLoading(false)
    })
  }

  const handleTxsSuccess = ({ hash, poolAddress }) => {
    txsActions.handleTxsSuccess({
      message: t('pleaseWaitOrder'),
      callBack: () => getNewData({ hash, poolAddress })
    })
  }

  const handleTxsFailed = (textMess = 'txsFail') => {
    window.closeModal()
    showNotificationToast(t(textMess), 'error', 2000)
    handleSetLoading(false)
  }

  const renderText = useMemo(() => {
    if (isRemoveLiquidity) {
      return (
        <div className="content-liquidity-modal-item__title color-grey uppercase">
          {`${token0Symbol}/${token1Symbol} LP`}
        </div>
      )
    }
    return (
      <div className="content-liquidity-modal-item__title color-grey">
        {token0Symbol} {'deposited'}
      </div>
    )
  }, [type])

  const renderBalanceHead = () => {
    switch (type) {
    case sarosSwapType.removeLiquidity:
      return (
        <div className="info-token-remove mt-5 mb-4">
          <div className="info-token-remove--token-item">
            <TokenImage
              src={get(token0, 'icon')}
              className="token-remove-icon img-48"
            />

            <div className="token-remove-name text-base">{token0Symbol}</div>

            <div className="token-remove-balance color-theme">
              {formatNumberBro({
                number: token0Amount, mantissa: 6
              })}
            </div>
          </div>

          <Icon name="web_add" className="icon-plus" />

          <div className="info-token-remove--token-item">
            <TokenImage
              src={get(token1, 'icon')}
              className="token-remove-icon img-48"
            />

            <div className="token-remove-name text-base">{token1Symbol}</div>

            <div className="token-remove-balance color-theme">
              {formatNumberBro({
                number: token1Amount, mantissa: 6
              })}
            </div>
          </div>
        </div>
      )
    default:
      return (
        <div className="total-balance-header mb-4">
          {formatNumberBro({ number: lpAmountReceive, mantissa: 2 })}
        </div>
      )
    }
  }

  const renderContentModal = () => {
    switch (type) {
    case sarosSwapType.removeLiquidity:
      return (
        <>
          <div className="content-liquidity-modal-item text-sm">
            {renderText}

            <div className="content-liquidity-modal-item__value">
              {formatNumberBro({ number: lpAmountReceive })}
              <TokenImage
                src={get(token0, 'icon')}
                className="token-img img-24 ml-2"
              />
              <TokenImage
                src={get(token1, 'icon')}
                className="token-img img-24 ml-2"
              />
            </div>
          </div>

          <div className="content-liquidity-modal-item content-liquidity-modal-item--rate text-sm">
            <div className="content-liquidity-modal-item__title color-grey">
              {t('rate')}
            </div>

            <div className="content-liquidity-modal-item__value">
                1 {token0Symbol} = {formatNumberBro({ number: rate0, mantissa: 6 })}{' '}
              {token1Symbol} <br />1 {token1Symbol} ={' '}
              {formatNumberBro({ number: rate1, mantissa: 6 })} {token0Symbol}
            </div>
          </div>
        </>
      )
    default:
      return (
        <>
          <div className="content-liquidity-modal-item text-sm">
            <div className="content-liquidity-modal-item__title color-grey">
              {token0Symbol} {t('deposited')}
            </div>

            <div className="content-liquidity-modal-item__value">
              {formatNumberBro({ number: token0Amount, mantissa: 6 })}{' '}
              <TokenImage
                src={get(token0, 'icon')}
                className="token-img img-24 ml-2"
              />
            </div>
          </div>

          <div className="content-liquidity-modal-item text-sm">
            <div className="content-liquidity-modal-item__title color-grey">
              {token1Symbol} {t('deposited')}
            </div>

            <div className="content-liquidity-modal-item__value">
              {formatNumberBro({ number: token1Amount, mantissa: 6 })}{' '}
              <TokenImage
                src={get(token1, 'icon')}
                className="token-img img-24 ml-2"
              />
            </div>
          </div>

          <div className="content-liquidity-modal-item content-liquidity-modal-item--rate text-sm">
            <div className="content-liquidity-modal-item__title color-grey">
              {t('rate')}
            </div>

            <div className="content-liquidity-modal-item__value">
                1 {token0Symbol} = {formatNumberBro({ number: rate0, mantissa: 6 })}{' '}
              {token1Symbol} <br />1 {token1Symbol} ={' '}
              {formatNumberBro({ number: rate1, mantissa: 6 })} {token0Symbol}
            </div>
          </div>

          <div className="content-liquidity-modal-item text-sm">
            <div className="content-liquidity-modal-item__title color-grey">
              {t('shareOfPool')}
            </div>

            <div className="content-liquidity-modal-item__value">
              {formatNumberBro({ number: percentShare, mantissa: 2 })}%
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <div className={cn(styles['modal-add-liquidity-container'])}>
      <div className="modal-add-liquidity__header">
        <Icon name="web_share_token" type="lg" className="mb-3" />

        <div className="text-header text-l">{t('youWillReceive')}</div>

        {renderBalanceHead()}
      </div>

      <div className="modal-add-liquidity__content">{renderContentModal()}</div>

      <div className="modal-add-liquidity__actions">
        <Button type="grey" className="btn-action mr-4" onClick={onCloseModal}>
          {t('cancel')}
        </Button>
        <Button
          isLoading={isAddLiquidity}
          className="btn-action"
          onClick={handleConfirm}
        >
          {t(isRemoveLiquidity ? 'remove' : 'supply')}
        </Button>
      </div>
    </div>
  )
}

export default ModalConfirmLiquidity
