import images from 'assets/images'
import { IS_DEV } from 'common/constants'
import Button from 'components/common/Button'
import { Icon } from 'components/Icon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

const PopupHash = ({ hash }) => {
  const { t } = useTranslation()

  const handleOpenTxsHash = () => {
    const url = `https://solscan.io/tx/${hash}${IS_DEV ? '?cluster=devnet' : ''}`
    window.open(url)
    window.closeModal()
  }
  return (
    <div className={styles.successModalContainer}>
      <div className='modal-top'>
        {/* <img className="logo-image" src={images.logoSuccess} alt="" /> */}
        <Icon type="lg" name="saros_success"/>
        <div className='modal-label text-xl'>{t('success')}</div>
      </div>
      <div className='text-center mt-2'>{t('completeOrderFarm')}</div>
      <Button onClick={() => { window.closeModal() }} isFullWidth className="button-close">{t('close')}</Button>
      <div onClick={handleOpenTxsHash} className='mt-3 mb-3 text-center'>
        <a href='#'>{t('viewTransaction')}</a>
      </div>
    </div>
  )
}

export default PopupHash
