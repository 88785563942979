import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import LiquidityAmm from 'common/liquidity'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'

const SwapContext = createContext()

const SwapContextProvider = ({ children }) => {
  const history = useHistory()
  const accountSol = useSelector((state) => state.accountSol)
  const isSession = useSelector(state => state.isSession)

  const [listPoolNew, setListPoolNew] = useState([])

  const [isLoadingListToken, setIsLoadingListToken] = useState(false)

  const [swapRouterData, setSwapRouterData] = useState({})
  const [isLoadingSwapRouter, setIsLoadingSwapRouter] = useState()
  const [selectedRecentSwap, setSelectedRecentSwap] = useState({})

  const usdcMint = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
  const c98Mint = 'C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9'
  const cusdMint = 'CUSDvqAQLbt7fRofcmV2EXfPA2t36kzj7FjzdmqDiNQL'

  const isShortcut = useMemo(() => {
    return !get(history, 'location.pathname', '').includes('swap')
  }, [history])

  useEffect(() => {
    fetchTokenListNew()
  }, [accountSol, isSession])

  const fetchTokenListNew = async () => {
    setIsLoadingListToken(true)
    const dataPool = await LiquidityAmm.getAllAmm()
    const listPool = [
      // ...dataPool.saros,
      ...dataPool.orca
      // ...dataPool.aldrin,
      // ...dataPool.crema,
      // ...dataPool.cropper
      // ...dataPool.saber,
      // ...dataPool.whirlpool,
      // ...dataPool.mercurial,
      // ...dataPool.raydium
    ]
    setListPoolNew(listPool)

    setIsLoadingListToken(false)
  }

  const isBurnOrMintCUSD = (fromMintAddress, toMintAddress) => {
    const isCUSDMint = fromMintAddress === usdcMint && toMintAddress === cusdMint
    const isCUSDBurn = fromMintAddress === cusdMint && toMintAddress === usdcMint
    if (fromMintAddress && toMintAddress) {
      return isCUSDMint || isCUSDBurn
    } else return false
  }

  return (
    <SwapContext.Provider
      value={{
        listPoolNew,
        setSwapRouterData,
        swapRouterData,
        setIsLoadingSwapRouter,
        selectedRecentSwap,
        setSelectedRecentSwap,
        isLoadingSwapRouter,
        isLoadingListToken,
        isShortcut,
        isBurnOrMintCUSD
      }}
    >
      {children}
    </SwapContext.Provider>
  )
}

export { SwapContext, SwapContextProvider }
