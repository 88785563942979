import initState from '../lib/initState'
import createReducer from '../lib/reducerConfig'
import { KEY_ACTION } from '../lib/constants'

export const langRedux = createReducer('en', {
  [KEY_ACTION.SET_LANG] (state, action) {
    return action.payload
  }
})

export const accountSol = createReducer(initState.stringEmpty, {
  [KEY_ACTION.SET_ACCOUNT_SOL] (state, action) {
    return action.payload
  }
})

export const balanceSol = createReducer(initState.numberZero, {
  [KEY_ACTION.SET_BALANCE_SOL] (state, action) {
    return action.payload
  }
})

export const isConnectFirst = createReducer(initState.boolFalse, {
  [KEY_ACTION.SET_CONNECT_FIRST] (state, action) {
    return action.payload
  }
})

export const isSession = createReducer(initState.boolFalse, {
  [KEY_ACTION.SET_IS_SESSION] (state, action) {
    return action.payload
  }
})

export const isConnected = createReducer(initState.boolFalse, {
  [KEY_ACTION.SET_CONNECTED] (state, action) {
    return action.payload
  }
})

export const walletRedux = createReducer(initState.arrayEmpty, {
  [KEY_ACTION.SET_WALLET_REDUX] (state, action) {
    return action.payload
  }
})

export const deviceId = createReducer(initState.stringEmpty, {
  [KEY_ACTION.SET_DEVICE_ID] (state, action) {
    return action.payload
  }
})

export const walletActive = createReducer(initState.objEmpty, {
  [KEY_ACTION.SET_WALLET_ACTIVE] (state, action) {
    return action.payload
  }
})

export const themeRedux = createReducer('light', {
  [KEY_ACTION.SET_THEME_MODE] (state, action) {
    return action.payload
  }
})

export const dataFarmStake = createReducer(initState.objNull, {
  [KEY_ACTION.SET_DATA_FARM_STAKE] (state, action) {
    return action.payload
  }
})

export const listFavoriteTokenRedux = createReducer(initState.arrayEmpty, {
  [KEY_ACTION.SET_LIST_FAVORITE_TOKEN] (state, action) {
    return action.payload
  }
})

export const listFavoritePoolRedux = createReducer(initState.arrayEmpty, {
  [KEY_ACTION.SET_LIST_FAVORITE_POOL] (state, action) {
    return action.payload
  }
})

export const currencyRedux = createReducer(initState.initCurrency, {
  [KEY_ACTION.SET_CURRENCY_REDUX] (state, action) {
    return action.payload
  }
})

export const recentSwapRedux = createReducer(initState.arrayEmpty, {
  [KEY_ACTION.SET_CURRENT_SWAP] (state, action) {
    return action.payload
  }
})
