import get from 'lodash/get'
import SupportAPI from 'controller/api/SupportAPI'
import BaseAdapter from 'controller/api/BaseAdapter'
import axios from 'axios'

export default class WalletServices {
  constructor (props) {
    this.tokenSolana = []
    this.coinGecko = []
    this.findCoingeckoData = this.findCoingeckoData.bind(this)
    this.findCoinGeckoPrice = this.findCoinGeckoPrice.bind(this)
    this.refreshFetchData = this.refreshFetchData.bind(this)
    this.fetchSetting = this.fetchSetting.bind(this)
    this.fetchTokenSolana = this.fetchTokenSolana.bind(this)
  }

  findCoinGeckoPrice (cgkId) {
    const findInCgk = this.coinGecko.find((data) => data.id === cgkId)
    return get(findInCgk, 'current_price', 0)
  }

  findCoingeckoData (cgkId) {
    const findInCgk = this.coinGecko.find((data) => data.id === cgkId)
    return findInCgk
  }

  findSetting () {
    return this.setting
  }

  allTokenSolana () {
    return this.tokenSolana
  }

  getMultiplyFeePriority () {
    return this.multiplyFeePriority || 100
  }

  async fetchSetting () {
    return new Promise(async (resolve) => {
      try {
        const response = await SupportAPI.getSetting()
        const numberMultiply = await axios.get('https://rapid.coin98.com/Config%2FbufferGas.json')
        if (numberMultiply?.status === 200) {
          this.multiplyFeePriority = numberMultiply?.data
        }
        if (response) {
          this.setting = response
        }
        resolve()
      } catch (error) {
        resolve()
      }
    })
  }

  async refreshFetchData (isReloadNew) {
    return new Promise(async (resolve, reject) => {
      const response = await SupportAPI.getCoinGecko()
      if (response) {
        this.coinGecko = response
        resolve(response)
      }
    })
  }

  async fetchTokenSolana () {
    const getAllTokenSolana = await BaseAdapter.getData('wallet/spl')
    const dataReplace = JSON.stringify(get(getAllTokenSolana, 'data') || [])
      .replaceAll('"address"', '"mintAddress"')
      .replaceAll('"image"', '"icon"')
      .replaceAll('"cgkId"', '"id"')
    this.tokenSolana = JSON.parse(dataReplace)
  }
}
