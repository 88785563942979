export const KEY_STORE = {
  LIST_TOKEN_CUSTOM: 'LIST_TOKEN_CUSTOM',
  SET_TRADE_SETTING: 'SET_TRADE_SETTING',
  JWT_TOKEN: 'JWT_TOKEN',
  JWT_TOKEN_ADAPTER: 'JWT_TOKEN_ADAPTER',
  SPAM_TOKEN: 'SPAM_TOKEN',
  GET_LOCALE: 'GET_LOCALE',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  IS_RESET_PASS: 'IS_RESET_PASS',
  FEE_TXS: 'FEE_TXS',
  ACCOUNT: 'ACCOUNT',
  GAS_VALUE: 'GAS_VALUE',
  CREATE_WALLET: 'CREATE_WALLET',
  TERM: 'TERM',
  ADDRESS_CONNECTED: 'ADDRESS_CONNECTED',
  DATA_MODAL: 'DATA_MODAL',
  MARKET_TABLE: 'MARKET_TABLE',
  IS_CONNECT_FIRST: 'IS_CONNECT_FIRST',
  TYPE_PASSWORD: 'TYPE_PASSWORD',
  LIST_FAVORITE: 'LIST_FAVORITE',
  VERSION_LIST_TOKEN: 'VERSION_LIST_TOKEN',
  HOME_STATISTICAL: 'HOME_STATISTICAL'
}
