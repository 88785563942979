import { getLength } from 'common/functions'
import useScreenWidth from 'hooks/useScreenWidth'
import FavoritePool from 'pages/NewInfoPool/Components/FavoritePool'
import FavoriteToken from 'pages/NewInfoPool/Components/FavoriteToken'
import React, { useRef } from 'react'
import styles from './style.module.scss'

const FavoriteScrollList = ({ listFavorite, type = 'token' }) => {
  const { width } = useScreenWidth()
  const isMobile = width < 576
  const scrollRef = useRef()

  const onTouchFavList = (isTouch) => () => {
    if (!isMobile) return
    const numline = parseFloat(Math.ceil(getLength(listFavorite) / 4))
    const paddingTopSpace = 1

    if (isTouch) {
      scrollRef.current.style.maxHeight =
        numline < 7
          ? `${numline * 6.5 + paddingTopSpace}rem`
          : `${30 + paddingTopSpace}rem`
      scrollRef.current.style.transition = '0.5s'
    } else {
      scrollRef.current.style.maxHeight = '13rem'
      scrollRef.current.style.backgroundColor = 'transparent'
      scrollRef.current.style.transition = '0.5s'
    }
  }

  const onSrcollFavoriteList = () => {
    if (isMobile) return
    const threshHold = 50
    const paddingTopSpace = 1
    if (scrollRef.current) {
      const scroll = scrollRef.current.scrollTop
      const numline = parseFloat(Math.ceil(getLength(listFavorite) / 4))
      if (scroll > threshHold) {
        // downscroll
        scrollRef.current.style.maxHeight =
          numline < 7
            ? `${numline * 5.6 + paddingTopSpace}rem`
            : `${30 + paddingTopSpace}rem`
        scrollRef.current.style.transition = '0.5s'
      } else if (scroll === 0) {
        // upscroll
        scrollRef.current.style.maxHeight = '11rem'
        scrollRef.current.style.backgroundColor = 'transparent'
        scrollRef.current.style.transition = '0.5s'
      }
    }
  }
  return (
    <div
      ref={scrollRef}
      className={styles.favorListContainer}
      onTouchMove={onTouchFavList(true)}
      onTouchCancel={onTouchFavList(false)}
      onScroll={onSrcollFavoriteList}
    >
      <div className="favorite-list">
        {listFavorite.map((item, index) => (
          <React.Fragment key={index}>
            {type === 'token'
              ? (
                <FavoriteToken data={item} />
              )
              : (
                <FavoritePool data={item} />
              )}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default FavoriteScrollList
