/* eslint-disable new-cap */
import { u64 } from '@solana/spl-token'
import { OrcaU64 } from 'common/orca/ultils/orcaU64'
import { DecimalUtil } from 'common/orca/ultils/decimalUltils'

export const ZERO = new u64(0)
export const ONE = new u64(1)

export class U64Utils {
  static toTokenU64 (input, token, varName) {
    if (input instanceof OrcaU64) {
      if (input.scale !== token.scale) {
        throw new Error(
          `${varName}'s scale of ${input.scale} does not match token's decimal of ${token.scale}`
        )
      }
      return input.toU64()
    }

    return DecimalUtil.toU64(input, token.scale)
  }

  static toPoolU64 (input, pool, varName) {
    if (input instanceof OrcaU64) {
      if (input.scale !== pool.poolTokenDecimals) {
        throw new Error(
          `${varName}'s scale of ${input.scale} does not match pool's decimal of ${pool.poolTokenDecimals}`
        )
      }
      return input.toU64()
    }

    return DecimalUtil.toU64(input, pool.poolTokenDecimals)
  }

  static toFarmU64 (input, farm, varName) {
    if (input instanceof OrcaU64) {
      if (input.scale !== farm.baseTokenDecimals) {
        throw new Error(
          `${varName}'s scale of ${input.scale} does not match baseToken's decimal of ${farm.baseTokenDecimals}`
        )
      }
      return input.toU64()
    }

    return DecimalUtil.toU64(input, farm.baseTokenDecimals)
  }

  static ceilingDivision (dividend, divisor) {
    let quotient = dividend.div(divisor)
    if (quotient.eq(ZERO)) {
      return [ZERO, divisor]
    }

    let remainder = dividend.mod(divisor)
    if (remainder.gt(ZERO)) {
      quotient = quotient.add(ONE)
      divisor = dividend.div(quotient)
      remainder = dividend.mod(quotient)
      if (remainder.gt(ZERO)) {
        divisor = divisor.add(ONE)
      }
    }

    return [quotient, divisor]
  }
}
