import React, { useContext } from 'react'
import Slider, { createSliderWithTooltip } from 'rc-slider'

import './style.scss'
import { AppContext } from 'context/appContext'
const SliderWithTooltip = createSliderWithTooltip(Slider)

function SliderComponent ({ onChange, value, min = 0, max = 100, step = 1, marks, tipFormatter, className, onAfterChange }) {
  const appContext = useContext(AppContext)

  const { isDarkMode } = appContext

  return (
    <div className="slider-container" >
      <SliderWithTooltip
        step={step}
        className={className}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        onAfterChange={onAfterChange}
        marks={marks}
        tipFormatter={tipFormatter}
        railStyle={{
          height: 4,
          background: isDarkMode ? '#fff' : '#bbb'
        }}
        dotStyle={{
          borderColor: 'transparent',
          background: isDarkMode ? '#fff' : '#bbb'
        }}
        handleStyle={{
          height: 16,
          width: 16,
          backgroundColor: '#5f3cd8',
          top: '-10%',
          border: 0,
          outline: 'none'
        }}
        trackStyle={{
          background: '#5f3cd8',
          height: 4
        }}
      />
    </div>
  )
}

export default SliderComponent
