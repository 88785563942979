import React from 'react'
import style from './style.module.scss'
import cn from 'clsx'
import { Icon } from 'components/Icon'

const EmptyLiquidity = ({ content }) => {
  return (
    <div className={cn(style['empty-content'], 'text-base')}>
      <Icon type="lg" className="icon" name="saros_no_liquidity"/>
      {content}
    </div>
  )
}

export default EmptyLiquidity
