import React from 'react'
import cn from 'clsx'
import styles from './style.module.scss'
export const Icon = ({ type, name, className, isBackground, backgroundClassName, round, isPrefix, ...props }) => {
  const classNames = cn(
    `icon-${name}`,
    isBackground ? styles.normalSize : '',
    styles.iconFont,
    className)

  if (isBackground) {
    return (
      <div {...props} className={cn(styles.backgroundContainer, backgroundClassName)}>
        <span className={classNames} ></span>
      </div>
    )
  }
  if (props.onClick && !isBackground) {
    return (
      <div {...props} className={cn(styles.clickIconContainer, backgroundClassName)}>
        <span className={classNames} ></span>
      </div>
    )
  }
  if (type === 'lg') {
    return <span className={cn(`icon-${name}`, styles.logoImage, className)} {...props}></span>
  }
  return <span className={classNames} {...props}></span>
}
