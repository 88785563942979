import InputSearch from 'components/InputSearch'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FarmBanner from './Components/FarmBanner'
import styles from './style.module.scss'
import cn from 'clsx'
import FarmBox from './Components/FarmBox'
import SwitchButton from 'components/SwitchButton/SwitchButton'
import { FarmContext } from 'context/farm/farmContext'
import { get } from 'lodash'
import EmptyData from 'components/EmptyData'
import { getLength, lowerCase } from 'common/functions'
import PuffLoading from 'components/common/PuffLoading'
import CountdownCircle from 'components/CountdownCircle'
import { useSelector } from 'react-redux'
import { Icon } from 'components/Icon'
import useScreenWidth from 'hooks/useScreenWidth'
import Tooltip from 'components/Tooltip'

const ACTIVE = 'ACTIVE'
const COMPLETED = 'COMPLETED'
const SORT_APR = 'SORT_APR'
const SORT_LIQUIDITY = 'SORT_LIQUIDITY'
const TYPE_SORT = SORT_LIQUIDITY

const NewFarmScreen = () => {
  const { t } = useTranslation()
  const accountSol = useSelector((state) => state.accountSol)
  const isSession = useSelector((state) => state.isSession)
  const currencyRedux = useSelector(state => state.currencyRedux)
  const currencyRate = get(currencyRedux, 'value', 1)

  const { width } = useScreenWidth()
  const isMobile = width < 768

  const farmContext = useContext(FarmContext)
  const {
    listFarm,
    isLoading,
    isStakeScreen,
    isFirstRender,
    getListPool,
    updateDataUserInfoInPool
  } = farmContext
  const [txtSearch, setTxtSearch] = useState('')
  const [isStaked, setIsStaked] = useState(false)
  const [activeTab, setActiveTab] = useState(ACTIVE)
  const [typeSort, setTypeSort] = useState(TYPE_SORT)
  const [isSortHightToLow, setIsSortHightToLow] = useState(true)

  const farmBoxRef = useRef()

  useEffect(() => {
    handleClear()
  }, [isStakeScreen])

  useEffect(() => {
    handleScrollToFarmBox()
  }, [listFarm, isFirstRender])

  useEffect(() => {
    getListPool().then((res) => {
      handleRefreshFarm(res)
    })
  }, [accountSol, isStakeScreen, isSession])

  const handleRefreshFarm = (res = listFarm) => {
    updateDataUserInfoInPool(res)
  }

  const handleScrollToFarmBox = () => {
    const search = new URL(window.location.href).search
    const query = new URLSearchParams(search)
    const poolAddress = query.get('poolAddress')
    if (!poolAddress && getLength(listFarm) > 0) return

    const poolSearch = listFarm.find(
      (farm) => farm.poolAddress === poolAddress
    )
    const poolName = get(poolSearch, 'name', '')
    const statusFarm = get(poolSearch, 'isActive')

    if (!isLoading && poolName && isFirstRender) {
      setTxtSearch(poolName)
      setActiveTab(statusFarm ? ACTIVE : COMPLETED)
    }
  }

  const newDataFarm = useMemo(() => {
    let newList = listFarm.filter((item) => {
      const name = lowerCase(get(item, 'name', ''))
      const token0 = get(item, 'token0', '')
      const token1 = get(item, 'token1', '')
      const stakingToken = get(item, 'stakingToken', '')

      const poolAddress = lowerCase(get(item, 'poolAddress', ''))
      const stringSearch = lowerCase(txtSearch)

      return (
        name.includes(stringSearch) ||
        poolAddress.includes(stringSearch) ||
        lowerCase(get(token0, 'name', '')).includes(stringSearch) ||
        lowerCase(get(token1, 'name', '')).includes(stringSearch) ||
        lowerCase(get(stakingToken, 'name', '')).includes(stringSearch) ||
        lowerCase(get(stakingToken, 'mintAddress', '')).includes(
          stringSearch
        ) ||
        lowerCase(get(token0, 'mintAddress', '')).includes(stringSearch) ||
        lowerCase(get(token1, 'mintAddress', '')).includes(stringSearch)
      )
    })
    if (isStaked) {
      newList = newList.filter((item) => {
        const userStaked = get(item, 'userInfo.amountUserStaked', 0)
        return userStaked > 0
      })
    }

    // action sort
    newList = newList.sort((a, b) => {
      const keySort = typeSort === SORT_APR ? 'totalApr' : 'liquidityUsd'
      if (isSortHightToLow) {
        return get(b, `${keySort}`, 0) - get(a, `${keySort}`, 0)
      }
      return get(a, `${keySort}`, 0) - get(b, `${keySort}`, 0)
    })

    const listActive = newList.filter((farm) => get(farm, 'isActive'))
    const listEnd = newList.filter((farm) => !get(farm, 'isActive'))
    return {
      listEnd,
      listActive
    }
  }, [
    listFarm,
    txtSearch,
    isStaked,
    activeTab,
    isStakeScreen,
    typeSort,
    isSortHightToLow
  ])

  const DUMMY_TABS = [
    {
      key: ACTIVE,
      name: 'active',
      length: getLength(get(newDataFarm, 'listActive', [])),
      notification: ''
    },
    {
      key: COMPLETED,
      name: 'completed',
      length: getLength(get(newDataFarm, 'listEnd', [])),
      notification: ''
    }
  ]

  const handleClear = () => {
    setTxtSearch('')
    setIsStaked(false)
    setActiveTab(ACTIVE)
    setTypeSort(TYPE_SORT)
    setIsSortHightToLow(true)
  }

  const handleChangeSearch = (e) => {
    const { value } = e.target
    setTxtSearch(value)
  }

  const handleToggleSwitch = () => {
    setIsStaked(!isStaked)
  }

  const handleActivePool = (tab) => () => {
    setActiveTab(tab.key)
  }

  const handleSort = (type) => () => {
    setTypeSort(type)
    if (type === typeSort) {
      setIsSortHightToLow(!isSortHightToLow)
    } else {
      setIsSortHightToLow(true)
    }
  }

  const isEmptyFarm = useMemo(() => {
    const listData = get(
      newDataFarm,
      `${activeTab === COMPLETED ? 'listEnd' : 'listActive'}`,
      []
    )
    return getLength(listData) <= 0 && !isLoading
  }, [isLoading, newDataFarm, activeTab])

  const renderContentFarm = () => {
    const listData = get(
      newDataFarm,
      `${activeTab === COMPLETED ? 'listEnd' : 'listActive'}`,
      []
    )
    if (isLoading) {
      return (
        <div className="list-farm-empty">
          <PuffLoading />
        </div>
      )
    }
    return isEmptyFarm
      ? (
        <div className="list-farm-empty">
          <EmptyData />
        </div>
      )
      : (
        <div ref={farmBoxRef} className="list-farm-container">
          {listData.map((farm) => (
            <FarmBox dataFarm={farm} key={get(farm, 'poolAddress', '')} />
          ))}
        </div>
      )
  }

  return (
    <div className={cn(styles.newFarmContainer, 'container')}>
      <FarmBanner />

      <div className="action-bar">
        <div className="action-left">
          <div className="list-tab">
            {DUMMY_TABS.map((tab) => (
              <div
                onClick={handleActivePool(tab)}
                key={tab.key}
                className={`list-tab__item  ${
                  activeTab === tab.key ? 'list-tab__item--active' : ''
                }`}
              >
                {!!tab.notification && (
                  <span className="tab-item-notification">
                    {tab.notification}
                  </span>
                )}
                {t(tab.name)}
                <span className="ml-1 color-normal">({tab.length})</span>
              </div>
            ))}
          </div>
          <div className="switch-wrapper">
            <SwitchButton onToggle={handleToggleSwitch} isToggle={isStaked} />
            <div className="text-sm font-bold color-grey ml-2">
              {t('staked')}
            </div>
          </div>
        </div>
        <div className="action-right">
          <div className='d-flex align-items-center justify-content-between'>
            <div className="action-sort">
              {t('sort')}:{' '}
              <div
                onClick={handleSort(SORT_LIQUIDITY)}
                className={cn('action-sort__item', {
                  'action-sort__item--active': typeSort === SORT_LIQUIDITY
                })}
              >
                <p>{t('liquidity')}</p>
                <Icon
                  className={cn('item-icon', {
                    'item-icon--high-to-low': !isSortHightToLow
                  })}
                  name="web_arrow_down"
                />
              </div>
              <div className="action-sort__item color-grey">|</div>
              <div
                onClick={handleSort(SORT_APR)}
                className={cn('action-sort__item', {
                  'action-sort__item--active': typeSort === SORT_APR
                })}
              >
                <p>{t('apr')}</p>
                <Icon
                  className={cn('item-icon', {
                    'item-icon--high-to-low': !isSortHightToLow
                  })}
                  name="web_arrow_down"
                />
              </div>

            </div>

            {
              isMobile &&
        <Tooltip align="right" className="ml-3" content={t('loadingPieTooltip', { number: 20 })} isLongContent>
          <CountdownCircle
            size={isMobile ? 20 : 28}
            onComplete={handleRefreshFarm}
            onClick={handleRefreshFarm}
          />
        </Tooltip>
            }

          </div>

          <InputSearch
            handleClear={handleClear}
            initValue={txtSearch}
            handleChange={handleChangeSearch}
            placeHolder={isStakeScreen ? t('search') : 'C98 - USDC'}
          />
          {
            !isMobile &&

          <Tooltip align="right" className="ml-3" content={t('loadingPieTooltip', { number: 20 })} isLongContent>
            <CountdownCircle
              size={isMobile ? 20 : 28}
              onComplete={handleRefreshFarm}
              onClick={handleRefreshFarm}
            />
          </Tooltip>
          }
        </div>
      </div>
      {renderContentFarm()}
    </div>
  )
}

export default NewFarmScreen
