import React, { useEffect, useRef, useState } from 'react'
import SnapshotBanner from './Components/SnapshotBanner'
import styles from './style.module.scss'
import cn from 'clsx'
import SwitchButton from 'components/SwitchButton/SwitchButton'
import { useTranslation } from 'react-i18next'
import PageDropdown from 'components/PageDropdown'
import CardSnapshot from './Components/CardSnapshot'
import EmptyData from 'components/EmptyData'
import BaseAPI from 'controller/api/BaseAPI'
import get from 'lodash/get'
import omit from 'lodash/omit'
import { getLength } from 'common/functions'
import PuffLoading from 'components/common/PuffLoading'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import CountdownCircle from 'components/CountdownCircle'
import Tooltip from 'components/Tooltip'
import useScreenWidth from 'hooks/useScreenWidth'

const DUMMY_TABS = [
  {
    value: 'total',
    label: 'all',
    amount: 0
  },
  {
    value: 'onGoing',
    label: 'onGoing',
    amount: 0
  },
  {
    value: 'upComing',
    label: 'upComing',
    amount: 0
  },
  {
    value: 'completed',
    label: 'completed',
    amount: 0
  },
  {
    value: 'participated',
    label: 'participated',
    amount: 0
  }

]

const cacheCampaign = {
  all: null,
  onGoing: null,
  upComing: null,
  value: null,
  participated: null

}

const SnapshotScreen = () => {
  const { t } = useTranslation()
  const ARR_SORT = [
    {
      label: t('earliest'),
      value: 'desc'
    },
    {
      label: t('latest'),
      value: 'asc'
    }
  ]
  const [activeTab, setActiveTab] = useState('total')
  const [sort, setSort] = useState('desc')
  const [listCampaign, setListCampaign] = useState([])
  const [listJoined, setListJoined] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const addressWalletActive = useSelector(state => state.accountSol)
  const finishTime = 600

  const [listTabs, setListTabs] = useState(DUMMY_TABS)

  const campaignRef = useRef(cacheCampaign)
  const timer = useRef()
  const timingRefresh = useRef(0)
  const [time, setTime] = useState(timingRefresh.current)
  const { width } = useScreenWidth()
  const isMobile = width < 576

  useEffect(() => {
    loadingParticipatedList()
    fetchSnapshotCamp({ isLoading: true })
    fetchTotalStatistical()
  }, [addressWalletActive])

  useEffect(() => {
    if (timingRefresh.current === 0) {
      timingRefresh.current = finishTime
      setTime(finishTime)
      campaignRef.current = cacheCampaign
      return
    }
    timer.current = setInterval(() => {
      timingRefresh.current -= 1
      setTime(timingRefresh.current)
    }, 1000)

    return () => {
      clearInterval(timer.current)
    }
  })

  const setCampaignRef = (tab, data) => {
    const newCampRef = cloneDeep(campaignRef.current)
    newCampRef[tab] = data
    campaignRef.current = newCampRef
  }

  const fetchTotalStatistical = async () => {
    const res = await BaseAPI.getData('snapshot/campaign/statistical', {
      address: addressWalletActive,
      from: 'C98SARMENCS'
    })
    if (res) {
      const formatList = DUMMY_TABS.map(item => ({
        ...item,
        amount: res[item.value]
      }))

      setListTabs(formatList)
    }
  }

  const fetchSnapshotCamp = async (props) => {
    const sortType = get(props, 'sortType') || sort
    const tab = get(props, 'tab') || activeTab
    const isLoadingSnapshot = get(props, 'isLoading')
    setIsLoading(isLoadingSnapshot)
    let query = {}
    const bodyQuery = {
      keyword: '',
      page: 1,
      size: 100,
      sort: sortType,
      status: tab
    }
    if (tab === 'total') {
      query = omit(bodyQuery, 'status')
    } else if (tab === 'participated') {
      query = { ...bodyQuery, address: addressWalletActive }
    } else {
      query = { ...bodyQuery }
    }
    const res = await BaseAPI.getData('snapshot/campaign', query)
    if (res) {
      setListCampaign(get(res, 'data'))
      setCampaignRef(tab, get(res, 'data'))
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }

  const loadingParticipatedList = async () => {
    if (!addressWalletActive) return
    const res = await BaseAPI.getData('snapshot/participant/list', {
      address: addressWalletActive
    })
    if (res) {
      setListJoined(res)
    }
  }

  const handleActivePool = (tab) => () => {
    setActiveTab(tab.value)

    if (campaignRef.current[tab.value]) {
      setListCampaign(campaignRef.current[tab.value])
      setIsLoading(false)
      return
    }

    fetchSnapshotCamp({ tab: tab.value, isLoading: true })
  }

  const onChangeSort = async (sort) => {
    setSort(sort.value)
    campaignRef.current = cacheCampaign // reset cache

    fetchSnapshotCamp({ sortType: sort.value, isLoading: true })
  }

  const renderListCampaign = () => {
    if (isLoading) {
      return (
        <div className='empty-snapshot'>
          <PuffLoading />
        </div>
      )
    }
    if (getLength(listCampaign) === 0) {
      return (
        <div className='empty-snapshot'>
          <EmptyData size='lg'/>
        </div>
      )
    }
    return (
      <div className='grid-container'>
        {
          listCampaign.map(item => (
            <CardSnapshot
              key={get(item, 'id')}
              data={item}
              isJoinedCamp={listJoined?.includes(item.id)}
            />
          ))
        }
      </div>
    )
  }
  return (
    <div className={cn(styles.snapshotContainer, 'container')}>
      <SnapshotBanner/>

      <div className="action-bar">
        <div className="action-left">
          <div className="list-tab">
            {listTabs.map((tab) => (
              <div
                onClick={handleActivePool(tab)}
                key={tab.value}
                className={`list-tab__item  ${
                  activeTab === tab.value ? 'list-tab__item--active' : ''
                }`}
              >
                {t(tab.label)}
                <span className="ml-1 color-normal">({tab.amount})</span>
              </div>
            ))}
          </div>

        </div>
        <div className="action-right">
          {/* <div className="d-flex align-items-center">
            <SwitchButton onToggle={handleToggleSwitch} isToggle={isParticipated} />
            <div className="text-sm font-bold color-grey ml-2">{t('participated')}</div>
          </div> */}
          <PageDropdown
            className="right-menu-down"
            menuClassName="right-menu-down"
            options={ARR_SORT}
            onChange={onChangeSort}
          />

          <Tooltip align="right" className="ml-3" content={t('loadingPieTooltip', { number: 20 })} isLongContent>
            <CountdownCircle
              size={isMobile ? 20 : 28}
              onComplete={fetchSnapshotCamp}
              onClick={fetchSnapshotCamp}

            />
          </Tooltip>

        </div>
      </div>

      {renderListCampaign()}

    </div>
  )
}

export default SnapshotScreen
