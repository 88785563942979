import React, { useRef, useState, useEffect, forwardRef } from 'react'
import styles from './style.module.scss'
import cx from 'clsx'
import { Form } from 'react-bootstrap'
import { formatNumberBro, getLength } from 'common/functions'
import { useTranslation } from 'react-i18next'
import useEffectCustom from 'hooks/useEffectCustom'
import { Icon } from 'components/Icon'

const Input = ({
  onChange,
  id,
  value,
  placeholder,
  textRows = 4,
  prefix,
  suffix,
  className,
  disabled,
  maxLength = 50,
  maxNum,
  inputType,
  name,
  type,
  isChecked = false,
  isTouchInput = false,
  errMsg,
  as = 'input',
  autoComplete = 'off',
  ...props
}, ref) => {
  const { t } = useTranslation()
  const [isTouch, setIsTouch] = useState(isTouchInput)
  const [isFocus, setIsFocus] = useState(false)

  const checkBoxRef = useRef()

  useEffect(() => {
    setIsTouch(isTouchInput)
  }, [isTouchInput])

  const onToggleFocus = (isFocus) => () => {
    setIsFocus(isFocus)
  }
  const isNumeric = (num) => {
    return !isNaN(num)
  }

  const handleFocusInput = () => {
    if (checkBoxRef.current) {
      checkBoxRef.current.click()
    }
    onChange && onChange(!isChecked)
  }

  const onChangeInput = (e) => {
    setIsTouch(true)
    if (typeof onChange === 'function') {
      onChange(e)
    }
  }

  const onChangeNumber = (e) => {
    const { value } = e.target
    const fValue = value.toString()
    if (isNumeric(fValue)) {
      // if (fValue.startsWith('0') && getLength(fValue) > 3) {
      //   fValue = value.slice(1)
      // }

      onChange(fValue)
    }
  }

  useEffectCustom(() => {
    setIsTouch(true)
  }, [value])

  return (
    <>
      <div className={cx(styles.inputContainer, className, errMsg && isTouch && getLength(value) > 0 && styles.errInput)}>
        <div
          onClick={as === 'checkbox' ? handleFocusInput : null}
          className={cx(
            'd-flex align-items-center',
            as === 'checkbox' && 'justify-content-between',
            `${as}-wrapper`

          )}
        >
          {prefix}

          {as === 'textarea' && (
            <textarea
              rows={textRows}
              name={name}
              disabled={disabled}
              placeholder={placeholder}
              value={value}
              // onKeyPress={handleKeyPress}
              onChange={onChange}
              maxLength={maxLength}
              {...props}
              className={cx(styles.textArea, disabled && styles.disableInput)}
            />
          )}

          {as === 'search' && (
            <div
              className={cx(
                styles.inputSearch,
                'd-flex align-items-center w-100'
              )}
            >
              <label htmlFor={ id || 'search'} className="mb-0 cursor-pointer d-flex">
                {/* <img
                  src={images.iconSearchLeft}
                  className={cx(styles.iconSearchInput, 'mr-2')}
                  alt="icon"
                /> */}
                <Icon
                  className={cx(styles.iconSearchInput, 'mr-2')}
                  name="search_left"
                />
              </label>
              <input
                ref={ref}
                id={ id || 'search'}
                name={name}
                maxLength={maxLength}
                disabled={disabled}
                placeholder={placeholder || 'Search'}
                value={value}
                onChange={onChange}
                {...props}
                className="w-100"
                autoComplete={autoComplete}
              />
            </div>
          )}

          {as === 'checkbox' && (
            <Form.Check type="radio" className={styles.formCheckBox}>
              <Form.Check.Input
                ref={checkBoxRef}
                type="radio"
                checked={isChecked}
              />
            </Form.Check>
          )}

          {as === 'number' && (
            <>
              {isFocus
                ? <input
                  ref={ref}
                  maxLength={10}
                  onBlur={onToggleFocus(false)}
                  value={value}
                  className="w-100"
                  disabled={disabled}
                  onChange={onChangeNumber}
                  placeholder={t('amount')}
                  autoComplete={autoComplete}
                />
                : <input
                  maxLength={10}
                  disabled={disabled}
                  onChange={onChangeNumber}
                  onFocus={onToggleFocus(true)}
                  value={getLength(value.toString()) > 0 ? formatNumberBro(value, 5) : '' }
                  className="w-100"
                  placeholder={t('amount')}
                  autoComplete={autoComplete}
                />
              }
            </>
          )}

          {as === 'center' && (
            <>
              <input
                ref={ref}
                type={inputType || 'text'}
                name={name}
                maxLength={maxLength}
                disabled={disabled}
                placeholder={placeholder}
                value={value}
                onChange={onChangeInput}
                {...props}
                className={cx(disabled && styles.disableInput, 'center-input')}
                autoComplete={autoComplete}
              />
            </>
          )}

          {as === 'input' && (
            <input
              ref={ref}
              type={inputType || 'text'}
              name={name}
              maxLength={maxLength}
              disabled={disabled}
              placeholder={placeholder}
              value={value}
              onChange={onChangeInput}
              {...props}
              className={cx(disabled && styles.disableInput, 'w-100')}
              autoComplete={autoComplete}
            />
          )}
          <div className={as === 'checkbox' ? 'd-none' : undefined}>
            {suffix}
          </div>
        </div>
      </div>
      {errMsg && isTouch && <div className={cx(styles.errMsg, 'err-msg')}>{errMsg}</div>}
    </>
  )
}

export default forwardRef(Input)
