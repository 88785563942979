import {
  convertWeiToBalance,
  formatNumberBro,
  getItemStorage,
  getLength,
  lowerCase,
  nFormatter,
  setItemStorage
} from 'common/functions'
import { cloneDeep, get, compact, uniqBy } from 'lodash'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'
import cn from 'clsx'
import InputSearch from 'components/InputSearch'
import PuffLoading from 'components/common/PuffLoading'
import { AppContext } from 'context/appContext'
import { getBalanceSol, NATIVE_SOL } from 'common/solana'
import TokenImage from 'components/common/TokenImage'
import { Icon } from 'components/Icon'
import EmptyData from 'components/EmptyData'
import { TokenProgramService } from 'common/pool/tokenProgramService'
import { SarosSwapService } from 'common/pool/saros_swap/sarosSwapServices'
import BaseAdapter from 'controller/api/BaseAdapter'
import { useDispatch, useSelector } from 'react-redux'
import StoreActions from 'controller/redux/actions/storeActions'
import { LIST_FAVORITES } from 'common/constants'
import TokenFavorite from 'components/TokenFavorite'

// const USDC_MINT = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
// const C98_MINT = 'C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9'
// const SOL_MINT = 'So11111111111111111111111111111111111111112'
// const USDT_MINT = 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB'
// const LIST_FAVORITES = [USDC_MINT, C98_MINT, SOL_MINT, USDT_MINT]

const ListToken = ({
  lisToken = window.walletServices.tokenSolana,
  handleSelectedToken,
  isBase,
  selectedMarket,
  fromCoin,
  toCoin,
  coinFilter,
  isShortcut,
  isLoadingListToken
}) => {
  const appContext = useContext(AppContext)
  const dispatch = useDispatch()
  const { listAccountOwner, setIsOpenListTokenMobile } = appContext

  const { t } = useTranslation()
  const [isLoadingToken, setIsLoadingToken] = useState(isLoadingListToken)
  const scrollRef = useRef()
  const isMobile = window.innerWidth < 576

  const listFavoriteTokenRedux = useSelector(
    (state) => state.listFavoriteTokenRedux
  )
  const accountSol = useSelector((state) => state.accountSol)

  useEffect(() => {
    if (isLoadingListToken) {
      setTimeout(() => {
        setIsLoadingToken(false)
      }, 1500)
    }
  }, [isLoadingListToken])

  useEffect(() => {
    setIsOpenListTokenMobile(false)
  }, [window.location.href])

  const [keyWord, setKeyWord] = useState('')
  const [newListToken, setNewListToken] = useState(lisToken)
  const [listFavorite, setListFavorite] = useState(listFavoriteTokenRedux)
  const decimalsLp = 2

  useEffect(() => {
    getListFavorite()
  }, [])

  useEffect(() => {
    if (!accountSol) {
      dispatch(StoreActions.setListFavoriteTokenRedux(LIST_FAVORITES))
    }
  }, [accountSol])

  useEffect(() => {
    updateBalanceListToken()
  }, [listAccountOwner])

  const getListFavorite = () => {
    if (getLength(listFavorite) <= 0) {
      setListFavorite(listFavoriteTokenRedux)
      return
    }
    return listFavorite
  }

  const handleFavoriteToken = (token) => async (event) => {
    event.stopPropagation()
    let listFavoriteClone = cloneDeep(listFavorite)
    const findTokenAddress = listFavoriteClone.find(
      (item) => item === token.mintAddress
    )
    if (findTokenAddress) {
      listFavoriteClone = listFavoriteClone.filter(
        (item) => item !== findTokenAddress
      )
    } else {
      listFavoriteClone.push(token.mintAddress)
    }

    const newArr = listFavoriteClone.filter((item) => {
      if (typeof item === 'object') return null
      return item
    })

    await BaseAdapter.postData('favorite/take', {
      type: 'sarosToken',
      bonusValue: compact(newArr)
    })
    setListFavorite(listFavoriteClone)
    dispatch(StoreActions.setListFavoriteTokenRedux(listFavoriteClone))
  }

  const handleChooseToken =
    ({ isBase, coinSelected }) =>
      () => {
        if (get(coinFilter, 'mintAddress') === get(coinSelected, 'mintAddress')) {
          return handleSelectedToken({ isBase, coinSelected: coinFilter })()
        }
        handleSelectedToken({ isBase, coinSelected })()
      }

  const updateBalanceListToken = async () => {
    let listTokenClone = cloneDeep(newListToken).filter(
      (token) => parseFloat(token.decimals) > decimalsLp
    )
    if (getLength(listAccountOwner) <= 0) {
      setNewListToken(listTokenClone)
      return
    }
    const solBalance = await getBalanceSol()
    listTokenClone = listTokenClone.map((token) => {
      const TOKEN_DATA = { ...token }
      listAccountOwner.forEach((tokenAccountInfo) => {
        const { mint, amount, address } = tokenAccountInfo
        const decimals = get(token, 'decimals')
        if (mint === token.mintAddress) {
          TOKEN_DATA.balance = convertWeiToBalance(amount, decimals)
          TOKEN_DATA.account = address
        }
        if (token.mintAddress === NATIVE_SOL.mintAddress) {
          TOKEN_DATA.balance = solBalance
        }
      })
      return TOKEN_DATA
    })
    listTokenClone = listTokenClone
      .map((item) => {
        return {
          ...item,
          fiatValue: calculateValue(item)
        }
      })
      .sort((a, b) => get(b, 'fiatValue', 0) - get(a, 'fiatValue', 0))

    setNewListToken(listTokenClone)
  }

  const isFavorite = (token) => {
    // const listMint = listFavorite.map((item) => item.mintAddress)
    return listFavorite.includes(get(token, 'mintAddress'))
  }

  const formatTokenBalance = (num) => {
    if (parseInt(num) > 100000000) {
      return nFormatter(Number(num), 4)
    }
    return formatNumberBro({ number: num, mantissa: 4 })
  }

  const getBalance = (tokenData) => {
    const balance = get(tokenData, 'balance', 0)
    return balance || 0
  }

  const calculateValue = (tokenData) => {
    const balance = parseFloat(get(tokenData, 'balance', 0))
    const price = parseFloat(
      window.walletServices.findCoinGeckoPrice(get(tokenData, 'id'))
    )
    const value = balance * price
    return isNaN(value) ? 0 : value
  }

  const onTouchFavList = (isTouch) => () => {
    if (!isMobile) return
    const numline = parseFloat(Math.ceil(getLength(listFavorite) / 4))
    const paddingTopSpace = 1

    if (isTouch) {
      scrollRef.current.style.maxHeight =
        numline < 7
          ? `${numline * 5.2 + paddingTopSpace}rem`
          : `${30 + paddingTopSpace}rem`
      scrollRef.current.style.transition = '0.5s'
    } else {
      scrollRef.current.style.maxHeight = '13rem'
      scrollRef.current.style.backgroundColor = 'transparent'
      scrollRef.current.style.transition = '0.5s'
    }
  }

  const onSrcollFavoriteList = () => {
    if (isMobile) return
    const threshHold = 50
    const paddingTopSpace = 1
    if (scrollRef.current) {
      const scroll = scrollRef.current.scrollTop
      const numline = parseFloat(Math.ceil(getLength(listFavorite) / 4))
      if (scroll > threshHold) {
        // downscroll
        scrollRef.current.style.maxHeight =
          numline < 7
            ? `${numline * 5 + paddingTopSpace}rem`
            : `${30 + paddingTopSpace}rem`
        scrollRef.current.style.transition = '0.5s'
      } else if (scroll === 0) {
        // upscroll
        scrollRef.current.style.maxHeight = '11rem'
        scrollRef.current.style.backgroundColor = 'transparent'
        scrollRef.current.style.transition = '0.5s'
      }
    }
  }

  const listDropdown = useMemo(() => {
    const cloneList = cloneDeep(newListToken)

    const newListClone = cloneList.map((item) => ({
      ...item,
      isActive: isFavorite(item)
    }))

    const lisTokenClone = newListClone.sort((a, b) => {
      if (a.isActive === b.isActive) {
        // return get(b, 'fiatValue', 0) - get(a, 'fiatValue', 0)
      } else if (a.isActive || !b.isActive) {
        return -1
      } else if (b.isActive || !a.isActive) {
        return 1
      }
      return 0
    })

    const fromMint = get(fromCoin, 'mintAddress', '')
    const toMint = get(toCoin, 'mintAddress', '')
    const fromSymbol = lowerCase(get(fromCoin, 'symbol', ''))
    const toSymbol = lowerCase(get(toCoin, 'symbol', ''))

    let newList = lisTokenClone.filter((token) => {
      if (isBase) {
        return (
          token.mintAddress !== toMint && lowerCase(token.symbol) !== toSymbol
        )
      }
      return (
        token.mintAddress !== fromMint && lowerCase(token.symbol) !== fromSymbol
      )
    })
    newList = uniqBy(
      newList.filter(
        (item) => item.mintAddress !== get(coinFilter, 'mintAddress', '')
      ),
      'mintAddress'
    )
    if (!keyWord) return newList
    const textSearch = keyWord.toLowerCase()
    const arrTemp = newList.filter((token) => {
      return (
        lowerCase(token.name).includes(textSearch) ||
        lowerCase(token.symbol).includes(textSearch) ||
        token.mintAddress === keyWord
      )
    })
    return arrTemp
  }, [keyWord, newListToken])

  const handleClearSearch = () => {
    setKeyWord('')
  }

  const handleCloseModal = () => {
    window.closeModal()
  }

  return (
    <div className={styles.listToken}>
      {
        (isShortcut || !isMobile) &&
        <div className='header-text'>
          <div className='text-xl bold'>{t('selectToken')}</div>
          <Icon name="web_close" onClick={handleCloseModal} />
        </div>
      }
      <div className="box-search">
        <InputSearch
          className="box-search__input"
          initValue={keyWord}
          handleChange={(e) => setKeyWord(e.target.value)}
          handleClear={handleClearSearch}
          placeHolder={t('searchByNameOrAddress')}
        />
      </div>

      <div
        ref={scrollRef}
        className="favorite-wrap"
        onTouchMove={onTouchFavList(true)}
        onTouchCancel={onTouchFavList(false)}
        onScroll={onSrcollFavoriteList}
      >
        <div className="favorite-list">
          {listFavorite?.map((item) => {
            const tokenInfo = item
              ? SarosSwapService.getInfoByMintAddress(item)
              : {}
            return (
              <TokenFavorite
                isPopup={true}
                onRemove={handleFavoriteToken(tokenInfo)}
                onClick={handleChooseToken({ isBase, coinSelected: tokenInfo })}
                key={get(tokenInfo, 'mintAddress')}
              >
                <TokenImage
                  className="token-image"
                  src={get(tokenInfo, 'icon')}
                />
                <span className="text-xs truncate-one-line uppercase symbol">
                  {get(tokenInfo, 'symbol')}{' '}
                </span>
              </TokenFavorite>
            )
          })}
        </div>
      </div>

      <div className="list-token-search">
        <div className="list-token-search__head">
          <div className="head-item color-grey text-sm">{t('token')}</div>
          <div className="head-item color-grey text-sm">{t('balance')}</div>
          <div className="head-item color-grey text-sm">{t('value')}</div>
        </div>
        <div className={cn('content-list scroll-list')}>
          {getLength(listDropdown) !== 0
            ? (
              listDropdown.map((token, index) => (
                <div
                  className={`content-list__item ${
                    get(selectedMarket, 'address', '') === token.address
                      ? 'search-item--active'
                      : ''
                  }`}
                  onClick={handleChooseToken({ isBase, coinSelected: token })}
                  key={get(token, 'mintAddress')}
                >
                  <div className="item-child item-child--token">
                    <Icon
                      onClick={handleFavoriteToken(token)}
                      className={cn(
                        'favorite-icon text-l',
                        isFavorite(token) ? 'favorite-icon--active' : ''
                      )}
                      backgroundClassName={!accountSol && 'd-none'}
                      name={`${
                        isFavorite(token) ? 'web_like_active' : 'web_like'
                      } `}
                    />
                    <div className="token-detail">
                      <TokenImage
                        className="token-image token-detail__image"
                        src={get(token, 'icon')}
                      />
                      <div className="token-info">
                        <div className="token-info__symbol color-normal uppercase">
                          {get(token, 'symbol')}
                        </div>
                        <div className="token-info__name text-xs color-grey truncate-multilines">
                          {get(token, 'name')}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="item-child item-child--balance">
                    {formatTokenBalance(getBalance(token))}
                  </div>
                  <div className="item-child item-child--value">
                    {`$${formatNumberBro({
                      number: calculateValue(token),
                      mantissa: 2
                    })}`}
                  </div>
                </div>
              ))
            )
            : (
              <div className="list-dropdown-empty">
                {isLoadingToken
                  ? (
                    <PuffLoading />
                  )
                  : (
                    <EmptyData className="empty-data-container" />
                  )}
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default ListToken
