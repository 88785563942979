import { get } from 'lodash'
import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import styles from './style.module.scss'

const ARR_TABS = [
  {
    name: 'overview',
    url: '/info',
    type: 'overview'
  },
  {
    name: 'pools',
    url: '/info/pool',
    type: 'pool'

  },
  {
    name: 'tokens',
    url: '/info/token',
    type: 'token'

  },
  {
    name: 'wallets',
    url: '/info/wallet',
    type: 'wallet'

  }
]

const PoolTabbar = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { type } = useParams()

  const activeTab = useMemo(() => {
    const tab = ARR_TABS.find(item => item.type === type)
    return tab || ARR_TABS[0]
  }, [type])

  const handleChangeTab = (val) => () => {
    const { url } = val
    history.push(url)
  }

  return (
    <div className={styles.listTab}>
      {ARR_TABS.map((item, i) => {
        return (
          <div
            className={`tab-item semibold ${
              get(activeTab, 'type') === item.type && 'tab-item--active'
            }`}
            key={i}
            onClick={handleChangeTab(item)}
          >
            {t(item.name)}
          </div>
        )
      })}
    </div>
  )
}

export default PoolTabbar
