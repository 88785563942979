import images from 'assets/images'
import { formatNumberBro, formatPrice, lowerCase, upperCase } from 'common/functions'
import Badge from 'components/Badge'
import TokenImage from 'components/common/TokenImage'
import LineChart from 'components/LineChart'
import { get, isEmpty } from 'lodash'
import React, { useMemo, useState } from 'react'
import styles from './style.module.scss'
import cn from 'classnames'
import { Icon } from 'components/Icon'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import ReduxServices from 'common/redux'
import { useSelector } from 'react-redux'

const usdc = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
const c98 = 'C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9'
const sol = 'So11111111111111111111111111111111111111112'

const BoxTokenInfo = ({ data }) => {
  const { t } = useTranslation()

  const history = useHistory()
  const price = get(data, 'current_price', 0)
  const currentChange = get(data, 'price_change_percentage_7d', 0)
  const address = get(data, 'mintAddress')

  const currencyRedux = useSelector(state => state.currencyRedux)

  const renderChart = useMemo(() => {
    const chart = get(data, 'chart', [])
    if (!chart.length) return null
    return <LineChart dataChart={chart} percent={currentChange} height="150" />
  }, [data])

  const handleRouteToSwap = () => {
    const symbol = get(data, 'symbol')
    const mintAddress = symbol === 'SOL' ? sol : address
    const swapRoute = lowerCase(mintAddress) === lowerCase(usdc)
      ? `/swap?base=${usdc}&pair=${c98}`
      : `/swap?base=${usdc}&pair=${mintAddress}`

    history.push(swapRoute)
  }

  if (isEmpty(data?.info)) return null

  return (
    <div onClick={handleRouteToSwap} className={styles.boxTokenInfo}>
      <div className="token-img-container">
        <div className='token-content'>
          <TokenImage src={data.icon} />
          <div className='text-l uppercase mt-2 semibold'>{data.symbol}</div>
          <div className='token-name text-sm semibold color-grey'>{data.name}</div>
        </div>
      </div>

      <div className={styles.tokenPrice}>
        {/* <div className='text-2xl color-theme bold'>${formatNumberBro({ number: price, mantissa: 4 })}</div> */}
        <div className='text-2xl color-theme bold'>{ReduxServices.formatPrice(price)}</div>
        <Badge
          variant={currentChange > 0 ? 'success' : 'danger'}
          className='value-change'
        >
          <span>{currentChange > 0 ? '+' : '-'}</span>
          {formatNumberBro({ number: Math.abs(currentChange), mantissa: 2 })}%
        </Badge>
        <div className="chart">
          {renderChart}
        </div>
      </div>

      <Link to={`/swap?pair=${get(data, 'address', '')}`} className={styles.swapNow}>
        <span className='text-xl bold'>{t('swapNow')}</span>
        <Icon name="web_arrow_right" className="ml-1 text-2xl color-primary"/>
      </Link>

    </div>

  )
}

export default BoxTokenInfo
