import React from 'react'
import { FarmProvider } from 'context/farm/farmContext'
import NewFarmScreen from '.'

const NewFarmScreenContent = () => {
  return (
    <FarmProvider>
      <NewFarmScreen />
    </FarmProvider>
  )
}

export default NewFarmScreenContent
