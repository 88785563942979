import get from 'lodash/get'

export const IS_DEV = false
export const IS_STAGING = process.env.REACT_APP_STAGING === 'true'

export const keySaros = 99

const USDC_MINT = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
const C98_MINT = 'C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9'
const SOL_MINT = 'So11111111111111111111111111111111111111112'
const USDT_MINT = 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB'
const CUSD_MINT = 'CUSDvqAQLbt7fRofcmV2EXfPA2t36kzj7FjzdmqDiNQL'

export const isDapp = get(window, 'coin98.isMobile')

export const LIST_FAVORITES = [USDC_MINT, C98_MINT, SOL_MINT, CUSD_MINT]

export const LIST_RECENT_SWAP = [
  {
    token0Address: USDC_MINT,
    token1Address: C98_MINT
  }
]

export const REQUEST_TYPE = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch'
}

export const chainType = {
  solana: 'solana',
  usdc: 'usdc',
  usdt: 'usdt',
  usdh: 'usdh'
}

export const CHAIN_DATA = {
  solana: {
    isToken: true,
    trcToken: 'SPL',
    image: 'web_solana',
    isMnemonic: true,
    id: 'solana',
    name: 'Solana',
    shortName: 'Solana',
    symbol: 'SOL',
    chain: chainType.solana,
    trcName: 'SOL SPL'
  }
}

export const LOCALE = {
  EN: 'en',
  VI: 'vi'
  // CN: 'cn',
  // JP: 'jp',
  // ES: 'es',
  // FR: 'fr',
  // ID: 'id',
  // KR: 'kr',
  // PT: 'pt',
  // RU: 'ru',
  // TH: 'th',
  // TR: 'tr'
}

export const LOCALE_DATA = [
  { key: 'vi', name: 'Tiếng Việt' },
  { key: 'en', name: 'English' }
  // { key: 'cn', name: '中文' },
  // { key: 'jp', name: '日本語' },
  // { key: 'kr', name: '한국어' },
  // { key: 'tr', name: 'Türkçe' },
  // { key: 'th', name: 'ภาษาไทย' },
  // { key: 'ru', name: 'Pусский' },
  // { key: 'es', name: 'Español' },
  // { key: 'id', name: 'Indonesia' },
  // { key: 'pt', name: 'Português' },
  // { key: 'fr', name: 'Français' }
]

export const CURRENCY_DATA = [
  'USD',
  'VND',
  'CNY',
  'JPY',
  'THB',
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'KRW',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PHP',
  'PKR',
  'PLN',
  'RUB',
  'SEK',
  'SGD',
  'TRY',
  'TWD',
  'ZAR'
]

export const CURRENCY_SYMBOL = {
  USD: '$',
  JPY: '¥',
  AUD: '$',
  BRL: 'R$',
  CAD: '$',
  CHF: 'FS',
  CLP: '$',
  CNY: '¥',
  CZK: 'Kč',
  DKK: 'kr',
  EUR: '€',
  GBP: '£',
  HKD: '$',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: 'Rs.',
  ISK: 'kr',
  KRW: '₩',
  MXN: '$',
  MYR: 'RM',
  NOK: 'kr',
  NZD: '$',
  PHP: '₱',
  PKR: 'Rs.',
  PLN: 'zł',
  RUB: '₽',
  SEK: 'kr',
  SGD: '$',
  THB: '฿',
  TRY: '₺',
  TWD: 'NT$',
  ZAR: 'R',
  VND: 'đ'
}

export const ARR_CUSTOM_COSMOS = [
  {
    chainId: 'serenity-testnet-001',
    chainName: 'Aura serenity testnet',
    rpc: 'https://rpc.serenity.aura.network',
    rest: 'https://lcd.serenity.aura.network',
    bip44: {
      coinType: 118
    },
    bech32Config: {
      bech32PrefixAccAddr: 'aura',
      bech32PrefixAccPub: 'aurapub',
      bech32PrefixValAddr: 'auravaloper',
      bech32PrefixValPub: 'auravaloperpub',
      bech32PrefixConsAddr: 'auravalcons',
      bech32PrefixConsPub: 'auravalconspub'
    },
    currencies: [
      {
        coinDenom: 'AURA',
        coinMinimalDenom: 'uaura',
        coinDecimals: 6
      }
    ],
    feeCurrencies: [
      {
        coinDenom: 'AURA',
        coinMinimalDenom: 'uaura',
        coinDecimals: 6
      }
    ],
    stakeCurrency: {
      coinDenom: 'AURA',
      coinMinimalDenom: 'uaura',
      coinDecimals: 6
    },
    coinType: 118,
    gasPriceStep: {
      low: 0.001,
      average: 0.0025,
      high: 0.004
    },
    features: ['no-legacy-stdTx'],
    walletUrlForStaking: 'https://serenity.aurascan.io/validators',
    logo: 'https://i.imgur.com/zi0mTYb.png',
    explorer: 'https://serenity.aurascan.io/'
  }
]

export const SOLANA_LINK = {
  serumSolana: 'https://wallet.coin98.com/rpcsolana',
  solana: 'https://orca.rpcpool.com/'
}

export const SOL_SCAN_URL = {
  devnet: 'https://explorer.solana.com',
  mainnet: 'https://solscan.io'
}

export const sarosSwapType = {
  swap: 'swap',
  addLiquidiTy: 'addLiquidiTy',
  removeLiquidity: 'removeLiquidity',
  createPool: 'createPool'
}

export const sarosSwapPoolType = {
  RAYDIUM: 'RAYDIUM',
  ORCA: 'ORCA',
  C98: 'C98'
}

export const LIST_TOKEN_DEFAULT_TEST = [
  {
    decimals: '9',
    icon: 'https://coin98.s3.amazonaws.com/0w5iuQ37CorbfQSj',
    id: 'mango-markets',
    mintAddress: 'CgJ1HVDZPoVb2sAg73Z1nbjYm3FVcTqoTHFU2AkZiLnC',
    name: 'Mango',
    symbol: 'MNGO'
  },
  {
    decimals: '6',
    icon: 'https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png?1547042389',
    id: 'usd-coin',
    mintAddress: 'USDCXkb3AZi7MxTxqbdw2XoMhkqUPz4nQb7Cxm1Co22',
    name: 'USD Coin',
    symbol: 'usdc'
  },
  {
    decimals: '6',
    icon: 'https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707',
    id: 'usd-coin',
    mintAddress: 'USDThRLHuGVe8FQct1q7eV61eWoBGqX27DaVYZJQG7u',
    name: 'USD Coin',
    symbol: 'usdt'
  },
  {
    decimals: '6',
    icon: 'https://assets.coingecko.com/coins/images/11939/small/shiba.png?1622619446',
    id: 'shiba-inu',
    mintAddress: '8jk4eJymMfNZV9mkRNxJEt2VJ3pRvdJvD5FE94GXGBPM',
    name: 'Shiba Inu',
    symbol: 'SHIB'
  },
  {
    decimals: '6',
    icon: 'https://coin98.s3.amazonaws.com/uwqLJuMfd8fsehgM',
    id: 'coin98',
    mintAddress: 'TknEFTTZKMhZLQxMuE2UPWBVu8RpwCevvSW5BXMEKD8',
    name: 'coin98',
    symbol: 'C98'
  }
]

export const isWindow = typeof window !== 'undefined'

export const listStableCoin = ['EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v', 'CASHVDm2wsJXfhj6VWxb7GiMdoLc17Du7paH4bNr5woT', 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB', 'Ea5SjE2Y6yvCeW5dYTn7PYMuW5ikXkvbGdcmSnXeaLjS']

export const DECIMAL_LP = 2
