import { formatBilion, formatNumberBro } from 'common/functions'
import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'clsx'
import styles from './style.module.scss'

function ItemBlockInfo ({ isChangeUp, title, icon, value, percent }) {
  const { t } = useTranslation()

  return (
    <div className={styles['item-block']}>
      <span className={`icon-block icon-${icon}`}></span>

      <div className='item-content'>
        <div className="title-block text-sm">{t(title)}</div>
        <div className="value-block">{value}</div>
        <div className={cn('text-xs', isChangeUp ? 'color-success' : 'color-danger', !percent && 'd-none')}>
          <span>{isChangeUp ? '+' : ''}</span>
          {formatNumberBro({ number: percent, mantissa: 4 })}%
        </div>
      </div>

    </div>
  )
}

export default ItemBlockInfo
