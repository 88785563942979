import images from 'assets/images'
import React from 'react'
import cn from 'clsx'
import styles from './style.module.scss'
import Button from 'components/common/Button'
import { useTranslation } from 'react-i18next'
import Ecosystem from './Ecosystem'
import DefiSuite from './DefiSuite'
import MissionList from './MissionList'
import Sponsors from './Sponsors'
import { Fade } from 'react-awesome-reveal'
import { Link } from 'react-router-dom'
import VideoContainer from 'components/VideoContainer'
import useScreenWidth from 'hooks/useScreenWidth'
import NewSnapshot from './NewSnapshot'
import { Icon } from 'components/Icon'

const NewLandingScreen = () => {
  const { t } = useTranslation()
  const { width } = useScreenWidth()
  const isMobile = width < 576

  return (
    <div className={cn(styles.landingContainer)}>
      <a href ='https://www.saros.xyz' target="_blank" className='saros-v2-content' rel="noreferrer">
        <div className='line' />
        <div className='content-text'>
          <span>{t('sarosV2Launching')}</span>
          <span>{t('viewDetails')} </span>
          <Icon name="web_arrow_right" className="icon-arrow-right"/>
        </div>
        <div className='line' />
      </a>
      <div className='home-banner-wrapper'>
        <div className='left-image mobile-hidden'>
          <img src={images.sarosAtom} alt="" />
        </div>

        <div className='home-banner container'>

          <div className='home-banner__left'>
            <Fade duration="500" direction="up">
              <div className='title' dangerouslySetInnerHTML={{ __html: t('sarosLandingTitle') }}/>
              {/* A Unified Suite of DeFi Products on <span className='color-theme'>Solana</span> */}
              <div className='description' dangerouslySetInnerHTML={{ __html: t('sarosLandingDescription') }}/>
              {/* One decentralized and permissionless platform for you to swap, stake, and invest with the low-cost, high efficiency and ultimate experience. */}
            </Fade>
            <Link to='/swap' className='btn-banner' >
              <Button
              >
                {t('launchApp')}
              </Button>
            </Link>

          </div>
          <div className='home-banner__right'>
            {
              isMobile
                ? <div className='banner-video'>
                  <img
                    src={images.sarosLandingGif}
                  />
                  {/* <VideoContainer
                    src={images.sarosLandingMp4}
                    type="video/mp4"
                    autoPlay={false}
                  /> */}
                  {/* <video loop muted autoPlay controls="" playsInline>
                    <source src={images.sarosLandingMp4} type="video/mp4" />
                  </video> */}
                </div>
                : <div className='banner-video'>
                  <div className='top-image'>
                    <img src={images.sarosAtom} alt="" />
                  </div>
                  <div className='bottom-image'>
                    <img src={images.sarosAtom} alt="" />
                  </div>
                  <VideoContainer
                    src={images.sarosLandingWebm}
                  />
                  {/* <video loop muted autoPlay controls="" playsInline>
                    <source src={images.sarosLandingWebm} type="video/webm" />
                  </video> */}
                </div>
            }

          </div>
        </div>

      </div>

      <div className='section-page container'>
        <NewSnapshot/>
      </div>

      <div className='section-page container'>
        <Ecosystem/>
      </div>

      <div className='section-page container'>
        <DefiSuite tagID="products"/>
      </div>
      <div className='section-page container'>
        <MissionList/>
      </div>

      {/* <div className='section-page container'>
        <PoweringSaros/>
      </div> */}

      <div className='section-page container'>
        <Sponsors/>
      </div>

    </div>
  )
}

export default NewLandingScreen
