import { get } from 'lodash'

export const SOL_BLOCK_TIME = 0.4 // 0.4s
export const BLOCKS_PER_YEAR = (60 / SOL_BLOCK_TIME) * 60 * 24 * 365 // 78840000
export const PRECISION_MULTIPLIER = 10 ** 9

export const calculateBlockByDay = (days) => {
  if (!days) return 0
  const blockOneDay = (60 / SOL_BLOCK_TIME) * 60 * 24
  return blockOneDay * parseFloat(days)
}

export const historyType = {
  stake: 'stake',
  unStake: 'unStake',
  harvest: 'harvest'
}

export const getHistoryType = (type) => {
  return get(historyType, 'type', historyType.stake)
}
