import TokenImage from 'components/common/TokenImage'
import React from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
import { Icon } from 'components/Icon'
import { get } from 'lodash'
import useFavoritePool from 'hooks/pools/useFavoritePool'
import TokenFavorite from 'components/TokenFavorite'
import { useHistory } from 'react-router-dom'

const FavoritePool = ({ data }) => {
  const token0 = get(data, 'info.token0')
  const token1 = get(data, 'info.token1')
  const poolName = `${get(token0, 'symbol')} / ${get(token1, 'symbol')}`
  const history = useHistory()

  const { onChangeListPoolFavor } = useFavoritePool()

  const onRemovePoolItem = (info) => (e) => {
    e.stopPropagation()
    onChangeListPoolFavor(info)
  }

  const onRouteToDetailPool = () => {
    history.push(`/pool/${get(data, 'poolAddress')}`)
  }

  return (
    <TokenFavorite
      onRemove={onRemovePoolItem(data)}
      onClick={onRouteToDetailPool}
      // onClick={handleChooseToken({ isBase, coinSelected: tokenInfo })}
    >
      <div className={styles['favorite-pool-image']}>
        <TokenImage
          src={get(token0, 'icon')}
          className={styles['image-item']}
        />
        <TokenImage
          src={get(token1, 'icon')}
          className={styles['image-item']}
        />
      </div>
      <p className={cn(styles['favorite-pool-name'], 'text-sm uppercase')}>
        {poolName}
      </p>
    </TokenFavorite>
  )
}

export default FavoritePool
