/* eslint-disable react/react-in-jsx-scope */
import { showNotificationToast } from 'common/functions'
import PopupHash from 'components/PopupHash'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const useStatusTxsHash = () => {
  const { t } = useTranslation()
  const handleTxsSuccess = async ({ message = 'success', callBack }) => {
    toast.dismiss()
    callBack && await callBack()
    showNotificationToast(t(message), 'success', 10000, false)
  }

  const handleTxsFailed = async ({ message = 'txsFailed', callBack }) => {
    toast.dismiss()
    callBack && await callBack()
    showNotificationToast(t(message), 'error', 10000, false)
    callBack && callBack()
  }

  const viewCompleteHash = ({ hash, title = 'success' }) => {
    toast.dismiss()
    window.openModal({
      isCustomModal: true,
      content: <PopupHash hash={hash} />
    })
  }

  return { viewCompleteHash, handleTxsSuccess, handleTxsFailed }
}

export default useStatusTxsHash
