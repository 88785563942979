import BaseAdapter from 'controller/api/BaseAdapter'
import StoreActions from 'controller/redux/actions/storeActions'
import { cloneDeep, compact } from 'lodash'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

const useFavoriteToken = () => {
  const dispatch = useDispatch()
  const listFavoriteTokenRedux = useSelector((state) => state.listFavoriteTokenRedux) || []

  const onChangeListTokenFavor = async (mintAddress) => {
    let listFavoriteClone = cloneDeep(listFavoriteTokenRedux)
    const findTokenAddress = listFavoriteClone.find(
      (item) => item === mintAddress
    )
    if (findTokenAddress) {
      listFavoriteClone = listFavoriteClone.filter(
        (item) => item !== findTokenAddress
      )
    } else {
      listFavoriteClone.push(mintAddress)
    }

    const newArr = listFavoriteClone.filter((item) => {
      if (typeof item === 'object') return null
      return item
    })

    await BaseAdapter.postData('favorite/take', {
      type: 'sarosToken',
      bonusValue: compact(newArr)
    })
    dispatch(StoreActions.setListFavoriteTokenRedux(listFavoriteClone))
  }
  return {
    onChangeListTokenFavor
  }
}

export default useFavoriteToken
