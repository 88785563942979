import { Icon } from 'components/Icon'
import React, { useState, useRef, useEffect, useContext } from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
import useOnClickOutside from 'common/customHooks/useClickOutSide'
import NotiItem from './NotiItem'
import BaseAdapter from 'controller/api/BaseAdapter'
import get from 'lodash/get'
import { getLength } from 'common/functions'
import InfiniteScroll from 'react-infinite-scroll-component'
import PuffLoading from 'components/common/PuffLoading'
import { cloneDeep, isEmpty } from 'lodash'
import useEffectCustom from 'hooks/useEffectCustom'
import EmptyData from 'components/EmptyData'
import { useSelector } from 'react-redux'
import { AppContext } from 'context/appContext'

const NotiList = ({ list }) => {
  return list.map(item => (
    <NotiItem data={item} key={item._id}/>
  ))
}

const LIMIT = 10
let cacheNoti = {}

const NotiDropdown = () => {
  const [isShowDropdown, setIsShowDropdown] = useState()
  const [listNoti, setListNoti] = useState([])
  const [isHasMore, setIsHasMore] = useState(true)
  const [currPage, setCurrPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const accountSol = useSelector(state => state.accountSol)

  const appContext = useContext(AppContext)
  const { isDarkMode } = appContext

  const isEmptyData = !isLoading && getLength(listNoti) <= 0

  const dropdownNotiRef = useRef()

  useOnClickOutside(dropdownNotiRef, () => {
    setIsShowDropdown(false)
  })

  useEffect(() => {
    setIsShowDropdown(false)
  }, [isDarkMode, window.location.href])

  useEffect(() => {
    cacheNoti = {}
    setListNoti([]) // reset
  }, [accountSol])

  useEffect(() => {
    if (isShowDropdown) {
      setCurrPage(1)
      fetchListNoti()
    }
  }, [isShowDropdown, accountSol])

  useEffect(() => {
    if (totalPage === 0) return
    setIsHasMore(currPage < totalPage)
  }, [currPage, totalPage])

  useEffectCustom(() => {
    if (currPage !== 0) {
      fetchMoreData()
    }
  }, [currPage])

  const fetchListNoti = async () => {
    if (cacheNoti && getLength(cacheNoti.data) > 0) {
      setListNoti(cacheNoti.data)
      setTotalPage(cacheNoti.total)
      return
    }
    setIsLoading(true)
    const params = {
      page: 1,
      size: LIMIT
    }
    const res = await BaseAdapter.getData('saros/noti/address', params)
    if (res && res.success) {
      setIsLoading(false)
      setListNoti(get(res, 'data.data'))
      setTotalPage(get(res, 'data.total'))
      cacheNoti = get(res, 'data') // cache page 1
    }
  }

  const onNextPage = () => {
    setCurrPage(currPage + 1)
  }

  const fetchMoreData = async () => {
    const params = {
      page: currPage,
      size: LIMIT
    }
    const moreData = await await BaseAdapter.getData('saros/noti/address', params)

    if (moreData && moreData.data) {
      const listNotiClone = cloneDeep(listNoti)
      const newList = get(moreData, 'data.data', [])
      setTimeout(() => {
        const newListData = listNotiClone.concat(newList)
        setListNoti(newListData)
      }, 500)
    }
  }

  const handleToggleNoti = () => {
    setIsShowDropdown(!isShowDropdown)
  }
  return (
    <div ref={dropdownNotiRef} className={styles.notiDropdownContainer}>
      <Icon backgroundClassName='noti-icon' name="web_notification" onClick={handleToggleNoti}/>
      <div className={cn('noti-dropdown', isShowDropdown && 'noti-dropdown--active')}>
        {
          isEmptyData
            ? (<div className={'d-flex justify-content-center align-items-center center-loading'}>
              <EmptyData isNoContent size="sm" />
            </div>
            )
            : <InfiniteScroll
              dataLength={getLength(listNoti)}
              next={onNextPage}
              height={280}
              hasMore={isHasMore}
              className='noti-scrollbar'
              loader={
                <div className={`d-flex justify-content-center align-items-center ${isLoading ? 'center-loading' : 'bottom-loading'}`}>
                  <PuffLoading />
                </div>
              }
              endMessage={null}
            >
              {isShowDropdown &&
          <NotiList
            list={listNoti}
          />
              }
            </InfiniteScroll>
        }

      </div>
    </div>
  )
}

export default NotiDropdown
