const images = {

  iconInfoLiquidity: require('assets/images/icons/iconInfoLiquidity.svg').default,
  iconMenu: require('assets/images/icons/menu.svg').default,

  // logo
  logoLuna: require('assets/images/logo/lunaLogo.png').default,
  logoLunaCircle: require('assets/images/logo/lg_circle_luna.svg').default,
  logoSaros: require('assets/images/logo/saros_logo-04.svg').default,
  logoSarosDark: require('assets/images/logo/saros_logo-04-dark.svg').default,
  logoSarosText: require('assets/images/logo/logo-saros-text.svg').default,
  logoSarosTextDark: require('assets/images/logo/logo-saros-text-dark.svg').default,

  // banner
  ballToken: require('assets/images/background/ballToken.png').default,
  lastBall: require('assets/images/background/lastBall.png').default,
  ballSmall: require('assets/images/background/ballSmall.png').default,
  bigBall: require('assets/images/background/bigBall.png').default,
  ballDark: require('assets/images/background/ballDark.png').default,
  ballLight: require('assets/images/background/ballLight.png').default,
  netWorkMobile: require('assets/images/background/networkMobile.svg').default,
  logoSarosPopUp: require('assets/images/background/logoSarosPopUp.svg').default,

  // banner example
  bannerEx: require('assets/images/banners/bannerEx.png').default,
  bannerEx2: require('assets/images/banners/bannerEx2.png').default,
  bannerEx3: require('assets/images/banners/bannerEx3.png').default,
  bannerEx4: require('assets/images/banners/bannerEx4.png').default,

  // sponsors
  hashed: require('assets/images/sponsors/hashed.png').default,
  cryptomind: require('assets/images/sponsors/cryptomind.png').default,
  kdoo: require('assets/images/sponsors/kdoo.png').default,
  portico: require('assets/images/sponsors/portico.png').default,
  spartan: require('assets/images/sponsors/spartan.png').default,
  bgv: require('assets/images/sponsors/bgv.png').default,
  asymVentures: require('assets/images/sponsors/asymVentures.svg').default,
  K300: require('assets/images/sponsors/k300.png').default,
  alamedaSearch: require('assets/images/sponsors/Alameda_Research.png').default,
  impossipleFinance: require('assets/images/sponsors/impossipleFinance.svg')
    .default,
  asymmVentures: require('assets/images/sponsors/asymmVentures.svg').default,
  logoSolana: require('assets/images/sponsors/logoSolana.svg').default,
  logoGenblock: require('assets/images/sponsors/logoGenblock.svg').default,
  kyros: require('assets/images/sponsors/kyros.svg').default,
  evernew: require('assets/images/sponsors/evernew.png').default,

  hashedDark: require('assets/images/sponsors/hashedDark.svg').default,
  cryptomindDark: require('assets/images/sponsors/cryptomindDark.svg').default,
  // kdooDark: require('assets/images/sponsors/kdooDark.svg').default,
  // porticoDark: require('assets/images/sponsors/porticoDark.svg').default,
  spartanDark: require('assets/images/sponsors/spartanDark.svg').default,
  bgvDark: require('assets/images/sponsors/bgvDark.svg').default,
  // asymVenturesDark: require('assets/images/sponsors/asymVenturesDark.svg').default,
  K300Dark: require('assets/images/sponsors/k300Dark.svg').default,
  alamedaSearchDark: require('assets/images/sponsors/alamedaReasearchDark.png').default,
  impossipleFinanceDark: require('assets/images/sponsors/impossipleFinanceDark.svg')
    .default,
  asymmVenturesDark: require('assets/images/sponsors/asymmVenturesDark.svg').default,
  logoSolanaDark: require('assets/images/sponsors/logoSolanaDark.svg').default,
  logoGenblockDark: require('assets/images/sponsors/logoGenblockDark.svg').default,
  kyrosDark: require('assets/images/sponsors/kyrosDark.svg').default,
  evernewDark: require('assets/images/sponsors/evernewDark.webp').default,

  // coinGecko: require('assets/images/sponsors/coinGecko.png').default,
  // kyberNetwork: require('assets/images/sponsors/kyberNetwork.png').default,
  // linkPad: require('assets/images/sponsors/linkPad.png').default,
  // multiCoin: require('assets/images/sponsors/multiCoin.png').default,
  // parafi: require('assets/images/sponsors/parafi.png').default,

  // token
  solana: require('assets/images/token/solana.svg').default,
  solanaDark: require('assets/images/token/solanaDark.svg').default,
  tokenFida: require('assets/images/token/tokenFida.svg').default,
  tokenMedia: require('assets/images/token/tokenMedia.svg').default,
  tokenRay: require('assets/images/token/tokenRay.svg').default,
  tokenRayDark: require('assets/images/token/tokenRayDark.svg').default,
  tokenScope: require('assets/images/token/tokenScope.png').default,
  tokenSRM: require('assets/images/token/tokenSRM.svg').default,
  tokenSRMDark: require('assets/images/token/tokenSRMDark.svg').default,
  tokenStep: require('assets/images/token/tokenStep.png').default,
  chartUp: require('assets/images/token/chartUp.png').default,
  tokenMercurial: require('assets/images/token/mercurial.svg').default,
  solStarter: require('assets/images/token/solstarter.svg').default,
  tokenSolStarter: require('assets/images/token/tokenSolStarter.svg').default,
  solStarterWhite: require('assets/images/token/solStarterWhitte.png').default,

  // icons
  iconCapital: require('assets/images/icons/iconCapital.svg').default,
  iconCheck: require('assets/images/icons/iconCheck.svg').default,
  iconComposability: require('assets/images/icons/iconComposability.svg')
    .default,
  iconFriendly: require('assets/images/icons/iconFriendly.svg').default,
  iconLowCost: require('assets/images/icons/iconLowCost.svg').default,
  iconPermisson: require('assets/images/icons/iconPermisson.svg').default,
  iconScalibility: require('assets/images/icons/iconScalibility.svg').default,
  iconTelegram: require('assets/images/icons/iconTelegram.svg').default,
  iconTwitter: require('assets/images/icons/iconTwitter.svg').default,
  iconArrow: require('assets/images/icons/icon-arrow.svg').default,
  iconArrowDown: require('assets/images/icons/iconArrowDown.svg').default,

  iconSetting: require('assets/images/icons/iconSetting.svg').default,
  iconSettingDark: require('assets/images/icons/iconSettingDark.svg').default,
  iconDropdown: require('assets/images/icons/iconDropdown.svg').default,
  iconDropdownDark: require('assets/images/icons/iconDropdownDark.svg').default,
  iconSearchBlack: require('assets/images/icons/iconSearch.svg').default,
  iconSwap: require('assets/images/icons/iconSwap.svg').default,
  iconSwapDark: require('assets/images/icons/iconSwapDark.svg').default,
  iconSolWallet: require('assets/images/icons/iconSolWallet.svg').default,
  loadingLottie: require('assets/images/icons/loadingLotties.json').default,
  iconError: require('assets/images/icons/iconError.svg').default,
  iconSend: require('assets/images/icons/iconSend.svg').default,
  iconSuccessDark: require('assets/images/icons/iconSuccess.svg').default,
  iconSync: require('assets/images/icons/iconSync.svg').default,
  iconSearchs: require('assets/images/icons/search.svg').default,
  iconClose: require('assets/images/icons/iconClose.svg').default,
  iconClose2: require('assets/images/icons/iconClose2.svg').default,
  iconNoti: require('assets/images/icons/iconNoti.svg').default,
  successIcon: require('assets/images/icons/successIcon.svg').default,
  errIcon: require('assets/images/icons/iconErr.svg').default,
  iconWarningYell: require('assets/images/icons/iconWarningYell.svg').default,
  ballModal: require('assets/images/icons/ballModal.svg').default,
  iconAlertConfirm: require('assets/images/icons/alertConfirm.svg').default,
  iconBallWarning: require('assets/images/icons/iconBallWarning.svg').default,
  iconBallWarningDark: require('assets/images/icons/iconBallWarningDark.svg').default,

  halfShape: require('assets/images/icons/half-shape-2.svg').default,
  iconClaim: require('assets/images/icons/icon-claim.svg').default,
  arrowLeft: require('assets/images/icons/arrow-left-circle.svg').default,
  iconSolana: require('assets/images/tokenSwap/sol.png').default,
  icoUnknown: require('assets/images/tokenSwap/unknown.png').default,
  iconSearchGrey: require('assets/images/icons/iconSearchGrey.svg').default,
  iconPagiantionLeft: require('assets/images/icons/iconPagiantionLeft.svg')
    .default,
  iconFavorite: require('assets/images/icons/iconFavorite.svg').default,
  iconScan: require('assets/images/icons/iconScan.svg').default,
  iconGrowDown: require('assets/images/icons/iconGrowDown.svg').default,
  iconGrowUp: require('assets/images/icons/iconGrowUp.svg').default,
  iconCheckWhite: require('assets/images/icons/iconCheckWhite.svg').default,
  iconBackLeft: require('assets/images/icons/iconBackLeft.svg').default,
  iconPlus: require('assets/images/icons/iconPlus.svg').default,
  iconDropdownGrey: require('assets/images/icons/iconDropdownGrey.svg').default,

  iconUFO: require('assets/images/icons/iconUFO.svg').default,
  iconUFODark: require('assets/images/icons/iconUFODark.svg').default,
  iconLoad: require('assets/images/icons/iconLoad.svg').default,
  iconPlusAdd: require('assets/images/icons/addPlus.svg').default,
  iconEmptyTable: require('assets/images/icons/iconEmptyTable.svg').default,
  // iconEmptyToken: require('assets/images/icons/iconEmptyToken.svg').default,
  iconEmptyToken: require('assets/images/icons/tokenEmpty.png').default,

  // launchpad
  iconCaretBlue: require('assets/images/icons/iconCaretBlue.svg').default,
  iconCaretRightPurple: require('assets/images/icons/iconCaretRightPurple.svg')
    .default,
  iconClock: require('assets/images/icons/iconClock.svg').default,
  iconCopy: require('assets/images/icons/iconCopy.svg').default,
  loadingSol: require('assets/images/icons/loadingSol.svg').default,

  // defi
  lunaFarm: require('assets/images/defi/lunaFarm.png').default,
  lunaPad: require('assets/images/defi/lunaPad.png').default,
  lunaStack: require('assets/images/defi/lunaStack.png').default,
  lunaSwap: require('assets/images/defi/lunaSwap.png').default,

  // token swap
  swapSolana: require('assets/images/tokenSwap/swapSolana.png').default,
  iconHistory: require('assets/images/icons/iconHistory.svg').default,
  chart: require('assets/images/icons/chart.svg').default,
  chartDark: require('assets/images/icons/chartDark.svg').default,
  chartClose: require('assets/images/icons/chartClose.svg').default,
  iconChange: require('assets/images/icons/iconChange.svg').default,
  iconCollapse: require('assets/images/icons/iconCollapse.svg').default,
  iconExpand: require('assets/images/icons/iconExpand.svg').default,

  // background
  bgBlueBlur: require('assets/images/background/bg-blue-blur.svg').default,
  shape1: require('assets/images/background/shape-1.svg').default,
  backgroundSaros: require('assets/images/background/bg-saros.svg').default,
  halfBall: require('assets/images/background/half-ball.svg').default,
  swapShare: require('assets/images/background/swap_share.jpg').default,
  farmShare: require('assets/images/background/farm_share.jpg').default,
  stakeShare: require('assets/images/background/stake_share.jpg').default,

  // light - dark
  iconMoon: require('assets/images/icons/iconMoon.svg').default,
  iconSun: require('assets/images/icons/iconSun.svg').default,

  // search
  iconClear: require('assets/images/icons/iconClear.svg').default,

  // icon token example
  iconBTC: require('assets/images/icons/IconBTC.png').default,
  iconETH: require('assets/images/icons/IconETH.png').default,
  // pool detail
  iconVerify: require('assets/images/icons/iconVerify.svg').default,
  iconVerifyCheck: require('assets/images/icons/iconVerifyCheck.svg').default,
  ballNetwork: require('assets/images/background/ballNetwork.png').default,
  ballNetworkDark: require('assets/images/background/ballNetworkDark1.png').default,

  // farm
  iconDown: require('assets/images/icons/iconDown.svg').default,
  iconViewBlock: require('assets/images/icons/iconViewBlock.svg').default,
  iconPairInfo: require('assets/images/icons/iconPairInfo.svg').default,
  iconC98BNB: require('assets/images/icons/iconC98-BNB.svg').default,
  iconCancelPass: require('assets/images/icons/iconCancelPass.svg').default,
  iconCopyLine: require('assets/images/icons/IconCopyLine.svg').default,

  iconSortDown: require('assets/images/icons/iconSortDown.svg').default,
  iconSortUp: require('assets/images/icons/iconSortUp.svg').default,

  // new img
  illusBallSnapshot: require('assets/images/background/illus_snapshot_ball.png').default,
  illusBallDetailSnapshot: require('assets/images/background/illus_detail_snapshot.png').default,
  blurBallSnapshot1: require('assets/images/background/snapshot_blur1.png').default,
  blurBallSnapshot2: require('assets/images/background/snapshot_blur2.png').default,
  waveBanner: require('assets/images/banners/wave-farm.png').default,

  // landing
  sarosAtom: require('assets/images/background/saros-atom.png').default,
  landingSwap1: require('assets/images/background/swap-1.png').default,
  landingSwap2: require('assets/images/background/swap-2.png').default,
  landingStake1: require('assets/images/background/stake-1.png').default,
  landingStake2: require('assets/images/background/stake-2.png').default,
  landingFarm1: require('assets/images/background/farm-1.png').default,
  landingFarm2: require('assets/images/background/farm-2.png').default,
  landingSnapshot1: require('assets/images/background/snapshot-1.png').default,
  landingSnapshot2: require('assets/images/background/snapshot-2.png').default,

  sliderCircle: require('assets/images/icons/sliderCircle.png').default,
  iconSaros: require('assets/images/icons/logoSaros.svg').default,
  iconSarosWhite: require('assets/images/icons/logoSarosWhite.svg').default,
  iconSarosBlack: require('assets/images/icons/logoSarosBlack.svg').default,
  iconToken: require('assets/images/icons/saros_token.png').default,

  footerSarosLight: require('assets/images/logo/footerSarosLight.svg').default,
  footerSarosDark: require('assets/images/logo/footerSarosDark.svg').default,

  emptyLiquidity: require('assets/images/icons/emptyLiquidity.png').default,
  bgReceiveNew: require('assets/images/logo/bgReceiveNew.png').default,
  logoSlippage: require('assets/images/logo/slippage.png').default,
  logoEmpty: require('assets/images/logo/empty.png').default,
  logoUnstake: require('assets/images/logo/unstake.png').default,
  logoHarvest: require('assets/images/logo/harvest.png').default,
  logoSuccess: require('assets/images/logo/success.png').default,
  logoReview: require('assets/images/logo/review.png').default,
  logoFarm: require('assets/images/logo/farm_logo.png').default,
  logoStake: require('assets/images/logo/stake_logo.png').default,
  logoSarosNew: require('assets/images/logo/new_logo_saros.svg').default,
  logoSarosNewDark: require('assets/images/logo/new_logo_saros_dark.svg').default,

  //

  capitalEffiency: require('assets/images/logo/capital-effiency.png').default,
  composability: require('assets/images/logo/composability.png').default,
  friendly: require('assets/images/logo/friendly.png').default,
  lowCost: require('assets/images/logo/low-cost.png').default,
  permissionless: require('assets/images/logo/permissionless.png').default,
  scalability: require('assets/images/logo/scalability.png').default,

  successIconNew: require('assets/images/iconsNew/success.svg').default,
  warningIconNew: require('assets/images/iconsNew/warning.svg').default,
  errorIconNew: require('assets/images/iconsNew/error.svg').default,
  infoIconNew: require('assets/images/iconsNew/info.svg').default,
  logoActiveWalletNew: require('assets/images/logo/logoActiveWalletNew.svg').default,

  // lotties

  sarosFarmVideo: require('assets/images/lotties/saros-farm.webm').default,
  sarosFarmGif: require('assets/images/lotties/saros-farm.gif').default,
  sarosSnapshotVideo: require('assets/images/lotties/saros-snapshot.webm').default,
  sarosSnapshotGif: require('assets/images/lotties/saros-snapshot.gif').default,
  sarosStakeVideo: require('assets/images/lotties/saros-stake.webm').default,
  sarosStakeGif: require('assets/images/lotties/saros-stake.gif').default,
  sarosLandingWebm: require('assets/images/lotties/saros-landing.webm').default,
  sarosLandingMp4: require('assets/images/lotties/saros-landing.mp4').default,
  sarosLandingGif: require('assets/images/lotties/saros-landing.gif').default

}

export default images

//   iconFlowerSaros: require('pages/FarmScreen/assets/image/banner-left.png').default,

//   iconNotiPopupSaros: require('pages/FarmScreen/assets/image/noti-popup-saros.png').default,
