import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'
import Button from 'components/common/Button'

const duration = 5

const ModalConfirmImpact = ({ numImpact, onConfirm, onLoading }) => {
  const { t } = useTranslation()
  const [newDuration, setDuration] = useState(duration)
  const [interval, setCountdown] = useState(null)

  const isDisable = newDuration > 0

  const onCancelPopup = () => {
    window.closeModal()
    onLoading && onLoading(false)
  }

  const onConfirmPopup = () => {
    window.closeModal()
    onConfirm && onConfirm()
  }

  useEffect(() => {
    if (interval && !newDuration) {
      clearInterval(interval)
    }
  }, [newDuration, interval])

  useEffect(() => {
    let number = duration
    const newInterval = setInterval(() => {
      number = number - 1
      setDuration(number)
    }, 1000)

    setCountdown(newInterval)
  }, [])

  return (
    <div className='box-impact-large'>
      <span>
        {t('impactLarge', {
          number: numImpact
        })}
      </span>

      <span>{t('sureContinue')}</span>

      <div className="button-list-impact">
        <Button
          type="border-popup"
          className="button-item button-item--cancel"
          block
          onClick={onCancelPopup}
        >
              Cancel
        </Button>
        <Button
          className="button-item"
          block
          onClick={onConfirmPopup}
          isDisable={isDisable}
        >
          {newDuration ? `Confirm (${newDuration}s)` : 'Confirm'}
        </Button>
      </div>
    </div>
  )
}

export default ModalConfirmImpact
