import ReactDOM from 'react-dom'
import React, { useContext, useEffect, useState } from 'react'
import styles from './style.module.scss'
import cn from 'classnames'
import { AppContext } from 'context/appContext'
import { Icon } from 'components/Icon'
import { useTranslation } from 'react-i18next'
const ListTokenMobileContainer = ({ children }) => {
  const appContext = useContext(AppContext)
  const [isOpenListTokenMobile, setIsOpenListTokenMobile] = useState(false)
  const [screenContent, setScreenContent] = useState()
  const { t } = useTranslation()

  const handleOpenModal = (props) => {
    const { content } = props
    setScreenContent(content)
    setIsOpenListTokenMobile(true)
    document.body.style.overflow = 'hidden'
  }

  const handleCloseModal = () => {
    setIsOpenListTokenMobile(false)
    document.body.style.overflow = 'unset'
  }

  useEffect(() => {
    window.showListTokenMobile = handleOpenModal
    window.closeListTokenMobile = handleCloseModal
  }, [])

  return (
    <div className={cn(styles.tokenMobileContainer, isOpenListTokenMobile && styles.acitvieContainer)}>
      <div className='header-text'>
        <div className='text-xl bold'>{t('selectToken')}</div>
        <Icon name="web_close" onClick={handleCloseModal} />
      </div>
      {screenContent}
    </div>
  )
}

export default ListTokenMobileContainer
