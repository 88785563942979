const { PublicKey } = require('@solana/web3.js')

// AMM PROGRAM
export const SABER_PROGRAM_ID = new PublicKey(
  'SSwpkEEcbUqx4vtoEByFjSkhKdCT862DNVb52nZg1UZ'
)

// TOKEN
export const TOKEN_PROGRAM_ID = new PublicKey(
  'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'
)
