import React, { useState, useEffect, useMemo, useRef, useContext } from 'react'
import CustomTable from 'components/CustomTable'
import cn from 'clsx'
import { formatAddress, formatBilion, formatNumberBro, formatPrice, getItemStorage, getLength, validateBlockChainAddress } from 'common/functions'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import { useHistory } from 'react-router-dom'
import get from 'lodash/get'
import TokenImage from 'components/common/TokenImage'
import Pagination from 'components/Pagination'
import { useSelector } from 'react-redux'
import ReduxServices from 'common/redux'

const LIMIT = 10

function TableFarmStake ({ address, tableData, isLoading, totalPage, onChangePage, isStake, currPage, onClickRow }) {
  // if has address props =>  remove search input
  const { t } = useTranslation()
  const history = useHistory()
  const currencyRedux = useSelector(state => state.currencyRedux) // needed to rerender onChange Currency

  // const windowRef = React.useRef(window)
  // const { width } = useElementWidth(windowRef)
  const width = window.innerWidth
  const widthResize = width
  const isMobile = width < 768

  const handleChangePage = (val) => {
    const { selected } = val

    onChangePage && onChangePage(selected + 1)
  }

  const tableRowsData = useMemo(() => {
    if (getLength(tableData) === 0) return []
    return tableData.map((item, index) => {
      return {
        index: LIMIT * (currPage - 1) + index + 1,
        account: item.address,
        tokenIn: item.tokenIn,
        status: get(item, 'status'),
        tokenOut: item.tokenOut,
        apr: item.apr,
        value: item.amountUSD
      }
    })
  }, [tableData, currencyRedux])

  const columnHeaderFarmStake = [
    {
      title: '',
      dataIndex: 'index',
      width: 2,
      hide: widthResize <= 768,
      render: (val) => {
        return <div>{val}</div>
      }
    },
    {
      title: t('account'),
      dataIndex: 'account',
      width: 20,
      render: (val) => {
        return <div className='color-theme'>{formatAddress(val, isMobile ? 6 : 10) }</div>
      }
    },
    {
      title: t(isStake ? 'Token' : 'Pair'),
      dataIndex: 'tokenIn',
      width: 20,
      render: (val) => {
        return (
          <div className='d-flex align-items-center  uppercase'>
            {
              val?.map(item => (
                <TokenImage key={item.id} src={get(item, 'icon')} alt="" className="token-img token-img--token-1 img-24 mr-2" />
              ))
            }
            {
              val?.map((item, index) => (
                <div key={item.id} className='token-symbol-container'>
                  <span >{get(item, 'symbol')}</span>
                  { (index === 0 && !isStake) && <span >/</span>}
                </div>

              ))
            }

          </div>
        )
      }
    },
    {
      title: t('apr'),
      dataIndex: 'apr',
      width: 20,
      align: 'right',
      hide: widthResize <= 768,
      render: (val) => {
        return <div>{formatNumberBro({ number: val, mantissa: 2 })}%</div>
      }
    },
    {
      title: t('status'),
      dataIndex: 'status',
      width: 15,
      align: 'right',
      hide: widthResize <= 768,
      render: (val) => {
        return <div className={cn(val === 'active' ? 'color-success' : 'color-theme')}>{t(val)}</div>
      }
    },
    {
      title: t(isStake ? 'tokenValue' : 'lpValue'),
      dataIndex: 'value',
      width: 15,
      align: 'right',
      render: (val) => {
        return <div>{ReduxServices.formatPrice(val)}</div>
      }
    },
    {
      title: t('earn'),
      dataIndex: 'tokenOut',
      align: 'right',
      hide: widthResize <= 768,
      width: 20,
      render: (val) => {
        return (
          <div className='uppercase union-icon-list'>
            {
              val?.map(item => (
                <TokenImage key={item.id} src={get(item, 'icon')} alt="" className="token-img union-icon-list--icon token-img--token-1 img-24 mr-2" />
              ))
            }

          </div>
        )
      }
    }
  ]

  const handleClickRow = (row) => {
    const address = get(row, 'account')
    history.push(`/info/${address}`)

    onClickRow && onClickRow(row)
  }

  return (
    <div className={cn(styles.tableFarmStakeOverview, 'mb-5')}>
      <div className="table-wallet-info__content">
        <CustomTable
          isLoading={isLoading}
          columns={columnHeaderFarmStake}
          dataSource={tableRowsData }
          onClickRow={handleClickRow}
          className="table-height"
        />
        {
          getLength(tableData) > 0 &&
          <Pagination
            forcePage={currPage - 1}
            totalPage={totalPage}
            handleSelectedPage={handleChangePage}
          />
        }

      </div>
    </div>
  )
}

export default TableFarmStake
