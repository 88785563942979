import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import {
  getItemStorage,
  getLength,
  nFormatter,
  setItemStorage
} from 'common/functions'
import BannerImage from 'components/common/BannerImage'
import { KEY_STORE } from 'common/constants/keystore'
import { io } from 'socket.io-client'
import cn from 'clsx'
import { Link } from 'react-router-dom'
import ReactNumberTicker from 'react-animate-number-ticker'
import 'react-animate-number-ticker/dist/index.css'
import Badge from 'components/Badge'
import Slider from 'react-slick'
import BaseAPI from 'controller/api/BaseAPI'

let socketService

const NewSnapshot = () => {
  const { t } = useTranslation()

  const initStat = getItemStorage(KEY_STORE.HOME_STATISTICAL) || {}

  const [sarosLandingStats, setSarosLandingStats] = useState(initStat)
  const [latestSnapshot, setLatestSnapshot] = useState([])

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    className: 'bundle-carousel',
    slidesToShow: 1,
    swipe: false,
    slidesToScroll: 1,
    arrows: false
  }

  useEffect(() => {
    onListenSocketHome()
    getPinnedSnapshot()
  }, [])

  const onListenSocketHome = () => {
    const jwtToken = getItemStorage(KEY_STORE.JWT_TOKEN)
    try {
      if (jwtToken) {
        setTimeout(() => {
          socketService = io(process.env.REACT_APP_SOCKET, {
            transports: ['websocket']
          })

          socketService.on('connect', async (e) => {
            socketService.emit('authentication_saros', jwtToken)
          })

          socketService.on('UPDATE_SAROS_HOME', (e) => {
            const { type, data } = e
            if (type === 'SAROS_HOME') {
              setSarosLandingStats((prevState) => {
                if (
                  nFormatter(prevState.totalLiquidity) ===
                    nFormatter(data.totalLiquidity) ||
                  nFormatter(prevState.totalVolume) ===
                    nFormatter(data.totalVolume) ||
                  nFormatter(prevState.totalAddress) ===
                    nFormatter(data.totalAddress)
                ) {
                  return prevState
                } else {
                  return data
                }
              })
              setItemStorage(data, KEY_STORE.HOME_STATISTICAL)
            }
          })
        }, 500)
      }
    } catch (error) {
      //   this.activesocket(true)
    }
  }

  const getPinnedSnapshot = async () => {
    const bodyQuery = {
      keyword: '',
      page: 1,
      size: 100,
      sort: 'desc',
      status: 'onGoing'
    }

    const res = await BaseAPI.getData('snapshot/campaign', bodyQuery)
    if (res && res.data) {
      setLatestSnapshot(res.data)
    }
  }

  return (
    <div className={styles.newSnapshotContainer}>
      <div className="stat-list">
        <div className="stat-box">
          <div className="stat-box__num">
            <ReactNumberTicker
              number={nFormatter(sarosLandingStats.totalLiquidity) + '+'}
            />
            {/* <CountUpComponent
              endNum={sarosLandingStats.totalLiquidity}
              suffix="+"
            /> */}
          </div>
          <div className="stat-box__text">{t('totalLiquidity')}</div>
        </div>
        <div className="stat-box">
          <div className="stat-box__num">
            <ReactNumberTicker
              number={nFormatter(sarosLandingStats.totalVolume) + '+'}
            />
          </div>

          <div className="stat-box__text">{t('totalTradingVolume')}</div>
        </div>
        <div className="stat-box">
          <div className="stat-box__num">
            <ReactNumberTicker
              number={nFormatter(sarosLandingStats.totalAddress) + '+'}
            />
          </div>
          <div className="stat-box__text">{t('totalUser')}</div>
        </div>
      </div>

      {/* <div className={cn('newest-snapshot', !get(latestSnapshot, 'image') && 'd-none')}>
        <div className='newest-snapshot__text'>{t('newestSnapshot')}</div>
        <Link to={`/snapshot/${latestSnapshot.id}`} className='snapshot-image-container'>
          <a>
            <BannerImage className="newest-snapshot__image" src={latestSnapshot.image}/>
          </a>
        </Link>
      </div> */}

      <div
        className={cn(
          'newest-snapshot',
          getLength(latestSnapshot) === 0 && 'd-none'
        )}
      >
        <div className="newest-snapshot__text">{t('newestSnapshot')}</div>
        <div className={styles.carouselWidth}>
          <Slider {...settings}>
            {latestSnapshot?.map((item) => {
              const href = item.id ? '/snapshot/' + item.id : '/snapshot'
              return (
                <div key={item.id}>
                  <Link to={href} className="snapshot-image-container">
                    <a>
                      <BannerImage
                        className="newest-snapshot__image"
                        src={item.image}
                      />
                      <Badge className="badge-snapshot" variant="success">
                        {t('onGoing')}
                      </Badge>
                    </a>
                  </Link>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default NewSnapshot
