export default {
  version: '0.1.0',
  name: 'coin98_dollar_mint_burn',
  instructions: [
    {
      name: 'createMinter',
      accounts: [
        {
          name: 'root',
          isMut: true,
          isSigner: true
        },
        {
          name: 'minter',
          isMut: true,
          isSigner: false
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false
        }
      ],
      args: [
        {
          name: 'derivationPath',
          type: 'bytes'
        }
      ]
    },
    {
      name: 'setMinter',
      accounts: [
        {
          name: 'root',
          isMut: false,
          isSigner: true
        },
        {
          name: 'minter',
          isMut: true,
          isSigner: false
        }
      ],
      args: [
        {
          name: 'isActive',
          type: 'bool'
        },
        {
          name: 'inputTokens',
          type: {
            vec: 'publicKey'
          }
        },
        {
          name: 'inputDecimals',
          type: {
            vec: 'u16'
          }
        },
        {
          name: 'inputPercentages',
          type: {
            vec: 'u16'
          }
        },
        {
          name: 'inputPriceFeeds',
          type: {
            vec: 'publicKey'
          }
        },
        {
          name: 'feePercent',
          type: 'u16'
        },
        {
          name: 'totalMintedLimit',
          type: 'u64'
        },
        {
          name: 'perPeriodMintedLimit',
          type: 'u64'
        }
      ]
    },
    {
      name: 'createBurner',
      accounts: [
        {
          name: 'root',
          isMut: true,
          isSigner: true
        },
        {
          name: 'burner',
          isMut: true,
          isSigner: false
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false
        }
      ],
      args: [
        {
          name: 'derivationPath',
          type: 'bytes'
        }
      ]
    },
    {
      name: 'setBurner',
      accounts: [
        {
          name: 'root',
          isMut: false,
          isSigner: true
        },
        {
          name: 'burner',
          isMut: true,
          isSigner: false
        }
      ],
      args: [
        {
          name: 'isActive',
          type: 'bool'
        },
        {
          name: 'outputToken',
          type: 'publicKey'
        },
        {
          name: 'outputDecimals',
          type: 'u16'
        },
        {
          name: 'outputPriceFeed',
          type: 'publicKey'
        },
        {
          name: 'feePercent',
          type: 'u16'
        },
        {
          name: 'totalBurnedLimit',
          type: 'u64'
        },
        {
          name: 'perPeriodBurnedLimit',
          type: 'u64'
        }
      ]
    },
    {
      name: 'mint',
      accounts: [
        {
          name: 'user',
          isMut: false,
          isSigner: true
        },
        {
          name: 'appData',
          isMut: false,
          isSigner: false
        },
        {
          name: 'rootSigner',
          isMut: false,
          isSigner: false
        },
        {
          name: 'cusdMint',
          isMut: true,
          isSigner: false
        },
        {
          name: 'minter',
          isMut: true,
          isSigner: false
        },
        {
          name: 'recipient',
          isMut: true,
          isSigner: false
        },
        {
          name: 'chainlinkProgram',
          isMut: false,
          isSigner: false
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false
        }
      ],
      args: [
        {
          name: 'amount',
          type: 'u64'
        },
        {
          name: 'extraInstructions',
          type: 'bytes'
        }
      ]
    },
    {
      name: 'burn',
      accounts: [
        {
          name: 'user',
          isMut: false,
          isSigner: true
        },
        {
          name: 'appData',
          isMut: false,
          isSigner: false
        },
        {
          name: 'rootSigner',
          isMut: false,
          isSigner: false
        },
        {
          name: 'cusdMint',
          isMut: true,
          isSigner: false
        },
        {
          name: 'burner',
          isMut: true,
          isSigner: false
        },
        {
          name: 'poolCusd',
          isMut: true,
          isSigner: false
        },
        {
          name: 'userCusd',
          isMut: true,
          isSigner: false
        },
        {
          name: 'chainlinkProgram',
          isMut: false,
          isSigner: false
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false
        }
      ],
      args: [
        {
          name: 'amount',
          type: 'u64'
        }
      ]
    },
    {
      name: 'withdrawToken',
      accounts: [
        {
          name: 'root',
          isMut: false,
          isSigner: true
        },
        {
          name: 'appData',
          isMut: false,
          isSigner: false
        },
        {
          name: 'rootSigner',
          isMut: false,
          isSigner: false
        },
        {
          name: 'poolToken',
          isMut: true,
          isSigner: false
        },
        {
          name: 'recipientToken',
          isMut: true,
          isSigner: false
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false
        }
      ],
      args: [
        {
          name: 'amount',
          type: 'u64'
        }
      ]
    },
    {
      name: 'unlockTokenMint',
      accounts: [
        {
          name: 'root',
          isMut: false,
          isSigner: true
        },
        {
          name: 'appData',
          isMut: false,
          isSigner: false
        },
        {
          name: 'rootSigner',
          isMut: false,
          isSigner: false
        },
        {
          name: 'tokenMint',
          isMut: true,
          isSigner: false
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false
        }
      ],
      args: []
    },
    {
      name: 'createAppData',
      accounts: [
        {
          name: 'root',
          isMut: true,
          isSigner: true
        },
        {
          name: 'appData',
          isMut: true,
          isSigner: false
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false
        }
      ],
      args: []
    },
    {
      name: 'setAppData',
      accounts: [
        {
          name: 'root',
          isMut: false,
          isSigner: true
        },
        {
          name: 'appData',
          isMut: true,
          isSigner: false
        }
      ],
      args: [
        {
          name: 'limit',
          type: 'u32'
        }
      ]
    }
  ],
  accounts: [
    {
      name: 'AppData',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'nonce',
            type: 'u8'
          },
          {
            name: 'signerNonce',
            type: 'u8'
          },
          {
            name: 'limit',
            type: 'u32'
          }
        ]
      }
    },
    {
      name: 'Minter',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'nonce',
            type: 'u8'
          },
          {
            name: 'isActive',
            type: 'bool'
          },
          {
            name: 'inputTokens',
            type: {
              vec: 'publicKey'
            }
          },
          {
            name: 'inputDecimals',
            type: {
              vec: 'u16'
            }
          },
          {
            name: 'inputPercentages',
            type: {
              vec: 'u16'
            }
          },
          {
            name: 'inputPriceFeeds',
            type: {
              vec: 'publicKey'
            }
          },
          {
            name: 'feePercent',
            type: 'u16'
          },
          {
            name: 'accumulatedFee',
            type: 'u64'
          },
          {
            name: 'totalMintedAmount',
            type: 'u64'
          },
          {
            name: 'totalMintedLimit',
            type: 'u64'
          },
          {
            name: 'perPeriodMintedAmount',
            type: 'u64'
          },
          {
            name: 'perPeriodMintedLimit',
            type: 'u64'
          },
          {
            name: 'lastPeriodTimestamp',
            type: 'i64'
          }
        ]
      }
    },
    {
      name: 'Burner',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'nonce',
            type: 'u8'
          },
          {
            name: 'isActive',
            type: 'bool'
          },
          {
            name: 'outputToken',
            type: 'publicKey'
          },
          {
            name: 'outputDecimals',
            type: 'u16'
          },
          {
            name: 'outputPriceFeed',
            type: 'publicKey'
          },
          {
            name: 'feePercent',
            type: 'u16'
          },
          {
            name: 'accumulatedFee',
            type: 'u64'
          },
          {
            name: 'totalBurnedAmount',
            type: 'u64'
          },
          {
            name: 'totalBurnedLimit',
            type: 'u64'
          },
          {
            name: 'perPeriodBurnedAmount',
            type: 'u64'
          },
          {
            name: 'perPeriodBurnedLimit',
            type: 'u64'
          },
          {
            name: 'lastPeriodTimestamp',
            type: 'i64'
          }
        ]
      }
    }
  ],
  types: [
    {
      name: 'BurnTokenParams',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'instruction',
            type: 'u8'
          },
          {
            name: 'amount',
            type: 'u64'
          }
        ]
      }
    },
    {
      name: 'MintTokenParams',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'instruction',
            type: 'u8'
          },
          {
            name: 'amount',
            type: 'u64'
          }
        ]
      }
    },
    {
      name: 'TransferAuthorityParams',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'instruction',
            type: 'u8'
          },
          {
            name: 'authorityType',
            type: 'u8'
          },
          {
            name: 'hasAuthority',
            type: 'bool'
          },
          {
            name: 'newAuthority',
            type: 'publicKey'
          }
        ]
      }
    },
    {
      name: 'TransferTokenParams',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'instruction',
            type: 'u8'
          },
          {
            name: 'amount',
            type: 'u64'
          }
        ]
      }
    },
    {
      name: 'AccountState',
      type: {
        kind: 'enum',
        variants: [
          {
            name: 'Uninitialized'
          },
          {
            name: 'Initialized'
          },
          {
            name: 'Frozen'
          }
        ]
      }
    }
  ],
  events: [
    {
      name: 'CreateMinterEvent',
      fields: [
        {
          name: 'isActive',
          type: 'bool',
          index: false
        }
      ]
    },
    {
      name: 'SetMinterEvent',
      fields: [
        {
          name: 'isActive',
          type: 'bool',
          index: false
        },
        {
          name: 'inputTokens',
          type: {
            vec: 'publicKey'
          },
          index: false
        },
        {
          name: 'inputDecimals',
          type: {
            vec: 'u16'
          },
          index: false
        },
        {
          name: 'inputPercentages',
          type: {
            vec: 'u16'
          },
          index: false
        },
        {
          name: 'inputPriceFeeds',
          type: {
            vec: 'publicKey'
          },
          index: false
        },
        {
          name: 'feePercent',
          type: 'u16',
          index: false
        },
        {
          name: 'totalMintedLimit',
          type: 'u64',
          index: false
        },
        {
          name: 'perPeriodMintedLimit',
          type: 'u64',
          index: false
        }
      ]
    },
    {
      name: 'CreateBurnerEvent',
      fields: [
        {
          name: 'isActive',
          type: 'bool',
          index: false
        }
      ]
    },
    {
      name: 'SetBurnerEvent',
      fields: [
        {
          name: 'isActive',
          type: 'bool',
          index: false
        },
        {
          name: 'outputToken',
          type: 'publicKey',
          index: false
        },
        {
          name: 'outputDecimals',
          type: 'u16',
          index: false
        },
        {
          name: 'outputPriceFeed',
          type: 'publicKey',
          index: false
        },
        {
          name: 'feePercent',
          type: 'u16',
          index: false
        },
        {
          name: 'totalBurnedLimit',
          type: 'u64',
          index: false
        },
        {
          name: 'perPeriodBurnedLimit',
          type: 'u64',
          index: false
        }
      ]
    },
    {
      name: 'SetAppDataEvent',
      fields: [
        {
          name: 'limit',
          type: 'u32',
          index: false
        }
      ]
    },
    {
      name: 'WithdrawTokenEvent',
      fields: [
        {
          name: 'recipientTokenAccount',
          type: 'publicKey',
          index: false
        },
        {
          name: 'amount',
          type: 'u64',
          index: false
        }
      ]
    },
    {
      name: 'UnlockTokenMintEvent',
      fields: [
        {
          name: 'tokenMint',
          type: 'publicKey',
          index: false
        },
        {
          name: 'newAuthority',
          type: 'publicKey',
          index: false
        }
      ]
    }
  ],
  errors: [
    {
      code: 6000,
      name: 'InvalidAccount',
      msg: 'CUSD Factory: Invalid account.'
    },
    {
      code: 6001,
      name: 'InvalidInput',
      msg: 'CUSD Factory: Invalid input.'
    },
    {
      code: 6002,
      name: 'LimitReached',
      msg: 'CUSD Factory: Limit reached'
    },
    {
      code: 6003,
      name: 'Unauthorized',
      msg: 'CUSD Factory: Unauthorized'
    },
    {
      code: 6004,
      name: 'Unavailable',
      msg: 'CUSD'
    }
  ]
}
