import images from 'assets/images'
import React, { useMemo, useState } from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
import { useTranslation } from 'react-i18next'
import Input from 'components/common/Input'
import Button from 'components/common/Button'
import { get } from 'lodash'
import { formatAmountInput } from 'common/functions'
import { Icon } from 'components/Icon'

const LIST_SLIPPAGE = [
  {
    label: '0.5%',
    value: 0.5
  },
  {
    label: '1%',
    value: 1
  },
  {
    label: '5%',
    value: 5
  },
  {
    label: '10%',
    value: 10
  }
]

const SlippageModal = ({ slippageValue, setSlippageValue }) => {
  const { t } = useTranslation()
  const [slippageLocal, setSlippageLocal] = useState(slippageValue)

  const onSelectSlippage = (value) => () => {
    setSlippageLocal(value)
  }

  const handleSaveSlippage = () => {
    setSlippageValue(slippageLocal)
    window.closeModal()
  }

  const onChangeInput = (event) => {
    const value = get(event, 'target.value', '')
    const newValue = formatAmountInput(value)

    if (parseFloat(newValue) >= 25) {
      return setSlippageLocal('25')
    }
    if (newValue.toString().length > 4) {
      return setSlippageLocal(parseFloat(newValue).toFixed(2))
    }
    setSlippageLocal(newValue)
  }

  const typeButton = useMemo(() => {
    if (!slippageLocal) {
      return {
        isDisabled: true,
        text: 'inputAmount'
      }
    }
    return {
      isDisabled: false,
      text: 'save'
    }
  }, [slippageLocal])

  return (
    <div className={styles.slippageContainer}>
      <div className="modal-top">
        {/* <img className="logo-slippage" src={images.logoSlippage} alt="" /> */}
        <Icon type="lg" name="saros_slippage"/>
        <div className="modal-label text-xl">{t('slippageTolerance')}</div>
      </div>
      <div className="grid-container">
        {LIST_SLIPPAGE.map((item) => {
          const isActive = parseFloat(slippageLocal) === item.value
          return (
            <div
              key={item.value}
              onClick={onSelectSlippage(item.value)}
              className={cn(
                'slippage-item',
                isActive && 'slippage-item--active'
              )}
            >
              {item.label}
            </div>
          )
        })}
      </div>
      <Input
        value={slippageLocal}
        inputType="number"
        onChange={onChangeInput}
        as="center"
        className="slippage-input"
        placeholder="0%"
      />
      <Button
        isDisable={get(typeButton, 'isDisabled')}
        onClick={handleSaveSlippage}
        className="save-btn"
        isFullWidth
      >
        {t(get(typeButton, 'text'))}
      </Button>{' '}
    </div>
  )
}

export default SlippageModal
