import React from 'react'
import cn from 'clsx'
import { formatNumberBro } from 'common/functions'
import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'

function BlockColor ({ variant, className, children, type }) {
  const colorStyle = styles[variant]
  const typeStyle = type === 'center' ? styles['block-color-center'] : type === 'right' ? styles['block-color-right'] : null

  return (
    <div className={cn(colorStyle, styles['block-color'], typeStyle, className)}>
      {children}
    </div>
  )
}

export default BlockColor
