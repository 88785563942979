import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'clsx'
import styles from './style.module.scss'
import CountUpComponent from 'components/CountUpComponent'
import { Icon } from 'components/Icon'
import FarmBoxController from './FarmBoxController'
import TooltipListHarvest from '../TooltipListHarvest'
import { get } from 'lodash'
import TokenImage from 'components/common/TokenImage'
import { Link } from 'react-router-dom'
import ConnectWallet from 'components/ConnectWallet'
import ShareModal from '../Modals/ShareModal'
import Tooltip from 'components/Tooltip'
import { formatNumberBro } from 'common/functions'
import InfoTokenEarn from 'pages/NewFarmScreen/Components/FarmBox/InfoTokenEarn'
import ReduxServices from 'common/redux'
import { useWallet } from '@coin98-com/wallet-adapter-react'

const FarmBox = ({ dataFarm }) => {
  const { t } = useTranslation()
  const { connected } = useWallet()

  const isStakeScreen = get(dataFarm, 'isStakeScreen', false)
  const token0 = get(dataFarm, 'token0')
  const token1 = get(dataFarm, 'token1')
  const farmName = get(dataFarm, 'name', '')
  const stakingToken = get(dataFarm, 'stakingToken')

  const rewards = get(dataFarm, 'rewards', [])
  const endBlock = get(dataFarm, 'endBlock', 0)
  const liquidityUsd = get(dataFarm, 'liquidityUsd')
  const isCheckAprByAmount = get(dataFarm, 'isCheckAprByAmount', false)
  const isEnd = !get(dataFarm, 'isActive')
  const apr = isEnd ? 0 : get(dataFarm, 'apr')
  const poolAddress = get(dataFarm, 'poolAddress')
  const aprTrading = get(dataFarm, 'feeAPR', 0)
  const totalApr = get(dataFarm, 'totalApr', 0)

  const listTokenInfo = [
    {
      ...get(dataFarm, 'token0'),
      type: 'token'
    },
    {
      ...get(dataFarm, 'token1'),
      type: 'token'
    },
    {
      symbol: 'LP',
      mintAddress: get(dataFarm, 'poolLpAddress'),
      type: 'pool'
    }
  ]

  const listTokenStake = get(dataFarm, 'rewards', [])

  const linkTitle = isStakeScreen
    ? `/token/${get(dataFarm, 'stakingToken.mintAddress', '')}`
    : `/pool/${get(dataFarm, 'poolLpAddress', '')}`

  const onShowShareModal = () => {
    window.openModal({
      isCustomModal: true,
      className: styles.listTokenModal,
      isFullHeight: true,
      content: (
        <ShareModal
          type={isStakeScreen ? 'stake' : 'farm'}
          poolAddress={poolAddress}
          token0={isStakeScreen ? stakingToken : token0}
          token1={token1}
        />
      ),
      onCancel: () => {}
    })
  }

  const renderHead = useMemo(() => {
    if (isStakeScreen) {
      return (
        <figure>
          <TokenImage src={get(stakingToken, 'icon')} />
        </figure>
      )
    }
    return (
      <>
        <figure>
          <TokenImage src={get(token0, 'icon')} />
        </figure>
        <figure>
          <TokenImage src={get(token1, 'icon')} />
        </figure>
      </>
    )
  }, [dataFarm])

  const renderApr = useMemo(() => {
    return (
      <div className="apr-wrap">
        <div className="apr-wrap__item text-sm">
          {t('tradingApr')}:{' '}
          {formatNumberBro({ number: aprTrading, mantissa: 2 })}%
        </div>
        <div className="apr-wrap__item text-sm">
          {t('farmApr')}: {formatNumberBro({ number: apr, mantissa: 2 })}%
        </div>
      </div>
    )
  }, [dataFarm])

  return (
    <div className={cn('box-wrapper', styles.farmBoxContainer)}>
      <div className="box-top-content">
        <Icon
          backgroundClassName="icon-share-container"
          name="app_share"
          onClick={onShowShareModal}
        />
        <div className="list-token-farm">{renderHead}</div>
        <Link to={linkTitle} className="top-title d-flex align-items-center">
          {farmName}
          <Icon name="web_arrow_right" className="text-l ml-2 icon-arrow" />
        </Link>
        <div className="static-farm">
          <div className="static-farm__item">
            <div className="static-label">{t('apr')}</div>
            <div className="static-right">
              <CountUpComponent endNum={totalApr} suffix="%" />
              {!isStakeScreen && (
                <Tooltip
                  className="cursor-pointer"
                  contentClassName="tooltip-apr"
                  content={renderApr}
                >
                  <Icon
                    backgroundClassName="cursor-pointer"
                    name="web_info"
                    className="icon-info"
                  />
                </Tooltip>
              )}
            </div>
          </div>
          <div className="static-farm__item">
            <div className="static-label">
              {t('earn')}{' '}
              <Tooltip
                contentClassName="tooltip-earn"
                className="cursor-pointer"
                content={
                  <InfoTokenEarn
                    listTokenInfo={
                      isStakeScreen ? listTokenStake : listTokenInfo
                    }
                  />
                }
              >
                <Icon
                  backgroundClassName={styles.iconSetting}
                  name="web_info"
                  className="icon-info"
                />
              </Tooltip>
            </div>
            <TooltipListHarvest
              className="d-flex"
              isCheckAprByAmount={isCheckAprByAmount}
              listReward={rewards}
            >
              <div className="static-right">
                {rewards.map((rw, index) => (
                  <figure key={index} className="static-icon-wrapper ml-2">
                    <TokenImage src={get(rw, 'icon')} />
                  </figure>
                ))}
              </div>
            </TooltipListHarvest>
          </div>
          <div className="static-farm__item">
            <div className="static-label">{t('endBlock')}</div>
            <div className="static-right static-right--end-block">
              <CountUpComponent endNum={endBlock} decimals={0} />
            </div>
          </div>
          <div className="static-farm__item">
            <div className="static-label">{t('liquidity')}</div>
            <div className="static-right static-right--end-block">
              <CountUpComponent
                isCheckAprByAmount={isCheckAprByAmount}
                endNum={ReduxServices.getCurrencyPrice(liquidityUsd)}
                isCurrency
              />

              {isCheckAprByAmount && (
                <span className="uppercase static-right__symbol">
                  {get(stakingToken, 'symbol')}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {connected
        ? (
          <FarmBoxController dataFarm={dataFarm} />
        )
        : (
          <ConnectWallet />
        )}
    </div>
  )
}

export default FarmBox
