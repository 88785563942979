import images from 'assets/images'
import React, { useEffect, useRef, useState } from 'react'
import styles from './style.module.scss'
import { getLength } from 'common/functions'
import cn from 'clsx'
import { useTranslation } from 'react-i18next'

const DUMMY_LIST = ['permissionless', 'capitalEffiency', 'friendlyUI', 'lowCost', 'scalability', 'composability']
const VerticalSlider = ({ onChangeMenu }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [isHover, setIsHover] = useState(false)
  const { t } = useTranslation()

  const timer = useRef()

  const onSlidingMenu = (index) => {
    setActiveIndex(index)
    onChangeMenu && onChangeMenu(index)
    const SPACE_GAP = 6 // rem
    const spaceTranslate = SPACE_GAP * index
    const element = document.getElementById('bullet-pointer')
    if (element) {
      element.style.transition = 'all ease 0.3s'
      element.style.transform = `translateY(${spaceTranslate}rem)`
    }
  }

  const onHoverMenuItem = (index) => () => {
    clearTimeout(timer.current)
    setIsHover(true)
    onSlidingMenu(index)
  }

  const onLeaveMenuItem = () => {
    setIsHover(false)
  }

  useEffect(() => {
    if (isHover) return
    const nextIndex = activeIndex < 5 ? activeIndex + 1 : 0
    timer.current = setTimeout(() => {
      onSlidingMenu(nextIndex)
    }, 3000)
  }, [activeIndex, isHover])
  return (
    <div className={styles.verticalMenuContainer}>
      <span className='text-white'>0{activeIndex + 1} <span className='secondary-menu'> - 0{getLength(DUMMY_LIST)}</span></span>
      <div className={styles.verticalMenu}>
        <div className={styles.verticalSlider}>
          <div id="bullet-pointer" className='bullet'>
          </div>
        </div>

        <div className='list-menu'>
          {
            DUMMY_LIST.map((item, index) => (
              <div
                onMouseOver={onHoverMenuItem(index)}
                onMouseLeave={onLeaveMenuItem}
                className={cn('menu-item', index === activeIndex && 'menu-item__active')}
                key={item}
              >
                {t(item)}
              </div>
            ))
          }
        </div>
      </div>
    </div>

  )
}

export default VerticalSlider
