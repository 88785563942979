import { PublicKey } from '@solana/web3.js'
import { chainType, CHAIN_DATA, IS_DEV } from 'common/constants'

export const INITIALIZE_MINT_SPAN = 82
export const TOKEN_PROGRAM_ID = new PublicKey(
  'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'
)
export const ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey(
  'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL'
)
export const INITIALIZE_POOL_SPAN = 324
export const SAROS_SWAP_PROGRAM_ADDRESS_V1 = new PublicKey(
  'SSwapUtytfBdBn1b9NUGG6foMVPtcWgpRU32HToDUZr'
)
export const mintAddressUsdt = IS_DEV
  ? 'USDThRLHuGVe8FQct1q7eV61eWoBGqX27DaVYZJQG7u'
  : 'BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4'
export const mintAddressUsdc = IS_DEV
  ? 'USDCXkb3AZi7MxTxqbdw2XoMhkqUPz4nQb7Cxm1Co22'
  : 'BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW'
export const SAROS_FARM_ADDRESS = IS_DEV
  ? 'AzmLCLuoLMo3yGVcs4iMZkRVYjP6Q3eiA6sDfdhpWH7a'
  : 'SFarmWM5wLFNEw1q5ofqL7CrwBMwdcqQgK6oQuoBGZJ'
export const FEE_OWNER_LIQUIDITY = new PublicKey(
  'FDbLZ5DRo61queVRH9LL1mQnsiAoubQEnoCRuPEmH9M8'
)

export const STABLE_COIN_DATA = {
  [chainType.usdc]: {
    mintAddress: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
  },
  [chainType.usdt]: {
    mintAddress: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB'
  },
  [chainType.usdh]: {
    mintAddress: 'USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX'
  }
}

export const LIST_MINT_STABLE_COIN = [
  STABLE_COIN_DATA[chainType.usdc],
  STABLE_COIN_DATA[chainType.usdt],
  STABLE_COIN_DATA[chainType.usdh]
]

export const MINT_LP_DEFAULT = 1000000000
