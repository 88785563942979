import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { render } from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './styles/global.scss'
import './i18n'
import { Provider } from 'react-redux'
import { store, persistor } from 'controller/redux/store/configureStore'
import { PersistGate } from 'redux-persist/integration/react'
import WalletServices from 'common/WalletServices'
import 'normalize.css'
import { AppContextProvider } from 'context/appContext'
import { WalletProvider, BLOCKCHAINS_DATA } from '@coin98-com/wallet-adapter-react'
import { Coin98WalletAdapter } from '@coin98-com/wallet-adapter-coin98'
import { SarosWalletAdapter } from '@coin98-com/wallet-adapter-saros'
import { PhantomWalletAdapter } from '@coin98-com/wallet-adapter-phantom'
import { WalletModalProvider } from '@coin98-com/wallet-adapter-react-ui'

window.walletServices = new WalletServices()

const renderer = async () => {
  const enables = [BLOCKCHAINS_DATA.solana]
  const wallets = [Coin98WalletAdapter, PhantomWalletAdapter, SarosWalletAdapter]
  await Promise.all([
    window.walletServices.fetchSetting(),
    window.walletServices.refreshFetchData(true),
    window.walletServices.fetchTokenSolana()
  ])

  return render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <WalletProvider wallets={wallets} enables={enables} autoConnect>
            <WalletModalProvider>
              <BrowserRouter>
                <AppContextProvider>
                  <App />
                </AppContextProvider>
              </BrowserRouter>
            </WalletModalProvider>
          </WalletProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

renderer()

serviceWorker.unregister()
