import images from 'assets/images'
import Button from 'components/common/Button'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import CountUpComponent from 'components/CountUpComponent'
import { get } from 'lodash'
import TokenImage from 'components/common/TokenImage'
import { formatNumberBro, upperCase } from 'common/functions'
import useRateFee from 'hooks/swap/useRateFee'
import { Icon } from 'components/Icon'
import ReduxServices from 'common/redux'

const HarvestModal = ({ dataFarm, handleHarvest, dataReward }) => {
  const { t } = useTranslation()
  const { feeFull } = useRateFee()
  const [isLoading, setIsLoading] = useState(false)

  const isCheckAprByAmount = get(dataFarm, 'isCheckAprByAmount', false)
  const totalUsd = useMemo(() => {
    const total = dataReward.reduce((totalUsd, curr) => {
      const price = parseFloat(get(curr, 'price'))
      const amount = parseFloat(get(curr, 'earn', 0))
      const amountUsd = amount * price
      if (isCheckAprByAmount) {
        totalUsd += amount
      } else {
        totalUsd += amountUsd
      }

      return totalUsd
    }, 0)
    return total
  }, [dataReward])

  const handleConfirmHarvest = () => {
    setIsLoading(true)
    handleHarvest({
      dataFarm: dataFarm || null,
      dataReward: dataReward[0]
    }).then((res) => setIsLoading(false))
  }

  const handleCancel = () => {
    window.closeModal()
  }

  return (
    <div>
      <div className={styles.harvestContainer}>
        <div className="modal-top">
          <Icon name="saros_harvest" type="lg" />
          <div className="modal-label text-xl semibold">{t('harvest')}</div>
        </div>

        {dataReward.map((rw) => (
          <div
            key={get(rw, 'address', '')}
            className="d-flex justify-content-between align-items-center mt-2 mb-3"
          >
            <div className="d-flex align-items-center">
              <TokenImage
                src={get(rw, 'icon')}
                className="img-24 mr-2 rounded-circle"
                alt=""
              />
              {upperCase(get(rw, 'symbol'))}
            </div>
            <div className="bold color-theme">
              {formatNumberBro({ number: get(rw, 'earn', 0) })}
            </div>
          </div>
        ))}
        <div className="border-line mt-4 mb-4"></div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="text-sm color-grey">{t('totalReceive')}</div>
          <CountUpComponent
            className="color-theme bold text-l"
            endNum={isCheckAprByAmount ? totalUsd : ReduxServices.getCurrencyPrice(totalUsd)}
            isCurrency
            isCheckAprByAmount
            decimals={4}
          />
        </div>

        <div className="d-flex justify-content-between align-items-center mt-1 mb-4">
          <div className="text-sm color-grey">{t('estFee')}</div>
          <div className="text-sm color-normal">{feeFull}</div>
        </div>

        <div className="d-flex">
          <Button
            onClick={handleCancel}
            type="grey"
            className="save-btn mr-2"
            isFullWidth
          >
            {t('cancel')}
          </Button>
          <Button
            isLoading={isLoading}
            onClick={handleConfirmHarvest}
            className="save-btn ml-2"
            isFullWidth
          >
            {t('confirm')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default HarvestModal
