import { array } from '@project-serum/borsh'
import images from 'assets/images'
import { formatPrice, getLength, upperCase } from 'common/functions'
import React, { useEffect } from 'react'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import './style.scss'
import { BounceLoader } from 'react-spinners'
import PuffLoading from '../PuffLoading'
import { Icon } from 'components/Icon'
import InputSearch from 'components/InputSearch'

function SearchMobile ({
  isOpenSearch,
  onCloseSearch,
  txtSearch,
  tokens,
  pools,
  handleGoTo,
  onChangeSearch,
  checkEmptyDataTokens,
  isLoading
}) {
  const { t } = useTranslation()

  // handle functions

  // renders
  const renderTokenItem = (arrToken) => {
    return arrToken.map((item, i) => {
      return (
        <div className="token-item" key={i} onClick={handleGoTo('/token/' + item.mintAddress)}>
          <div className="token-item__name">
            <img src={item.icon} className='name-token' alt="" />
            <div className="name-text">{upperCase(item.symbol)}</div>
          </div>

          <div className="token-item__price">{formatPrice(item.price)}</div>
        </div>
      )
    })
  }
  const renderPoolItem = (arrPools) => {
    return arrPools.map((item, i) => {
      return (
        <div className="token-item" key={i} onClick={handleGoTo('/pool/' + item.id)}>
          <div className="token-item__name">
            <img src={get(item, 'token0.icon')} className='name-token' alt="" />
            <img src={get(item, 'token1.icon')} className='name-token' alt="" />
            <div className="name-text">{upperCase(get(item, 'token0.symbol'))}</div>
            <span>-</span>
            <div className="name-text">{upperCase(get(item, 'token1.symbol'))}</div>
          </div>

          <div className="token-item__price">{formatPrice(item.totalLiquidity)}</div>
        </div>
      )
    })
  }

  const renderContent = (data, titleLeft, titleRight, isToken) => {
    if (getLength(data) === 0) return <></>
    return (
      <div className='token-content'>
        <div className="token-content__title">
          <div className="title-text">
            {titleLeft}
          </div>

          <div className="title-price">
            {titleRight}
          </div>
        </div>

        <div className="token-content__tokens">
          {isToken ? renderTokenItem(data) : renderPoolItem(data)}
        </div>
      </div>
    )
  }

  // effect data
  useEffect(() => {
    if (isOpenSearch || getLength(txtSearch) !== 0) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpenSearch, txtSearch])

  return (
    <div className={`token-search ${(isOpenSearch || txtSearch) && 'token-search--active'}`}>

      <div className='header-search'>
        <div className='search-wrapper'>
          <Icon onClick={onCloseSearch} name="web_search_left"/>
          <input
            type="text"
            className='input-search'
            placeholder={t('searchPoolOrToken')}
            onChange={onChangeSearch}
            value={txtSearch}
          />
        </div>

        <Icon onClick={onCloseSearch} name="web_close"/>
      </div>

      {!isLoading
        ? (checkEmptyDataTokens)
          ? <div className="box-search-item">
            {renderContent(tokens, t('tokens'), t('price'), true)}
            {renderContent(pools, t('pools'), t('liquidity'))}
          </div>
          : <div className={`box-search-item ${!checkEmptyDataTokens && 'box-search-item--empty'} ${getLength(txtSearch) !== 0 && 'box-search-item--show'}`}>
            <img src={images.iconEmptyTable} className='icon-empty-search-mobile' alt="" />
            {t('emptyData')}
          </div>
        : <div className={`box-search-item ${isLoading && 'box-search-item--loading'}`}>
          <PuffLoading />
        </div>
      }
    </div>
  )
}

export default SearchMobile
