import React from 'react'
import { LiquidityProvider } from 'context/liquidity/liquidityContext'
import NewLiquidityScreen from 'pages/NewLiquidityScreen'

const NewLiquidityScreenContent = ({ shortcut0Mint, shortcut1Mint }) => {
  return (
    <LiquidityProvider
      shortcut0Mint={shortcut0Mint}
      shortcut1Mint={shortcut1Mint}
    >
      <NewLiquidityScreen />
    </LiquidityProvider>
  )
}

export default NewLiquidityScreenContent
