import React, { useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { Icon } from 'components/Icon'
import { getItemStorage, setItemStorage } from 'common/functions'
import BoxTokenInfo from './components/BoxTokenInfo'
import { cloneDeep, get, uniq } from 'lodash'
import { NATIVE_SOL_RAYDIUM } from 'common/raydium/constants'
import BaseAPI from 'controller/api/BaseAPI'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Ecosystem = () => {
  const { t } = useTranslation()

  const cacheData = getItemStorage('DATA_SOLANA_SYSTEM_V2')

  const [listTokenInfo, setListTokenInfo] = useState(cacheData || [])

  useEffect(() => {
    // getListToken()
    getTopToken()
  }, [])

  const getTopToken = async () => {
    const listTopToken = await BaseAPI.getData('saros/token/home')
    if (listTopToken) {
      const listTokenId = listTopToken.map(item => get(item, 'info.id'))

      const chartData = await BaseAPI.getData('coin/cgk/chart', {
        isMinimize: true,
        idList: listTokenId,
        day: 7,
        interval: 3600
      })
      const listInfo = listTopToken.map((itm) => {
        const coinID = get(itm, 'info.id')
        const priceInfo = window.walletServices.findCoingeckoData(coinID)

        const dataCoin = {
          ...itm.info,
          chart: '',
          current_price: get(priceInfo, 'current_price'),
          price_change_percentage_7d: parseFloat(
            get(priceInfo, 'price_change_percentage_24h', 0)
          )
        }
        itm = { ...itm, ...dataCoin }
        return itm
      })

      setItemStorage(listInfo, 'DATA_SOLANA_SYSTEM_V2')
      setListTokenInfo(listInfo)
    }
  }

  // const getListToken = async () => {
  //   if (cacheData) {
  //     setListTokenInfo(cacheData)
  //   }

  //   let listToken = []
  //   const nativeSol = cloneDeep(NATIVE_SOL_RAYDIUM)
  //   const getAllTokenSolana = window.walletServices.tokenSolana || BaseAPI.getData('solanaTokenV2')
  //   const getPoolToken = BaseAPI.getData('ammMarket/pool/tokens')
  //   Promise.all([getAllTokenSolana, getPoolToken]).then(
  //     async ([resAllTokenSolana, resPoolToken]) => {
  //       console.log('resAllTokenSolana', resAllTokenSolana)
  //       console.log('resPoolToken', resPoolToken)
  //       const orcaPool = get(resPoolToken, 'orca', {})
  //       const raydiumPool = get(resPoolToken, 'raydium', '')
  //       const arrBase = raydiumPool.map((item) => item.base)
  //       const arrPair = raydiumPool.map((item) => item.pair)
  //       const mintOrca = Object.values(orcaPool)
  //         .map((mint) => mint.tokenIds)
  //         .flat()
  //       const arrMintAddress = uniq([...arrBase, ...arrPair, ...mintOrca])
  //       resAllTokenSolana.forEach((token) =>
  //         arrMintAddress.map((mint) => {
  //           if (mint === token.mintAddress) {
  //             listToken.push({ ...token, key: token.symbol })
  //           }
  //         })
  //       )
  //       listToken = listToken.sort((a, b) => {
  //         return a.symbol.localeCompare(b.symbol)
  //       })
  //       listToken = [...[nativeSol], ...listToken].slice(0, 8)
  //       console.log('listToken.map((itm) => itm.id),', listToken.map((itm) => itm.id))
  //       const chartData = await BaseAPI.getData('coin/cgk/chart', {
  //         isMinimize: true,
  //         idList: listToken.map((itm) => itm.id),
  //         day: 7,
  //         interval: 3600
  //       })

  //       console.log('chartData hahahha', chartData)

  //       const listInfo = await Promise.all(
  //         listToken.map(async (itm) => {
  //           const coinDetail = await BaseAPI.getData('coin/cgk/detail', {
  //             id: itm.id,
  //             symbol: itm.symbol
  //           })
  //           const findChart = chartData.find((data) => data.id === itm.id)
  //           const dataCoin = {
  //             chart: get(findChart, 'data'),
  //             current_price: get(
  //               coinDetail,
  //               'market_data.current_price.usd',
  //               0
  //             ),
  //             price_change_percentage_7d: parseFloat(
  //               get(coinDetail, 'market_data.price_change_percentage_7d', 0)
  //             )
  //           }
  //           itm = { ...itm, ...dataCoin }
  //           return itm
  //         })
  //       )
  //       console.log('listInfo ole', listInfo)
  //       setItemStorage(listInfo, 'DATA_SOLANA_SYSTEM_V2')
  //       setListTokenInfo(listInfo)
  //     }
  //   )
  // }

  return (
    <div className={styles.ecosystemContainer}>
      <div className='head-topic'>
        <Fade className='head-topic__title' duration="500" direction="up">
          <div>{t('exploreSolanaEcosystem')}</div>
        </Fade>
        <Fade className='head-topic__trading' duration="500" direction="up">
          <Link to="/swap" className='d-flex align-items-center'>
            <span className='text-base semibold'>{t('tradingNow')}</span>
            <Icon name="web_arrow_right" className="ml-1"/>
          </Link>
        </Fade>
      </div>

      <div className="ecosystem-grid">
        {listTokenInfo.map((item, index) => (
          <div key={index}>
            <BoxTokenInfo data={item} />
          </div>
        ))}
      </div>

    </div>
  )
}

export default Ecosystem
