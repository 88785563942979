import { AppContext } from 'context/appContext'
import BaseAdapter from 'controller/api/BaseAdapter'
import StoreActions from 'controller/redux/actions/storeActions'
import { cloneDeep, compact, get } from 'lodash'
import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useFavoritePool = () => {
  const [listPoolFavor, setListPoolFavor] = useState([])

  const dispatch = useDispatch()

  const listFavoritePoolRedux = useSelector(state => state.listFavoritePoolRedux)

  const appContext = useContext(AppContext)
  const { listFavoritePool, setListFavoritePool } = appContext

  const handleChangePinnedPoolFavorite = (info) => {
    let listFavorPinned = cloneDeep(listFavoritePool)
    const findTokenAddress = listFavorPinned.find(
      (item) => item.poolAddress === info.poolAddress
    )
    if (findTokenAddress) {
      listFavorPinned = listFavorPinned.filter(
        (item) => item.poolAddress !== findTokenAddress.poolAddress
      )
    } else {
      listFavorPinned.push(info)
    }

    // setListPoolFavor(listFavorPinned)
    setListFavoritePool(listFavorPinned)
  }

  const onChangeListPoolFavor = async (info) => {
    handleChangePinnedPoolFavorite(info)
    const poolAddress = get(info, 'poolAddress')
    let listFavoriteClone = cloneDeep(listFavoritePoolRedux)
    const findTokenAddress = listFavoriteClone.find(
      (item) => item === poolAddress
    )
    if (findTokenAddress) {
      listFavoriteClone = listFavoriteClone.filter(
        (item) => item !== findTokenAddress
      )
    } else {
      listFavoriteClone.push(poolAddress)
    }

    const newArr = listFavoriteClone.filter((item) => {
      if (typeof item === 'object') return null
      return item
    })

    const resPool = await BaseAdapter.postData('favorite/take', {
      type: 'sarosPool',
      bonusValue: compact(newArr)
    })
    if (resPool && resPool.success) {
      dispatch(StoreActions.setListFavoritePoolRedux(newArr))
    }
  }

  return {
    listPoolFavor,
    onChangeListPoolFavor
  }
}

export default useFavoritePool
