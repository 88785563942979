import images from 'assets/images'
import { getLength, nFormatter } from 'common/functions'
import PuffLoading from 'components/common/PuffLoading'
import moment from 'moment'
import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import './style.scss'
import cn from 'clsx'
import { Icon } from 'components/Icon'
import ReduxServices from 'common/redux'

const RechartBar = ({ dataChart, isHideTitle, isLoading }) => {
  const { t } = useTranslation()

  const currencyRedux = useSelector(state => state.currencyRedux)

  const [dataHead, setDataHead] = useState({
    date: new Date(),
    number: 0
  })

  useEffect(() => {
    if (!dataChart.length) return
    setDataHead({
      ...dataChart[dataChart.length - 1]
    })
  }, [dataChart])

  const tickFormatter = (data) => {
    return moment.unix(data).format('MMM DD')
  }
  const tickFormatterYAxis = (data) => {
    return ReduxServices.formatPrice(data, true, 0)
  }

  const onMouseOver = (data) => {
    setDataHead({
      ...data
    })
  }

  const onMouseOut = () => {
    const lengthDataChart = getLength(dataChart)

    if (lengthDataChart > 0) {
      const data = dataChart[lengthDataChart - 1]
      setDataHead({
        ...data
      })
    }
  }

  const formatDate = (date) => {
    return moment.unix(date).format('LL')
  }
  const renderChart = useMemo(() => {
    if (isLoading) {
      return (
        <div className="loading-chart">
          <PuffLoading/>
        </div>
      )
    } else {
      return (dataChart.length && !isLoading)
        ? (
          <ResponsiveContainer width="99%" height='100%'>
            <BarChart
              width={500}
              height={300}
              data={dataChart}
              margin={{
                top: 20,
                right: -45,
                left: -15,
                bottom: 55
              }}
              onMouseLeave={() => {
                onMouseOut()
              }}
            >
              <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={'#8ef8c6'} stopOpacity={0.5} />
                  <stop offset="129%" stopColor={'#8064ea'} stopOpacity={0.5} />
                </linearGradient>
              </defs>
              <XAxis
                tickFormatter={tickFormatter}
                dataKey="date"
                axisLine={false}
                tickLine={false}
                dy={5}
                interval="preserveEnd"
                domain={['auto', 'auto']}
              />
              <YAxis
                tickFormatter={tickFormatterYAxis}
                orientation="right" axisLine={false}
                tickLine={false}
                dy={-6}
                scale="linear"
              />
              {/* <Tooltip wrapperStyle={{ display: 'none' }} cursor={{ fill: 'blue' }} /> */}
              <Tooltip
                wrapperStyle={{ display: 'none' }}
                cursor={{ fill: '#5f3cd8', width: 1 }}
                formatter={(tooltipValue, name, props) => {
                  onMouseOver(get(props, 'payload', {}))
                }}
              />
              <Bar
                onMouseMove={onMouseOver}
                dataKey="number"
                fill="#6946e2"
              />
            </BarChart>
          </ResponsiveContainer>
        )
        : (
          <div className="empty-chart">
            {/* <img src={images.iconEmptyTable} alt="" /> */}
            <Icon name="web_empty" type="lg"/>
            {t('emptyData')}
          </div>
        )
    }
  }, [isLoading, dataChart])

  return (
    <React.Fragment>
      <div className="bar-info-value">
        <div className={cn('bar-info-value__title', isHideTitle && 'd-none')}>
          {t('volume24h')}
        </div>
        <div className="bar-info-value__usd">{ReduxServices.formatPrice(dataHead.number, true)}</div>
        <div className="bar-info-value__date">{formatDate(dataHead.date)}</div>
      </div>
      {renderChart}

    </React.Fragment>
  )
}

export default RechartBar
