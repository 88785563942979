import { KEY_ACTION } from '../lib/constants'
export default class StoreActions {
  static setSession (payload) {
    return {
      type: KEY_ACTION.SET_IS_SESSION,
      payload
    }
  }

  static setBalanceSol (payload) {
    return {
      type: KEY_ACTION.SET_BALANCE_SOL,
      payload
    }
  }

  static setAccountSol (payload) {
    return {
      type: KEY_ACTION.SET_ACCOUNT_SOL,
      payload
    }
  }

  static setWalletRedux (payload) {
    return {
      type: KEY_ACTION.SET_WALLET_REDUX,
      payload
    }
  }

  static setLang (payload) {
    return {
      type: KEY_ACTION.SET_LANG,
      payload
    }
  }

  static setCurrency (payload) {
    return {
      type: KEY_ACTION.SET_CURRENCY_REDUX,
      payload
    }
  }

  static setConnected (payload) {
    return {
      type: KEY_ACTION.SET_CONNECTED,
      payload
    }
  }

  static setConfigRedux (payload) {
    return {
      type: KEY_ACTION.SET_CONFIG,
      payload
    }
  }

  static setDeviceId (payload) {
    return {
      type: KEY_ACTION.SET_DEVICE_ID,
      payload
    }
  }

  static setIsConnectFirst (payload) {
    return {
      type: KEY_ACTION.SET_CONNECT_FIRST,
      payload
    }
  }

  static setThemeRedux (payload) {
    return {
      type: KEY_ACTION.SET_THEME_MODE,
      payload
    }
  }

  static setListFavoriteTokenRedux (payload) {
    return {
      type: KEY_ACTION.SET_LIST_FAVORITE_TOKEN,
      payload
    }
  }

  static setListFavoritePoolRedux (payload) {
    return {
      type: KEY_ACTION.SET_LIST_FAVORITE_POOL,
      payload
    }
  }

  static setWalletActive (payload) {
    return {
      type: KEY_ACTION.SET_WALLET_ACTIVE,
      payload
    }
  }

  static setDataFarmStake (payload) {
    return {
      type: KEY_ACTION.SET_DATA_FARM_STAKE,
      payload
    }
  }

  static setRecentSwapRedux (payload) {
    return {
      type: KEY_ACTION.SET_CURRENT_SWAP,
      payload
    }
  }
}
