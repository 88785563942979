import TokenImage from 'components/common/TokenImage'
import { Icon } from 'components/Icon'
import React from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
import { get } from 'lodash'
import { upperCase } from 'common/functions'
import useFavoriteToken from 'hooks/pools/useFavoriteToken'
import TokenFavorite from 'components/TokenFavorite'
import { useHistory } from 'react-router-dom'

const FavoriteToken = ({ data }) => {
  const { onChangeListTokenFavor } = useFavoriteToken()
  const history = useHistory()

  const onRemoveTokenItem = (mintAddress) => (e) => {
    e.stopPropagation()
    onChangeListTokenFavor(mintAddress)
  }

  const onRouteToDetailToken = () => {
    history.push(`/token/${get(data, 'mintAddress')}`)
  }
  return (
    <TokenFavorite
      onRemove={onRemoveTokenItem(get(data, 'mintAddress'))}
      onClick={onRouteToDetailToken}
      // onClick={handleChooseToken({ isBase, coinSelected: tokenInfo })}
    >
      <TokenImage
        src={get(data, 'icon')}
        className={styles['favorite-token-image']}
      />
      <p className={cn(styles['favorite-token-name'], 'text-sm')}>
        {get(data, 'name')}
      </p>
      <p className={cn(styles['favorite-token-symbol'], 'text-sm color-grey')}>
        {upperCase(get(data, 'symbol'))}
      </p>
    </TokenFavorite>
    // <div className={cn(styles['favorite-token'], 'cursor-pointer')}>

  //   <div className={styles['icon-close']}>
  //     <Icon name="web_close" onClick={onRemoveTokenItem(get(data, 'mintAddress'))}/>
  //   </div>
  // </div>
  )
}

export default FavoriteToken
