import React, { useState, useEffect } from 'react'
import ItemTable from './ItemTable.js'
import styles from './style.module.scss'
import cx from 'classnames'
import { getLength } from 'common/functions/index.js'
import EmptyData from 'components/EmptyData/index.js'
import PuffLoading from 'components/common/PuffLoading/index.js'
import { Icon } from 'components/Icon'
import { generateId } from 'common/functions/utils.js'

const columnsDefault = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 20,
    icon: ''
  },
  {
    title: 'Age',
    dataIndex: 'age',
    width: 40,
    icon: ''

  },
  {
    title: 'Address',
    dataIndex: 'address',
    width: 40,
    icon: ''
  },
  {
    title: 'reward',
    dataIndex: 'reward',
    width: 40,
    icon: ''
  }
]

const dataSourceDefault = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    reward: '10000000 C98'
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    reward: '10000000 C98'

  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    reward: '10000000 C98'

  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    reward: '10000000 C98'

  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    reward: '10000000 C98'

  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    reward: '10000000 C98'

  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    reward: '10000000 C98'

  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    reward: 10

  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    reward: 10

  }
]

const CustomTable = ({
  className,
  columns = columnsDefault,
  dataSource = dataSourceDefault,
  rawData,
  tableClassName,
  contentClassName,
  headerClassName,
  loadingContainerClassName,
  isLoading = false,
  rowPadding = '5px',
  onClickRow,
  isShowSkeleton
}) => {
  const [isSortedUp, setIsSortedUp] = useState(false)

  const [dataTable, setDataTable] = useState(dataSource)
  useEffect(() => {
    setDataTable(dataSource)
  }, [dataSource])

  const onRowClicked = (row, index) => () => {
    if (typeof onClickRow === 'function') {
      onClickRow(row)
    }
    // const el = document.getElementById('row-' + index.toString())
    // if(el)
  }
  const handleSwitchSort = (dataIndex) => () => {
    setIsSortedUp(!isSortedUp)
    const listData = [...dataTable]
    const sortedArr = !isSortedUp
      ? listData.sort((a, b) => (+a[dataIndex] - +b[dataIndex] >= 0 ? 1 : -1))
      : listData.sort((a, b) => (+a[dataIndex] - +b[dataIndex] <= 0 ? 1 : -1))
    setDataTable(sortedArr)
  }

  if (isLoading) {
    return <div className={cx(styles['no-result'], loadingContainerClassName)}><PuffLoading /></div>
  }

  if (getLength(dataTable) === 0) {
    return <div className={cx(styles['no-result'], loadingContainerClassName)}><EmptyData /></div>
  }

  return (
    <div className={cx(styles['table-wrapper'], className)}>
      <table className={cx(styles['table-container'], tableClassName)}>
        <thead className={cx(headerClassName, styles['table-head'])}>
          <tr>
            {columns.map((col) => {
              if (col.hide) return null
              return (
                <th
                  style={{ width: `${col.width}%` }}
                  key={col.dataIndex}
                  className={cx(
                    styles['head-item-wrapper'],
                    col.className
                  )}
                >
                  <div className={cx('d-flex align-items-center', col.align && styles[`head-item--${col.align}`])}>
                    <div className={styles['head-item']}>{col.title}</div>
                    {/* {col.icon || null} */}
                    <div>
                      {col.isSort && (
                        <div
                          className="d-flex ml-2 cursor-pointer"
                          onClick={handleSwitchSort(col.dataIndex)}
                        >
                          <Icon
                            className={cx(
                              styles[!isSortedUp ? 'rotate180' : 'rotate360'],
                              styles['color-grey']
                            )}
                            name="web_arrow_up"
                          ></Icon>
                        </div>
                      )}
                    </div>
                  </div>
                </th>
              )
            })}
          </tr>
        </thead>

        <tbody
          className={cx(
            contentClassName,
            styles['table-ancient__body'],
            getLength(dataTable) === 0 || isLoading
              ? 'd-flex justify-center items-center w-full'
              : ''
          )}
        >
          {(
            dataTable.map((row, index) => {
              const isActiveRank = row?.isActiveRank
              return (
                <React.Fragment key={row.key || generateId()}>
                  <tr style={{ height: index === 0 ? '1.8rem' : rowPadding }}></tr>
                  <tr
                    id={`row-${index}`}
                    href="http://myspace.com"
                    className={cx(styles['body-row'], onClickRow && 'cursor-pointer')}
                    onClick={onRowClicked(row, index)}
                  >
                    <ItemTable
                      isSortedUp={isSortedUp}
                      columns={columns}
                      data={row}
                      isActiveRank={isActiveRank}
                    />

                  </tr>
                </React.Fragment>
              )
            })
          )}
        </tbody>
      </table>
    </div>
  )
}

export default CustomTable
