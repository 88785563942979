import React, { useMemo, useState, useEffect, useContext } from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
import PoolTabbar from 'pages/NewInfoPool/Components/PoolTabbar'
import { useTranslation } from 'react-i18next'
import BoxStatistical from 'pages/NewInfoPool/Components/BoxStatitical'
import { compact, get } from 'lodash'
import FavoriteToken from 'pages/NewInfoPool/Components/FavoriteToken'
import FavoritePool from 'pages/NewInfoPool/Components/FavoritePool'
import { useParams } from 'react-router-dom'
import { getLength } from 'common/functions'
import BaseAdapter from 'controller/api/BaseAdapter'
import { useSelector } from 'react-redux'
import { SarosSwapService } from 'common/pool/saros_swap/sarosSwapServices'
import { AppContext } from 'context/appContext'
import FavoriteScrollList from 'components/FavoriteScrollList'
// import { PoolInfoContext } from 'context/poolInfo/poolInfoContext'

const LayoutInfoSaros = ({ children }) => {
  const { t } = useTranslation()
  const { type } = useParams()

  const listFavoriteTokenRedux = useSelector((state) => state.listFavoriteTokenRedux) || []
  const accountSol = useSelector(state => state.accountSol)

  // const poolInfoContext = useContext(PoolInfoContext)
  const appContext = useContext(AppContext)
  const { listFavoritePool, setListFavoritePool } = appContext

  const [listFavorite, setListFavorite] = useState([])

  const [isLoadingTrendingToken, setIsLoadingTrendingToken] = useState(true)
  const [isLoadingTrendingPool, setIsLoadingTrendingPool] = useState(true)

  const [listTokenTrending, setListTokenTrending] = useState([
    {
      name: 'trending',
      value: []
    },
    {
      name: 'gainer',
      value: []
    },
    {
      name: 'recentlyAdded',
      value: []
    }
  ])

  const [listPoolTrending, setListPoolTrending] = useState([
    {
      name: 'topVolume24h',
      value: []
    },
    {
      name: 'topLiquidity',
      value: []
    },
    {
      name: 'popular',
      value: []
    }
  ])
  // useEffect(() => {
  //   fetchListFavorite()
  // }, [accountSol])

  useEffect(() => {
    getListFavorite()
  }, [type, listFavoritePool, listFavoriteTokenRedux])

  useEffect(() => {
    fetchListPoolTrending()
    fetchListTokenTrending()
  }, [])

  const fetchListPoolTrending = async () => {
    const response = await BaseAdapter.getData('saros/pool/statistical')
    if (!get(response, 'success', false)) return

    const dataObj = get(response, 'data', {})

    const formatRes = Object.keys(dataObj).map(item => ({
      name: item,
      value: dataObj[item]
    }))
    setListPoolTrending(formatRes)
    setIsLoadingTrendingPool(false)
  }

  const fetchListTokenTrending = async () => {
    const response = await BaseAdapter.getData('saros/token/statistical')
    if (!get(response, 'success', false)) return

    const dataObj = get(response, 'data', {})

    const formatRes = Object.keys(dataObj).map(item => ({
      name: item,
      value: dataObj[item]
    }))
    setListTokenTrending(formatRes)
    setIsLoadingTrendingToken(false)
  }

  const getListFavorite = async () => {
    if (type === 'token') {
      const listTokenFavor = compact(listFavoriteTokenRedux.map(item => SarosSwapService.getInfoByMintAddress(item)))
      return setListFavorite(listTokenFavor)
    }
    if (type === 'pool') {
      return setListFavorite(listFavoritePool)
    }
    return setListFavorite([])
  }

  return (
    <div className={cn(styles['layout-info-saros'], 'container-lg ')}>
      <PoolTabbar />
      {getLength(listFavorite) > 0 && (
        <div className={styles['list-favorite']}>
          {/* {listFavorite.map((item, index) => (
            <React.Fragment key={index}>
              {type === 'token'
                ? (
                  <FavoriteToken data={item} />
                )
                : (
                  <FavoritePool data={item} />
                )}
            </React.Fragment>
          ))} */}
          <FavoriteScrollList
            listFavorite={listFavorite}
            type={type}
          />
        </div>
      )}

      {type === 'token' && (
        <div className={styles['list-trending']}>
          {listTokenTrending.map((item) => (
            <BoxStatistical isLoading={isLoadingTrendingToken} data={item.value} label={get(item, 'name')} key={get(item, 'name')} />
          ))}
        </div>
      )}

      {type === 'pool' && (
        <div className={styles['list-trending']}>
          {listPoolTrending.map((item) => (
            <BoxStatistical isLoading={isLoadingTrendingPool} data={item.value} type='pool' label={get(item, 'name')} key={get(item, 'name')} />
          ))}
        </div>
      )}

      {children}
    </div>
  )
}

export default LayoutInfoSaros
