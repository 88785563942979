import React from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
function Badge ({ children, className, ...props }) {
  const { variant } = props

  const badgeStyle = styles[variant]
  return (
    <div className={cn(badgeStyle, className, styles.badgeWrapper)}>
      {children}
    </div>
  )
}
export default Badge
