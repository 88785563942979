import images from 'assets/images'
import React, { useState } from 'react'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { Fade } from 'react-awesome-reveal'
import { Icon } from 'components/Icon'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { get } from 'lodash'

const LIST_PRODUCT = [
  {
    id: 'swap',
    title: 'Swap',
    description: 'landingSwapDescription',
    linkText: 'tradingNow',
    href: '#',
    image1: images.landingSwap1,
    image2: images.landingSwap2,
    actions: 'trading'
  },
  {
    id: 'stake',
    title: 'Stake',
    description: 'landingStakeDescription',
    linkText: 'stakeNow',
    href: '#',
    image1: images.landingStake1,
    image2: images.landingStake2,
    actions: 'stake'
  },
  {
    id: 'farm',
    title: 'Farm',
    description: 'landingFarmDescription',
    linkText: 'farmNow',
    href: '#',
    image1: images.landingFarm1,
    image2: images.landingFarm2,
    actions: 'farm'

  },
  {
    id: 'snapshot',
    title: 'Snapshot',
    description: 'landingSnapshotDescription',
    linkText: 'joinNow',
    href: '#',
    image1: images.landingSnapshot1,
    image2: images.landingSnapshot2,
    actions: 'join'

  }
]
const DefiSuite = ({ tagID }) => {
  const { t } = useTranslation()
  const [focusItem, setFocusItem] = useState()

  const onHoverProduct = (val) => () => {
    setFocusItem(val)
  }
  const onMouseLeaveProduct = () => {
    setFocusItem('')
  }
  return (
    <div id={tagID} className={styles.defiSuiteContainer}>
      <Fade duration="500" direction="up">
        <div className='text-l color-grey'>{t('ourProducts')}</div>
        <div className='head-title'>{t('defiSuiteDescription')}</div>

      </Fade>

      <div className='product-grid'>
        {
          LIST_PRODUCT.map(item => (
            <Link key={item.title} to={`/${item.id}`} >
              <div
                className='product-item box-wrapper'
                onMouseEnter={onHoverProduct(item.id)}
                onMouseLeave={onMouseLeaveProduct}
              >
                <div className='product-item__image' >
                  <img className={cn('image1', focusItem === item.id ? 'show-image' : 'hide-image')}src={item.image2} alt="" />
                  <img className={cn(focusItem !== item.id ? 'show-image' : 'hide-image')} src={item.image1} alt="" />
                </div>
                <div className='product-item__info'>
                  <div className='title'>{item.title}</div>
                  <div className='mt-2'>{t(item.description)}</div>
                  <div className='d-flex align-items-center mt-2 mb-4'>
                    <span className='text-sm bold '>{t(item.linkText)}</span>
                    <Icon name="web_arrow_right" className="ml-1 text-l color-normal"/>
                  </div>
                </div>

              </div>
            </Link>

          ))
        }
      </div>

    </div>
  )
}

export default DefiSuite
