import React, { useState, useEffect, useMemo, useRef } from 'react'
import styles from './style.module.scss'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import CustomTable from 'components/CustomTable'
import {
  convertDateMomentHistory,
  formatAddress,
  formatNumberBro,
  formatPrice,
  getLength,
  upperCase
} from 'common/functions'
import BaseAPI from 'controller/api/BaseAPI'
import { cloneDeep, get } from 'lodash'
import { SOL_SCAN_URL } from 'common/constants'
import Pagination from 'components/Pagination'
import BaseAdapter from 'controller/api/BaseAdapter'
import { useSelector } from 'react-redux'
import ReduxServices from 'common/redux'

const LIST_TYPE = [
  {
    name: 'all',
    value: 'all'
  },
  {
    name: 'swap',
    value: 'swap'

  },
  {
    name: 'add',
    value: 'addLiquidiTy'

  },
  {
    name: 'remove',
    value: 'removeLiquidity'

  }
]

const ALL = 'all'
const SWAP = 'swap'
const ADD = 'addLiquidiTy'
const REMOVE = 'removeLiquidity'
const CREATE = 'createPool'
const LIMIT = 50

const cachedTxsTable = {
  all: [],
  swap: [],
  addLiquidiTy: [],
  removeLiquidity: []
}

const totalCacheDefault = {
  all: 1,
  swap: 1,
  addLiquidiTy: 1,
  removeLiquidity: 1
}

const TransactionTable = ({ address, typeBoard = '' }) => {
  const { t } = useTranslation()
  const typeTxs = {
    [ALL]: t('all'),
    [SWAP]: t('swap'),
    [ADD]: t('add'),
    [REMOVE]: t('remove'),
    [CREATE]: t('create')
  }
  const currencyRedux = useSelector(state => state.currencyRedux) // needed to rerender onChange Currency

  const [typeActive, setActiveType] = useState('all')
  const [widthResize, setWidthResize] = useState(window.innerWidth)

  const [listTxsData, setListTxsData] = useState()

  const [isLoading, setIsLoading] = useState()
  const [total, setTotal] = useState()
  const [currPage, setCurrPage] = useState(1)

  const txsDataRef = useRef(cachedTxsTable)
  const totalPageRef = useRef(totalCacheDefault)

  const urlExplorer = SOL_SCAN_URL.mainnet

  useEffect(() => {
    window.addEventListener('resize', handleResizeWidth)

    return () => {
      window.removeEventListener('resize', handleResizeWidth)
    }
  }, [])

  useEffect(() => {
    getListTxs()
  }, [currPage, typeActive, address])

  const getListTxs = async () => {
    let url = null
    const params = {
      page: currPage,
      size: LIMIT,
      type: typeActive
    }
    if (typeActive === 'all') {
      params.type = ''
    }
    switch (typeBoard) {
    case 'token':
      url = 'saros/token/transaction'
      params.tokenAddress = address
      break
    case 'pool':
      url = 'saros/pool/transaction'
      params.poolAddress = address
      break
    case 'wallets':
      url = 'saros/wallet/transaction'
      params.address = address
      break
    default:
      url = 'saros/transaction'
      break
    }

    const cacheDataList = txsDataRef.current[typeActive]
    const cacheDataTotal = totalPageRef.current[typeActive]

    if (getLength(cacheDataList) > 0) {
      setTotal(cacheDataTotal)
      setListTxsData(cacheDataList)
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    let response

    if (typeBoard === 'wallets') {
      const res = await BaseAdapter.getData(url, params)

      response = get(res, 'data')
    } else {
      response = await BaseAPI.postData(url, params)
    }

    if (!response) {
      setIsLoading(false)
      return
    }
    setTxsDataRef(typeActive, get(response, 'data'))
    setTotalPageRef(typeActive, get(response, 'total'))

    setTotal(get(response, 'total'))
    setListTxsData(get(response, 'data'))
    setIsLoading(false)
  }

  const renderTypeTxs = (type) => {
    return typeTxs[type]
  }

  const setTxsDataRef = (tab, data) => {
    const newDataRef = cloneDeep(txsDataRef.current)
    newDataRef[tab] = data
    txsDataRef.current = newDataRef
  }

  const setTotalPageRef = (tab, data) => {
    const newDataRef = cloneDeep(totalPageRef.current)
    newDataRef[tab] = data
    totalPageRef.current = newDataRef
  }

  const handleViewTxs = (row) => {
    const hash = get(row, 'hash')
    const urlExplorer = SOL_SCAN_URL.mainnet
    window.open(`${urlExplorer}/tx/${hash}`)
  }

  const columnHeader = [
    {
      title: t('action'),
      dataIndex: 'action',
      width: 20,
      render: (val) => {
        const { token0, token1, type, hash } = val
        return (
          <a
            href={`${urlExplorer}/tx/${hash}`}
            target="_blank"
            className="color-theme transaction-name" rel="noreferrer"
          >
            {`${renderTypeTxs(type)} ${upperCase(get(token0, 'symbol', ''))} 
            and ${upperCase(get(token1, 'symbol', ''))}`}{' '}
          </a>
        )
      }
    },
    {
      title: t('totalValue'),
      dataIndex: 'totalValue',
      width: 15,
      align: 'right',
      hide: widthResize <= 768,
      render: (val) => (<span>{val}</span>)
    },
    {
      title: t('tokenAmount'),
      dataIndex: 'tokenSend',
      align: 'right',
      width: 15,
      hide: widthResize <= 768,
      render: (val) => {
        const { token, amount } = val
        return (
          <div className='d-flex align-items-center justify-content-end'>
            <div>{amount}&nbsp;</div>
            <div className='uppercase'>{get(token, 'symbol')}</div>
          </div>
        )
      }
    },
    {
      title: t('tokenAmount'),
      dataIndex: 'tokenReceive',
      align: 'right',
      width: 15,
      hide: widthResize <= 768,
      render: (val) => {
        const { token, amount } = val
        return (
          <div className='d-flex align-items-center justify-content-end'>
            <div>{amount}&nbsp;</div>
            <div className='uppercase'>{get(token, 'symbol')}</div>

          </div>
        )
      }
    },
    {
      title: t('account'),
      dataIndex: 'account',
      width: 15,
      align: 'right',
      hide: widthResize <= 768,
      render: (val) => {
        const { address, hash } = val

        return (
          <a
            href={`${urlExplorer}/tx/${hash}`}
            target="_blank"
            className="color-theme " rel="noreferrer"
          >{formatAddress(address)}</a>)
      }
    },
    {
      title: t('time'),
      dataIndex: 'time',
      width: 15,
      align: 'right'
    }
  ]

  const tableData = useMemo(() => {
    if (getLength(listTxsData) === 0) return []
    return listTxsData.map((item) => {
      const type = get(item, 'type', '')
      const token0 = get(item, 'token0', '')
      const token1 = get(item, 'token1', '')
      const amountUSD = get(item, 'amountUSD', 0)
      const amount0 = get(item, 'amount0', 0)
      const amount1 = get(item, 'amount1', 0)
      const address = get(item, 'from', '')
      const time = get(item, 'time', '')
      const hash = get(item, 'hash')

      return {
        action: { token0, token1, type, hash },
        tokenSend: { hash, token: token0, amount: formatNumberBro({ number: amount0, mantissa: 2 }) },
        tokenReceive: { hash, token: token1, amount: formatNumberBro({ number: amount1, mantissa: 2 }) },
        totalValue: ReduxServices.formatPrice(amountUSD),
        time: convertDateMomentHistory(time, 'HH:mm DD/MM/YYYY'),
        account: { address, hash },
        hash

      }
    })
  }, [listTxsData, currencyRedux])

  const handleResizeWidth = () => {
    setWidthResize(window.innerWidth)
  }

  const onChooseTab = (tab) => () => {
    setActiveType(tab)
    setCurrPage(1)
  }

  const renderTab = () => {
    return LIST_TYPE.map((item, i) => {
      return (
        <div
          className={`tab-item ${
            typeActive === item.value && 'tab-item--active'
          }`}
          key={i}
          onClick={onChooseTab(item.value)}
        >
          {t(item.name)}
        </div>
      )
    })
  }

  const onChangePage = (val) => {
    const { selected } = val
    setCurrPage(selected + 1)
    setTxsDataRef(typeActive, [])
  }

  return (
    <div className={cn(styles.tableContainer, 'box-wrapper')}>
      <div className="title-table">
        {t('transactions')}

        <div className="list-tabs-table">{renderTab()}</div>
      </div>
      <CustomTable
        isLoading={isLoading}
        // onClickRow={handleViewTxs}
        columns={columnHeader}
        dataSource={tableData}
      />

      {getLength(listTxsData) > 0 && <Pagination forcePage={currPage - 1} totalPage={total} handleSelectedPage={onChangePage} />}
    </div>
  )
}

export default TransactionTable
