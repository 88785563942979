import Badge from 'components/Badge'
import { Icon } from 'components/Icon'
import React, { useContext } from 'react'
import styles from './style.module.scss'
import get from 'lodash/get'
import { SwapContext } from '../../../context/SwapContext'
import { formatNumberBro, formatPrice, getLength } from 'common/functions'
import TokenImage from 'components/common/TokenImage'
import { generateId } from 'common/functions/utils'
import { useTranslation } from 'react-i18next'
import EmptyData from 'components/EmptyData'
import PuffLoading from 'components/common/PuffLoading'

const CompareList = ({ isVisible, isBase }) => {
  const swapContext = useContext(SwapContext)
  const { swapRouterData, isLoadingSwapRouter } = swapContext

  const { t } = useTranslation()
  const routerData = get(swapRouterData, isBase ? 'routerData.base' : 'routerData.pair')
  const youSaveAmount = get(swapRouterData, isBase ? 'youSave.base' : 'youSave.pair')

  const compareList = get(swapRouterData, isBase ? 'ammCompareData.base' : 'ammCompareData.pair', [])
  const tokenId = get(routerData, 'token1.id')
  const tokenPrice = window.walletServices.findCoinGeckoPrice(tokenId)

  const fiatSavePrice = youSaveAmount * tokenPrice

  const bestPrice = get(compareList, '[0].amount', 0) * tokenPrice

  const getPercentageIncrease = (numA, numB) => {
    const numReturn = ((numA - numB) / numB) * 100
    return formatNumberBro({ number: numReturn, mantissa: 2 })
  }

  if (!isVisible) return null

  if (isLoadingSwapRouter) {
    return (
      <div className='loading-container'>
        <PuffLoading/>
      </div>
    )
  }

  return (
    <div className={styles.compareListContainer}>
      {getLength(compareList) <= 0
        ? <div className='d-flex align-items-center justify-content-center compareListContainerEmpty'>
          <EmptyData />
        </div>
        : compareList.map((item, index) => {
          const fiatPrice = item.amount * tokenPrice
          const percent = getPercentageIncrease(fiatPrice, bestPrice)
          return (
            <div key={item.trade.name || generateId()} className='compare-item'>
              <div className='d-flex align-items-center flex-1'>
                <TokenImage src={get(item, 'trade.image')} alt="trade-icon" className="trade-icon"/>
                <div className='text-swap'>{get(item, 'trade.name')}</div>
              </div>
              <div className='text-center d-flex align-items-center'>{formatPrice(fiatPrice)}</div>
              <div className='flex-1 d-flex justify-content-end align-items-center'>
                { index === 0
                  ? (
                    <div className='best-exchange-item'>
                      { fiatSavePrice > 0 &&
                        <div className='d-flex'>
                          <div className='color-grey'>{t('save')}: </div>
                          <div className='best-exchange-item__price'>{formatPrice(fiatSavePrice)}</div>
                        </div>
                      }
                      <Badge className="custom-badge" variant="success">{t('bestMatch')}</Badge>
                    </div>
                  )
                  : <span className='color-danger'> {percent}%</span>
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default CompareList
