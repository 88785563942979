/* eslint-disable multiline-ternary */
import { Icon } from 'components/Icon'
import BaseAPI from 'controller/api/BaseAPI'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import isEmpty from 'lodash/isEmpty'
import InfiniteScroll from 'react-infinite-scroll-component'

import styles from './style.module.scss'
import { formatNumberBro, getItemStorage, getLength } from 'common/functions'
import moment from 'moment'
import PuffLoading from 'components/common/PuffLoading'
import useEffectCustom from 'hooks/useEffectCustom'
import useRenderHash from 'hooks/useRenderHash'
import EmptyData from 'components/EmptyData'
import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import TokenImage from 'components/common/TokenImage'
import BaseAdapter from 'controller/api/BaseAdapter'
import { SarosSwapService } from 'common/pool/saros_swap/sarosSwapServices'
import { KEY_STORE } from 'common/constants/keystore'
import { AppContext } from 'context/appContext'

let cacheHistory = {}
const HistoryList = ({ isVisible }) => {
  const accountSol = useSelector((state) => state.accountSol)
  const viewHash = useRenderHash()
  const [listHistory, setListHistory] = useState([])
  const [isHasMore, setIsHasMore] = useState(true)
  const [currPage, setCurrPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(false)

  const appContext = useContext(AppContext)
  const { isWaitingUpdateJWT } = appContext

  const isEmptyData = !isLoading && getLength(listHistory) <= 0

  useEffect(() => {
    setTimeout(() => {
      const JWT_TOKEN_ADAPTER = getItemStorage(KEY_STORE.JWT_TOKEN_ADAPTER)
      if (isVisible) {
        cacheHistory = {}
        loadingHistory()
      }
    }, [500]) // temporary :(
  }, [isVisible, accountSol, isWaitingUpdateJWT])

  const loadingHistory = async (page = 1) => {
    if (!isEmpty(cacheHistory)) {
      setListHistory(cacheHistory.data)
      setTotalPage(cacheHistory.total)
      return
    }
    setIsLoading(true)
    setIsHasMore(true)
    const params = {
      page: page,
      size: pageSize
    }

    const res = await BaseAdapter.getData('saros/swap/history', params)
    if (res && res.data) {
      cacheHistory = res
      setListHistory(res.data)
      setTotalPage(res.total)
    }
    setIsLoading(false)
  }

  const onNextPage = () => {
    setCurrPage(currPage + 1)
  }

  useEffectCustom(() => {
    if (currPage !== 0) {
      fetchMoreData()
    }
  }, [currPage])

  const fetchMoreData = async () => {
    const params = {
      page: currPage,
      size: pageSize
    }
    const moreData = await BaseAdapter.getData('saros/swap/history', params)
    if (moreData && moreData.data) {
      const listHistoryClone = cloneDeep(listHistory)
      const newList = get(moreData, 'data', [])
      setTimeout(() => {
        const newListData = listHistoryClone.concat(newList)
        setListHistory(newListData)
      }, 500)
    }
  }

  useEffect(() => {
    if (totalPage === 0) return
    if (currPage >= totalPage) {
      setIsHasMore(false)
    }
  }, [currPage, totalPage])

  if (!isVisible) return null
  return (
    <div className={clsx(styles.historyListContainer)}>
      {isEmptyData ? (
        <div className='d-flex justify-content-center align-items-center history-empty'>
          <EmptyData isNoContent size="sm" />
        </div>
      ) : (
        <InfiniteScroll
          dataLength={getLength(listHistory)}
          next={onNextPage}
          height={200}
          hasMore={isHasMore}
          loader={
            <div className="d-flex justify-content-center align-items-center loading-list">
              <PuffLoading />
            </div>
          }
          endMessage={null}
        >
          {listHistory?.map((item, index) => {
            const token0 = SarosSwapService.getInfoByMintAddress(get(item, 'token0')) || {}
            const token1 = SarosSwapService.getInfoByMintAddress(get(item, 'token1')) || {}
            const amount0 = get(item, 'amount0')
            const amount1 = get(item, 'amount1')
            return (
              <a
                href={viewHash.renderHashByType({ hash: get(item, 'hash') })}
                target="_blank"
                key={item.index}
                className="history-item" rel="noreferrer"
              >
                <div className='token-pair'>
                  <div className="token-item">
                    <TokenImage src={token0.icon} className="token-icon" />
                    <div className="token-text">
                      <div className="text-l token-text--truncate">
                        {formatNumberBro({ number: amount0, mantissa: amount0 < 1 ? 4 : 2 })}
                      </div>
                      <div className="token-text--truncate text-xs color-grey uppercase">
                        {token0.symbol}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <Icon name="web_swap" className="text-l pl-5" />
                  </div>

                  <div className="token-pair-item">
                    <TokenImage src={token1.icon} className="token-icon" />
                    <div className="token-text">
                      {/* <div className='text-l'>{formatNumberBro({ number: amount1, mantissa: 2 })}</div> */}
                      <div className="text-xs color-grey uppercase">
                        {token1.symbol}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swap-date color-grey" >
                  {moment(get(item, 'time')).format('DD/MM/YYYY')}
                </div>
              </a>
            )
          })}
        </InfiniteScroll>

      )}
    </div>
  )
}

export default HistoryList
