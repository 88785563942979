import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import styles from './style.module.scss'
import cx from 'classnames/bind'
import CustomToggle from './CustomToggle'
import get from 'lodash/get'
import { Icon } from 'components/Icon'
import { useTranslation } from 'react-i18next'
import Tooltip from 'components/Tooltip'

const defaultOptions = [
  { label: 'item1', value: 'item1' },
  { label: 'item2', value: 'item2' }
]
const PageDropdown = (props) => {
  const {
    tooltipText,
    options = defaultOptions,
    isAsyncChange,
    isWaitingChange,
    prefix,
    prefixActive,
    suffix,
    onChange,
    defaultValue,
    disabled,
    className,
    toggleclassName,
    menuClassName,
    dropdownItemClassName,
    wrapperItemClassName,
    translate
  } = props

  // dropList must be passed as an Object, exmaple : dropList = {'key1': 'value1', 'key2': 'value2', .....}
  // receiveValue is the value you get from dropList
  const { t } = useTranslation()
  const [dropdownItemValue, setDropdownItemValue] = useState()
  const [isActiveToggle, setIsActiveToggle] = useState(false)
  const [isHover, setIsHover] = useState()

  const handleSelectDropdownItem = (value) => () => {
    onChange && onChange(value)
    if (isAsyncChange) {
      if (isWaitingChange) {
        setDropdownItemValue(value)
      }
    } else {
      setDropdownItemValue(value)
    }

    setIsActiveToggle(false)
  }

  const handleActiveDropdown = () => {
    setIsActiveToggle(!isActiveToggle)
  }

  const handleToggleMenu = (isOpen) => {
    setIsActiveToggle(isOpen)
  }

  useEffect(() => {
    setDropdownItemValue(defaultValue)
  }, [defaultValue])

  const displayLabel = dropdownItemValue ? dropdownItemValue.label : get(options, '[0].label')
  const formatLabel = translate ? t(displayLabel) : displayLabel

  const toggleDropdown = (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={handleActiveDropdown}
      className={cx(
        styles.toggleButton,
        isActiveToggle && styles.activeToggle,
        toggleclassName
      )}
    >
      <div className="d-flex align-items-center">
        <div className={prefix ? styles.prefixWrapper : 'd-none'}>
          {isHover ? prefixActive || prefix : prefix}
        </div>
        <div
          className={cx(styles.buttonText, 'button-text')}
        >
          {formatLabel}
        </div>
        <div className={styles.prefixWrapper}>{suffix}</div>
      </div>
      <Icon name="web_drop_down" className={cx(isActiveToggle && styles.rotate180, 'icon')}></Icon>
    </div>

  )

  const renderDropdownToggle = () => {
    if (tooltipText) {
      return (
        <Tooltip disabled={isActiveToggle} className={'d-flex align-items-center w-100'} content={tooltipText}>
          {toggleDropdown}
        </Tooltip>
      )
    }
    return toggleDropdown
  }

  return (
    <Dropdown className={className} onToggle={handleToggleMenu}>
      <Dropdown.Toggle as={CustomToggle} >

        {renderDropdownToggle()}
      </Dropdown.Toggle>
      <div className={disabled && 'd-none'}>
        <Dropdown.Menu
          className={cx(
            menuClassName,
            styles.customMenu,
            !isActiveToggle && styles.hideDropdown
          )}
        >
          <div className={cx(wrapperItemClassName)}>
            {
              options.map(item => (
                <Dropdown.Item
                  className={cx(dropdownItemClassName, 'text-small')}
                  onClick={handleSelectDropdownItem(item)}
                  key={item._id || Math.random().toString()}
                >
                  <div className="caption-normal align-items-center ">
                    <div className="ml-3 ">
                      {item.preIcon}
                      {
                        item.render || (translate ? t(item.label) : item.label)
                      }
                    </div>
                  </div>
                </Dropdown.Item>
              ))
            }
          </div>
        </Dropdown.Menu>
      </div>
    </Dropdown>
  )
}

export default PageDropdown
