/* eslint-disable no-template-curly-in-string */
/* eslint-disable new-cap */
import { u64 } from '@solana/spl-token'
import Decimal from 'decimal.js'

export class DecimalUtil {
  static fromU64 (input, shift = 0) {
    return new Decimal(input.toString()).div(new Decimal(10).pow(shift))
  }

  static toU64 (input, shift = 0) {
    if (input.isNeg()) {
      throw new Error(
        'Negative decimal value ${input} cannot be converted to u64.'
      )
    }

    const shiftedValue = input.mul(new Decimal(10).pow(shift))
    const zeroDecimalValue = shiftedValue.trunc()
    return new u64(zeroDecimalValue.toString())
  }
}
