import React from 'react'
import WalletOverview from '.'
import { WalletOverviewContextProvider } from './Context/WalletOverviewContext'

const WalletOverviewProvider = (props) => {
  return (
    <WalletOverviewContextProvider>
      <WalletOverview/>
    </WalletOverviewContextProvider>
  )
}

export default WalletOverviewProvider
