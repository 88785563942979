import React, { useContext, useState, useEffect } from 'react'
import { Icon } from 'components/Icon'
import styles from './style.module.scss'
import cn from 'clsx'
import TokenImage from 'components/common/TokenImage'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getLength } from 'common/functions'
import { generateId } from 'common/functions/utils'
import { SwapContext } from 'pages/NewSwapScreen/context/SwapContext'
import { get } from 'lodash'

const SwapRecent = ({ children, className, isShowList }) => {
  const { t } = useTranslation()

  const [isShowRecentSwap, setIsShowRecentSwap] = useState()

  useEffect(() => {
    setIsShowRecentSwap(isShowList)
  }, [isShowList])

  const swapContext = useContext(SwapContext)
  const { setSelectedRecentSwap } = swapContext

  const recentSwapRedux = useSelector(state => state.recentSwapRedux)

  const listToken = window.walletServices.tokenSolana

  const getTokenInfo = (mintAddress) => {
    if (getLength(listToken) === 0) return {}
    return listToken.find(item => item.mintAddress === mintAddress)
  }

  const onSelectRecentSwap = (token0Mint, token1Mint) => () => {
    setSelectedRecentSwap({ token0Mint, token1Mint })
  }

  const onHoverSwapRecent = (bool) => () => {
    if (isShowList) {
      return setIsShowRecentSwap(isShowList)
    }
    setIsShowRecentSwap(bool)
  }

  return (
    <div className={styles.swapRecentContainer}>
      <div
        onMouseOver={onHoverSwapRecent(true)}
        onMouseLeave={onHoverSwapRecent(false)}
        className={cn(className, 'list-recent-wrap')}
      >
        <div className="list-recent-wrap__space">

          <div className={
            cn('swap-recent-list',
              isShowRecentSwap ? 'swap-recent-list--show' : 'swap-recent-list--hide'
            )}>
            <div>{t('recentSwap')}</div>

            {
              recentSwapRedux.map(item => {
                const token0Info = getTokenInfo(item.token0Address)
                const token1Info = getTokenInfo(item.token1Address)
                return (
                  <div key={generateId()}
                    className='swap-recent-list__item'
                    onClick={onSelectRecentSwap(item.token0Address, item.token1Address)}
                  >
                    <div className='token-item'>
                      <TokenImage src={get(token0Info, 'icon')} alt="" className="token-img img-24 mr-2" />
                      <div className='uppercase token-item__symbol'>{get(token0Info, 'symbol')}</div>
                    </div>

                    <div className='d-flex justify-content-center'>
                      <Icon
                        name="web_swap"
                        className="text-l ml-2 mr-2"
                      />
                    </div>

                    <div className='token-item '>
                      <TokenImage src={get(token1Info, 'icon')} alt="" className="token-img img-24 mr-2" />
                      <div className='uppercase token-item__symbol'>{get(token1Info, 'symbol')}</div>
                    </div>
                  </div>
                )
              })
            }

          </div>
        </div>
        <div className="tooltip-children">{children}</div>
      </div>
    </div>
  )
}

export default SwapRecent
