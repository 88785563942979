import React from 'react'
import style from './style.module.scss'
import { getLength } from 'common/functions'
import { Link } from 'react-router-dom'
import { Icon } from 'components/Icon'

const NavbarDropdown = ({ menus, chooseUrl }) => {
  const checkActiveUrl = (menu) => {
    const findIdx = menu.subMenu?.findIndex(item => chooseUrl.includes(item.url))
    return (findIdx >= 0) || (chooseUrl === menu.url)
  }

  const renderItemMenu = (item) => {
    const isActve = checkActiveUrl(item)
    const isNew = item?.isNew
    return (
      <Link to={item.url} className={isActve ? 'active' : ''}>{item.name}
        {isNew && <span className='new-menu'>NEW</span>}
      </Link>

    )
  }

  return (
    <div className={style.navbarDropdownContainer}>
      <div className="topnav" id="myTopnav">
        {menus.map(item => {
          const isActve = checkActiveUrl(item)
          const isSubmenu = getLength(item?.subMenu) > 0
          return (
            !isSubmenu
              ? renderItemMenu(item)
              : <div className="dropdown">
                <button className={`dropbtn ${isActve ? 'active' : ''}`}>{item.name}
                  <Icon name="web_arrow_down" className="ml-1"/>
                </button>
                <div className="dropdown-content">
                  {item.subMenu.map((itemSub, index) => {
                    return (
                      <Link key={index} to={itemSub.url}>{itemSub.name}</Link>
                    )
                  })}
                </div>
              </div>
          )
        })}
      </div>
    </div>
  )
}

export default NavbarDropdown
