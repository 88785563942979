import { PublicKey } from '@solana/web3.js'
import images from 'assets/images'
import { IS_DEV, LIST_TOKEN_DEFAULT_TEST } from 'common/constants'
import { BLOCKS_PER_YEAR, PRECISION_MULTIPLIER } from 'common/farm/functions'
import { SarosFarmInstructionService } from 'common/farm/SarosFarmInstruction'
import { SarosFarmService } from 'common/farm/SarosFarmService'
import { convertWeiToBalance, upperCase } from 'common/functions'
import { SAROS_FARM_ADDRESS } from 'common/pool/constants'
import { TokenProgramInstructionService } from 'common/pool/tokenProgramInstructionService'
import { TokenProgramService } from 'common/pool/tokenProgramService'
import {
  fetchMultipleAccount,
  genConnectionSolana,
  genOwnerSolana
} from 'common/solana'
import { chunk, get } from 'lodash'
import { useSelector } from 'react-redux'

const useFarmHook = () => {
  const accountSol = useSelector((state) => state.accountSol)
  const connection = genConnectionSolana()
  const isStakeScreen = get(window, 'location.pathname', '').includes('/stake')

  const fetchInfoToken = async (mintAddress) => {
    if (!mintAddress) return null
    const listToken = IS_DEV
      ? LIST_TOKEN_DEFAULT_TEST
      : await window.walletServices.tokenSolana
    let infoToken = listToken.find(
      (token) => token.mintAddress === mintAddress
    )
    if (!infoToken && accountSol) {
      const owner = await genOwnerSolana(accountSol)
      const accountAddress =
        await TokenProgramService.findAssociatedTokenAddress(
          owner.publicKey,
          new PublicKey(mintAddress)
        )
      const accountInfo = await connection.getTokenAccountBalance(
        accountAddress
      )
      infoToken = {
        decimals: parseFloat(get(accountInfo, 'value.decimals', 9)),
        mintAddress,
        icon: images.icoUnknown,
        name: 'un know',
        symbol: 'unknow'
      }
    }

    const price = window.walletServices.findCoinGeckoPrice(
      get(infoToken, 'id', '')
    )
    return {
      ...infoToken,
      decimals: parseFloat(get(infoToken, 'decimals', 9)),
      price: parseFloat(price),
      address: mintAddress
    }
  }

  const calculateValuePoolByAmount = ({
    amountStakedInPool,
    isEnd,
    totalRewardOneYearUSD
  }) => {
    const liquidityUsd = parseFloat(amountStakedInPool)
    const aprStake = isEnd ? 0 : (totalRewardOneYearUSD / liquidityUsd) * 100
    return {
      priceLp: 0,
      liquidityUsd,
      apr: aprStake,
      totalApr: aprStake
    }
  }

  const calculateValuePool = async ({
    dataPoolInfo,
    token0,
    token1,
    stakingToken,
    rewards,
    decimalTokenStake
  }) => {
    const isEnd = get(dataPoolInfo, 'isActive', false)
    const isCheckAprByAmount = get(dataPoolInfo, 'isAmountBase', false)
    const amountStakedInPool = convertWeiToBalance(
      get(dataPoolInfo, 'dataPoolInfo.amount', 0),
      decimalTokenStake
    )
    const rewardOneYearUSD = await Promise.all(
      rewards.map(async (reward) => await calculateRewardOneYear(reward, isCheckAprByAmount))
    )
    const totalRewardOneYearUSD = rewardOneYearUSD.reduce((total, curr) => {
      total += curr
      return total
    }, 0)

    // calculate value for stake
    if (isStakeScreen) {
      if (isCheckAprByAmount) {
        return calculateValuePoolByAmount({
          amountStakedInPool,
          isEnd,
          totalRewardOneYearUSD
        })
      }
      const stakingTokenPrice = window.walletServices.findCoinGeckoPrice(
        get(stakingToken, 'id')
      )

      const priceLp = parseFloat(stakingTokenPrice)
      const liquidityUsd = parseFloat(amountStakedInPool) * priceLp
      const aprStake = isEnd ? 0 : (totalRewardOneYearUSD / liquidityUsd) * 100
      return {
        priceLp,
        liquidityUsd,
        apr: aprStake,
        totalApr: aprStake
      }
    }

    // calculate value for farm
    const token0Price = window.walletServices.findCoinGeckoPrice(
      get(token0, 'id')
    )
    const token1Price = window.walletServices.findCoinGeckoPrice(
      get(token1, 'id')
    )

    const amountToken0InPool = parseFloat(
      convertWeiToBalance(
        get(dataPoolInfo, 'infoPoolLiquidity.token0Account.amount', 0),
        get(token0, 'decimals')
      )
    )
    const amountToken1InPool = parseFloat(
      convertWeiToBalance(
        get(dataPoolInfo, 'infoPoolLiquidity.token1Account.amount', 0),
        get(token1, 'decimals')
      )
    )

    const totalPriceLiquidity =
      amountToken0InPool * parseFloat(token0Price) +
      amountToken1InPool * parseFloat(token1Price)
    const tokenLpSupply = convertWeiToBalance(
      get(dataPoolInfo, 'lpInfo.supply'),
      decimalTokenStake
    )
    const priceLp = parseFloat(totalPriceLiquidity) / parseFloat(tokenLpSupply)

    const liquidityUsd = parseFloat(amountStakedInPool) * priceLp
    const aprFarm = isEnd ? 0 : (totalRewardOneYearUSD / liquidityUsd) * 100

    return {
      priceLp,
      liquidityUsd,
      apr: aprFarm
    }
  }
  const fetchDetailPool = async (dataPoolInfo, currentBlock) => {
    try {
      const isCheckAprByAmount = get(dataPoolInfo, 'isAmountBase', false)

      const stakingToken = isStakeScreen
        ? await fetchInfoToken(get(dataPoolInfo, 'dataPoolInfo.mint'))
        : null
      const token0 = await fetchInfoToken(
        get(dataPoolInfo, 'infoPoolLiquidity.token0Mint')
      )
      const token1 = await fetchInfoToken(
        get(dataPoolInfo, 'infoPoolLiquidity.token1Mint')
      )
      const decimalTokenStake = isStakeScreen
        ? get(stakingToken, 'decimals')
        : get(dataPoolInfo, 'lpInfo.decimals')

      const endBlock = parseFloat(get(dataPoolInfo, 'endBlock', 0))
      const rewards = await Promise.all(
        get(dataPoolInfo, 'rewards', []).map(async (item) => {
          const info = await fetchInfoToken(get(item, 'rewardTokenMint'))
          return {
            ...item,
            ...info
          }
        })
      )
      let name = `${upperCase(get(token0, 'symbol'))} - ${upperCase(
        get(token1, 'symbol')
      )} LP`
      const { priceLp, apr, liquidityUsd } = await calculateValuePool({
        token0,
        token1,
        stakingToken,
        dataPoolInfo,
        rewards,
        decimalTokenStake
      })

      if (isStakeScreen) {
        const rewardsSymbol = rewards.map((rew) =>
          upperCase(get(rew, 'symbol'))
        )
        name = `Stake ${upperCase(
          get(stakingToken, 'symbol')
        )} Earn ${rewardsSymbol.join(' ')}`
      }

      const aprTrading = get(dataPoolInfo, 'feeAPR', 0)
      const totalApr = apr + aprTrading
      const isActive = endBlock > currentBlock
      const data = {
        ...dataPoolInfo,
        stakingToken,
        name,
        token0,
        token1,
        rewards,
        isActive,
        isStakeScreen,
        priceLp,
        apr: !isActive ? 0 : apr,
        liquidityUsd,
        decimalTokenStake,
        currentBlock,
        isCheckAprByAmount,
        totalApr: totalApr === Infinity || !isActive ? 0 : totalApr
      }
      return { isErr: false, data }
    } catch (err) {
      console.log({ err })
      return { isErr: true, data: err }
    }
  }

  const updateDataUserInfoInPool = async (pools) => {
    try {
      if (accountSol) {
        const owner = await genOwnerSolana(accountSol)
        const listAddressUserInPool = await Promise.all(
          pools.map(async (item) => {
            const lpMint = new PublicKey(get(item, 'dataPoolInfo.mint'))
            const [userPoolAddress] =
              await SarosFarmService.findUserPoolAddress(
                owner.publicKey,
                new PublicKey(get(item, 'poolAddress')),
                new PublicKey(SAROS_FARM_ADDRESS)
              )
            const userLpAccount =
              await TokenProgramService.findAssociatedTokenAddress(
                owner.publicKey,
                lpMint
              )
            const rewards = get(item, 'rewards', [])
            const userPoolRewardList = await Promise.all(
              rewards
                .map(async (rw) => {
                  const poolRewardAddress = new PublicKey(rw.poolRewardAddress)
                  const [userPoolRewardAddress] =
                    await SarosFarmService.findUserPoolRewardAddress(
                      owner.publicKey,
                      poolRewardAddress,
                      new PublicKey(SAROS_FARM_ADDRESS)
                    )
                  return [userPoolRewardAddress, poolRewardAddress]
                })
                .flat()
            )
            return [userPoolAddress, userLpAccount, userPoolRewardList].flat()
          })
        )
        const listAddressUserInPoolFlat = listAddressUserInPool.flat(2)
        const listAddressUserInPoolChunk = chunk(listAddressUserInPoolFlat, 99)
        const fetchAllData = await fetchMultipleAccount(
          listAddressUserInPoolChunk
        )
        const newDataPool = pools.map((pool, index) => {
          const rewards = get(pool, 'rewards', [])
          const rewardLength = rewards.length
          const indexCircle = 3 + rewardLength
          const indexUserInPool = indexCircle * index
          const indexUserLpAccount = indexUserInPool + 1
          const dataUserInPool = fetchAllData[indexUserInPool]
          const dataUserLpAccount = fetchAllData[indexUserLpAccount]

          const bufferUserInPool = dataUserInPool
            ? Buffer.from(get(dataUserInPool, 'data[0]'), 'base64')
            : null
          const bufferUserLpAccount = dataUserLpAccount
            ? Buffer.from(get(dataUserLpAccount, 'data[0]'), 'base64')
            : null

          const userInfo = {
            amountUserStaked: 0,
            balance: 0
          }

          let dataUserInPoolDecode = null
          let bufferUserLpAccountDecode = null

          if (bufferUserInPool) {
            dataUserInPoolDecode =
              SarosFarmInstructionService.decodeUserPoolAccount(
                bufferUserInPool
              )
            userInfo.amountUserStaked = parseFloat(
              get(dataUserInPoolDecode, 'amount', 0).toString()
            )
          }

          if (bufferUserLpAccount) {
            bufferUserLpAccountDecode =
              TokenProgramInstructionService.decodeTokenAccountInfo(
                bufferUserLpAccount
              )
            userInfo.balance = parseFloat(
              get(bufferUserLpAccountDecode, 'amount', 0).toString()
            )
          }

          const newRewards = rewards.map((rw, indexRw) => {
            const indexUserPoolReward = indexUserLpAccount + indexRw + 1
            const indexPoolReward = indexUserPoolReward + 1
            const dataUserPoolReward = fetchAllData[indexUserPoolReward]
            const dataPoolReward = fetchAllData[indexPoolReward]

            const bufferUserPoolReward = dataUserPoolReward
              ? Buffer.from(get(dataUserPoolReward, 'data[0]'), 'base64')
              : null
            const bufferPoolReward = dataPoolReward
              ? Buffer.from(get(dataPoolReward, 'data[0]'), 'base64')
              : null
            let dataUserInPoolRewardDecode = null
            let dataPoolRewardDecode = null

            if (bufferUserPoolReward) {
              dataUserInPoolRewardDecode =
                SarosFarmInstructionService.decodeUserPoolRewardAccount(
                  bufferUserPoolReward
                )
            }

            if (bufferPoolReward) {
              dataPoolRewardDecode =
                SarosFarmInstructionService.decodePoolRewardAccount(
                  bufferPoolReward
                )
            }

            const amountEarn = calculateReward(
              dataPoolRewardDecode,
              get(pool, 'currentBlock'),
              dataUserInPoolRewardDecode
            )
            const decimals = get(rw, 'decimals')
            const earn = parseFloat(convertWeiToBalance(amountEarn, decimals))
            return {
              ...rw,
              earn
            }
          })

          return {
            ...pool,
            userInfo,
            rewards: newRewards
          }
        })
        return newDataPool
      }
      return pools
    } catch (err) {
      console.log('err update user', { err })
      return pools
    }
  }

  const calculateReward = (
    dataPoolReward,
    currentBlock,
    infoPoolUserReward
  ) => {
    const {
      lastUpdatedBlock,
      rewardPerBlock,
      totalShares,
      accumulatedRewardPerShare,
      rewardEndBlock
    } = dataPoolReward
    const rewardAtBlock =
      parseFloat(currentBlock) > parseFloat(rewardEndBlock.toString())
        ? parseFloat(rewardEndBlock.toString())
        : currentBlock

    const pendingRewardBlocks =
      rewardAtBlock - parseFloat(lastUpdatedBlock.toString())
    const pendingReward =
      pendingRewardBlocks *
      parseFloat(rewardPerBlock.toString()) *
      PRECISION_MULTIPLIER
    const pendingRewardPerShare =
      pendingReward / parseFloat(totalShares.toString())
    const accumulateRewardPerShare =
      parseFloat(accumulatedRewardPerShare.toString()) + pendingRewardPerShare

    if (parseFloat(lastUpdatedBlock) > rewardAtBlock) {
      return 0
    }

    if (!infoPoolUserReward) return 0
    const { rewardDebt, rewardPending, amount } = infoPoolUserReward
    const subTotal =
      (parseFloat(amount.toString()) * accumulateRewardPerShare) / 10 ** 12
    const totalWei =
      subTotal +
      parseFloat(rewardPending.toString()) -
      parseFloat(rewardDebt.toString())

    return totalWei
  }

  const calculateTotalEarn = (rewards) => {
    try {
      const totalRewardsUSD = rewards.reduce((total, curr) => {
        total += curr.earn * curr.price
        return total
      }, 0)

      const totalReward = rewards.reduce((total, curr) => {
        total += curr.earn
        return total
      }, 0)
      return { totalReward, totalRewardsUSD }
    } catch (err) {
      return { totalReward: 0, totalRewardsUSD: 0 }
    }
  }

  const calculateRewardOneYear = async (reward, isReturnAmount) => {
    const { rewardPerBlock, id, decimals } = reward
    const rewardPrice = window.walletServices.findCoinGeckoPrice(id)
    const decimalsTotal = decimals + 3 // plus 3 because decimal in admin rule
    const rewardPerBlockConvert = convertWeiToBalance(
      rewardPerBlock.toString(),
      decimalsTotal
    )
    const rewardOneYearUSD =
      BLOCKS_PER_YEAR * rewardPerBlockConvert * parseFloat(rewardPrice)
    if (isReturnAmount) {
      return BLOCKS_PER_YEAR * rewardPerBlockConvert
    }
    return rewardOneYearUSD
  }

  return {
    calculateTotalEarn,
    fetchInfoToken,
    fetchDetailPool,
    updateDataUserInfoInPool
  }
}

export default useFarmHook
