/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react'
import Button from 'components/common/Button'
import './style.scss'
import { useSelector } from 'react-redux'
import {
  formatAddress,
  formatNumberBro
} from 'common/functions'
import ReduxServices from 'common/redux'
import { useTranslation } from 'react-i18next'
import { isDapp } from 'common/constants'
import images from 'assets/images'
import useOnClickOutside from 'common/customHooks/useClickOutSide'
import { useWalletModal } from '@coin98-com/wallet-adapter-react-ui'
import { useWallet, WALLETS_NAME } from '@coin98-com/wallet-adapter-react'

const ButtonConnected = () => {
  const { t } = useTranslation()
  const { address, disconnect, connected, selectWallet } = useWallet()

  const balanceSol = useSelector((state) => state.balanceSol)

  const { openWalletModal } = useWalletModal()

  const [isShowMenu, setIsShowMenu] = useState(false)

  const dropdownRef = useRef(null)

  useOnClickOutside(dropdownRef, () => {
    setIsShowMenu(false)
  })

  useEffect(() => {
    onResetScroll()
  }, [isShowMenu])

  useEffect(() => {
    ReduxServices.fetchDataSol({ address })
  }, [address])

  const disconnectWallet = () => {
    window.openModal({
      okText: t('disconnect'),
      title: t('disconnectWallet'),
      type: 'confirm',
      onCancel: () => {},
      onOk: async () => {
        await disconnect()
        setIsShowMenu(false)
        window.closeModal()
      }
    })
  }

  const onConnect = async () => {
    if (!isDapp) return openWalletModal()
    try {
      selectWallet(WALLETS_NAME.coin98Solana, 'solana')
    } catch (error) {
      console.log('🚀 ~ file: index.js:93 ~ onConnect ~ error:', error)
    }
  }

  const onResetScroll = () => {
    const listWalletEl = document.getElementById('wallet-dropdown-scroll')
    if (listWalletEl) {
      listWalletEl.scrollTop = 0
    }
  }

  const onToggleAddressMenu = () => {
    setIsShowMenu(true)
  }

  return (
    <div className="button-connected-wr">

      { connected
        ? (
          <div className="button-connect-container" ref={dropdownRef}>
            <div className="button-connect" onClick={onToggleAddressMenu}>
              <div className="button-connect__circle">
                <img src={images.iconToken} alt="" className="icon-connect" />
              </div>
              <div className="button-connect__info">
                <span>
                  {formatNumberBro({ number: balanceSol, mantissa: 4 })} SOL{' '}
                </span>
                <span className="text-address">
                  {formatAddress(address)}
                </span>
              </div>

              {isShowMenu &&
              <div className="button-connect__dropdown">
                <Button
                  className="actions-disconnect"
                  onClick={disconnectWallet}
                  isFullWidth
                >
                  {t('disconnect')}
                </Button>
              </div> }
            </div>

          </div>
        )
        : (
          <Button
            onClick={onConnect}
            className="actions-connect"
          >
            {t('connectWallet')}
          </Button>
        )}
    </div>
  )
}

export default ButtonConnected
