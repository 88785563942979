import images from 'assets/images'
import Button from 'components/common/Button'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import cn from 'classnames'
import { Icon } from 'components/Icon'

const ModalJoinSnapshot = ({ title, onCancel, onConfirm, isJoined = false }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState()

  const handleConfirm = async () => {
    setIsLoading(true)
    await onConfirm()
    setIsLoading(false)
  }

  return (
    <div>
      <div className={styles.joinSnapshotModal}>
        <div className='modal-top'>
          <Icon type="lg" name={isJoined ? 'saros_success' : 'saros_slippage'} alt="" />
          <div className='modal-label text-xl'>{title || t('reviewOrder')}</div>
          <div className='modal-description text-center'>{isJoined ? t('alreadyJoinedSnapshot') : t('joinSnapshotDescription')}</div>
        </div>

        <div className='d-flex'>
          <Button
            onClick={onCancel}
            type="grey"
            className={cn('save-btn mr-2', isJoined ? 'd-none' : '')} isFullWidth
          >
            {t('cancel')}
          </Button>

          <Button
            onClick={handleConfirm}
            className={cn('save-btn ml-2', isJoined ? 'ml-0' : '')}
            isFullWidth
            isLoading={isLoading}
          >
            {t('confirm')}
          </Button>
        </div>

      </div>
    </div>
  )
}

export default ModalJoinSnapshot
