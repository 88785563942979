import React from 'react'
import cn from 'clsx'
import { convertWeiToBalance, nFormatter, upperCase } from 'common/functions'

import styles from './style.module.scss'
import TokenImage from 'components/common/TokenImage'
import { get } from 'lodash'

function TokenInfo ({ tokenInfo, className }) {
  const amountWei = get(tokenInfo, 'amount', '').toString()
  const amountToken = convertWeiToBalance(amountWei, get(tokenInfo, 'decimals'))
  return (
    <div
      className={cn(
        styles['token-info__item'],
        ' color-normal text-xl mb-3',
        className
      )}
    >
      <div className="item-info-left">
        <TokenImage
          src={get(tokenInfo, 'icon')}
          alt=""
          className="token-icon img-48 mr-3"
        />
        {upperCase(get(tokenInfo, 'symbol'))}
      </div>
      <div className="item-info-right">{nFormatter(amountToken)}</div>
    </div>
  )
}

export default TokenInfo
