import React, { useEffect, useMemo, useRef, useState } from 'react'
import cn from 'clsx'

import styles from './style.module.scss'
import RechartLine from 'components/RechartLine'
import { useTranslation } from 'react-i18next'
import { SOL_SCAN_URL } from 'common/constants'
import { Icon } from 'components/Icon'
import { formatNumberBro, formatPrice, lowerCase, nFormatter } from 'common/functions'
import Badge from 'components/Badge'
import Button from 'components/common/Button'
import TransactionTable from 'pages/NewInfoPool/Components/TransactionTable'
import { Link, useHistory, useParams } from 'react-router-dom'
import TopPoolTable from '../TableTopPool'
import BlockColor from 'components/BlockColor'
import ItemBlockInfo from 'components/ItemBlockInfo'
import BaseAPI from 'controller/api/BaseAPI'
import { isArray } from 'lodash'
import RechartBar from 'components/RechartBar'
import get from 'lodash/get'
import TokenImage from 'components/common/TokenImage'
import PuffLoading from 'components/common/PuffLoading'
import PoolTabbar from '../PoolTabbar'
import moment from 'moment'
import NewSwapContent from 'pages/NewSwapScreen/NewSwapContent'
import { useSelector } from 'react-redux'
import ReduxServices from 'common/redux'

const usdc = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
const c98 = 'C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9'
function NewPoolInfoToken () {
  const { t } = useTranslation()
  const { address } = useParams()
  const history = useHistory()

  const CHART_TYPES = [
    {
      name: t('volume'),
      type: 'VOLUME'
    },
    {
      name: t('liquidity'),
      type: 'LIQUIDITY'
    }
  ]

  const currencyRedux = useSelector(state => state.currencyRedux) // needed to rerender onChange Currency

  const [tabChoose, setTabChoose] = useState('VOLUME')

  const [selectedTabbar, setSelectedTabbar] = useState('tokens')

  const [dataChartVolume, setDataChartVolume] = useState([])
  const [dataChartLiquidity, setDataChartLiquidity] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [infoToken, setInfoToken] = useState({})

  const [isLoadingScreen, setIsLoadingScreen] = useState()

  const isPoolInfo = useMemo(() => {
    const { pathname } = history.location
    return pathname.includes('pool')
  }, [history.location.pathname])

  useEffect(() => {
    getTokenInfoChart()
  }, [])

  useEffect(() => {
    // TRIGGER RERENDER CHART
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
  }, [currencyRedux])

  useEffect(() => {
    getTokenInfo()
  }, [address])

  const getTokenInfoChart = async () => {
    const typeFetch = isPoolInfo ? 'pool' : 'token'
    setIsLoading(true)
    const response = await BaseAPI.postData(`saros/${typeFetch}/chart`, {
      [`${isPoolInfo ? 'poolAddress' : 'tokenAddress'}`]: address
    })
    if (!isArray(response)) return setIsLoading(false)
    const arrLiquidity = response.map((item) => ({
      number: item.totalLiquidity,
      date: moment(item.date).unix()
    }))
    const arrVolume = response.map((item) => ({
      number: item.volume,
      date: moment(item.date).unix()

    }))
    setDataChartLiquidity(arrLiquidity)
    setDataChartVolume(arrVolume)
    setIsLoading(false)
  }

  const getDetailCgk = async ({ id, symbol }) => {
    const response = await BaseAPI.getData('coin/cgk/detail', { id })
    return response
  }

  const goToNotFoundScreen = () => {
    history.push('/not-found')
  }

  const getTokenInfo = async () => {
    setIsLoadingScreen(true)
    const response = await BaseAPI.postData('saros/token/info', {
      tokenAddress: address
    })
    if (!response) return goToNotFoundScreen()
    const tokenInfo = get(response, 'info.token', {})
    const { id, symbol } = tokenInfo
    const detail = await getDetailCgk({ id })
    const price = get(detail, 'market_data.current_price.usd', 0)
    const priceChange = get(
      detail,
      'market_data.price_change_24h_in_currency.usd',
      0
    )
    setInfoToken({ ...response, price, priceChange })

    setIsLoadingScreen(false)
  }

  const handleViewPoolOnScan = () => {
    const urlExplorer = SOL_SCAN_URL.mainnet
    window.open(`${urlExplorer}/token/${address}`)
  }

  const handleChooseTab = (tab) => () => {
    setTabChoose(tab)
  }

  const handleSelectTabbar = (val) => {
    setSelectedTabbar(val)
    if (val === 'overview') {
      history.push('/info')
    } else {
      history.push(`/info?type=${val}`)
    }
  }

  const handleOpenSwapShortcut = () => {
    window.openModalFirst({
      noIcon: true,
      contentClassName: 'modal-swap-shortcut',
      content: <NewSwapContent shortcut1Mint={get(infoToken, 'info.token.mintAddress')} />
    })
  }

  const volume24h = ReduxServices.formatPrice(get(infoToken, 'volume24h'), true)
  const volume7d = ReduxServices.formatPrice(get(infoToken, 'volume7d'), true)
  const liquidity = ReduxServices.formatPrice(get(infoToken, 'liquidity'), true)
  const transaction24h = formatNumberBro({ number: get(infoToken, 'transaction24h') })
  const detailToken = get(infoToken, 'info.token')
  const price = ReduxServices.formatPrice(get(infoToken, 'price'))

  const priceChange = parseFloat(get(infoToken, 'priceChange', 0))
  const isPriceChangeUp = parseFloat(get(infoToken, 'priceChange', 0)) > 0

  const liquidity24hChange = parseFloat(get(infoToken, 'liquidity24hChange', 0))
  const isLquidity24hChangeUp = parseFloat(get(infoToken, 'liquidity24hChange', 0)) > 0

  const volume24hChange = parseFloat(get(infoToken, 'volume24hChange', 0))
  const isVolume24hChangeUp = parseFloat(get(infoToken, 'volume24hChange', 0)) > 0

  if (isLoadingScreen) {
    return (
      <div className={styles.poolLoading}>
        <PuffLoading isFullScreen />
      </div>
    )
  }

  return (
    <div className={cn(styles['pool-info-token'], 'container-lg color-grey')}>
      <PoolTabbar onChangeTab={handleSelectTabbar} selectedTab={selectedTabbar}/>

      <div className="pool-info-token__top mb-5">
        <div className="box-wrapper chart-box-container ">
          <div className="list-tab">
            {
              CHART_TYPES.map(item => {
                return (
                  <div key={item.name} className={`item-tab ${tabChoose === item.type && 'item-tab--active'}`} onClick={handleChooseTab(item.type)}>
                    {item.name}
                  </div>
                )
              })
            }
          </div>

          <div className="chart-content">
            {
              tabChoose === 'LIQUIDITY'
                ? <RechartLine
                  dataChart={dataChartLiquidity}
                  isLoading={isLoading}
                />
                : <RechartBar
                  isHideTitle
                  dataChart={dataChartVolume}
                  isLoading={isLoading}
                />

            }
          </div>
        </div>

        <div className="info-token-new box-wrapper d-flex flex-column justify-content-between">
          <div>
            <div className="info-token-new__head mb-2">
              <div className="head-left text-xl ">
                <TokenImage src={get(detailToken, 'icon')} className="token-img mr-3" />
                <span className='color-normal mr-2 '>{get(detailToken, 'name')}</span>
                <span className='uppercase'>{get(detailToken, 'symbol')}</span>
              </div>

              <Icon name='web_explorer' className='icon-scan color-normal' onClick={handleViewPoolOnScan} />
            </div>

            <div className="info-token-new__info-balance mb-3">
              <div className="info-balance-value color-normal">
                {price}
              </div>

              <Badge variant={isPriceChangeUp ? 'success' : 'danger'} className='info-balance-percent'>
                <span> {priceChange === 0 ? '' : isPriceChangeUp ? '+' : ''}</span>
                {formatNumberBro({ number: priceChange, mantissa: 4 })}%
              </Badge>
            </div>

            <div className='list-block-info'>
              <BlockColor variant='pink'>
                <ItemBlockInfo isChangeUp={isVolume24hChangeUp} percent={volume24hChange} title='volume24h' icon='web_volume_24h' value={volume24h}/>
              </BlockColor>

              <BlockColor variant='blue'>
                <ItemBlockInfo isChangeUp={isLquidity24hChangeUp} percent={liquidity24hChange} title='liquidity' icon='web_direct_inbox' value={liquidity} />
              </BlockColor>

              <BlockColor variant='green'>
                <ItemBlockInfo title='volume7d' icon='web_volume_24h' value={volume7d} />
              </BlockColor>

              <BlockColor variant='purple'>
                <ItemBlockInfo title='transaction24h' icon='web_share_token' value={transaction24h} />
              </BlockColor>
            </div>
          </div>

          <Button onClick={handleOpenSwapShortcut} className='btn-swap'>
            {t('swap')}
          </Button>
        </div>
      </div>

      <TopPoolTable type='token' address={address} />

      <TransactionTable typeBoard="token" address={address}/>

    </div>
  )
}

export default NewPoolInfoToken
