/* eslint-disable multiline-ternary */
import {
  convertWeiToBalance,
  formatNumberBro,
  formatPrice,
  nFormatter
} from 'common/functions'
import { get } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import BlockColor from '../../../../components/BlockColor'
import TokenImage from 'components/common/TokenImage'

import styles from './style.module.scss'
import useFarmHook from 'hooks/farm/useFarmHook'
import { LiquidityContext } from 'context/liquidity/liquidityContext'
import PuffLoading from 'components/common/PuffLoading'
import Tooltip from 'components/Tooltip'
import { Icon } from 'components/Icon'
import InfoTokenEarn from 'pages/NewFarmScreen/Components/FarmBox/InfoTokenEarn'
import ReduxServices from 'common/redux'

function ManagerInfoLiquidity () {
  const { t } = useTranslation()
  const farmsAction = useFarmHook()
  const liquidityContext = useContext(LiquidityContext)
  const { dataPoolInfo, isLoadingDetailPool, liquidityToken } =
    liquidityContext
  const [dataPoolInfoDetail, setDataPoolInfoDetail] = useState({
    ...dataPoolInfo
  })

  const token0 = useMemo(() => {
    return get(liquidityToken, 'token0')
  }, [liquidityToken])

  const token1 = useMemo(() => {
    return get(liquidityToken, 'token1')
  }, [liquidityToken])

  const listTokenInfo = useMemo(() => {
    if (!dataPoolInfoDetail) return []
    const defaultList = [
      {
        ...get(dataPoolInfoDetail, 'token0'),
        type: 'token'
      },
      {
        ...get(dataPoolInfoDetail, 'token1'),
        type: 'token'
      },
      {
        symbol: 'LP Pool',
        type: 'pool',
        mintAddress: get(dataPoolInfoDetail, 'id')
      }
    ]

    if (get(dataPoolInfoDetail, 'poolFarm.poolAddress')) {
      return defaultList.concat({
        symbol: 'Farm',
        type: 'farm',
        mintAddress: get(dataPoolInfoDetail, 'poolFarm.poolAddress')
      })
    }
    return defaultList
  }, [dataPoolInfoDetail])

  const liquidityUsd = get(dataPoolInfoDetail, 'liquidityUsd', 0)
  const volume24h = get(dataPoolInfoDetail, 'volume24h', 0)
  const fee24h = get(dataPoolInfoDetail, 'fee24h', 0)
  const amount0InPoolWei = get(
    dataPoolInfoDetail,
    'token0Account.amount',
    '0'
  ).toString()
  const amount1InPoolWei = get(
    dataPoolInfoDetail,
    'token1Account.amount',
    '0'
  ).toString()
  const amount0InPool = convertWeiToBalance(
    amount0InPoolWei,
    get(token0, 'decimals')
  )
  const amount1InPool = convertWeiToBalance(
    amount1InPoolWei,
    get(token1, 'decimals')
  )
  const decimalsLp = get(dataPoolInfoDetail, 'lpInfo.decimals')
  const amountUserInPool = parseFloat(
    convertWeiToBalance(
      get(dataPoolInfoDetail, 'userInfo.amount', '').toString(),
      decimalsLp
    )
  )
  const totalAmountInPool = parseFloat(
    get(dataPoolInfoDetail, 'poolAccountInfo.supply', '').toString()
  )
  const priceLp = parseFloat(get(dataPoolInfoDetail, 'priceLp', 0))
  const shareOfPool = (amountUserInPool / totalAmountInPool) * 100
  const amountUserInPoolPrice = amountUserInPool * priceLp
  // farm
  const dataFarm = get(dataPoolInfoDetail, 'userInfo.dataFarm')
  const lpFarming = convertWeiToBalance(
    get(dataFarm, 'userInfo.amountUserStaked', 0),
    decimalsLp
  )

  const rewardEarn = farmsAction.calculateTotalEarn(
    get(dataFarm, 'rewards', [])
  )

  useEffect(() => {
    setDataPoolInfoDetail(dataPoolInfo)
  }, [dataPoolInfo])

  return (
    <div className={styles['manager-liquidity-container']}>
      {isLoadingDetailPool ? (
        <div className="empty-content text-base">
          <PuffLoading isCenter />
        </div>
      ) : (
        <div className="manager-liquidity-container__info-liquidity">
          <div
            // to={`/pool/${get(dataPoolInfoDetail, 'poolAddress')}`}
            className="info-token-liquidity"
          >
            <div className="info-liquidity-token mb-3">
              <TokenImage src={get(token0, 'icon')} className="img-24 mr-3" />
              <span className="color-normal mr-3 uppercase">
                {get(token0, 'symbol')}
              </span>{' '}
              /{' '}
              <TokenImage
                src={get(token1, 'icon')}
                className="img-24 mr-3 ml-3"
              />
              <span className="color-normal uppercase">
                {get(token1, 'symbol')}
              </span>
              <Tooltip
                className="ml-2 cursor-pointer"
                contentClassName="info-liquidity-tooltip"
                content={<InfoTokenEarn listTokenInfo={listTokenInfo} />}
              >
                <Icon
                  backgroundClassName="info-liquidity-icon"
                  name="web_info"
                  className="icon-info"
                />
              </Tooltip>
            </div>

            <div className="info-liquidity-value mb-5">
              <div className="item-liquidity-value">
                <div className="item-liquidity-value__value-number text-base color-normal">
                  {ReduxServices.formatPrice(liquidityUsd, true)}
                </div>

                <div className="item-liquidity-value__value-title text-sm">
                  {t('liquidity')}
                </div>
              </div>

              <div className="item-liquidity-value">
                <div className="item-liquidity-value__value-number text-base color-normal">
                  {ReduxServices.formatPrice(volume24h)}
                </div>

                <div className="item-liquidity-value__value-title text-sm">
                  {t('volume24h')}
                </div>
              </div>

              <div className="item-liquidity-value">
                <div className="item-liquidity-value__value-number text-base color-normal">
                  {/* {`$${formatNumberBro({ number: fee24h })}`} */}
                  {ReduxServices.formatPrice(fee24h, true)}
                </div>

                <div className="item-liquidity-value__value-title text-sm">
                  {t('fee24h')}
                </div>
              </div>
            </div>
          </div>

          <div className="info-liquidity-statistics mb-5">
            <BlockColor variant="pink" type="center">
              <div className="statistics-item-value">
                {shareOfPool
                  ? formatNumberBro({
                    number: shareOfPool,
                    mantissa: shareOfPool > 0.0001 ? 2 : 4
                  })
                  : 0}
                %
              </div>

              <div className="statistics-item-title text-sm">
                {t('yourPoolShare')}
              </div>
            </BlockColor>

            <BlockColor variant="blue" type="center">
              <div className="statistics-item-value">
                {/* ${formatNumberBro({ number: amountUserInPoolPrice })} */}
                {ReduxServices.formatPrice(amountUserInPoolPrice, true)}
              </div>

              <div className="statistics-item-title text-sm">
                {t('yourPoolToken')}
              </div>
            </BlockColor>

            <BlockColor variant="green" type="center">
              <div className="statistics-item-value">
                {nFormatter(amount0InPool)}
                <span className="ml-2 uppercase">{get(token0, 'symbol')}</span>
              </div>

              <div className="statistics-item-title text-sm">{t('pooled')}</div>
            </BlockColor>

            <BlockColor variant="purple" type="center">
              <div className="statistics-item-value">
                {nFormatter(amount1InPool)}
                <span className="ml-2 uppercase">{get(token1, 'symbol')}</span>
              </div>

              <div className="statistics-item-title text-sm">{t('pooled')}</div>
            </BlockColor>
          </div>

          {dataFarm && (
            <Link
              to={`/farm?poolAddress=${get(dataFarm, 'poolAddress')}`}
              className="info-liquidity-farming"
            >
              <div className="info-liquidity-farming__item">
                <div className="info-lp-value text-base color-normal">
                  {formatNumberBro({ number: lpFarming })}
                </div>

                <div className="info-lp-title text-sm">
                  {t('yourLpFarming')}
                </div>
              </div>

              <div className="info-liquidity-farming__item">
                <div className="info-lp-value text-base color-normal">
                  {ReduxServices.formatPrice(get(rewardEarn, 'totalRewardsUSD'))}
                </div>

                <div className="info-lp-title text-sm">{t('yourReward')}</div>
              </div>
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

export default ManagerInfoLiquidity
